import React, { useState, createContext, useEffect, useRef } from "react";
import tmdbApi from "../api/tmdbApi";
import SessionPopup from "../pages/SessionPopup";
import moment from "moment";
import axios from "axios";
export const contentContext = createContext();

function ChangeContentProvider(props) {
  const [menus, setMenus] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [data, setData] = useState([]);
  const [initialCategoriesData1, setInitialCategoriesData1] = useState([]);
  const [isLoad1, setIsLoad1] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [perPageConst, setPerPageConst] = useState(18);
  const [perpage, setPerpage] = useState(18);
  const [totalPages, setTotalPages] = useState(0);
  const [totalPagesArray, setTotalPagesArray] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [catBasedContentFields, setCatBasedContentFields] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [pay, setPay] = useState({});
  const [ActivePageName, setActivePageName] = useState("");
  const [activeTab, setActiveTab] = useState("details");
  const [navigateToggle, setNavigateToggle] = useState("");

  const [conversationFormData, setConversationFormData] = useState({});
  const [conversationData, setConversationData] = useState([]);
  const [allLeadsData, setAllLeadsData] = useState([]);
  const [leadData, setLeadData] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [tempLeadList, setTempLeadList] = useState([]);
  
  const [activeconversationItem, setActiveconversationItem] = useState([]);
  const [leadsFormData, setLeadsFormData] = useState({});
  const [addClientData, setAddClientData] = useState({});
  const [filterLeads, setFilterLeads] = useState([]);
  const [updateLead, setUpdateLead] = useState(false);
  const [saveLeadId, setSaveLeadId] = useState("");
  const [leadSearch, setLeadSearch] = useState("");
  const [clearIcon, setClearIcon] = useState(false);
  const [content, setContent] = useState([]);
  const [addComments, setAddComments] = useState("");
  const [activeSearch, setActiveSearch] = useState(false);
  const [flagg, setFlagg] = useState("");

  const [sortingPayload, setSortingPayload] = useState("");
  const [blockData, setBlockData] = useState("");
  const [ClientNameSearch, setClientNameSearch] = useState("");
  const getFormattedDate = (date) => {
    return date.toISOString().split("T")[0];
  };

  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 180);

  const formattedFromDate = getFormattedDate(thirtyDaysAgo);
  const formattedToDate = getFormattedDate(new Date());

  const [fromdate, setFromDate] = useState(formattedFromDate);
  const [todate, setToDate] = useState(formattedToDate);
  // const [fromdate, setFromDate] = useState(() => {
  //   // Set default value for fromdate (one month ago)
  //   const oneMonthAgo = new Date();
  //   oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
  //   return oneMonthAgo.toISOString().split('T')[0];
  // });

  // const [todate, setToDate] = useState(() => {
  //   // Set default value for todate (today)
  //   return new Date().toISOString().split('T')[0];
  // });

  const [assetTotal, setAssetTotal] = useState(0);
  const [hideMenu, setHideMenu] = useState(true);
  const [showSessionPopupup, setShowSessionPopupup] = useState(false);

  const [categoryName, setCategoryName] = useState([]);
  const [categoryNameAdv, setCategoryNameAdv] = useState([]);
  const [selectedOptionsClientName, setSelectedOptionsClientName] = useState(
    []
  );

  const [clientData1, setClientData1] = useState([]);
  const [userData, setUserData] = useState([]);
  const [activeMenuId, setActiveMenuId] = useState("");

  const [arrow, setArrow] = useState("0");

  const [clientsearch, setClientSearch] = useState("");
  const[contactSearch,setContactSearch]=useState("");
  const [recommendedsearch, setRecommendedSearch] = useState("");
  const [categorysearch, setCategorySearch] = useState("");
  const [dealsearch, setDealSearch] = useState("");
  const [clientmanagesearch, setClientMangeSearch] = useState("");
  const [companysearch, setCompanySearch] = useState("");
  const [usersearch, setUserSearch] = useState("");
  const [contentsearch, setContentSearch] = useState("");
  const [itemsearch, setItemSearch] = useState("");
  const [clientAdvSearch, setClientAdvSearch] = useState({});
  const [dealsadvSearch, setDealsAdvSearch] = useState({});
  const [companyadvSearch, setCompanyAdvSearch] = useState({});

  const [searchPayload, setSearchPayload] = useState({});
  const [contentAdvCount, setContentAdvCount] = useState("");
  const [importFlag, setImportFlag] = useState(false);
  const [bidsSearch, setBidsSearch] = useState("");
  const [bidsAdvSearch, setBidsAdvSearch] = useState({});
  const [contactussearch, setContactusSearch] = useState("");
  // const [countries, setCountries] = useState('');

  const [currentPage, setcurrentPage] = useState(1);

  const [paginationnumber, setPagintionNumber] = useState("");

  const [popup, setShowPopup] = useState(false);
  const [activeMenuObj, setActiveMenuObj] = useState({});
  const [isChecked1, setIsChecked1] = useState(false);
  const [multiSelectFields, setMultiSelectFields] = useState({});
  const [activeFieldsObj, setActiveFieldsObj] = useState({
    CookingshowActive: false,
    seriesActive: false,
    SportsActive: false,
    MusicActive: false,
    seriesActive: false,
  });
  const [selectedItems, setSelectedItems] = useState([])
  const [searchable, setSearchable] = useState(false);
  const [searchedFlag, setSearchedFlag] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [searchData, SetSearchData] = useState([]);
  const [currentPageNew, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
const[searchExport, setSearchExport]=useState(false);
  const [lookUpType, setlookUpType] = useState("");

  const [lookupsearch, setLookupSearch] = useState("");
  const [route, setRoute] = useState("");
    const [SubRoute, setSubRoute] = useState("");
    const [currentCPageNew, setCurrentCPage] = useState(1);
    const [rowsPerCPage, setRowsPerCPage] = useState(15);
  const prevRouteRef = useRef(null);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  const handleClosePopup = () => setShowPopup(false);

  function usePrevious(newRoute) {
    const ref = React.useRef();
    React.useEffect(() => {
      ref.current = newRoute;
    }, [newRoute]);
    return ref.current;
  }

  useEffect(() => {
    GetUserDataContext();
    userAgent();
  }, []);
  const userAgent = async () => {
    axios({
      method: "GET",
      url: "https://d4nv8o5tzs3mt.cloudfront.net/",
    }).then(function (response) {
      let locData = JSON.stringify(response?.data);
      localStorage.setItem("loc", locData);
    });
  };

  const GetUserDataContext = async () => {
    try {
      const response = await tmdbApi.getUserData({});

      if (response?.statusCode === 200) {
        setUserData(response?.result?.[0]);
        localStorage.setItem("ClientName", response?.result?.[0]?.name);
        localStorage.setItem("ClientType", response?.result?.[0]?.type);
        let userArr = response?.result?.[0]?.permissions;

        const obj = userArr.reduce((acc, item) => {
          if (item.submenus) acc[item.menu] = false;
          return acc;
        }, {});
      }
    } catch {
      console.log("error");
    }
  };

  const Categories = async () => {
    try {
      const response = await tmdbApi.getCategory({});
      console.log("response in content context ", response);
      if (
        response?.result == "Invalid token or Expired" &&
        response?.statusCode === 200
      ) {
        setShowSessionPopupup(true);
      } else if (response?.statusCode === 200) {
        let arr = [];
        let advArr = [];
        setCatBasedContentFields(
          response && response?.result && response?.result.data
        );
        response?.result.data.forEach((item) => {
          if (item.status === "ACTIVE") {
            arr.push(item.name);
          }
          advArr.push(item.name);
        });
        const arrOfObj = arr.map((item) => {
          return { value: item, label: item };
        });
        const arrOfObj2 = advArr.map((item) => {
          return { value: item, label: item };
        });
        setCategoryName(arrOfObj);
        setCategoryNameAdv(arrOfObj2);
        setIsLoading(false);
      }
    } catch {
      console.log("error");
    }
  };

  const GetTimeActivity = () => {
    localStorage.setItem(
      "timeActivity",
      moment().format("DD-MMM-YYYY HH:mm:ss")
    );
  };
  const GetClientDataFunction = async () => {
    try {
      const response = await tmdbApi.getUserData({});
      if (response?.statusCode === 200) {
        setClientData1(response?.result?.[0]);
        localStorage.setItem("signed", response?.result?.[0]?.signednda);
      }
    } catch {
      console.log("error");
    }
  };

  function sortTableAlpha(e, n) {
    var table,
      rows,
      switching,
      i,
      x,
      y,
      shouldSwitch,
      dir,
      switchcount = 0;
    table = document.getElementById("table");
    switching = true;
    dir = "asc";

    while (switching) {
      switching = false;
      rows = table.rows;

      for (i = 1; i < rows.length - 1; i++) {
        shouldSwitch = false;
        x = rows[i].getElementsByTagName("TD")[n];
        y = rows[i + 1].getElementsByTagName("TD")[n];

        if (dir == "asc") {
          if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
            shouldSwitch = true;
            setArrow(n);
            break;
          }
        } else if (dir == "desc") {
          if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
            shouldSwitch = true;
            setArrow(false);
            break;
          }
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        switchcount++;
      } else {
        if (switchcount == 0 && dir == "asc") {
          dir = "desc";
          switching = true;
        }
      }
    }
  }
  const processObject = (obj) => {
    Object.keys(obj).forEach((key) => {
      obj[key] = obj[key].trim();

      if (obj[key] === "" || obj[key].length <= 1) {
        delete obj[key];
      }
    });

    return obj;
  };

  function sortTableByDate(e, n) {
    var table,
      rows,
      switching,
      i,
      x,
      y,
      shouldSwitch,
      switchcount = 0,
      order = "asc";
    table = document.getElementById("table");
    switching = true;
    while (switching) {
      switching = false;
      rows = table.getElementsByTagName("tr");
      for (i = 1; i < rows.length - 1; i++) {
        shouldSwitch = false;
        x = rows[i].getElementsByTagName("td")[n];
        y = rows[i + 1].getElementsByTagName("td")[n];
        var dateX = new Date(x.innerHTML);
        var dateY = new Date(y.innerHTML);
        if (order == "asc") {
          if (dateX > dateY) {
            shouldSwitch = true;
            setArrow(n);
            break;
          }
        } else if (order == "desc") {
          if (dateX < dateY) {
            shouldSwitch = true;
            setArrow(false);
            break;
          }
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        switchcount++;
      } else {
        if (switchcount == 0 && order == "asc") {
          order = "desc";
          switching = true;
        }
      }
    }
  }

  return (
    <contentContext.Provider
      value={{
        popup,
        blockData,
        setBlockData,
        setShowPopup,
        handleClosePopup,
        setInitialCategoriesData1,
        initialData,
        Categories,
        categoryName,
        setCategoryName,
        categoryNameAdv,
        setCategoryNameAdv,
        setIsLoad1,
        searchable, setSearchable,
        isLoad1,
        searchExport, 
        setSearchExport,
        initialCategoriesData1,
        setHideMenu,
        hideMenu,
        searchData, SetSearchData,
        perPageConst,
        setPerPageConst,
        pageNumber,
        setPageNumber,
        assetTotal,
        setAssetTotal,
        perpage,
        setPerpage,
        totalPagesArray,
        setTotalPagesArray,
        totalPages,
        setTotalPages,
        menus,
        isLoading,
        setIsLoading,
        clientData1,
        sortTableAlpha,
        arrow,
        sortTableByDate,
        userData,
        selectedItems, setSelectedItems,
        isChecked1, setIsChecked1,
        setUserData,
        activeMenuObj,
        setActiveMenuObj,
        activeMenuId,
        setActiveMenuId,
        catBasedContentFields,
        setCatBasedContentFields,
        clientsearch,
        setClientSearch,
        contactSearch,setContactSearch,
        setRecommendedSearch,
        recommendedsearch,
        categorysearch,
        setCategorySearch,
        setDealSearch,
        dealsearch,
        clientmanagesearch,
        setClientMangeSearch,
        companysearch,
        setCompanySearch,
        usersearch,
        setUserSearch,
        contentsearch,
        setContentSearch,
        clientAdvSearch,
        setClientAdvSearch,
        dealsadvSearch,
        setDealsAdvSearch,
        companyadvSearch,
        setCompanyAdvSearch,
        searchPayload,
        setSearchPayload,
        contentAdvCount,
        setContentAdvCount,
        GetUserDataContext,
        currentPage,
        setcurrentPage,
        paginationnumber,
        setPagintionNumber,
        selectedOptions,
        setSelectedOptions,
        selectedOptionsClientName,
        multiSelectFields,
        setMultiSelectFields,
        activeFieldsObj,
        setActiveFieldsObj,
        setSelectedOptionsClientName,
        pay,
        setPay,
        toggleMenu,
        setToggleMenu,
        totalRows,
        setTotalRows,
        sortingPayload,
        setSortingPayload,
        ClientNameSearch,
        setClientNameSearch,
        fromdate,
        setFromDate,
        todate,
        setToDate,
        ActivePageName,
        setActivePageName,
        data,
        setData,
        rowsPerPage,
        addComments,
        activeSearch,
        setActiveSearch,
        setAddComments,
        leadSearch,
        setLeadSearch,
        clearIcon,
        setClearIcon,
        content,
        setContent,
        processObject,
        activeTab,
        saveLeadId,
        setSaveLeadId,
        leadsFormData,
        updateLead,
        setUpdateLead,
        setLeadsFormData,
        addClientData,
        setAddClientData,
        filterLeads,
        setFilterLeads,
        tempLeadList,
        activeconversationItem,
        setActiveconversationItem,
        setTempLeadList,
        
    
        isChecked,
        setIsChecked,
        leadData,
        setLeadData,
        conversationFormData,
        setConversationFormData,
        conversationData,
        flagg,
        setFlagg,
        setConversationData,
        allLeadsData,
        setAllLeadsData,
        navigateToggle,
        setNavigateToggle,
        setActiveTab,
        setRowsPerPage,
        currentPageNew,
        setCurrentPage,
        lookUpType,
        setlookUpType,
        lookupsearch,
        importFlag,
        setImportFlag,
        setLookupSearch,
        route,
        setRoute,
        prevRouteRef,
        usePrevious,
        sortedColumn,
        setSortedColumn,
        sortDirection,
        setSortDirection,
        setShowSessionPopupup,
        showSessionPopupup,
        searchedFlag,
        setSearchedFlag,
        GetTimeActivity,
        itemsearch, setItemSearch,bidsSearch, setBidsSearch, bidsAdvSearch, setBidsAdvSearch,currentCPageNew, setCurrentCPage,rowsPerCPage, setRowsPerCPage,SubRoute, setSubRoute,contactussearch, setContactusSearch
      }}
    >
      {props.children}
    </contentContext.Provider>
  );
}

export default ChangeContentProvider;
