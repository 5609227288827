/***
**Module Name: lookup
 **File Name :  lookup.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains lookup table details.
 ***/
import React, { useState, useEffect, useContext } from "react";


import Footer from "../../components/dashboard/footer";
import Header from "../../components/dashboard/header";
import Sidebar from "../../components/dashboard/sidebar";
import tmdbApi from "../../api/tmdbApi";
import moment from "moment";

import { useHistory, Link, useLocation } from "react-router-dom";
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Config from "../../constants/Config";
import TableLoader from "../../components/tableLoader";
import { contentContext } from "../../context/contentContext";
import SessionPopup from "../SessionPopup"
import DataTable from 'react-data-table-component';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

let { lambda, appname } = window.app;



const LookUp = () => {
    const history = useHistory();
    const { state } = useLocation();
    const { search } = state || {};
    const [lookup, setLookUp] = useState("");

    // const [data, setData] = useState([]);

    const [dummy, setDummy] = useState([]);


    // const [lookUpType, setlookUpType] = useState("");

    const [num, setNum] = useState();
    const [tableErrMsg, setTableErrMsg] = useState(false);



    const [flag, setFlag] = useState(false);
    // const [isLoading, setIsLoading] = useState(false);

    const [showSessionPopupup, setShowSessionPopupup] = useState(false);

    // const [route, setRoute] = useState("lookup");

    let count = 0;

    const {searchedFlag, setSearchedFlag, setActiveMenuObj, isLoading,setCategorySearch, setIsLoading,userData, sortTableAlpha, arrow,  setSelectedOptions,   data, setData,rowsPerPage, setRowsPerPage,currentPageNew, setCurrentPage,lookUpType, setlookUpType,lookupsearch, setLookupSearch,route, setRoute,usePrevious,sortedColumn, setSortedColumn,sortDirection, setSortDirection, setActiveMenuId ,GetTimeActivity} = useContext(contentContext);





    const validateObj = userData && userData.permissions && userData.permissions.length > 0 && userData.permissions.filter(eachItem => eachItem.menu == "Lookups")
    const subValDashboard = validateObj && validateObj[0] && validateObj[0].dashboard
 
         

     


    const prevRoute = usePrevious(route)
    const keyForRerender = `${rowsPerPage}_${data?.length}`;
    useEffect(() => {
        if(prevRoute != undefined && prevRoute!=route){
            setCurrentPage(1)
            setRowsPerPage(15)
            setLookupSearch("")
            setlookUpType("all-type")
        }
    }, [prevRoute]);
    useEffect(() => {
        if(rowsPerPage!=undefined){
            handlePerRowsChange(rowsPerPage)
        }
    }, [rowsPerPage]);
    const location = useLocation();

    const handlePageChange = (page) => {
        setCurrentPage(page);
      };
  
      const handlePerRowsChange = (newPerPage) => {
        setRowsPerPage(newPerPage);
      };
     
   
    const columns = [

        {
            name: 'Name',
            selector: row => row?.name ?? "",
            sortable: true,
        },
        {
            name: 'Type',
            selector: row => row?.type ?? "",
            sortable: true,
        },
        {
            name: 'Status',
            // cell: (props) => <img src={props.image} width={60} alt='Image' />,
            selector: row => row?.status ?? "",
            sortable: true,

        },

        {
            name: <>{(subValDashboard && subValDashboard.view && subValDashboard.view.display === true) || (subValDashboard && subValDashboard.edit && subValDashboard.edit.display === true) ? 'Actions' : null}</>,
            // cell: (props) => <button onClick={(e) => handleButtonClick(e, props)}>Action</button>,
            cell: (props) => 
            // {
                subValDashboard && subValDashboard.view && subValDashboard.edit && (subValDashboard.view.display === true || subValDashboard.edit.display === true) &&
                
                <div className="d-flex">
                    {subValDashboard && subValDashboard.view && subValDashboard.view.display === true &&

                        <a onClick={e => handleViewLookup(e, props.lookupcode)} className={`${subValDashboard && subValDashboard.view && subValDashboard.view.enable === false ? 'pe-none' : ''} text-success action-button`}><i className="mdi mdi-eye font-size-18"></i></a>}
                    {subValDashboard && subValDashboard.edit && subValDashboard.edit.display === true && 
                        <a onClick={e => handleEditLookup(e, props.lookupcode)} className={`${subValDashboard && subValDashboard.edit && subValDashboard.edit.enable === false ? 'pe-none' : ''} text-danger action-button`}><i className="mdi mdi-pencil font-size-18"></i></a>
                        
                        }
                </div>
            // }
            ,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];


    useEffect(() => {
        setRoute("lookup")
        setActiveMenuId(200009)
        setActiveMenuObj({
            "Client Management": false,
            "Contact Management":false,
            "Reports": false
        })
         if (!localStorage.getItem("token")) {
            history.push("/");
        }
        GetLookUp();
        
        setSelectedOptions([])
        userActivity()
    }, []);


    const userActivity = () => {
        let path = window.location.pathname.split("/");
        const pageName = path[path.length - 1];
        var presentTime = moment();
        let payload;

        payload = {
            "userid": localStorage.getItem("userId"),
            "pagename": pageName,
            "pageurl": window.location.href,
            "starttime": presentTime,
            "useragent": JSON.parse(localStorage.getItem("loc"))

        };


   const previousId = localStorage.getItem("previousid");
const urlLink = lambda + '/useractivity?appname=' + appname + (previousId ? "&previousid=" + previousId : "");


        axios({
            method: 'POST',
            url: urlLink,
            data: payload
        })
            .then(function (response) {
                if (response?.data?.statusCode === 200) {
                    localStorage.setItem("previousid", response?.data?.result)
                }
            });
    }

    const GetLookUp = async (e) => {
        GetTimeActivity();
        let payload = lookUpType === "all-type" || lookUpType.trim() === "" ? {} : { type: [lookUpType] }

        setIsLoading(true)
       
        const token = localStorage.getItem("token");
        const linkUrl = `${lambda}/lookups?appname=${appname}${lookupsearch.trim() ? `&search=${lookupsearch.trim()}` : ""}&token=${token}&userid=${localStorage.getItem("userId")}`;
        
        axios({
            method: 'POST',
            url: linkUrl,
            data: payload,
        })
            .then(function (response) {
                if (response?.data?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                  
                    setData(response?.data?.result?.data);
                    global.exportExcelData = response?.data?.result?.data
                      


                    setLookUp(response?.data?.result);
                    setDummy(response?.data?.result?.data);
                    setIsLoading(false)
                   
                
                }
            });
    }
    const GetLookUp2 = async (item) => {
        GetTimeActivity();
    
        let payload = item === "all-type" || item === "" ? {} : { type: [item] }
        setIsLoading(true)
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        const searchParam = lookupsearch ? `&search=${lookupsearch}` : "";
        const linkUrl = `${lambda}/lookups?appname=${appname}${searchParam}&token=${token}&userid=${userId}`;
        
        axios({
            method: 'POST',
            url: linkUrl,
            data: payload,
        })
            .then(function (response) {
                if (response?.data?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                  
                    setData(response?.data?.result?.data);
                    global.exportExcelData = response?.data?.result?.data
                      


                    setLookUp(response?.data?.result);
                    setDummy(response?.data?.result?.data);
                    setIsLoading(false)
                    setLookupSearch("");
                }
            });
    }

    const handleLookUpType = async (e) => {
        GetTimeActivity()
        setlookUpType(e.target.value);
        const value = e.target.value
        setCurrentPage(1)
        setRowsPerPage(15)
        GetLookUp2(value)

    }




    const handleSearch = (e, flagggg) => {
        GetTimeActivity()   
        if (lookupsearch === "") {
            GetLookUp();
        }
        else {
            const token = localStorage.getItem("token")
            setIsLoading(true)
            let lookupobj = lookUpType.length > 0 && lookUpType !== "all-type" ? lookUpType : "";
            axios.post(lambda + '/lookups?appname=' + appname + "&search=" + lookupsearch + "&token=" + token + "&userid=" + localStorage.getItem("userId"), {
                type: lookupobj ? [lookupobj] : ""
            })
                .then(function (response) {

                  
                   if (response?.data?.result == "Invalid token or Expired") {
                        setShowSessionPopupup(true)
                    } else {
                        setData(response?.data?.result?.data);
                        global.exportExcelData = response?.data?.result?.data
                      


                        setLookUp(response?.data?.result);
                        setIsLoading(false)
                    }
                });
        }
    }
 
    const handleChange = (e) => {
        GetTimeActivity()
        setLookupSearch(e.target.value);
    }

    const handleAddLookUp = (e) => {
        GetTimeActivity();
        history.push("/addlookup");
    }
    const handleEditLookup = (e, id) => {
        GetTimeActivity();
        history.push("/editlookup/" + id);
    }

    const handleViewLookup = (e, id) => {
        GetTimeActivity();
        history.push("/viewlookup/" + id);
    }

    const handleKeypress = (e) => {
        //it triggers by pressing the enter key
        GetTimeActivity();
        if ((e.key === "Enter")) {
            setTimeout(function () {
                // handleSearch();
                GetLookUp();
            }, 1000);
        }
    };

    const clearSearch = () => {
        setlookUpType("all-type")
        GetTimeActivity()
        setLookupSearch("");
      
        setCurrentPage(1);
        setRowsPerPage(15);
   
    }
    useEffect(() => {
        if (lookupsearch === "") {
            GetLookUp();
        }
    }, [lookupsearch])
    const customNoRecords = () => {
        return(
            
            <div className="empty-state-body empty-record"  >
                <div className="empty-state__message">
                    <span className="material-icons">summarize</span>
                    <p className="form-check font-size-16">No lookups were found for the searched keyword</p>
                </div> </div>
        )
    }
// console.log('data',data)
    const handleExport = () => {
       
        // let k = defaultData || exportExcelData
        const modifiedArray = global.exportExcelData.map((each,i) => ({
            "S.NO":i+1,
            NAME: each?.name ?? "",
            TYPE: each?.type ?? "",
            STATUS: each?.status ?? ""
        }));
        // console.log('reorderedArray', reorderedArray)
            exportToExcel(modifiedArray, "data");
    
        }
        function exportToExcel(jsonData, fileName) {
            delete jsonData['_id'];
            const worksheet = XLSX.utils.json_to_sheet(jsonData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
            //FileSaver.saveAs(data, `${fileName}.xlsx`);
            FileSaver.saveAs(data, `LookupsData.xlsx`);
        }
        const customSort = (rows, selector, direction) => {
            const sortedData = rows.slice().sort((rowA, rowB) => {
              // use the selector function to resolve your field names by passing the sort comparators
              // const aField = selector(rowA);
        const aField = String(selector(rowA)).toLowerCase();

              //  const bField = selector(rowB);
          const bField = String(selector(rowB)).toLowerCase();
          
              let comparison = 0;
          
              if (aField > bField) {
                comparison = 1;
              } else if (aField < bField) {
                comparison = -1;
              }
          
              return direction === 'desc' ? comparison * -1 : comparison;
            });
          
            // Log the sorted data to the console
            // setSortedRecomData(sortedData)
            global.exportExcelData = sortedData
            // console.log('Sorted Data------>:', sortedData);
          
            return sortedData;
          };    
         
    return (
        <>
            {showSessionPopupup && <SessionPopup />}
            <div id="layout-wrapper">
                <Header />
                <Sidebar />

                <div className="main-content user-management lookups">

                    <div className="page-content">
                        <div className="container-fluid">



                            <div className="row mb-4 breadcrumb">
                                <div className="col-lg-12">
                                    <div className="d-flex align-items-center">

                                        <div className="flex-grow-1">
                                            <h4 className="mb-2 card-title">lookups</h4>

                                        </div>
                                        {subValDashboard && subValDashboard.Add && subValDashboard.Add.display === true &&
                                            <div>
                                                <button onClick={handleAddLookUp} className="btn btn-primary" disabled={subValDashboard && subValDashboard.Add && subValDashboard.Add.enable === false}>add lookups</button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="row table-data">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row mb-2">
                                            
                                                <div className="col-sm-4 button-block d-flex">
                                                <div className="dataTables_length" id="datatable_length">
                                                                         <button className={`fill_btn ${data.length <= 0 ?'disable pe-none':''}`} onClick={handleExport}><span className="material-icons">north_east</span>Export</button>
                                                                     </div>
                                                    <select name="Type-search" value={lookUpType} id="dropdown" className="ms-2 custom-select custom-select-sm form-control form-control-sm form-select form-select-sm" onChange={e => handleLookUpType(e)}>
                                                        <option value="all-type">All Types</option>
                                                        <option value="certificate">Certificate</option>
                                                        <option value="country">Country</option>
                                                        <option value="cuisine">Cuisine</option>
                                                        <option value="genre">Genre</option>
                                                        <option value="language">Language</option>
                                                        <option value="musicgenre">Musicgenre</option>
                                                        <option value="rights">Rights</option>
                                                        <option value="resolution">Resolution</option>
                                                        <option value="sports">Sports</option>
                                                        <option value="subtitlelanguage">Subtitle Language</option>
                                                        <option value="territories">Territories</option>
                                                        <option value="videoformat">Video Format</option>
                                                    
                                                    </select>
                                                </div>
                                                <div className="col-sm-8">
                                                    <div className="search-box mb-2 d-inline-block">
                                                        <div className="position-relative">
                                                            <input type="text" className="form-control" value={lookupsearch} onChange={(e) => handleChange(e)} onKeyPress={handleKeypress} placeholder="Search by LookUp Name" />
                                                            <button className="fill_btn" onClick={(e) => GetLookUp(e)}><span className="material-icons search-icon">search</span></button>
                                                        </div>
                                                        <div className="dataTables_length" id="datatable_length">
                                                            <button className="fill_btn" onClick={clearSearch}><span className="material-icons-outlined">sync</span>Reset</button>
                                                        </div>
                                                     
                                                    </div>
                                                    <div className="text-sm-end">

                                                    </div>
                                                </div>
                                            </div>

                                          
                                              < DataTable  key={keyForRerender}
                                                // title=""
                                                
                                                columns={columns}
                                                // className="table align-middle table-nowrap table-check"
                                                keyField='_id'
                                                data={data}
                                                // onSort={handleSort}
                                                direction="auto"
                                                highlightOnHover
                                                // sortedColumn={sortedColumn}
                                                // sortDirection={sortDirection}
                                                fixedHeaderScrollHeight="300px"
                                                pagination
                                                responsive
                                                persistTableHead
                                                // selectableRowsVisibleOnly
                                                striped
                                                // selectableRowsHighlight
                                                // selectableRows
                                                subHeaderAlign="right"
                                                defaultSortField="name"
                                                subHeaderWra
                                                noDataComponent={customNoRecords()}
                                                paginationTotalRows={data.length}
                                                onChangeRowsPerPage={handlePerRowsChange}
                                                onChangePage={handlePageChange}
                                                paginationPerPage={rowsPerPage}
                                                sortFunction={customSort}

                                                paginationDefaultPage={currentPageNew}
                                                paginationRowsPerPageOptions={[15,25,50,75,100]}
                                                paginationComponentOptions={{
                                                    rowsPerPageText: 'Per page:',
                                                    rangeSeparatorText: 'of',
                                                    noRowsPerPage: false,
                                                    selectAllRowsItem: false,
                                                    selectAllRowsItemText: 'All',
                                                  }}
                                          
                                                progressPending={isLoading}
                                                progressComponent={<TableLoader />}
                                                  />
                                              
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <SweetAlert show={tableErrMsg}
                         custom
                         confirmBtnText="ok"
                         confirmBtnBsStyle="primary"
                         title={"Something went wrong. Please reload again."}
                         onConfirm={e => setTableErrMsg(false)}
                     >
                     </SweetAlert>
                    <Footer />
                </div>



            </div>
        </>
    );
};

export default LookUp;