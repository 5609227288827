/***
**Module Name: content dashboard
 **File Name :  contentmanage.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains content mamnagement details.
 ***/
 import React, { useState, useEffect, useContext } from "react";


 import Footer from "../../components/dashboard/footer";
 import Header from "../../components/dashboard/header";
 import Sidebar from "../../components/dashboard/sidebar";
 import tmdbApi from "../../api/tmdbApi";
 import { useHistory, Link, useLocation } from "react-router-dom";
 import axios from 'axios';
 import $ from "jquery";
 import SweetAlert from 'react-bootstrap-sweetalert';
 import Button from 'react-bootstrap/Button';
 import moment from "moment";
 import Modal from 'react-bootstrap/Modal';
 import TableLoader from "../../components/tableLoader";
 import SessionPopup from "../SessionPopup"
//  import AdvanceSearch from "./advanceSearch";
 import Loader from "../../components/loader";
 import Dropdown from 'react-bootstrap/Dropdown';
 import DataTable from 'react-data-table-component';
 import * as XLSX from 'xlsx';
 import * as FileSaver from 'file-saver';
 
 import { contentContext } from "../../context/contentContext";
 let { lambda, appname } = window.app
 
 
 
 
 
 const News = () => {
     const history = useHistory();
     const { state } = useLocation();
     const { search } = state || {};
     // const [toggle, setToggle] = useState(false);
     const [content, setContent] = useState("");
     // const [data, setData] = useState([]);
     const [success, setSuccess] = useState(false);
     const [isAlert, setIsAlert] = useState(false);
     const [isdelete, setDelete] = useState(false);
     const [item, setItem] = useState("");
     const [activeCheckId, setActiveCheckId] = useState("");
     const [isCompanyInclude, setIsCompanyInclude] = useState(false);
     const [exportLoad, setExportLoad] = useState(false);
     const [importLoad, setImportLoad] = useState(false);
 
 
     const [activeImportCheckId, setActiveImportCheckId] = useState("");
 
     const [exportFlag, setExportFlag] = useState(true);
     const [exportData, setExportData] = useState({});
     //  const [importFlag, setImportFlag] = useState(false);
     const [importData, setImportData] = useState({});
 
     const [currentPageImport, setCurrentPageImport] = useState(1);
     const [currentPageExport, setCurrentPageEport] = useState(1);
     const [rowsPerPageImport, setRowsPerPageImport] = useState(15);
     
     const [rowsPerPageExport, setRowsPerPageExport] = useState(15);
 
     const [image, setImg] = useState('');
     const [flag, setFlag] = useState(false);
 
     const [loaderDelete, setLoaderDelete] = useState(false);
     const [tableErrMsg, setTableErrMsg] = useState(false);
     const [btnDisable, setBtnDisable] = useState(false);
     const [showSessionPopupup, setShowSessionPopupup] = useState(false);
     const [activeExportBtn, setActiveExportBtn] = useState("");
     // const [sortingPayload, setSortingPayload] = useState("");
 
     const { searchedFlag, setSearchedFlag, sortTableAlpha, arrow, sortTableByDate, contentsearch, setContentSearch, searchPayload, setActiveMenuObj, setSearchPayload, currentPage, setSelectedOptions, setMultiSelectFields, setActiveFieldsObj, setSelectedOptionsClientName, data, setData, rowsPerPage, setRowsPerPage, currentPageNew, setCurrentPage, route, setRoute, usePrevious, isLoading, setIsLoading, setActiveMenuId, pay, setPay, totalRows, setTotalRows, sortingPayload, setSortingPayload, importFlag, setImportFlag } = useContext(contentContext)
 
     const { initialCategoriesData1, userData, setInitialCategoriesData1, popup, setShowPopup, handleClosePopup, GetTimeActivity } = useContext(contentContext);
 
     const validateObj = userData && userData.permissions && userData.permissions.length > 0 && userData.permissions.filter(eachItem => eachItem.menu == "News Management")
     const subValDashboard = validateObj && validateObj[0] && validateObj[0].dashboard
   
     const prevRoute = usePrevious(route)
     useEffect(() => {
         if (prevRoute != undefined && prevRoute != route) {
             console.log('use eefffect prevroute')
             setCurrentPage(1)
             setRowsPerPage(15)
             setImportFlag(false)
             setSelectedOptions([])
             setSortingPayload("")
             setContentSearch("")
             setPay({})
             setMultiSelectFields({ dubbinglanguages: [], typeofrights: [], countryorigin: [], languages: [], typeofrights: [], genre: [], videoquality: [], certificate: [], subtitleslanguages: [], territoriesavailable: [], sport: [], musicgenre: [] })
             setActiveFieldsObj({ CookingshowActive: false, seriesActive: false, SportsActive: false, LiveEventActive: false, MusicActive: false })
             setSelectedOptionsClientName([])
             setSearchPayload({})
             setSearchedFlag(false);
         }
     }, [prevRoute]);
     const keyForRerender = `${rowsPerPage}_${data?.length}`;
     useEffect(() => {
         if (!localStorage.getItem("token")) {
             history.push("/");
         }
         setActiveMenuId(200012)
         setActiveMenuObj({
            "Client Management": false,
            "Contact Management": false,
            "Reports": false
        })
         setRoute("content")
       
        news()
         userActivity();
     }, []);
     //  console.log('importFlag--->1111',importFlag)
     const cardWidth = $(".image-table").width();
     const userActivity = () => {
         let path = window.location.pathname.split("/");
         const pageName = path[path.length - 1];
         var presentTime = moment();
         let payload;
 
         payload = {
             "userid": localStorage.getItem("userId"),
             "pagename": pageName,
             "pageurl": window.location.href,
             "starttime": presentTime,
             "useragent": JSON.parse(localStorage.getItem("loc"))
 
         };
 
 
         const previousId = localStorage.getItem("previousid");
         const urlLink = lambda + '/useractivity?appname=' + appname + (previousId ? "&previousid=" + previousId : "");
 
 
         axios({
             method: 'POST',
             url: urlLink,
             data: payload
         })
             .then(function (response) {
                 if (response?.data?.statusCode === 200) {
                     localStorage.setItem("previousid", response?.data?.result)
                 }
             });
     }
     const handleSort = (column, sortDirection) => {
 
         // console.log('handle sort', column)
         // console.log('sort direction', sortDirection)
 
         let name = column?.name.toLowerCase();
        //  name = name === "released" ? "releaseyear" : name === "videos" ? "videoAvailable" : name
         let direction = sortDirection === "asc" ? "ascending" : "descending"
         let payload = { "type": direction, "sortBy": name }
         setSortingPayload(payload)
 
     };
   
 
 
 
     const handleEditContent = (e, id) => {
         // if( subValDashboard && subValDashboard.edit && subValDashboard.edit.enable === true){
         history.push("/editnews/" + id);
         // }
 
     }
 
     
     
   
     const columns = [
 
        {
            name: 'Image',
            cell: (props) => (
              <div className="imagetable">
                <img 
                  src={
                    typeof props.defaultimage === 'string' && props.defaultimage.trim() !== '' 
                      ? `${String(image)}${props.defaultimage}?auto=compress,format&width=21`
                      : image+"orasi/client/resources/orasiv1/images/newsDefaultwhite.png?auto=compress,format&width=21"
                  }
                  alt="Image"
                  
                />
              </div>
            ),
            sortable: false,
          },
          
          
           
           {
            name: 'Title',
            selector: row => row?.title?.toLowerCase() ?? "", // Ensure that titles are lowercased for consistent sorting
            cell: row => row?.title ?? "No Title", // Display a fallback if the title is undefined
            sortable: true,
            sortFunction: (a, b) => {
              const titleA = a.title?.toLowerCase() || ""; // Convert to lowercase for case-insensitive sorting
              const titleB = b.title?.toLowerCase() || "";
              return titleA.localeCompare(titleB); // Use localeCompare for proper string sorting
            },
          },
        
          {
            name: 'Created Date',
            selector: row => row.created,
            cell: row => new Date(row.created).toLocaleDateString('en-IN', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            }),
            sortable: true,
            sortFunction: (a, b) => new Date(a.created) - new Date(b.created),
          },
          
          {
            name: 'STATUS',
            selector: row => row?.status === "INPROGRESS" ? "IN PROGRESS" : row?.status,
            sortable: true,
          },
        
         
        
          {
             name: 'NO OF VIEWS',
             selector: row => row.viewedCount,
             sortable: true,
         },
         {
             name: <>{(subValDashboard && subValDashboard.view && subValDashboard.view.display === true) || (subValDashboard && subValDashboard.edit && subValDashboard.edit.display === true) ? 'Actions' : null}</>,
             cell: (props) =>
                 //  {
                 subValDashboard && subValDashboard.view && subValDashboard.edit && (subValDashboard.view.display === true || subValDashboard.edit.display === true) &&
 
                 <div className="d-flex" >
 
                    
 
                     {subValDashboard && subValDashboard.edit && subValDashboard.edit.display === true &&
 
                         <a  className={`${subValDashboard && subValDashboard.edit && subValDashboard.edit.enable === false ? 'pe-none' : ''} text-danger action-button`} onClick={e => handleEditContent(e, props.newsid
                            )}><i className="mdi mdi-pencil font-size-18" ></i></a>}
                 </div>
             //  }
             ,
             ignoreRowClick: true,
             allowOverflow: true,
             button: true,
         },
     ];
     
     const handlePageChange = (page) => {
        GetTimeActivity()
        setCurrentPage(page);
    };


     const news = async () => {
        const token = localStorage.getItem("token");
        const userid = localStorage.getItem("userId");
    
        try {
            setIsLoading(true);
            const response = await axios({
                method: 'post',
                url: `${lambda}/news`,
                params: { appname, token, userid }, // Use params for query parameters in GET requests
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            if (response.data.result === "Invalid token or Expired") {
                setShowSessionPopupup(true);
            } else {
                console.log(response);
                setData(response?.data?.result?.data)
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error fetching news:', error);
            setIsLoading(false); // Ensure this is called in case of an error
        } finally {
            setIsLoading(false); // Ensure this is called to stop loading spinner regardless of success or failure
        }
    };
    const handlePerRowsChange = (newPerPage) => {
        GetTimeActivity()
        setRowsPerPage(newPerPage);
    };
   
 
     useEffect(() => {
 
         if (window.site === undefined) {
             setTimeout(() => {
                 if (window.site && window.site.common && window.site.common.imagesCDN) {
                     setImg(window.site.common.imagesCDN)
                 }
             }, 1000);
         }
         if (window.site && window.site.common && window.site.common.imagesCDN) {
             setImg(window.site.common.imagesCDN)
 
         }
 
     }, [window.site]);
     // console.log('contentsearch', contentsearch)
     // console.log('search payload', searchPayload)
 
 
    
 
 
 
 
 
    
 
 
 
     
     //  console.log('searchPayload',searchPayload)
     //  console.log('pay',pay)
     const handleSearchNew = async () => {
         setIsLoading(true);
 
         // console.log('currentPageNew', currentPageNew)
         const token = localStorage.getItem("token")
         const userid = localStorage.getItem("userId")
         const linkUrl = `${lambda}/content?appname=${appname}&token=${token}&userid=${userid}&pageNumber=${1}&assetcount=${rowsPerPage}`
         let payload = {}
         if (contentsearch) {
             console.log("contentsearch", contentsearch);
             payload = { 'title': contentsearch }
         } else {
             payload = searchPayload
         }
         setFlag(true)
         console.log('payload', payload)
         axios({
             method: 'POST',
             url: linkUrl,
             data: payload
         })
             .then(function (response) {
                 if (response?.data?.result == "Invalid token or Expired") {
                     setShowSessionPopupup(true)
                     setIsLoading(false);
                 } else {
                     const dataArray = Object.values(response?.data?.result?.data).map((obj) => obj);
                     setData(dataArray)
                     setContent(response?.data?.result)
                     setIsLoading(false);
                     setCurrentPage(1)
                     setTotalRows(response?.data?.result?.totalContent)
                     setMultiSelectFields({ dubbinglanguages: [], typeofrights: [], countryorigin: [], languages: [], typeofrights: [], genre: [], videoquality: [], certificate: [], subtitleslanguages: [], territoriesavailable: [], sport: [], musicgenre: [] })
                     setActiveFieldsObj({ CookingshowActive: false, seriesActive: false, SportsActive: false, LiveEventActive: false, MusicActive: false })
                     setSelectedOptionsClientName([])
                     setSearchPayload({})
                     setPay({})
                     setSelectedOptions([])
                     setSearchedFlag(false);
 
                 }
             }).catch((err) => {
                 console.log('error', err);
                 setTableErrMsg(true)
                 setIsLoading(false);
             });
     };
     const GetContent = async (page) => {
         // console.log('get content function ')
         setIsLoading(true);
         const token = localStorage.getItem("token")
         const userid = localStorage.getItem("userId")
         const linkUrl = `${lambda}/content?appname=${appname}&token=${token}&userid=${userid}&pageNumber=${page}&assetcount=${rowsPerPage}`
 
         axios({
             method: 'POST',
             // url: lambda + '/content?appname=' + appname + "&token=" + token + "&userid=" + userid,
             url: linkUrl,
 
         })
             .then(function (response) {
                 if (response?.data?.result == "Invalid token or Expired") {
                     setShowSessionPopupup(true)
                     setIsLoading(false);
                 } else {
                     // setData(response?.data?.result?.data)
                     const dataArray = Object.values(response?.data?.result?.data).map((obj) => obj);
                     setData(dataArray)
                     setContent(response?.data?.result)
                     setIsLoading(false);
                     setTotalRows(response?.data?.result?.totalContent)
 
                     setSearchedFlag(false);
 
                 }
             }).catch((err) => {
                 console.log('error', err);
                 setTableErrMsg(true)
                 setIsLoading(false);
             });
     };
 
 
     const handleCompanyInclude = (e) => {
         // console.log('e.target.checked',e.target.checked)
         // console.log('e.target.name',e.target.name)
         if (e.target.checked === true) {
             setIsCompanyInclude(true)
         } else {
             setIsCompanyInclude(false)
         }
     }
 
 
 
   
     const clearSearch = () => {
         setSuccess(false);
         // GetContent(1);
 
 
         setPay({});
         setSelectedOptions([])
         setMultiSelectFields({ dubbinglanguages: [], typeofrights: [], countryorigin: [], languages: [], typeofrights: [], genre: [], videoquality: [], certificate: [], subtitleslanguages: [], territoriesavailable: [], sport: [], musicgenre: [] })
         setActiveFieldsObj({ CookingshowActive: false, seriesActive: false, SportsActive: false, LiveEventActive: false, MusicActive: false })
         setSelectedOptionsClientName([])
         setSearchPayload({})
         setSortingPayload("")
         setContentSearch("")
         setCurrentPage(1);
         //  GetContentNew(1)
         GetContent(1)
     }
 
   
 
 
 
     const handleKeypress = (e) => {
 
         if ((e.key === "Enter")) {
             setTimeout(function () {
                 handleSearchNew();
             }, 100);
         }
     };
     const customNoRecords = () => {
         return (
 
             <div className="empty-state-body empty-record"  >
                 <div className="empty-state__message">
                     <span className="material-icons">summarize</span>
                     <p className="form-check font-size-16">No content was found for the searched keyword</p>
                 </div> </div>
         )
     }
     function onConfirm() {
         setSuccess(false);
         setIsAlert(false)
         if (exportData.length <= 0) {
             setExportFlag((prevState) => !prevState);
         }
 
     };
     const handleUpload = () => {
         history.push("/contentupload");
     };
     const handleImportContent = () => {
         // setActiveExportBtn("")
         setImportFlag((prevState) => !prevState);
 
         if (exportFlag === true) {
             setExportData({})
         }
         if (importFlag === false) {
             if (exportFlag === false) {
                 setExportFlag(true)
             }
             fetchImportData();
 
         }
         if (importFlag === true) {
             setImportData({})
         }
 
     };
     const handleAddContent = () => {
         history.push("/addcontent");
     }
 const handleCreate=()=>{
    history.push("/addnews")
 }
     //  console.log('import flag',importFlag)
     //  console.log('export flag',exportFlag)
     const handletoggle = () => {
         console.log('handle toggle')
         setActiveExportBtn("")
         setExportFlag((prevState) => !prevState);
 
         if (exportFlag === true) {
             fetchExportData();
 
         }
         if (exportFlag === false) {
             setIsCompanyInclude(false)
             setExportData({})
         }
         console.log('import flag inside', importFlag)
         console.log('export flag inside', exportFlag)
     };
     const fetchImportData = (e, id) => {
         setImportLoad(true)
         const importid = id || '';
         const token = localStorage.getItem("token");
         const userid = localStorage.getItem("userId");
         const importidParam = importid ? `&importId=${importid}` : '';
 
         const linkUrl = `${lambda}/importReports?appname=${appname}&token=${token}&userid=${userid}${importidParam}`;
 
         axios({
             method: 'POST',
             url: linkUrl
         })
             .then(function (response) {
                 if (response) {
                     if (response?.data?.result == "Invalid token or Expired") {
                         setShowSessionPopupup(true)
                     } else {
                         if (importid) {
                             let rejectedList = response?.data?.result?.[0]?.result?.data?.[0]?.rejected
                             console.log('rejected list', rejectedList)
                             handlecheck(rejectedList, 'data')
                         } else {
                             setImportData(response?.data?.result);
                             // console.log("export data", response?.data?.result);
                         }
                     }
                     setImportLoad(false)
                     setActiveImportCheckId("")
                 }
                 setImportLoad(false)
                 setActiveImportCheckId("")
 
             }).catch(err => {
                 setActiveImportCheckId("")
                 console.log('err', err)
             });
     };
     //  console.log('aasdfadsfasdf')
     const fetchExportData = (e) => {
         setExportLoad(true)
 
         const token = localStorage.getItem("token")
         let userid = localStorage.getItem("userId");
         axios({
             method: 'POST',
             url: lambda + '/fetchExports?appname=' + appname + "&source=content" + "&token=" + token + "&userid=" + userid,
         })
             .then(function (response) {
                 if (response) {
                     if (response?.data?.result == "Invalid token or Expired") {
                         setShowSessionPopupup(true)
                     } else {
                         setExportData(response?.data?.result);
                         //  console.log("export data", response?.data?.result);
 
 
                     }
                     setExportLoad(false)
                 }
                 setExportLoad(false)
 
             });
     };
 
 
     function onCancel() {
         setDelete(false)
     }
 
 
     const handleDeleteExportFile = (e) => {
         let userid = localStorage.getItem("userId");
         const token = localStorage.getItem("token")
         const id = item
         setLoaderDelete(true)
         axios({
             method: 'DELETE',
             url: lambda + '/deleteExports?appname=' + appname + "&exportid=" + id + "&userid=" + userid + "&token=" + token,
         })
             .then(function (response) {
                 if (response) {
                     if (response?.result == "Invalid token or Expired") {
                         setShowSessionPopupup(true)
                     } else {
 
                         fetchExportData();
                         setSuccess(true)
                     }
                     setLoaderDelete(false)
                     setDelete(false)
 
                 }
             });
     }
 
 
 
     useEffect(() => {
         if (exportFlag === false && exportData && exportData.length > 0) {
 
 
             const interval = setInterval(() => {
                 fetchExportData();
 
             }, 10000);
             return () => clearInterval(interval);
         }
     }, [exportFlag, exportData]);
 
     const handlecheck = (data, fileName) => {
 
         console.log('data before', data)
         for (let key in data) {
             delete data[key]['_id']
             delete data[key]['created']
             delete data[key]['companyid']
             delete data[key]['status']
             delete data[key]['mediaType']
             delete data[key]['appname']
             delete data[key]['source']
             delete data[key]['importId']
 
 
             for (let item in data[key]) {
                 console.log("iteeeeee", typeof (data[key][item]));
                 if (typeof (data[key][item]) === "object") {
                     data[key][item] = data[key] && data[key][item] && data[key][item].length > 1 && data[key][item]?.join(', ');
                 } else {
                     data[key][item] = data[key][item];
                 }
 
             }
 
 
         }
         console.log('data after', data)
 
         exportToExcel(data, "data");
 
     }
     function exportToExcel(jsonData, fileName) {
         delete jsonData['_id'];
         const worksheet = XLSX.utils.json_to_sheet(jsonData);
         const workbook = XLSX.utils.book_new();
         XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
         const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
         const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
         //FileSaver.saveAs(data, `${fileName}.xlsx`);
         FileSaver.saveAs(data, `rejectedcontent.xlsx`);
     }
     useEffect(() => {
         if (importFlag === true && importData.length > 0) {
 
 
             const interval = setInterval(() => {
                 fetchImportData();
 
             }, 10000);
             return () => clearInterval(interval);
         }
     }, [importFlag, importData]);
     useEffect(() => {
         if (importFlag === true) {
             fetchImportData();
         }
     }, [importFlag]);
 
    //  const handleExport = (e, type) => {
    //      setActiveExportBtn(type)
    //      let userid = localStorage.getItem("userId");
    //      let obj = {};
 
    //      if (contentsearch) {
    //          obj = { "title": contentsearch }
    //      } else if (pay) {
    //          obj = { ...obj, ...pay }
    //      }
    //      if (isCompanyInclude) {
    //          obj = { ...obj, "companyname": true }
    //      }
    //      const token = localStorage.getItem("token")
 
    //      axios({
    //          method: 'POST',
    //          url: lambda + '/export?appname=' + appname + "&userid=" + userid + '&type=' + type + "&source=content" + "&token=" + token,
    //          data: obj
    //      })
    //          .then(function (response) {
    //              if (response?.data?.result == "No data found with the filterd values") {
    //                  setIsAlert(true)
    //              }
    //              else {
    //                  setBtnDisable(true)
    //                  fetchExportData();
    //              }
    //          });
    //      // }
    //  }
 
 
 
     return (
         <>
             {showSessionPopupup && <SessionPopup />}
             <div id="layout-wrapper">
                 <Header />
                 <Sidebar />
 
 
 
                 <div className={`main-content user-management content-management news_management ${exportFlag === false ?'export':""} ${importFlag === true ? 'import_content' : ''}`}>
 
                     <div className="page-content">
                         <div className="container-fluid">
 
 
 
                             <div className="row mb-4 breadcrumb">
                                 <div className="col-lg-12">
                                     <div className="d-flex align-items-center">
                                         <div className="flex-grow-1">
                                             <div className="title-block">
                                                 <h4 className="mb-2 card-title"> News management </h4>
                                                 <div className="dropdown d-flex">
 
                                                  
                                                         <button className="btn-primary" me-2 type="button" onClick={handleCreate} >
                                                             CREATE NEWS
 
                                                         </button>
                                                    
 
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                             </div>
 
                             <div className="row table-data">
                                 <div className="col-12">
                                     <div className="card">
                                         <div className="card-body">
                                             <div className="row mb-2">
                                                 
                                             <div className="col-sm-4">
                                             </div>
                                                
                                                     {/* <div className="col-sm-8">
                                                         <div className="search-box mb-2 d-inline-block">
                                                             <div className="position-relative">
                                                                 <input type="text" className="form-control" value={contentsearch} onChange={(e) => handleChange(e)} onKeyPress={handleKeypress} placeholder="Search Title" />
                                                                 <button className="fill_btn" onClick={(e) => handleSearchNew(1)}><span className="material-icons search-icon">search</span></button>
                                                             </div>
                                                            
                                                             <div className="dataTables_length" id="datatable_length">
                                                                 <button className="fill_btn" onClick={clearSearch}><span className="material-icons-outlined">sync</span>Reset</button>
                                                             </div>
 
                                                         </div>
                                                         <div className="text-sm-end">
 
                                                         </div>
                                                     </div>  */}
                                             </div>
 
                                            
                                              
                                                             
<>
< DataTable
                                                // title=""
                                                columns={columns}
                                                // className="table align-middle table-nowrap table-check"
                                                keyField='_id'
                                                data={data}
                                                // onSort={handleSort}
                                                direction="auto"
                                                highlightOnHover
                                            
                                                // sortedColumn={sortedColumn}
                                                // sortDirection={sortDirection}
                                                fixedHeaderScrollHeight="300px"
                                                pagination
                                                responsive
                                                persistTableHead
                                                // selectableRowsVisibleOnly
                                                striped
                                                // selectableRowsHighlight
                                                // selectableRows
                                                subHeaderAlign="right"
                                                defaultSortField="name"
                                                subHeaderWra
                                                noDataComponent={customNoRecords()}
                                                paginationTotalRows={data.length}
                                                onChangeRowsPerPage={handlePerRowsChange}
                                                onChangePage={handlePageChange}
                                                paginationPerPage={rowsPerPage}
                                                paginationDefaultPage={currentPageNew}

                                                paginationComponentOptions={{
                                                    rowsPerPageText: 'Per page:',
                                                    rangeSeparatorText: 'of',
                                                    noRowsPerPage: false,
                                                    selectAllRowsItem: false,
                                                    selectAllRowsItemText: 'All',
                                                }}

                                                progressPending={isLoading}
                                                progressComponent={<TableLoader />}
                                            />
 
 
                                                     </>
                                             
 
                                         </div>
                                     </div>
                                 </div>
                             </div>
 
 
 
                         </div>
 
                     </div>
                     <SweetAlert show={success}
                         custom
                         confirmBtnText="ok"
                         confirmBtnBsStyle="primary"
                         title={"Deleted SuccessFully"}
                         onConfirm={e => onConfirm()}
                     >
                     </SweetAlert>
                     <SweetAlert show={isAlert}
                         custom
                         confirmBtnText="ok"
                         confirmBtnBsStyle="primary"
                         title={"No content to export as all selected are inactive content"}
                         onConfirm={e => onConfirm()}
                     >
                     </SweetAlert>
                     <SweetAlert show={tableErrMsg}
                         custom
                         confirmBtnText="ok"
                         confirmBtnBsStyle="primary"
                         title={"Something went wrong. Please reload again."}
                         onConfirm={e => setTableErrMsg(false)}
                     >
                     </SweetAlert>
                     <Modal className="advance-search" show={popup} onHide={handleClosePopup} backdrop="static">
                         <Modal.Header closeButton>
                             <Modal.Title>Advanced Search</Modal.Title>
                         </Modal.Header>
                         <Modal.Body></Modal.Body>
 
                     </Modal>
                     <Modal className="access-denied" show={isdelete}>
 
                         <div className="modal-body enquiry-form">
                             <div className="container">
                                 <button className="close-btn" onClick={e => onCancel()}><span className="material-icons">close</span></button>
                                 <span className="material-icons access-denied-icon">delete_outline</span>
                                 <h3>Delete</h3>
                                 <p>This action cannot be undone.</p>
                                 <p>Are you sure you want to delete File?</p>
                                 <div className="popup-footer">
                                     <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => handleDeleteExportFile()}> {loaderDelete ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}Yes, Delete</button>
                                 </div>
                             </div>
                         </div>
 
                     </Modal>
                     <Footer />
                 </div>
 
 
 
             </div>
         </>
     );
 };
 
 export default News;