/***
 **Module Name: editCategory
 **File Name :  editCategory.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains editCategory details.
 ***/
import React, { useState, useEffect, useRef, useContext } from "react";
import Footer from "../../../components/dashboard/footer";
import Header from "../../../components/dashboard/header";
import Sidebar from "../../../components/dashboard/sidebar";
import { useHistory, Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import { useParams } from 'react-router-dom';
import SessionPopup from "../../SessionPopup"
import TableLoader from "../../../components/tableLoader";
import DataTable from 'react-data-table-component';
import moment from "moment";
import { contentContext } from "../../../context/contentContext";
import Loader from "../../../components/loader";
let { appname, lambda } = window.app;

const ViewTitleBids = () => {
    const history = useHistory();
    let { id } = useParams();
    const ref = useRef();
    const [add, setAdd] = useState(false);
    const [combinationData, setCombinationData] = useState({});
    const [itemDetails, setItemDetails] = useState([]);
    const [biddersData, setBiddersData] = useState([]);
    const [gropuData, setGroupData] = useState([]);
    const [popupData, setPopUpData] = useState({});
    const [heading, setHeading] = useState([]);
    const [image, setImg] = useState('');
    const [showSessionPopupup, setShowSessionPopupup] = useState(false);

    const [listPopup, setListPopup] = useState(false);
    const [dealPopup, setDealPopup] = useState(false);
    const [group, setGroup] = useState(false);

    const [dealObj, setDealObj] = useState({});
    const [popup, setPopup] = useState(false);
    const [reason, setReason] = useState("");
    const [dealsuccess, setDealSuccess] = useState(false);

    const [loaderEnable, setLoaderEnable] = useState(false);
    const [awarditem, setAwardItem] = useState({});
    const [revertitem, setRevertItem] = useState({});
    const [awardSuccess, setAwardSucces] = useState(false);
    const [revertSuccess, setRevertSucces] = useState(false);
    const [loader, setLoader] = useState(false);

    const { route, currentPageNew, setRoute, setCurrentPage,setActiveMenuObj, rowsPerPage, setRowsPerPage, isLoading, setIsLoading, usePrevious, setActiveMenuId } = useContext(contentContext);

    const prevRoute = usePrevious(route);
    // console.log("prevRoute",prevRoute)
    useEffect(() => {
        if (prevRoute != undefined && prevRoute != route) {
            setCurrentPage(1)
            setRowsPerPage(15)

        }

    }, [prevRoute]);
    console.log('prevRoute--->', prevRoute)
    console.log('currentRoute--->', route)
    // console.log('currentPageNew--->',currentPageNew)
    useEffect(() => {
        if (!localStorage.getItem("token")) {
            history.push("/");
        }

        setCombinationData(JSON.parse(localStorage.getItem("item")));

        setActiveMenuId(300021)
        setActiveMenuObj({
            "Contact Management": false,
            "Client Management": false,
            "Reports": false,
            "Auction Management":true
        })
        getItemDetails();

        getBidders();


    }, []);

    useEffect(() => {
        if (localStorage.getItem("flag")) {
            setRoute("bidsdashboard");
        } else {
            setRoute("bids");
        }

    }, [prevRoute]);


    useEffect(() => {

        if (window.site === undefined) {
            setTimeout(() => {
                if (window.site && window.site.common && window.site.common.imagesCDN) {
                    setImg(window.site.common.imagesCDN)
                }
            }, 1000);
        }
        if (window.site && window.site.common && window.site.common.imagesCDN) {
            setImg(window.site.common.imagesCDN)

        }

    }, [window.site]);



    const getItemDetails = (e) => {

        const token = localStorage.getItem("token")
        axios({
            method: 'GET',
            url: lambda + '/biddingItems?appname=' + appname + "&itemid=" + localStorage.getItem("itemid") + "&token=" + token,
        })
            .then(function (response) {
                if (response.data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    console.log(response)
                    const result = response.data.result.data[0];
                    setItemDetails(result);

                }
            });

    }

    const getBidders = (e) => {
        const token = localStorage.getItem("token")
        setIsLoading(true)
        let data = JSON.parse(localStorage.getItem("item"))
        console.log("sferger", data);
        axios({
            method: 'POST',
            url: lambda + '/getbids?appname=' + appname + "&token=" + token + "&userid=" + localStorage.getItem("userId") + "&itemcombinationid=" + id + "&isCombination=true",
        })
            .then(function (response) {
                if (response.data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    setBiddersData(response.data.result.data)
                    setHeading(response.data.result.data[0])
                    setIsLoading(false)


                }
            });

    }

    const handleBack = () => {
        console.log("clicked")
        if (prevRoute === "bids") {
            console.log("came")
            history.push("/bids");
            localStorage.removeItem("item")
            localStorage.removeItem("itemid")
        }
        if (prevRoute === "bidsdashboard") {
            console.log("clicked1")
            localStorage.removeItem("flag")
            history.push("/itembids/" + itemDetails.itemid);
            localStorage.removeItem("item")
            localStorage.removeItem("itemid")
        }


    }
    console.log("bidder", biddersData)

    const columns = [

        {
            name: 'company name',
            selector: row => row.companyname,
            sortable: true,
        },
        {
            name: 'client name',
            // cell: (props) =>  <div> {props && props.category && props.category.join(", ")}</div>,
            selector: row => row.clientname,
            sortable: true,
        },
        {
            name: 'bid price',
            cell: (props) =>
                props.bidprice
            ,

            sortable: true,
        },
        {
            name: 'avg bid price',
            //    cell: (props) =>   <div>{JSON.stringify(props.featured)}</div>,
            selector: row => row.averageprice,
            sortable: true,
        },
        {
            name: 'period',
            //    cell: (props) =>   <div>{JSON.stringify(props.featured)}</div>,
            selector: row => row.period,
            sortable: true,
        },
        {
            name: 'last bid date',
            selector: row => row.bidon ? new Date(row.bidon).toLocaleDateString('en-IN', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
            }) : null,
            sortable: true,
        },

        {
            name: 'overall deal',
            selector: (row) =>
                row.isgroup === true ? <div> {combinationData.status === "NON-EXCLUSIVE AWARDED" || combinationData.status === "EXCLUSIVE AWARDED" ? "" : <button className="yes-btn" type="submit" onClick={(e) => handleShowPop(e, row.clientid)}>yes<span className="material-icons-outlined"> chevron_right</span></button>}</div> : ""
            ,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: 'deal',
            cell: (props) =>
                //  {
                <div className="d-flex">
                    {(props.status === "WITHDRAWN" || props.status === "AWARDED" || props.status === "APPROVED" || combinationData.status === "EXCLUSIVE AWARDED" || combinationData.status === "NON-EXCLUSIVE AWARDED") ? <a className=" text-success action-button" style={{ opacity: "0.5", cursor: "initial" }}><i class="mdi mdi-gavel font-size-18"></i>
                    </a> : <a onClick={(e) => handleDeal(e, props)} className=" text-success action-button"><i class="mdi mdi-gavel font-size-18"></i>
                    </a>}
                </div>
            //  }
            ,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

   
    if (biddersData.some((item) => item.status === "AWARDED" || item.status === "APPROVED")) {
        console.log("came")
        columns.push({
            name: 'status',
            selector: (row, index) => row.status === "OUT BIDDED" ? "ACTIVE" : row.status,
            sortable: true,
        });
        columns.push({
            name: 'Action',
            cell: (props) =>
                //  {
                <div className="d-flex">
                   {(props.status != "ACTIVE") && (props.status != "OUT BIDDED") ? <>{<>{props.status != "AWARDED" && <a onClick={(e)=>handleShow(e,props)} className="text-success action-button"><i class="mdi mdi-trophy font-size-18"></i>
                    </a>}
                    <a onClick={(e)=>handleShowPopup(e,props)} className=" text-success action-button"><i class="mdi mdi-backspace font-size-18"></i>
                        </a></>
                    }</>:<><a className="text-success action-button" disabled style={{ opacity: "0.5", cursor: "initial" }}><i class="mdi mdi-trophy font-size-18"></i></a><a  className=" text-success action-button" disabled style={{ opacity: "0.5", cursor: "initial" }}><i class="mdi mdi-backspace font-size-18"></i>
                    
                    </a></>}
                </div>
            //  }
            ,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        });
    }

    const handleDeal = (e, dealobject) => {
        setDealObj(dealobject);
        setDealPopup(true)
    }
    const handleShowPop = (e, id) => {
        setListPopup(true);
        setLoader(true)
        let token = localStorage.getItem("token")
        axios({
            method: 'POST',
            url: lambda + '/getbids/?appname=' + appname + "&token=" + token + "&itemid=" + localStorage.getItem("itemid") + "&userid=" + localStorage.getItem("userId") + "&clientid=" + id,
        })
            .then(function (response) {
                console.log("response", response);
                if (response.data.result == "Invalid token or Expired") {
                    setLoader(false)
                    setShowSessionPopupup(true)
                } else {
                    setLoader(false)
                    //setData(response.data.result.data);
                    console.log(response, "''''''''''''''''''''''")
                    if (response.data.result.data) {
                        setGroupData(response.data.result.data);
                        setPopUpData(response.data.result.data[0])
                    }


                }

            });
    };
    const handleCancel = () => {
        setListPopup(false)
        setPopUpData({})
        setGroupData([])
    };
    const handleCloseDealPopup = () => {
        setReason("")
        setDealPopup(false)
    };
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerRowsChange = (newPerPage) => {
        setRowsPerPage(newPerPage);
    };
    const handleDealSubmit = (e) => {
        let url;
        const apiUrl = lambda + '/deal/';

        let country = dealObj.country != undefined ? dealObj.country : ""
        let region = dealObj.region != undefined ? dealObj.region : ""
        let typesofrights = dealObj.typesofrights != undefined ? dealObj.typesofrights : ""
        let revenuetype = dealObj.revenuetype != undefined ? dealObj.revenuetype : ""
        let exclusivity = dealObj.exclusivity != undefined ? dealObj.exclusivity : ""

        let token = localStorage.getItem("token")
        let queryParams;
        if (dealObj.isgroup === true && dealObj.itemcombinationid === undefined) {
            queryParams = {
                appname: appname,
                token: token,
                clientbidid: dealObj.clientbidid,
                userid: localStorage.getItem("userId"),
                typesofrights: typesofrights,
                revenuetype: revenuetype,
                exclusivity: exclusivity,
                minbidprice:dealObj.minbidprice,
                itemid:dealObj.itemid,
                title:dealObj.title,
                region: dealObj.bidname,
            };
        } else {
            queryParams = {
                appname: appname,
                token: token,
                combinationid: dealObj.itemcombinationid,
                userid: localStorage.getItem("userId"),
                isgroup: dealObj.isgroup,
                country: country,
                // region: region,
                typesofrights: typesofrights,
                revenuetype: revenuetype,
                exclusivity: exclusivity,
                title:dealObj.title,
                itemid:dealObj.itemid,
                minbidprice:dealObj.minbidprice
            };

        }

        const buildQueryString = (params) => {
            return Object.keys(params)
                .filter((key) => params[key] !== '')
                .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
                .join('&');
        };

       
        url = `${apiUrl}?${buildQueryString(queryParams)}`;
        let payload;
        if (dealObj.isgroup === true && dealObj.clientbidcombinationid === undefined) {
            payload = {
                "status": "APPROVED",
                "notes": reason,
                "clientbidid": dealObj.clientbidid,
                "clientid": dealObj.clientid,
                "clientname": dealObj.clientname,
                "companyid": dealObj.companyid,
                "companyname": dealObj.companyname,
                "isgroup": dealObj.isgroup,
                "bidprice": dealObj.bidprice,
                "period": dealObj.period,
            }

        } else if (dealObj.isgroup === false) {
            payload = {
                "status": "APPROVED",
                "notes": reason,
                "clientbidid": dealObj.clientbidid,
                "clientid": dealObj.clientid,
                "clientname": dealObj.clientname,
                "companyid": dealObj.companyid,
                "companyname": dealObj.companyname,
                "isgroup": dealObj.isgroup,
                "bidprice": dealObj.bidprice,
                "period": dealObj.period,
            }
        } else {
            payload = {
                "status": "APPROVED",
                "notes": reason,
                "clientbidid": dealObj.clientbidid,
                "clientid": dealObj.clientid,
                "clientname": dealObj.clientname,
                "companyid": dealObj.companyid,
                "companyname": dealObj.companyname,
                "clientbidcombinationid": dealObj.clientbidcombinationid,
                "deal": true,
                "isgroup": dealObj.isgroup,
                "bidprice": dealObj.bidprice,
                "period": dealObj.period,
            }
        }

        console.log("url", url)
        console.log("payload", payload)
        setLoaderEnable(true);
        axios({
            method: 'POST',
            url: url,
            data: payload
        })
            .then(function (response) {
                console.log("response", response);
                if (response.data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    setLoaderEnable(false);
                    setDealPopup(false);
                    setDealSuccess(true);
                    setReason("")

                }

            });
    }

    const onConfirm = () => {
        setDealSuccess(false);
        if (dealObj.isgroup === false) {
            if(dealObj.exclusivity === "NON-EXCLUSIVE"){
                getBidders();
            }else{
            localStorage.setItem("item", JSON.stringify(dealObj));
            localStorage.setItem("itemid", dealObj.itemid);
            history.push("/viewbidsummary/" + dealObj.itemcombinationid);
            }
        } else {
            if (prevRoute === "bids") {
                history.push("/bids");
            }
            else if (prevRoute === "bidsdashboard") {
                history.push("/itembids/" + dealObj.itemid);
            }
        }

    }
    const customNoRecords = () => {
        return (

            <div className="empty-state-body empty-record"  >
                <div className="empty-state__message">
                    <span className="material-icons">summarize</span>
                    <p className="form-check font-size-16">No content was found for the searched keyword</p>
                </div> </div>
        )
    }





    //  function onCancel() {
    //      setIsDelete(false)
    //  }

    let defaultImg = `${image}orasi/common/images/img-default.jpg`;
    let imgUrl = itemDetails && itemDetails.itemimage ? image + itemDetails.itemimage : defaultImg

    const isWithdrawRow = (row) => row.status === 'WITHDRAWN';

    const withdrawRowStyle = {
        backgroundColor: '#FFCCCB',
    };

    const conditionalRowStyles = [
        {
            when: isWithdrawRow,
            style: withdrawRowStyle,
        },
    ];

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        setReason("")
    }
    const handleShow = (e,item) => {
        setAwardItem(item)
        setShow(true);
    }

    const handleShowPopup = (e,item) => {
        setRevertItem(item)
        setPopup(true);
    }
    const handleClosePopup = () => {
        setPopup(false);
        setReason("")
    }
    console.log("award",awarditem);
    console.log("rever",revertitem);
    console.log("deal",dealObj);
    const onConfirm2 = () => {
        setAwardSucces(false);
        getBidders()
    }
    const onConfirm1 = () => {
        setRevertSucces(false);
        getBidders()
       // history.push("/viewtitlebids/" + awardHistory.combinationid);
    }

    const handleAward = () => {
        let url;


        const apiUrl = lambda + '/deal/';

        let country = combinationData.country != undefined ? combinationData.country : ""
        let region = combinationData.region != undefined ? combinationData.region : ""
        let typesofrights = combinationData.typesofrights != undefined ? combinationData.typesofrights : ""
        let revenuetype = combinationData.revenuetype != undefined ? combinationData.revenuetype : ""
        let exclusivity = combinationData.exclusivity != undefined ? combinationData.exclusivity : ""

        let token = localStorage.getItem("token")
        let queryParams;

        queryParams = {
            appname: appname,
            token: token,
            combinationid: combinationData.combinationid,
            userid: localStorage.getItem("userId"),
            country: country,
            isgroup: awarditem.isgroup,
            itemid:awarditem.itemid,
            region: region,
            typesofrights: typesofrights,
            revenuetype: revenuetype,
            exclusivity: exclusivity,
            title:combinationData.title,
            minbidprice:combinationData.minbidprice
        };
        const buildQueryString = (params) => {
            return Object.keys(params)
                .filter((key) => params[key] !== '')
                .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
                .join('&');
        };

        url = `${apiUrl}?${buildQueryString(queryParams)}`;
        let payload ;
        if(awarditem.isgroup === true){
            payload ={
                "status": "AWARDED",
                "notes": reason,
                "clientbidid": awarditem.clientbidid,
                "clientid": awarditem.clientid,
                "clientname": awarditem.clientname,
                "companyid": awarditem.companyid,
                "companyname": awarditem.companyname,
                "bidprice": awarditem.bidprice,
                "period": awarditem.period,
                "clientbidcombinationid":awarditem.clientbidcombinationid
            }
        }else{
            payload ={
                "status": "AWARDED",
                "notes": reason,
                "clientbidid": awarditem.clientbidid,
                "clientid": awarditem.clientid,
                "clientname": awarditem.clientname,
                "companyid": awarditem.companyid,
                "companyname": awarditem.companyname,
                "bidprice": awarditem.bidprice,
                "period": awarditem.period,
            }
        }
        
           console.log("pay",payload)
        
        setLoaderEnable(true);
        axios({
            method: 'POST',
            url: url,
            data: payload
        })
            .then(function (response) {
                console.log("response", response);
                if (response.data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    setLoaderEnable(false);
                    setShow(false);
                    setAwardSucces(true);
                    setReason("");
                }

            });

    }

    const handleReverse = () => {
        let url;
        const apiUrl = lambda + '/deal/';
        let country = combinationData.country != undefined ? combinationData.country : ""
        let typesofrights = combinationData.typesofrights != undefined ? combinationData.typesofrights : ""
        let revenuetype = combinationData.revenuetype != undefined ? combinationData.revenuetype : ""
        let exclusivity = combinationData.exclusivity != undefined ? combinationData.exclusivity : ""

        let token = localStorage.getItem("token")
        let queryParams;

        queryParams = {
            appname: appname,
            token: token,
            combinationid: combinationData.combinationid,
            userid: localStorage.getItem("userId"),
            country: country,
            // region: region,
            isgroup: revertitem.isgroup,
            typesofrights: typesofrights,
            revenuetype: revenuetype,
            exclusivity: exclusivity,
            itemid:awarditem.itemid,
            title:combinationData.title,
            minbidprice:combinationData.minbidprice
        };
        const buildQueryString = (params) => {
            return Object.keys(params)
                .filter((key) => params[key] !== '')
                .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
                .join('&');
        };

        url = `${apiUrl}?${buildQueryString(queryParams)}`;
        let payload;
        if(revertitem.isgroup === true){
            payload = {
                "status": "REVERTED",
                "notes": reason,
                "clientbidid": revertitem.clientbidid,
                "clientid": revertitem.clientid,
                "clientname": revertitem.clientname,
                "companyid": revertitem.companyid,
                "deal": false,
                "companyname": revertitem.companyname,
                "bidprice": revertitem.bidprice,
                "period": revertitem.period,
                "clientbidcombinationid":revertitem.clientbidcombinationid
               
            }
        }else{
            payload = {
                "status": "REVERTED",
                "notes": reason,
                "clientbidid": revertitem.clientbidid,
                "clientid": revertitem.clientid,
                "clientname": revertitem.clientname,
                "companyid": revertitem.companyid,
                "deal": false,
                "companyname": revertitem.companyname,
                "bidprice": revertitem.bidprice,
                "period": revertitem.period,
               
            }

        }
        
        setLoaderEnable(true);

        axios({
            method: 'POST',
            url: url,
            data: payload
        })
            .then(function (response) {
                console.log("response", response);
                if (response.data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    setLoaderEnable(false);
                    setPopup(false);
                    setRevertSucces(true)
                    setReason("");
                }

            });
    }
    const customSort = (rows, selector, direction) => {
        return rows.sort((a, b) => {
            // use the selector to resolve your field names by passing the sort comparators
            const aField = String(selector(a) || '').toLowerCase();
          const bField = String(selector(b) || '').toLowerCase();

            let comparison = 0;

            if (aField > bField) {
                comparison = 1;
            } else if (aField < bField) {
                comparison = -1;
            }

            return direction === 'desc' ? comparison * -1 : comparison;
        });
    };
    console.log("obj........", revertitem);

    return (
        <>
        <Modal className="advance-search search-popup create-combination-pop deal-pop-up" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>AWARD</Modal.Title>
                </Modal.Header>
                <Modal.Body> <div>

                    <div className="row">

                        <p>Do you want to the award the bid to <span>{awarditem.clientname}</span> ?</p>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="form-label form-label">reason</label>
                                <textarea id="verticalnav-address-input" name="followup" onChange={(e) => setReason(e.target.value)} value={reason} placeholder="Add Reason For Award" class="form-control" rows="2" spellCheck="false"></textarea>
                            </div>
                        </div>

                    </div>
                </div></Modal.Body>
                <Modal.Footer>
                    <div className="adv_footer">

                        <button className="fill_btn yellow-gradient reset-btn"
                            onClick={handleClose}
                        >cancel</button>

                        <button
                            onClick={(e) => handleAward(e)}
                            className={`fill_btn yellow-gradient float-end ${reason === "" ? "disable" : ""}`} disabled={reason === ""}
                        >
                            {loaderEnable ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}
                            award
                        </button>

                        <div className="clearfix"></div>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal className="advance-search search-popup create-combination-pop deal-pop-up" show={popup} onHide={handleClosePopup}>
                <Modal.Header closeButton>
                    <Modal.Title>Reverse</Modal.Title>
                </Modal.Header>
                <Modal.Body> <div>

                    <div className="row">

                        <p>Are you sure you want to reverse the bid ?</p>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="form-label form-label">reason</label>
                                <textarea id="verticalnav-address-input" name="followup" onChange={(e) => setReason(e.target.value)} value={reason} placeholder="Add Reason For Revert" class="form-control" rows="2" spellCheck="false"></textarea>
                            </div>
                        </div>

                    </div>
                </div></Modal.Body>
                <Modal.Footer>
                    <div className="adv_footer">

                        <button className="fill_btn yellow-gradient reset-btn"
                            onClick={handleClosePopup}
                        >cancel</button>

                        <button
                            onClick={(e) => handleReverse(e)}
                            className={`fill_btn yellow-gradient float-end ${reason === "" ? "disable" : ""}`} disabled={reason === ""}
                        >
                            {loaderEnable ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}
                            reverse
                        </button>

                        <div className="clearfix"></div>
                    </div>
                </Modal.Footer>
            </Modal>

            {showSessionPopupup && <SessionPopup />}
            {listPopup &&
                <Modal show={true} className="seller-pop new-look-up content_edit add-document listbids" >
                    <Modal.Header >
                        <Modal.Title>Overall Deal</Modal.Title>
                        <button className="close-btn" onClick={handleCancel}><span className="material-icons">close</span></button>
                    </Modal.Header>
                    <Modal.Body>
                        {loader && <Loader />}
                        {gropuData && gropuData.length > 0 &&
                            <><p><b>{popupData.clientname}</b> from <b>{popupData.companyname}</b> has bid for following countries</p><table className="table table-striped seller_table buyer_table align-middle">
                                <thead>
                                    <tr>

                                        <th>Country/Region</th>
                                        <th>Types of rights</th>
                                        <th>Revenue Type</th>
                                        <th>Exclusivity</th>
                                        <th>Min Price(USD)</th>
                                        <th>Price(USD)</th>
                                        <th>Avg Price(USD)</th>
                                        <th>Period</th>
                                        <th>Deal</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {gropuData && gropuData.length > 0 && gropuData.map((item, i) => {
                                        console.log("item", item.deal)
                                        return (
                                            <tr className={item.status === "WITHDRAWN" ? "withdrawrow" : ""}>
                                                <td>{item.bidname}</td>
                                                <td>{item.typesofrights}</td>
                                                <td>{item.revenuetype}</td>
                                                <td>{item.exclusivity}</td>
                                                <td>{item.minbidprice}</td>
                                                <td>{item.bidprice}</td>
                                                <td>{item.averageprice}</td>
                                                <td>{item.period}</td>
                                                <td>
                                                    {(item.deal === false || item.deal === undefined)  ? <div className="d-flex">
                                                        {item.status != "ACTIVE" ? <a className=" text-success action-button" style={{ opacity: "0.5", cursor: "initial" }}><i class="mdi mdi-gavel font-size-18"></i></a> : <a onClick={(e) => handleDeal(e, item)} className=" text-success action-button"><i class="mdi mdi-gavel font-size-18"></i></a>}
                                                    </div> : null}
                                                </td>
                                            </tr>

                                        );
                                    })}


                                </tbody>
                            </table></>}
                    </Modal.Body>
                </Modal>


            }
            <div id="layout-wrapper">
                <Header />
                <Sidebar />

                <div className="main-content create-user edit-content add-client edit-category bidders-list user-management">

                    <div className="page-content ">
                        <div className="container-fluid">



                            <div className="row mb-4 breadcrumb">
                                <div className="col-lg-12">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <h4 className="mb-2 card-title">{itemDetails && itemDetails.title}</h4>
                                        </div>
                                        <div><a className="btn btn-primary" onClick={handleBack}>back</a></div>
                                    </div>
                                </div>
                            </div>
                            <div className="create-user-block content_edit block_buyer">



                                <div className="row">
                                    <div className="col-md-3 pe-1">
                                        <div className="form-block">
                                            <div className="left_block">
                                                <img src={imgUrl + "?auto=compress,format&width=40"} />
                                                <h5>combinations</h5>
                                                {combinationData && combinationData.country && <div class="input-field">
                                                    <label for="example-text-input" class="col-form-label">country</label>
                                                    <p>{combinationData.country}</p>
                                                </div>}
                                                {combinationData && combinationData.region && <div class="input-field">
                                                    <label for="example-text-input" class="col-form-label">region</label>
                                                    <p>{combinationData && typeof (combinationData.region) === 'string'? combinationData.region : combinationData.region.join(", ") }</p>
                                                </div>}
                                                {combinationData && combinationData.typesofrights && <div class="input-field">
                                                    <label for="example-text-input" class="col-form-label">type of rights</label>
                                                    <p>{combinationData.typesofrights}</p>
                                                </div>}
                                                {combinationData && combinationData.revenuetype && <div class="input-field">
                                                    <label for="example-text-input" class="col-form-label">revenue type</label>
                                                    <p>{combinationData.revenuetype}</p>
                                                </div>}
                                                {combinationData && combinationData.exclusivity && <div class="input-field">
                                                    <label for="example-text-input" class="col-form-label">exclusivity</label>
                                                    <p>{combinationData.exclusivity}</p>
                                                </div>}
                                                <div class="input-field">
                                                    <label for="example-text-input" class="col-form-label">period</label>
                                                    <p>{combinationData && combinationData.period}</p>
                                                </div>
                                                <div class="input-field">
                                                    <label for="example-text-input" class="col-form-label">min bid price</label>
                                                    <p>{combinationData && combinationData.minbidprice}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-9 ps-1">
                                        <div className="form-block">

                                            <div className="col-md-12">
                                                <div className="mb-1">
                                                    <label className="form-label form-label">bidders</label>
                                                </div>
                                            </div>
                                            {biddersData.length > 15 ?
                                                < DataTable
                                                    // title=""
                                                    columns={columns}
                                                    // className="table align-middle table-nowrap table-check"
                                                    keyField='_id'
                                                    data={biddersData}
                                                    conditionalRowStyles={conditionalRowStyles}
                                                    direction="auto"
                                                    highlightOnHover
                                                    fixedHeaderScrollHeight="300px"
                                                    pagination
                                                    responsive
                                                    persistTableHead
                                                    // selectableRowsVisibleOnly
                                                    striped
                                                    // selectableRowsHighlight
                                                    // selectableRows
                                                    subHeaderAlign="right"
                                                    // defaultSortField="name"
                                                    sortFunction={customSort}
                                                    subHeaderWrap
                                                    noDataComponent={customNoRecords()}
                                                    paginationTotalRows={biddersData.length}
                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                    onChangePage={handlePageChange}
                                                    paginationPerPage={rowsPerPage}
                                                    paginationDefaultPage={currentPageNew}
                                                    paginationRowsPerPageOptions={[15, 25, 50, 75, 100]}
                                                    paginationComponentOptions={{
                                                        rowsPerPageText: 'Per page:',
                                                        rangeSeparatorText: 'of',
                                                        noRowsPerPage: false,
                                                        selectAllRowsItem: false,
                                                        selectAllRowsItemText: 'All',
                                                    }}
                                                    // sortFunction={customSort}
                                                    progressPending={isLoading}
                                                    progressComponent={<TableLoader />}
                                                /> :
                                                < DataTable
                                                    // title=""
                                                    columns={columns}
                                                    // className="table align-middle table-nowrap table-check"
                                                    keyField='_id'
                                                    data={biddersData}
                                                    conditionalRowStyles={conditionalRowStyles}
                                                    direction="auto"
                                                    highlightOnHover
                                                    fixedHeaderScrollHeight="300px"
                                                    //pagination
                                                    responsive
                                                    persistTableHead
                                                    // selectableRowsVisibleOnly
                                                    striped
                                                    // selectableRowsHighlight
                                                    // selectableRows
                                                    subHeaderAlign="right"
                                                    defaultSortField="name"
                                                    subHeaderWrap
                                                    noDataComponent={customNoRecords()}
                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                    onChangePage={handlePageChange}


                                                    // sortFunction={customSort}
                                                    progressPending={isLoading}
                                                    progressComponent={<TableLoader />}
                                                />
                                            }




                                        </div>
                                    </div>
                                </div>





                            </div>


                        </div>
                    </div>


                    <Footer />
                    <SweetAlert show={dealsuccess}
                        custom
                        confirmBtnText="ok"
                        confirmBtnBsStyle="primary"
                        title={"Deal Approved Successfully"}
                        onConfirm={e => onConfirm()}
                    >
                    </SweetAlert>
                    <SweetAlert show={awardSuccess}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Awarded Successfully"}
                            onConfirm={e => onConfirm2()}
                        >
                        </SweetAlert>
                        <SweetAlert show={revertSuccess}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Reverted Successfully"}
                            onConfirm={e => onConfirm1()}
                        >
                        </SweetAlert>
                    {dealPopup &&
                        <Modal className="advance-search search-popup create-combination-pop deal-pop-up" show={true} onHide={handleCloseDealPopup} backdrop="static">
                            <Modal.Header closeButton>
                                <Modal.Title>Deal</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div>

                                    <div className="row">
                                        <p>Do you want to the approve the bid to <span>{dealObj.clientname}</span> ?</p>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="form-label form-label">reason</label>
                                                <textarea id="verticalnav-address-input" name="followup" onChange={(e) => setReason(e.target.value)} value={reason} placeholder="Add Reason For Approval" class="form-control" rows="2" spellcheck="false"></textarea>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="adv_footer">

                                    <button className="fill_btn yellow-gradient reset-btn"
                                        onClick={handleCloseDealPopup}
                                    >cancel</button>

                                    <button
                                        onClick={(e) => handleDealSubmit(e)}
                                        className={`fill_btn yellow-gradient float-end ${reason === "" ? "disable" : ""}`} disabled={reason === ""}
                                    >
                                        {loaderEnable ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}
                                        accept
                                    </button>

                                    <div className="clearfix"></div>
                                </div>
                            </Modal.Footer>
                        </Modal>}
                </div>

            </div>
        </>
    );
};

export default ViewTitleBids;
