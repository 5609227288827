/***
**Module Name: viewlookup
 **File Name :  viewlookup.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains viewlookup u details.
 ***/
import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/dashboard/footer";
import Header from "../../../components/dashboard/header";
import Sidebar from "../../../components/dashboard/sidebar";
import { useHistory, Link } from "react-router-dom";
import { useParams } from 'react-router-dom'
import axios from 'axios';
import * as Config from "../../../constants/Config";
import SweetAlert from 'react-bootstrap-sweetalert';
import SessionPopup from "../../SessionPopup"
import { updateLookup, addLookup } from "../../../utils/reducer";
import { contentContext } from "../../../context/contentContext";
import Loader from "../../../components/loader";
let { lambda, country, appname } = window.app;

const ViewBiddingLookUp = () => {
    let { id } = useParams();
    const history = useHistory();
    const [editlookup, setEditLookUp] = useState({});
    const [success, setSuccess] = useState(false);
    const [add, setAdd] = useState(false);
    const [lookupErrors, setLookupErrors] = useState({});
    const [showSessionPopupup, setShowSessionPopupup] = useState(false);
    const [invalidContent, setInvalidContent] = useState(false);


    const { route, setRoute, setCurrentPage, setRowsPerPage,setActiveMenuObj, usePrevious, setActiveMenuId } = useContext(contentContext);

    useEffect(() => {
        setRoute("biddingLookup")
        setActiveMenuId(300023)
        setActiveMenuObj({
            "Contact Management": false,
            "Client Management": false,
            "Reports": false,
            "Auction Management":true
        })
        // setActiveMenuId(200009)
        if (!localStorage.getItem("token")) {
            history.push("/");
        }

        getLookupData();


    }, []);

    const prevRoute = usePrevious(route)
    useEffect(() => {
        if (prevRoute != undefined && prevRoute != route) {
            setCurrentPage(1)
            setRowsPerPage(15)
        }
    }, [route]);
    // console.log('currentRoute--->',route)
    const handleBack = (e) => {
        if (add === false) {
            history.push({
                pathname: "/biddinglookups",
                state: { search: true }
            });
        } else {
            history.push("/biddinglookups")
        }
    }
    const getLookupData = (e) => {
        const token = localStorage.getItem("token")
        axios({
            method: 'POST',
            url: lambda + '/biddingLookups?appname=' + appname + '&lookupcode=' + id + "&token=" + token + "&userid=" + localStorage.getItem("userId"),
        })
            .then(function (response) {
                console.log("res", response)
                if (response.data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    if (response.data.result.data.length > 0) {
                        let result = response.data.result.data[0];
                        setEditLookUp({ ...result });
                    } else {
                        setInvalidContent(true)
                    }
                }
            });
    }

    const onclickInvalid = () => {
        setInvalidContent(false)
        history.push('/biddinglookups')
    }






    console.log(editlookup)

    return (
        <>
            {showSessionPopupup && <SessionPopup />}
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <SweetAlert show={invalidContent}
                    custom
                    confirmBtnText="ok"
                    confirmBtnBsStyle="primary"
                    title={"Lookups Not Found"}
                    onConfirm={e => onclickInvalid()}
                >
                </SweetAlert>
                {!invalidContent &&
                    <div className="main-content user-management create-user edit-lookups">

                        <div className="page-content ">
                            <div className="container-fluid">

                                <div className="row mb-4 breadcrumb">
                                    <div className="col-lg-12">
                                        <div className="d-flex align-items-center">

                                            <div className="flex-grow-1">
                                                <h4 className="mb-2 card-title">VIEW Bidding lookups</h4>
                                                <p className="menu-path">Bidding Lookups / <b>View bidding Lookups</b></p>
                                            </div>
                                            <div>
                                                <a onClick={handleBack} className="btn btn-primary">back</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {Object.keys(editlookup).length > 0 || add === true ?
                                    <>
                                        <div className="create-user-block">
                                            <div className="form-block">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="col-md-12">

                                                            <div className="mb-3 input-field">
                                                                <label className="form-label form-label">NAME <span className="required">*</span></label>
                                                                <input id="email" name="name" type="text" placeholder="Name" className="form-control form-control" aria-invalid="false" value={editlookup.name} disabled />

                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="mb-3 input-field">
                                                                <label className="form-label form-label">TYPE<span className="required">*</span></label>
                                                                <select name="type" className="colorselect capitalize form-control" value={editlookup.type} disabled >
                                                                    <option value="">Select Type</option>
                                                                    <option value="country">Country</option>
                                                                    <option value="exclusivity">Exclusivity</option>
                                                                    <option value="region">Region</option>
                                                                    <option value="revenue">Revenue</option>
                                                                    <option value="rights">Rights</option>
                                                                </select>



                                                            </div>
                                                        </div>
                                                       
                                                        {editlookup.type === "country" &&
                                                            <div className="col-md-12">
                                                                <div className="mb-3 input-field">
                                                                    <label className="form-label form-label">Region</label>
                                                                    <input id="region" name="region" type="text" className="form-control form-control" aria-invalid="false" value={editlookup?.region?.join(', ')} disabled />

                                                                </div>
                                                            </div>
                                                        }
                                                        {editlookup.type === "region" &&
                                                            <div className="col-md-12">
                                                                <div className="mb-3 input-field">
                                                                    <label className="form-label form-label">Country</label>

                                                                    <input id="country" name="country" type="text" className="form-control form-control" aria-invalid="false"value={editlookup.country?.join(', ')} disabled />

                                                                </div>
                                                            </div>
                                                        }

                                                        {(editlookup.type === "rights" && editlookup.revenue?.length > 0) &&
                                                            <div className="col-md-12">
                                                                <div className="mb-3 input-field">
                                                                <label className="form-label form-label">Revenue</label>
                                                                <input id="country" name="country" type="text" className="form-control form-control" aria-invalid="false" value={editlookup.revenue?.toString()} disabled />


                                                                </div>
                                                            </div>
                                                        }
                                                         {(editlookup.type === "revenue" && editlookup.rights?.length > 0) &&
                                                            <div className="col-md-12">
                                                                <div className="mb-3 input-field">
                                                                <label className="form-label form-label">Rights</label>
                                                                <input id="country" name="country" type="text" className="form-control form-control" aria-invalid="false" value={editlookup.rights?.toString()} disabled />


                                                                </div>
                                                            </div>
                                                        }


                                                        <div className="col-md-12">
                                                            <div className="mb-3 input-field">
                                                                <label className="form-label form-label">status<span className="required">*</span></label>
                                                                {add === true ?
                                                                    <select name="status" className="colorselect capitalize form-control" value={editlookup.status} disabled>

                                                                        <option value="">Select status</option>
                                                                        <option value="ACTIVE">ACTIVE</option>
                                                                        <option value="INACTIVE">INACTIVE</option>
                                                                    </select> :
                                                                    <select name="status" className="colorselect capitalize form-control" value={editlookup.status} disabled>

                                                                        <option value="ACTIVE">ACTIVE</option>
                                                                        <option value="INACTIVE">INACTIVE</option>
                                                                    </select>
                                                                }
                                                                {lookupErrors.status && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{lookupErrors.status}</span>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">

                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </>
                                    : <div className="create-user-block"><div className="form-block">
                                        <div className="tab-content p-3 text-muted">
                                            <div className="tab-pane active show" id="home1" role="tabpanel">
                                                <div className="row"><Loader /></div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                }


                            </div>
                        </div>



                        <Footer />
                    </div>
                }

            </div>

        </>
    );
};

export default ViewBiddingLookUp;
