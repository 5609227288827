/***
**Module Name: Company details
 **File Name :  Company.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains compant tables details.
 ***/
import React, { useState, useEffect, useContext } from "react";


import Footer from "../../../components/dashboard/footer";
import Header from "../../../components/dashboard/header";
import Sidebar from "../../../components/dashboard/sidebar";
import tmdbApi from "../../../api/tmdbApi";
import SweetAlert from 'react-bootstrap-sweetalert';
import SessionPopup from "../../SessionPopup";
import { useHistory, Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import moment from "moment";
import axios from 'axios';
import { contentContext } from "../../../context/contentContext";
import TableLoader from "../../../components/tableLoader";

let { lambda, appname } = window.app;



const ViewClientActivity = (props) => {
    let { id } = useParams();
    const history = useHistory();
    const [add, setAdd] = useState(false);
    const [clientActivityData, setClientActivityData] = useState([]);
    const [invalidContent, setInvalidContent] = useState(false);
    const [showSessionPopupup, setShowSessionPopupup] = useState(false);
    const [BtnLoader, SetBtnLoader] = useState(false);

    const { route, setRoute, setCurrentPage, setRowsPerPage, usePrevious, setActiveMenuId, setActiveMenuObj, GetTimeActivity } = useContext(contentContext);

    const prevRoute = usePrevious(route)
    useEffect(() => {
        if (prevRoute != undefined && prevRoute != route) {
            setCurrentPage(1)
            setRowsPerPage(15)
        }
    }, [prevRoute]);
    const onclickInvalid = () => {
        setInvalidContent(false)
        history.push('/lookups')
    }

    useEffect(() => {
        if (!localStorage.getItem("token")) {
            history.push("/");
        }
        setRoute("clientactivity")
        setActiveMenuId(300058)
        setActiveMenuObj({
            "Client Management": false,
            "Contact Management": false,
            "Reports": true
        })
        // setclientActivityData(props.data)
        if (id) {
            setAdd(false);
            getClientActivityData();

        } else {
            setAdd(true);
        }
    }, []);

    const getClientActivityData = (e) => {
        SetBtnLoader(true)
        const token = localStorage.getItem("token")
        axios({
            method: 'POST',
            url: lambda + '/clientActivity?appname=' + appname + '&activityid=' + id + "&token=" + token + "&userid=" + localStorage.getItem("userId"),
        })
            .then(function (response) {
                console.log("res", response)
                if (response?.data?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    console.log('response?.data?.result?.[0]', response?.data?.result?.[0])
                    if (response?.data?.result?.[0]) {
                        setClientActivityData([response?.data?.result?.[0]]);
                    } else {
                        setInvalidContent(true)
                    }
                }
                SetBtnLoader(false)
            }).catch((err)=>{
                SetBtnLoader(false)
                console.log('error',err)
            });
    }
    console.log("btnnnn--------->", BtnLoader);
    // console.log("id--------->", id);

    const handleBack = (e) => {
        // props.setClick(false);
        history.push("/clientActivity")
    }

    return (
        <>
            {showSessionPopupup && <SessionPopup />}
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <SweetAlert show={invalidContent}
                    custom
                    confirmBtnText="ok"
                    confirmBtnBsStyle="primary"
                    title={"Client Activity Not Found"}
                    onConfirm={e => onclickInvalid()}
                >
                </SweetAlert>
                {!invalidContent &&
                    <div className="main-content user-management viwe-client-search view_email_logs view_client_activity_report">

                       <div className="page-content">
                            <div className="container-fluid">



                                <div className="row mb-4 breadcrumb">
                                    <div className="col-lg-12">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1">
                                                <h4 className="mb-2 card-title">View Client Activity Report</h4>

                                            </div>
                                            <div>
                                                <a onClick={handleBack} className="btn btn-primary">back</a>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                               
                                <div className="row table-data">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                            {!BtnLoader ? <>
                                                <div className="row mb-2">
                                                    <div className="col-sm-12">
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            {/* <p className="menu-path">Client Name<b>{searchData.clientname}</b></p>
                                                 <p className="lg-badge badge">{type ? type : searchData.type}</p> */}
                                                        </div>
                                                    </div>

                                                    {/* <div className="col-sm-6">
                                                    
                                                 </div> */}
                                                </div>

                                                <div className="table-responsive">
                                                    <table className="table align-middle table-nowrap table-check">

                                                        <tbody>
                                                            {clientActivityData?.[0]?.pagename && <tr>
                                                                <td className="align-middle">Page Name</td>
                                                                <td className="align-middle">{clientActivityData?.[0]?.pagename}</td>
                                                            </tr>}
                                                            {clientActivityData?.[0]?.pageurl && <tr>
                                                                <td className="align-middle">Page Url</td>
                                                                <td className="align-middle"><a href={clientActivityData?.[0]?.pageurl}>{clientActivityData?.[0]?.pageurl}</a></td>
                                                            </tr>
                                                            }
                                                            {/* {clientActivityData?.[0]?.clientData?.[0]?.name && */}
                                                                <tr>
                                                                    <td className="align-middle">Client Name</td>
                                                                    <td className="align-middle">{clientActivityData?.[0]?.clientData?.[0]?.name ?? "Anonymous"}</td>
                                                                </tr>
                                                            {/* } */}
                                                            {/* {clientActivityData?.[0]?.clientData?.[0]?.companyname?.[0] && */}
                                                                <tr>
                                                                    <td className="align-middle">Company Name</td>
                                                                    <td className="align-middle">{clientActivityData?.[0]?.clientData?.[0]?.companyname?.[0] ?? "Anonymous"}</td>

                                                                </tr>
                                                            {/* } */}

                                                            {clientActivityData?.[0]?.starttime &&
                                                                <tr>
                                                                    <td className="align-middle">Start Time</td>
                                                                    <td className="align-middle">{clientActivityData?.[0]?.starttime ? new Date(clientActivityData?.[0]?.starttime).toLocaleDateString('en-IN', {
                                                                        day: 'numeric',
                                                                        month: 'short',
                                                                        year: 'numeric',
                                                                        hour: 'numeric',
                                                                        minute: 'numeric',
                                                                    }) : ""}</td>
                                                                </tr>
                                                            }

                                                            {clientActivityData?.[0]?.useragent !== undefined &&

                                                                Object.entries(clientActivityData?.[0]?.useragent?.headers).map(([key, values]) => (
                                                                    <tr key={key}>
                                                                        <td className="align-middle">{key}</td>
                                                                        <td className="align-middle">{values[0].value}</td>
                                                                    </tr>
                                                                ))


                                                            }
                                                        </tbody>

                                                    </table>
                                                </div>
                                                </>   :  <div className="row"><TableLoader /></div>  }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div> 
                       




                    </div>}
            </div>
        </>
    );
};

export default ViewClientActivity;
