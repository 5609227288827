/***
**Module Name: contact us 
 **File Name :  contactus.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains contact us table details.
 ***/
 import React, { useState, useEffect, useContext } from "react";
 import Footer from "../../../components/dashboard/footer";
 import Header from "../../../components/dashboard/header";
 import Sidebar from "../../../components/dashboard/sidebar";
 import tmdbApi from "../../../api/tmdbApi";
 import { useHistory, Link } from "react-router-dom";
 import SweetAlert from 'react-bootstrap-sweetalert';
 import axios from 'axios';
 import moment from "moment";
 import ViewContactList from "../contactus/viewContactus";
 import Loader from "../../../components/loader";
 import { contentContext } from "../../../context/contentContext";
 import TableLoader from "../../../components/tableLoader";
 import SessionPopup from "../../SessionPopup"
 import DataTable from 'react-data-table-component';
 import * as XLSX from 'xlsx';
 import * as FileSaver from 'file-saver';
 
 let { appname, lambda } = window.app;
 
 
 const Contact = () => {
     const history = useHistory();
     // const [toggle, setToggle] = useState(false);
     const [content, setContent] = useState("");
     const [perpage, setPerpage] = useState(10);
 
     const [tableErrMsg, setTableErrMsg] = useState(false);

    //  const [data, setData] = useState([]);
 


     const [sortDirection, setSortDirection] = useState('asc');
    const [arrowdir, setArrowDir] = useState('down');
    const [num, setNum]= useState();
 
     //const [search, setSearch] = useState("");
     const [viewContact, setViewContact] = useState({});
     const [click, setClick] = useState(false);
     const [contactussearch, setContactusSearch] = useState("");
       

    //  const [isLoading, setIsLoading] = useState(false);
     const [showSessionPopupup, setShowSessionPopupup] = useState(false);
     const { isLoading, setIsLoading,sortTableAlpha , arrow,sortTableByDate,userData ,data, setData,rowsPerPage, setRowsPerPage,currentPageNew, setCurrentPage,route, setRoute,usePrevious,setActiveMenuObj,setActiveMenuId, GetTimeActivity} = useContext(contentContext)
 

     const validateObj = userData && userData.permissions && userData.permissions.length > 0 && userData.permissions.filter(eachItem=>eachItem.menu == "Reports")
     const subValDashboard = validateObj && validateObj[0] && validateObj[0].submenus && validateObj[0].submenus[2] && validateObj[0].submenus[2].dashboard
    //  console.log('subValDashboard obj',subValDashboard)


//  var exportContactUsData = []

    const prevRoute = usePrevious(route)
    useEffect(() => {
        if(prevRoute != undefined && prevRoute!=route){
            setCurrentPage(1)
            setRowsPerPage(15)
        }
    }, [prevRoute]);
   //  console.log('prevRoute--->',prevRoute)
   //  console.log('currentRoute--->',route)
   const keyForRerender = `${rowsPerPage}_${data?.length}`;
    const columns = [

        {
            name: 'Name',
            selector: row => row?.name ?? "",
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row?.emailid ?? "",
            sortable: true,
        },
        {
            name: 'SUBJECT',
            selector: row => row?.subject ?? "", 
            // cell: (props) => <div className="text-elipsis">{props.subject}</div>,

            sortable: true,
        },
        { 
            name: 'MESSAGE',
         
            selector: row => row?.message ?? "",
            // cell: (props) => <div className="text-elipsis">{props.message}</div>,

            sortable: true,
        },
        {
           name: 'CREATED',
        //    selector: row => moment(row.created).utc().format("DD-MMM-YYYY HH:mm"),
        selector: row => row?.created,
        cell: (props) =>   props.created != undefined ?  new Date(props.created).toLocaleDateString('en-IN', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
      }) :'',
      sortable: true,
        //   sortFunction: (a, b) => moment(a.created).diff(b.created),
       },
    
        {
            name: <>{subValDashboard && subValDashboard.view && subValDashboard.view.display === true && 'Actions'}</>,
            cell: (props) => 
           //   {
            subValDashboard && subValDashboard.view && subValDashboard.view.display === true &&
           <div className="d-flex">
           <a className={`${ subValDashboard && subValDashboard.view && subValDashboard.view.enable === false ? 'pe-none':''} text-success action-button`} onClick={(e)=>handleContactView(e,props._id)}><i className="mdi mdi-eye font-size-18"></i></a>

       </div>
           //  }
            ,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const handlePageChange = (page) => {
        GetTimeActivity()   
        setCurrentPage(page);
      };
    
      const handlePerRowsChange = (newPerPage) => {
        GetTimeActivity()   
        setRowsPerPage(newPerPage);
      };
    
 
   
 
     useEffect(() => {
         if (!localStorage.getItem("token")) {
             history.push("/");
         }
         setRoute("contactus")
         setActiveMenuId(300053)
         setActiveMenuObj({
             "Client Management": false,
             "Contact Management": false,
             "Reports": true
         })
            getContact();
            userActivity();
     }, []);
 
 
     const userActivity = () => {
        let path = window.location.pathname.split("/");
        const pageName = path[path.length - 1];
        var presentTime = moment();
        let payload;
    
        payload = {
            "userid": localStorage.getItem("userId"),
            "pagename": pageName,
            "pageurl": window.location.href,
            "starttime": presentTime,
            "useragent": JSON.parse(localStorage.getItem("loc"))
    
        };
    
    
   const previousId = localStorage.getItem("previousid");
const urlLink = lambda + '/useractivity?appname=' + appname + (previousId ? "&previousid=" + previousId : "");

    
        axios({
            method: 'POST',
            url: urlLink,
            data: payload
        })
            .then(function (response) {
                if (response?.data?.statusCode === 200) {
                    localStorage.setItem("previousid", response?.data?.result)
                }
            });
    }
  
 
     const customNoRecords = () => {
        return(
            
            <div className="empty-state-body empty-record"  >
                <div className="empty-state__message">
                    <span className="material-icons">summarize</span>
                    <p className="form-check font-size-16">No names were found with the searched keyword</p>
                </div> </div>
        )
    }
 
 
 
 
     const getContact = () => {
        GetTimeActivity()   
        setCurrentPage(1);
        const token = localStorage.getItem("token")
      const linkUrl = `${lambda}/contactus?appname=${appname}&token=${token}&userid=${localStorage.getItem("userId")}${contactussearch ?`&search=${contactussearch}`:''}`
            setIsLoading(true)
           
             axios({
                 method: 'POST',
                 url: linkUrl,
             })
                 .then(function (response) {
                     if (response?.data?.result == "Invalid token or Expired") {
                        setShowSessionPopupup(true)
                    } else {
                         console.log("response", response);
                         setData(response?.data?.result?.data);
                         global.exportExcelData = response?.data?.result?.data
                           

                         setContent(response?.data?.result);
                         setIsLoading(false)
                     }
                 }).catch((err) => {
                    console.log('error', err);
                    setTableErrMsg(true)
                    setIsLoading(false);
                });
         
     }
 
     const handleChange = (e) => {
        GetTimeActivity()   
         setContactusSearch(e.target.value)
     }

 
     const handleKeypress = (e) => {
         //it triggers by pressing the enter key
         GetTimeActivity()   
       if ((e.key === "Enter")) {
         setTimeout(function () { 
         getContact();
     }, 1000);
       }
     };

     const handleContactView = (e, id) => {
        GetTimeActivity()   
        for (let key in data) {
          console.log("data",data);
          if (data.hasOwnProperty(key) && data[key]._id === id) {

            setViewContact(data[key]);
            setClick(true);
            
          }
        }
      };


      useEffect(()=>{
        if(contactussearch ===""){
            getContact()
        }
      },[contactussearch])
 
    //   console.log('datataatatatta',data)

      const handleExport = () => {
        // let k = defaultData || exportContactUsData
        const modifiedArray = global.exportExcelData.map((each,i) => ({
            "S.NO":i+1,
            "NAME": each?.name ?? "",
            "EMAIL": each?.emailid ?? "",
            "SUBJECT": each?.subject ?? "",
            "MESSAGE": each?.message ?? "",
            "CREATED": each?.created ? new Date(each?.created).toLocaleDateString('en-IN', {
                day: 'numeric',
                month: 'short', 
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
               
            }) :"",
          }));
          exportToExcel(modifiedArray, "data");
  
      }
      function exportToExcel(jsonData, fileName) {
          delete jsonData['_id'];
          const worksheet = XLSX.utils.json_to_sheet(jsonData);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
          const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
          const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
          //FileSaver.saveAs(data, `${fileName}.xlsx`);
          FileSaver.saveAs(data, `contactUsData.xlsx`);
      }
      const customSort = (rows, selector, direction) => {
        const sortedData = rows.slice().sort((rowA, rowB) => {
          // use the selector function to resolve your field names by passing the sort comparators
          // const aField = selector(rowA);
        const aField = String(selector(rowA)).toLowerCase();

          //  const bField = selector(rowB);
          const bField = String(selector(rowB)).toLowerCase();
      
          let comparison = 0;
      
          if (aField > bField) {
            comparison = 1;
          } else if (aField < bField) {
            comparison = -1;
          }
      
          return direction === 'desc' ? comparison * -1 : comparison;
        });
      
        // Log the sorted data to the console
        // setSortedRecomData(sortedData)
        global.exportExcelData = sortedData
        // console.log('Sorted Data------>:', sortedData);
      
        return sortedData;
      };
      
     return (
         <>
 {showSessionPopupup && <SessionPopup />}
             <div id="layout-wrapper">
                 <Header />
                 <Sidebar />
                { click ?  (<ViewContactList data={viewContact} click={click} setClick={setClick}  />) :
                ( <div className="main-content user-management clients clients-search contact">
 
                     <div className="page-content">
                         <div className="container-fluid">
 
 
 
                             <div className="row mb-4 breadcrumb">
                                 <div className="col-lg-12">
                                     <div className="d-flex align-items-center">
                                         <div className="flex-grow-1">
                                             <h4 className="mb-2 card-title">Contact Us</h4>
                                         </div>
                                     </div>
                                 </div>
                             </div>
 
                             <div className="row table-data">
                                 <div className="col-12">
                                     <div className="card">
                                         <div className="card-body">
                                             <div className="row mb-2">
                                             <div className="col-sm-4 button-block">
                                                <div className="dataTables_length" id="datatable_length">
                                                                         <button className={`fill_btn ${data.length <= 0 ?'disable pe-none':''}`} onClick={handleExport}><span className="material-icons">north_east</span>Export</button>
                                                                     </div>
                                                </div>
                                                 <div className="col-sm-8">
 
                                                     <div className="search-box mb-2 d-inline-block">
                                                         <div className="position-relative">
                                                             <input type="text" className="form-control" placeholder="Search name" value={contactussearch} onChange={(e) => handleChange(e)} onKeyPress={handleKeypress} />
                                                             {/* <input className="form-control u-calendar" name="availableFrom" type="date" placeholder="Release Date" max="2023-03-15" value="" />
                                                             <input className="form-control u-calendar" name="availableFrom" type="date" placeholder="Release Date" max="2023-03-15" value="" /> */}
                                                             <button className="fill_btn" onClick={getContact}><span className="material-icons search-icon">search</span></button>
                                                         </div>
                                                         <div className="dataTables_length" id="datatable_length">
                                                                <button className="fill_btn" onClick={e=> setContactusSearch("")}><span className="material-icons-outlined">sync</span>Reset</button>
                                                            </div>
 
                                                      
                                                     </div>
                                                     <div className="text-sm-end">
 
                                                     </div>
                                                 </div>
                                             </div>
 
                                               < DataTable  key={keyForRerender}
                                                 // title=""
                                                 columns={columns}
                                                 // className="table align-middle table-nowrap table-check"
                                                 keyField='_id'
                                                 data={data}
                                                 direction="auto"
                                                 highlightOnHover
                                                 fixedHeaderScrollHeight="300px"
                                                 pagination
                                                 responsive
                                                 persistTableHead
                                                 // selectableRowsVisibleOnly
                                                 striped
                                                 // selectableRowsHighlight
                                                 // selectableRows
                                                 subHeaderAlign="right"
                                                 defaultSortField="name"
                                                 subHeaderWra
                                                 noDataComponent={customNoRecords()}
                                                 paginationTotalRows={data.length}
                                                 onChangeRowsPerPage={handlePerRowsChange}
                                                 onChangePage={handlePageChange}
                                                 paginationPerPage={rowsPerPage}
                                                 paginationDefaultPage={currentPageNew}
                                                paginationRowsPerPageOptions={[15,25,50,75,100]}
                                                sortFunction={customSort}

                                                 paginationComponentOptions={{
                                                     rowsPerPageText: 'Per page:',
                                                     rangeSeparatorText: 'of',
                                                     noRowsPerPage: false,
                                                     selectAllRowsItem: false,
                                                     selectAllRowsItemText: 'All',
                                                   }}
                                           
                                                 progressPending={isLoading}
                                                 progressComponent={<TableLoader />}
                                                   />
 
                                         </div>
                                     </div>
                                 </div>
                             </div>
 
 
                         </div>
                     </div>
 
                     <SweetAlert show={tableErrMsg}
                         custom
                         confirmBtnText="ok"
                         confirmBtnBsStyle="primary"
                         title={"Something went wrong. Please reload again."}
                         onConfirm={e => setTableErrMsg(false)}
                     >
                     </SweetAlert>
 
                     <Footer />
                   
                 </div>)
 }
             </div>
         </>
     );
 };
 
 export default Contact;
 