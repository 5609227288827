/***
**Module Name: profile
 **File Name :  profile.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains profile page details.
 ***/
import React, { useState, useEffect } from "react";
import Footer from "../components/dashboard/footer";
import Header from "../components/dashboard/header";
import Sidebar from "../components/dashboard/sidebar";
import tmdbApi from "../api/tmdbApi";
import SweetAlert from 'react-bootstrap-sweetalert';
import SessionPopup from "./SessionPopup"
import { useHistory, Link } from "react-router-dom";
import * as Config from "../constants/Config";
import moment from "moment";
import axios from 'axios';

let { lambda, country, appname } = window.app;


const Profile = () => {
    const history = useHistory();
    const [countries, setCountries] = useState('');
    const [idc, setIdc] = useState('');

    const [image, setImg] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState("");
    const [phonenumber, setPhoneNumber] = useState("");
    const [data, setData] = useState([])
    const [success, setSuccess] = useState(false);
    const [phoneerror, setPhoneError] = useState('');
    const [nameerror, setNameError] = useState('');
    const [passwordInput, setPasswordInput] = useState({
        password: '',
        confirmPassword: ''
    })
    const [IdcError, setIdcError] = useState('');

    const [passwordError, setPasswordErr] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [passwordShown, setPasswordShown] = useState(false);
    const [number, setNumber] = useState(false);
    const [upper, setUpper] = useState(false);
    const [limit, setLimit] = useState(false);
    const [lower, setLower] = useState(false);
    const [special, setSpecial] = useState(false);
    const [showSessionPopupup, setShowSessionPopupup] = useState(false);
    const [error, setError] = useState('');
    const [oldPasswordError, setOldPasswordError] = useState("");
    const [loaderEnable, setLoaderEnable] = useState("");

    useEffect(() => {
        if (!localStorage.getItem("token")) {
            history.push("/");
        }
        GetUserData();
        GetCountries();
        userActivity();
    }, []);

    useEffect(() => {

        if (window.site === undefined) {
            setTimeout(() => {
                if (window.site && window.site.common && window.site.common.imagesCDN) {
                    setImg(window.site.common.imagesCDN)
                }
            }, 1000);
        }
        if (window.site && window.site.common && window.site.common.imagesCDN) {
            setImg(window.site.common.imagesCDN)
        }

    }, [window.site]);

    const userActivity = () => {
        let path = window.location.pathname.split("/");
        const pageName = path[path.length - 1];
        var presentTime = moment();
        let payload;

        payload = {
            "userid": localStorage.getItem("userId"),
            "pagename": pageName,
            "pageurl": window.location.href,
            "starttime": presentTime,
            "useragent": JSON.parse(localStorage.getItem("loc"))

        };


   const previousId = localStorage.getItem("previousid");
const urlLink = lambda + '/useractivity?appname=' + appname + (previousId ? "&previousid=" + previousId : "");


        axios({
            method: 'POST',
            url: urlLink,
            data: payload
        })
            .then(function (response) {
                if (response?.data?.statusCode === 200) {
                    localStorage.setItem("previousid", response?.data?.result)
                }
            });
    }
    const GetUserData = async () => {
        try {

            const response = await tmdbApi.getUserData({});

            if (response?.statusCode === 200) {
                if (response?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    setName(response?.result?.[0]?.name)
                    setEmail(response?.result?.[0]?.emailid)
                    setIdc(response?.result?.[0]?.idc)
                    setPhoneNumber(response?.result?.[0]?.phone)
                    setData(response?.result?.[0]);
                }
            }
        } catch {
            console.log("error");
        }
    };
    const GetCountries = async () => {
        try {
            console.log(tmdbApi);
            const response = await tmdbApi.getLookUp({
                "type": ["country"],
                "sortBy": "alpha3",
                "projection":"tiny"
            });

            // console.log(response?.result);
            if (response?.result.data == "Invalid token or Expired") {
                setShowSessionPopupup(true)
            } else {
                setCountries(response?.result.data);
            }
        } catch {
            console.log("error");
        }
    };

    console.log("data", data);
    const checkInput = (e) => {
        const onlyDigits = e.target.value.replace(/\D/g, "");
        setPhoneNumber(onlyDigits);

    };

    const handleBack = (e) => {
        // history.push("/usermanagement");
        history.go(-1)
    }

    const handleUserUpdate = (e) => {
        let flag = true;
        // if (phonenumber === "") {
        //     setPhoneError("Please enter phone number");
        //     flag = false
        //     setTimeout(function () { setPhoneError("") }, 3000);
        // }
        if (name === "") {
            setNameError("Please enter name");
            flag = false
            setTimeout(function () { setNameError("") }, 3000);
        }
        if ((!phonenumber&& idc) ) {
           
            setPhoneError("Enter phone number");
            setTimeout(function () { setPhoneError("") }, 3000);
            flag = false
          }
      
        
          if (phonenumber && !idc) {
            setIdcError("Select country code");
            setTimeout(function () { setIdcError("") }, 3000);
            flag = false
          }

        if (flag) {
            UpdateForm();
        }

    }

    const UpdateForm = async () => {
        setLoaderEnable("updateForm")
        try {

            const response = await tmdbApi.userDataUpdate({
                "name": name,
                "phone": phonenumber,
                "idc": idc
            });

            if (response?.statusCode === 200) {
                if (response?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    setSuccess(true);
                    GetUserData();
                    setLoaderEnable("")
                }
            }
        } catch {
            console.log("error");
        }
    };

    function onConfirm() {
        setSuccess(false)
    };

    const handlePwdGenerate = (e) => {

        if (passwordInput?.password == "") {
            setError("Please Enter Password");
            setTimeout(function () { setError("") }, 3000);

        } else if (passwordInput?.confirmPassword == "") {
            setError("Please Enter Confirm Password");
            setTimeout(function () { setError("") }, 3000);

        }else if (passwordInput?.oldPassword == "") {
            setError("Please Enter Old Password");
            setTimeout(function () { setError("") }, 3000);

        }
        else if (passwordInput?.confirmPassword !== passwordInput?.password) {
            setConfirmPasswordError("Confirm password is not matched");
            setTimeout(function () { setConfirmPasswordError("") }, 3000);
        }
        //else if(lower === false && number === false && limit === false && lower === false && special === false){
        //     setError("Please Enter Strong Password");
        //     setTimeout(function () { setError("") }, 3000);

        // }
     
        else if (!limit || !upper || !special || !lower || !number){
            console.log('adsfadsf')
            setError("Please enter the password as per the instructions");
            // setTimeout(function () { setError("") }, 3000);
        
      }
        else if (limit && upper && special && lower && number && confirmPasswordError === "") {
            setError("")
            if (passwordInput?.password === passwordInput?.confirmPassword) {
                setConfirmPasswordError("")
                pwdGenerate();
            }
        }
      
    }
    console.log('limit',limit)
    console.log('upper',upper)
    console.log('special',special)
    console.log('lower',lower)
    console.log('number',number)
    const pwdGenerate = async () => {
        setLoaderEnable("pwdGenerate")
        try {
            const response = await tmdbApi.pwdGenerate({
                "emailid": email,
                "password": passwordInput?.password,
                "oldPassword": passwordInput?.oldPassword
            });
            console.log(response);

            
            if (response?.statusCode === 200) {
                 

                if (response?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else if (response?.result == "old password is wrong") {
                    setOldPasswordError("old password is incorrect");
                    setLoaderEnable("")
                }else {
                    setSuccess(true);
                    setPasswordInput({ ...passwordInput, password: '', confirmPassword: '' ,oldPassword:''})
                    setLoaderEnable("")
                }

            }


        } catch {
            console.log("error");
        }
    };

    const handlePasswordChange = (evnt) => {
        const passwordInputValue = evnt.target.value.trim();
        const passwordInputFieldName = evnt.target.name;
        const NewPasswordInput = { ...passwordInput, [passwordInputFieldName]: passwordInputValue }
        setPasswordInput(NewPasswordInput);

    }
    const togglePassword = (e) => {
        setPasswordShown(!passwordShown);
    };
    const handleValidation = (evnt) => {
        const passwordInputValue = evnt.target.value.trim();
        const passwordInputFieldName = evnt.target.name;
        //for password 
        if (passwordInputFieldName === 'password' || passwordInputFieldName === 'oldPassword') {
            const uppercaseRegExp = /(?=.*?[A-Z])/;
            const lowercaseRegExp = /(?=.*?[a-z])/;
            const digitsRegExp = /(?=.*?[0-9])/;
            const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
            const minLengthRegExp = /.{8,}/;
            const passwordLength = passwordInputValue.length;
            const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
            const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
            const digitsPassword = digitsRegExp.test(passwordInputValue);
            const specialCharPassword = specialCharRegExp.test(passwordInputValue);
            const minLengthPassword = minLengthRegExp.test(passwordInputValue);
            let errMsg = "";
            if (passwordLength === 0) {
                errMsg = "Password is empty";
            }
            // else if (!uppercasePassword) {
            //     errMsg = "At least one Uppercase";
            // } else if (!lowercasePassword) {
            //     errMsg = "At least one Lowercase";
            // } else if (!digitsPassword) {
            //     errMsg = "At least one digit";
            // } else if (!specialCharPassword) {
            //     errMsg = "At least one Special Characters";
            // } else if (!minLengthPassword) {
            //     errMsg = "At least minumum 8 characters";
            // } else {
            //     errMsg = "";
            // }
            if (uppercasePassword) {
                var element = document.getElementById("err1");
                element.classList.add("vaild");
                setUpper(true);
            } else {
                var element = document.getElementById("err1");
                element.classList.remove("vaild");
                setUpper(false);
            }
            if (lowercasePassword) {
                var element = document.getElementById("err");
                element.classList.add("vaild");
                setLower(true);
            } else {
                var element = document.getElementById("err");
                element.classList.remove("vaild");
                setLower(false);
            }
            if (digitsPassword) {
                var element = document.getElementById("err2");
                element.classList.add("vaild");
                setNumber(true);
            } else {
                var element = document.getElementById("err2");
                element.classList.remove("vaild");
                setNumber(false);
            }
            if (specialCharPassword) {
                var element = document.getElementById("err3");
                element.classList.add("vaild");
                setSpecial(true)
            } else {
                var element = document.getElementById("err3");
                element.classList.remove("vaild");
                setSpecial(false)
            }
            if (minLengthPassword) {
                var element = document.getElementById("err4");
                element.classList.add("vaild");
                setLimit(true)
            } else {
                var element = document.getElementById("err4");
                element.classList.remove("vaild");
                setLimit(false)
            }

            if (limit && upper && special && lower && number && confirmPasswordError === "") {
                setError("")
            }
            setPasswordErr(errMsg);
        }
        // for confirm password
        // if (passwordInputFieldName === "confirmPassword" || (passwordInputFieldName === "password")) {

        //     if (passwordInput?.confirmPassword !== passwordInput?.password) {
        //         setConfirmPasswordError("Confirm password is not matched");
        //     } else {
        //         setConfirmPasswordError("");
        //     }

        // }
    }
    const handleAddclass = (evnt) => {
        var element = document.getElementById("instruction");
        element.classList.add("ins-dsp-none");
        setConfirmPasswordError("");
    }
    const handleRemoveclass = (evnt) => {
        var element = document.getElementById("instruction");
        element.classList.remove("ins-dsp-none");
    }
    const handleConfirm = (evnt) => {
        const passwordInputFieldName = evnt.target.name;
        if (passwordInputFieldName === "confirmPassword" || (passwordInputFieldName === "password")) {

            // if (passwordInput?.confirmPassword !== passwordInput?.password) {
            //     setConfirmPasswordError("Confirm password is not matched");
            // } else {
            //     setConfirmPasswordError("");
            // }

        }
    }

    const RemoveError = () => {
        setOldPasswordError("");
    }

    console.log("passwordInput", passwordInput);

    useEffect(() => {
        myArray = localStorage.getItem("ClientName")?.split(" ");


    }, [localStorage.getItem("ClientName")]);

    let myArray = []
    myArray = localStorage.getItem("ClientName")?.split(" ");


    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />

                <div className="main-content edit-content user-management profile">

                    <div className="page-content ">
                        <div className="container-fluid">
                            <div className="row mb-4 breadcrumb">
                                <div className="col-lg-12">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <h4 className="mb-2 card-title">profile</h4>

                                        </div>
                                        <div>
                                            <a onClick={handleBack} className="btn btn-primary">back</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="create-user-block">


                                <div className="form-block">
                                    <div className="row">


                                        <div className="col-md-7 user-details">
                                            <h3>User Details</h3>
                                            <div className="mb-3 row">
                                                <div className="col-md-12">
                                                    <label for="example-text-input" className="col-form-label">NAME</label>
                                                    <input className="form-control" placeholder="Enter Name" type="text" value={name} onChange={(e) => setName(e.target.value)} id="example-text-input" />

                                                </div>
                                                {nameerror ? <span className="errormsg" style={{ fontWeight: 'bold', color: 'red', }}>{nameerror}</span> : ""}
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-md-12">
                                                    <label for="example-text-input" className="col-form-label">E MAIL ID</label>
                                                    <input className="form-control contact-number" type="email" value={email} placeholder=" Enter Company Email" id="example-email-input" disabled />
                                                </div>
                                            </div>

                                            <div className="mb-3 row">
                                           
                                                <div className="col-md-12">
                                               
                                                    <label for="example-text-input" className="col-form-label">PHONE NUMBER</label>
                                                    <div className="country-code">
                                                    <select name="idc" value={idc} className="colorselect capitalize" onChange={(e) => setIdc(e.target.value)}>
                                                                <option value="">Select</option>
                                                                {/* <option value="91">IND(+91)</option> */}
                                                                {countries && countries?.length > 0 && countries?.map((task, i) => {
                                                                    return (
                                                                        <><option key={i} value={task.alpha3}>{task.alpha3 + task.countrycode}</option></>
                                                                    )
                                                                }
                                                                )}
                                                            </select>
                                                    <input className="form-control contact-number" type="tel" value={phonenumber} name="phone" onChange={e => checkInput(e)} placeholder="Phone number" id="example-tel-input" />
                                                      
                                                </div>
                                                    <span className="errormsg" style={{
                                                        fontWeight: 'bold',
                                                        color: 'red',
                                                    }}>{IdcError}</span>
                                                    {phoneerror ? <span className="errormsg" style={{
                                                        fontWeight: 'bold',
                                                        color: 'red',
                                                    }}>{phoneerror}</span> : ""
                                                    }    
                                                </div>


                                            </div>
                                      

                                         
                                            <div className="mb-3 row">
                                                <div className="col-md-12">
                                                    <button onClick={handleUserUpdate} className="btn btn-primary"> {   loaderEnable === "updateForm" ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon"/>) : null}UPDATE</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-5 prfile-block">
                                            {/* <div className="bg-primary bg-soft">
                                                 <div className="row">
                                                     <div className="col-7">
                                                         <div className="text-primary p-3">
                                                             <h5 className="text-primary">Welcome Back !</h5>
                                                             <p>It will seem like simplified</p>
                                                         </div>
                                                     </div>
                                                     <div className="col-5 align-self-end">
                                                         <img src={Config.img + "profile-img.png?" + Config.compress} alt="" className="img-fluid"/>
                                                     </div>
                                                 </div>
                                             </div>
                                             <div className="card-body pt-0">
                                                 <div className="row">
                                                     <div className="col-sm-4">
                                                         <div className="avatar-md profile-user-wid mb-4">
                                                             <img src={Config.img +"avatar-1.jpg?" + Config.compress} alt="" className="img-thumbnail rounded-circle"/>
                                                         </div>
                                                         <h5 className="font-size-15 text-truncate">{data.name}</h5>
                                                         <p className="text-muted mb-0 text-truncate">{data.type}</p>
                                                     </div>
 
                                                     <div className="col-sm-8">
            
                                                     </div>
                                                 </div>
                                             </div> */}
                                            <div className="avatar-block">
                                                <div className="avatar-header">

                                                    <div className="avatar-img-block"><p>{myArray && myArray.length > 1 ? myArray && myArray.length > 0 && myArray[0]?.slice(0, 1)?.toUpperCase() + myArray[1]?.slice(0, 1)?.toUpperCase()
                                                        : data && data.name?.slice(0, 2)?.toUpperCase()}</p>
                                                    </div>
                                                </div>
                                                <div className="avatar-body">
                                                    <h6 className="username mt-3">{data.name}</h6>
                                                    <p className="lg-badge badge">{data.type}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="create-user-block create-pwd-block">
                                <div className="form-block">
                                    <h3>Change Password</h3>
                                    <div className="row">
                                        <div className="col-md-7">

                                            <div className="mb-3 row">
                                                <div className="col-md-12 mb-3">
                                                    <label className="floatingInput">Old Password<span>*</span></label>
                                                    <input type={passwordShown ? "text" : "password"} value={passwordInput?.oldPassword} onChange={(e) => handlePasswordChange(e)} name="oldPassword" placeholder="Old Password" className="form-control" onFocus={RemoveError}
                                                    />
                                                    <p className="text-danger">{oldPasswordError}</p>
                                                </div>

                                                <div className="col-md-12 mb-3">
                                                    <label className="floatingInput">New Password<span>*</span></label>
                                                    <input type={passwordShown ? "text" : "password"} value={passwordInput?.password} onChange={(e) => handlePasswordChange(e)} onKeyUp={handleValidation} name="password" placeholder="Password" className="form-control" onBlur={(e) => { handleAddclass(e) }}
                                                        onFocus={(e) => { handleRemoveclass(e) }} />
                                                    <p className="text-danger">{passwordError}</p>
                                                </div>



                                                <div className="col-md-12 mb-3">
                                                    <label className="floatingInput">Confirm Password<span>*</span></label>
                                                    <input type={passwordShown ? "text" : "password"} value={passwordInput?.confirmPassword} onChange={(e) => handlePasswordChange(e)} onKeyUp={handleConfirm} onBlur={(e) => { handleAddclass(e) }} onFocus={(e) => { handleRemoveclass(e) }} name="confirmPassword" placeholder="Password" className="form-control" />
                                                    <p className="text-danger">{confirmPasswordError}</p>


                                                </div>


                                                <div className="col-md-12 mb-3">
                                                    <div className="flex-left terms-block"> <input type="checkbox" id="terms-check" onChange={(e) => togglePassword(e)} /><label>Show Password</label></div>
                                                </div>

                                                <p className="text-danger">{error}</p>

                                                <div className="col-md-12">
                                                    <button className="btn btn-primary" onClick={e => handlePwdGenerate(e)}>{   loaderEnable === "pwdGenerate" ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon"/>) : null}UPDATE</button>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="create-password-instruction ins-dsp-none" id="instruction">
                                                <p className="error" id="err">{lower ? <span className="material-symbols-outlined">
                                                    check
                                                </span> : <span className="material-symbols-outlined">
                                                    close
                                                </span>} Password must contain a lower case letter</p>
                                                <p className="error" id="err1"> {upper ? <span className="material-symbols-outlined">
                                                    check
                                                </span> : <span className="material-symbols-outlined">
                                                    close
                                                </span>} Password must contain an upper case letter</p>
                                                <p className="error" id="err2">  {number ? <span className="material-symbols-outlined">
                                                    check
                                                </span> : <span className="material-symbols-outlined">
                                                    close
                                                </span>} Password must contain a number</p>
                                                <p className="error" id="err3"> {special ? <span className="material-symbols-outlined">
                                                    check
                                                </span> : <span className="material-symbols-outlined">
                                                    close
                                                </span>} Password must contain a special character or a space</p>
                                                <p className="error" id="err4"> {limit ? <span className="material-symbols-outlined">
                                                    check
                                                </span> : <span className="material-symbols-outlined">
                                                    close
                                                </span>} Password must contain at least 8 characters</p>
                                            </div>
                                        </div>
                                    </div>



                                </div>
                            </div>




                        </div>
                    </div>
                    <SweetAlert show={success}
                        custom
                        confirmBtnText="Ok"
                        confirmBtnBsStyle="primary"
                        title={"Updated successfully"}
                        onConfirm={e => onConfirm()}
                    >
                    </SweetAlert>

                    <Footer />
                </div>


            </div>

        </>
    );
};

export default Profile;
