import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component';

const Test = () => {
  const [data, setData] = useState([
    {
        "_id": "65155d298b89a77d51fd4d4f",
        "exclusivity": "EXCLUSIVE",
        "typesofrights": "Broadcast",
        "country": "Afghanistan",
        "region": [
            "Asia",
            "SAARC",
            "Eurasia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d1e",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d4a",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "SVOD",
        "country": "Afghanistan",
        "region": [
            "Asia",
            "SAARC",
            "Eurasia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d19",
        "created": "2023-09-28T11:02:01.678Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.678Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d4b",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "TVOD",
        "country": "Afghanistan",
        "region": [
            "Asia",
            "SAARC",
            "Eurasia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d1a",
        "created": "2023-09-28T11:02:01.678Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.678Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d4c",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Broadcast",
        "country": "Afghanistan",
        "region": [
            "Asia",
            "SAARC",
            "Eurasia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d1b",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d4d",
        "exclusivity": "EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "SVOD",
        "country": "Afghanistan",
        "region": [
            "Asia",
            "SAARC",
            "Eurasia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d1c",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d4e",
        "exclusivity": "EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "TVOD",
        "country": "Afghanistan",
        "region": [
            "Asia",
            "SAARC",
            "Eurasia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d1d",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d54",
        "exclusivity": "EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "TVOD",
        "country": "Armenia",
        "region": [
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d23",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d50",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "SVOD",
        "country": "Armenia",
        "region": [
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d1f",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d51",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "TVOD",
        "country": "Armenia",
        "region": [
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d20",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d52",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Broadcast",
        "country": "Armenia",
        "region": [
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d21",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d53",
        "exclusivity": "EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "SVOD",
        "country": "Armenia",
        "region": [
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d22",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d55",
        "exclusivity": "EXCLUSIVE",
        "typesofrights": "Broadcast",
        "country": "Armenia",
        "region": [
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d24",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d56",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "SVOD",
        "country": "Azerbaijan",
        "region": [
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d25",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d5b",
        "exclusivity": "EXCLUSIVE",
        "typesofrights": "Broadcast",
        "country": "Azerbaijan",
        "region": [
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d2a",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d5a",
        "exclusivity": "EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "TVOD",
        "country": "Azerbaijan",
        "region": [
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d29",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d59",
        "exclusivity": "EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "SVOD",
        "country": "Azerbaijan",
        "region": [
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d28",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d58",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Broadcast",
        "country": "Azerbaijan",
        "region": [
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d27",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d57",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "TVOD",
        "country": "Azerbaijan",
        "region": [
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d26",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d5c",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "SVOD",
        "country": "Bahrain",
        "region": [
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d2b",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d61",
        "exclusivity": "EXCLUSIVE",
        "typesofrights": "Broadcast",
        "country": "Bahrain",
        "region": [
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d30",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d60",
        "exclusivity": "EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "TVOD",
        "country": "Bahrain",
        "region": [
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d2f",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d5f",
        "exclusivity": "EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "SVOD",
        "country": "Bahrain",
        "region": [
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d2e",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d5e",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Broadcast",
        "country": "Bahrain",
        "region": [
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d2d",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d5d",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "TVOD",
        "country": "Bahrain",
        "region": [
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d2c",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d62",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "SVOD",
        "country": "Bangladesh",
        "region": [
            "SAARC",
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d31",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d67",
        "exclusivity": "EXCLUSIVE",
        "typesofrights": "Broadcast",
        "country": "Bangladesh",
        "region": [
            "SAARC",
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d36",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d66",
        "exclusivity": "EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "TVOD",
        "country": "Bangladesh",
        "region": [
            "SAARC",
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d35",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d65",
        "exclusivity": "EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "SVOD",
        "country": "Bangladesh",
        "region": [
            "SAARC",
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d34",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d64",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Broadcast",
        "country": "Bangladesh",
        "region": [
            "SAARC",
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d33",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d63",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "TVOD",
        "country": "Bangladesh",
        "region": [
            "SAARC",
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d32",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d69",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "TVOD",
        "country": "Bhutan",
        "region": [
            "SAARC",
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d38",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d6d",
        "exclusivity": "EXCLUSIVE",
        "typesofrights": "Broadcast",
        "country": "Bhutan",
        "region": [
            "SAARC",
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d3c",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d6c",
        "exclusivity": "EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "TVOD",
        "country": "Bhutan",
        "region": [
            "SAARC",
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d3b",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d6b",
        "exclusivity": "EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "SVOD",
        "country": "Bhutan",
        "region": [
            "SAARC",
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d3a",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d6a",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Broadcast",
        "country": "Bhutan",
        "region": [
            "SAARC",
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d39",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d68",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "SVOD",
        "country": "Bhutan",
        "region": [
            "SAARC",
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d37",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65154cb344721af08793c162",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "SVOD",
        "country": "Brunei",
        "region": [
            "South-East Asia",
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65154cb344721af08793c155",
        "created": "2023-09-28T09:51:47.864Z",
        "createdBy": {
            "userid": "64300f8644960117a8ac1abf",
            "username": "Srikanth mvv"
        },
        "updated": [
            {
                "userid": "64300f8644960117a8ac1abf",
                "username": "Srikanth mvv",
                "date": "2023-09-28T09:51:47.864Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65154cb344721af08793c167",
        "exclusivity": "EXCLUSIVE",
        "typesofrights": "Broadcast",
        "country": "Brunei",
        "region": [
            "South-East Asia",
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 5600,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65154cb344721af08793c15a",
        "created": "2023-09-28T09:51:47.864Z",
        "createdBy": {
            "userid": "64300f8644960117a8ac1abf",
            "username": "Srikanth mvv"
        },
        "updated": [
            {
                "userid": "64300f8644960117a8ac1abf",
                "username": "Srikanth mvv",
                "date": "2023-09-28T09:51:47.864Z",
                "minbidprice": 5600
            }
        ]
    },
    {
        "_id": "65154cb344721af08793c166",
        "exclusivity": "EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "TVOD",
        "country": "Brunei",
        "region": [
            "South-East Asia",
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 7890,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65154cb344721af08793c159",
        "created": "2023-09-28T09:51:47.864Z",
        "createdBy": {
            "userid": "64300f8644960117a8ac1abf",
            "username": "Srikanth mvv"
        },
        "updated": [
            {
                "userid": "64300f8644960117a8ac1abf",
                "username": "Srikanth mvv",
                "date": "2023-09-28T09:51:47.864Z",
                "minbidprice": 7890
            }
        ]
    },
    {
        "_id": "65154cb344721af08793c165",
        "exclusivity": "EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "SVOD",
        "country": "Brunei",
        "region": [
            "South-East Asia",
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65154cb344721af08793c158",
        "created": "2023-09-28T09:51:47.864Z",
        "createdBy": {
            "userid": "64300f8644960117a8ac1abf",
            "username": "Srikanth mvv"
        },
        "updated": [
            {
                "userid": "64300f8644960117a8ac1abf",
                "username": "Srikanth mvv",
                "date": "2023-09-28T09:51:47.864Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65154cb344721af08793c164",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Broadcast",
        "country": "Brunei",
        "region": [
            "South-East Asia",
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65154cb344721af08793c157",
        "created": "2023-09-28T09:51:47.864Z",
        "createdBy": {
            "userid": "64300f8644960117a8ac1abf",
            "username": "Srikanth mvv"
        },
        "updated": [
            {
                "userid": "64300f8644960117a8ac1abf",
                "username": "Srikanth mvv",
                "date": "2023-09-28T09:51:47.864Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65154cb344721af08793c163",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "TVOD",
        "country": "Brunei",
        "region": [
            "South-East Asia",
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1800,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65154cb344721af08793c156",
        "created": "2023-09-28T09:51:47.864Z",
        "createdBy": {
            "userid": "64300f8644960117a8ac1abf",
            "username": "Srikanth mvv"
        },
        "updated": [
            {
                "userid": "64300f8644960117a8ac1abf",
                "username": "Srikanth mvv",
                "date": "2023-09-28T09:51:47.864Z",
                "minbidprice": 1800
            }
        ]
    },
    {
        "_id": "65154cb344721af08793c16d",
        "exclusivity": "EXCLUSIVE",
        "typesofrights": "Broadcast",
        "country": "Cambodia",
        "region": [
            "South-East Asia",
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1345,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65154cb344721af08793c160",
        "created": "2023-09-28T09:51:47.864Z",
        "createdBy": {
            "userid": "64300f8644960117a8ac1abf",
            "username": "Srikanth mvv"
        },
        "updated": [
            {
                "userid": "64300f8644960117a8ac1abf",
                "username": "Srikanth mvv",
                "date": "2023-09-28T09:51:47.864Z",
                "minbidprice": 1345
            }
        ]
    },
    {
        "_id": "65154cb344721af08793c168",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "SVOD",
        "country": "Cambodia",
        "region": [
            "South-East Asia",
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1221,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65154cb344721af08793c15b",
        "created": "2023-09-28T09:51:47.864Z",
        "createdBy": {
            "userid": "64300f8644960117a8ac1abf",
            "username": "Srikanth mvv"
        },
        "updated": [
            {
                "userid": "64300f8644960117a8ac1abf",
                "username": "Srikanth mvv",
                "date": "2023-09-28T09:51:47.864Z",
                "minbidprice": 1221
            }
        ]
    },
    {
        "_id": "65154cb344721af08793c169",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "TVOD",
        "country": "Cambodia",
        "region": [
            "South-East Asia",
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1200,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65154cb344721af08793c15c",
        "created": "2023-09-28T09:51:47.864Z",
        "createdBy": {
            "userid": "64300f8644960117a8ac1abf",
            "username": "Srikanth mvv"
        },
        "updated": [
            {
                "userid": "64300f8644960117a8ac1abf",
                "username": "Srikanth mvv",
                "date": "2023-09-28T09:51:47.864Z",
                "minbidprice": 1200
            }
        ]
    },
    {
        "_id": "65154cb344721af08793c16a",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Broadcast",
        "country": "Cambodia",
        "region": [
            "South-East Asia",
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 12990,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65154cb344721af08793c15d",
        "created": "2023-09-28T09:51:47.864Z",
        "createdBy": {
            "userid": "64300f8644960117a8ac1abf",
            "username": "Srikanth mvv"
        },
        "updated": [
            {
                "userid": "64300f8644960117a8ac1abf",
                "username": "Srikanth mvv",
                "date": "2023-09-28T09:51:47.864Z",
                "minbidprice": 12990
            }
        ]
    },
    {
        "_id": "65154cb344721af08793c16b",
        "exclusivity": "EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "SVOD",
        "country": "Cambodia",
        "region": [
            "South-East Asia",
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1200,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65154cb344721af08793c15e",
        "created": "2023-09-28T09:51:47.864Z",
        "createdBy": {
            "userid": "64300f8644960117a8ac1abf",
            "username": "Srikanth mvv"
        },
        "updated": [
            {
                "userid": "64300f8644960117a8ac1abf",
                "username": "Srikanth mvv",
                "date": "2023-09-28T09:51:47.864Z",
                "minbidprice": 1200
            }
        ]
    },
    {
        "_id": "65154cb344721af08793c16c",
        "exclusivity": "EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "TVOD",
        "country": "Cambodia",
        "region": [
            "South-East Asia",
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1380,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65154cb344721af08793c15f",
        "created": "2023-09-28T09:51:47.864Z",
        "createdBy": {
            "userid": "64300f8644960117a8ac1abf",
            "username": "Srikanth mvv"
        },
        "updated": [
            {
                "userid": "64300f8644960117a8ac1abf",
                "username": "Srikanth mvv",
                "date": "2023-09-28T09:51:47.864Z",
                "minbidprice": 1380
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d72",
        "exclusivity": "EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "TVOD",
        "country": "China",
        "region": [
            "North-East Asia",
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d41",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d6e",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "SVOD",
        "country": "China",
        "region": [
            "North-East Asia",
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d3d",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d6f",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "TVOD",
        "country": "China",
        "region": [
            "North-East Asia",
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d3e",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d70",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Broadcast",
        "country": "China",
        "region": [
            "North-East Asia",
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d3f",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d71",
        "exclusivity": "EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "SVOD",
        "country": "China",
        "region": [
            "North-East Asia",
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d40",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d73",
        "exclusivity": "EXCLUSIVE",
        "typesofrights": "Broadcast",
        "country": "China",
        "region": [
            "North-East Asia",
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d42",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d76",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Broadcast",
        "country": "Cyprus",
        "region": [
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d45",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d74",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "SVOD",
        "country": "Cyprus",
        "region": [
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d43",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d77",
        "exclusivity": "EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "SVOD",
        "country": "Cyprus",
        "region": [
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d46",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d75",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "TVOD",
        "country": "Cyprus",
        "region": [
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d44",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d79",
        "exclusivity": "EXCLUSIVE",
        "typesofrights": "Broadcast",
        "country": "Cyprus",
        "region": [
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d48",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d78",
        "exclusivity": "EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "TVOD",
        "country": "Cyprus",
        "region": [
            "Eurasia",
            "Asia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d47",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65155d298b89a77d51fd4d7a",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "TVOD",
        "country": "East Timor",
        "region": [
            "South-East Asia",
            "Asia",
            "Eurasia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 1000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65155d298b89a77d51fd4d49",
        "created": "2023-09-28T11:02:01.679Z",
        "createdBy": {
            "userid": "6464b2dfdd25f326724f37b5",
            "username": "Chandra sekhar"
        },
        "updated": [
            {
                "userid": "6464b2dfdd25f326724f37b5",
                "username": "Chandra sekhar",
                "date": "2023-09-28T11:02:01.679Z",
                "minbidprice": 1000
            }
        ]
    },
    {
        "_id": "65154cb344721af08793c16e",
        "exclusivity": "NON-EXCLUSIVE",
        "typesofrights": "Digital",
        "revenuetype": "SVOD",
        "country": "East Timor",
        "region": [
            "South-East Asia",
            "Asia",
            "Eurasia"
        ],
        "period": 1,
        "itemid": "648c33598e5e0b21144f79d8",
        "minbidprice": 5000,
        "title": "Yakushiji Temple Historic Restoration of 1300-year-old Pagoda",
        "itemimage": "orasidev/content/648c33598e5e0b21144f79d8/648c33598e5e0b21144f79db_CONTENT IMAGE_1686910605583.jpeg",
        "appname": "orasidev",
        "bidderscount": 0,
        "maxbidprice": 0,
        "status": "ACTIVE",
        "combinationid": "65154cb344721af08793c161",
        "created": "2023-09-28T09:51:47.864Z",
        "createdBy": {
            "userid": "64300f8644960117a8ac1abf",
            "username": "Srikanth mvv"
        },
        "updated": [
            {
                "userid": "64300f8644960117a8ac1abf",
                "username": "Srikanth mvv",
                "date": "2023-09-28T09:51:47.864Z",
                "minbidprice": 5000
            }
        ]
    }
]);
  const [visibleData, setVisibleData] = useState([]);
  const itemsPerPage1 = 30;
  const currentPage1 = useRef(1);
  const isLoading1 = useRef(false);
  const tableRef = useRef(null);

  useEffect(() => {
    setVisibleData(data.slice(0, itemsPerPage1));
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const table = tableRef.current;
      if (table) {
        const isAtBottom = table.scrollHeight - table.scrollTop === table.clientHeight;
        if (isAtBottom && !isLoading1.current) {
          // Load more data when at the bottom of the table
          loadMoreData();
        }
      }
    };

    console.log(" tableRef.current", tableRef)
    
    tableRef.current.addEventListener('scroll', handleScroll);

    return () => {
      tableRef.current.removeEventListener('scroll', handleScroll);
    };
  }, []);

  console.log("hi")

  const loadMoreData = () => {
    isLoading1.current = true;
    const nextPageStart = currentPage1.current * itemsPerPage1;
    const nextPageEnd = nextPageStart + itemsPerPage1;
    const nextData = data.slice(nextPageStart, nextPageEnd);
    if (nextData.length > 0) {
      setVisibleData((prevVisibleData) => [...prevVisibleData, ...nextData]);
      currentPage1.current++;
      isLoading1.current = false;
    }
  };

  const columns = [
    {
        name: 'country',
        selector: row => row.country,
        sortable: true,
    },
    {
        name: 'region',
        selector: row => row.region && row.region.length > 1 ? row.region.join(", "): row.region,
        sortable: true,
    },
    {
        name: 'types of rights',
        selector: row => row.typesofrights,
        sortable: true,
    },
    {
        name: 'revenue',
        // cell: (props) => <img src={props.image} width={60} alt='Image' />,
        selector: row => row.revenuetype,
        sortable: true,

    },

    {
        name: 'exclusivity',
        selector: row => row.exclusivity,
        // cell: (props) => <div className="text-elipsis">{props.comments}</div>,
        sortable: true,
    },
    {
        name: 'period',
        // selector: row => moment(row.updated).utc().format("DD-MMM-YYYY HH:mm"),
        selector: row => row.period + " year",

    },


    {
        name: <>{'min price (usd)'}</>,
        selector: (row, index) => {


           
                return (<div className="d-flex price-input">
                    {/* <span className="input-group-text">$</span> */}
                    <input type="number" class="form-control" inputMode="numeric" min="1" aria-label="Amount (to the nearest dollar)" />
                </div>)
            
           
        }
        ,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    },
];

  return (
    <div
      ref={tableRef}
      style={{ overflowY: 'scroll', height: '400px' }}
    >
      <DataTable
        //title="My Data Table"
        columns={columns}
        data={visibleData}
        pagination={false} // Disable built-in pagination
      />
    </div>
  );
};

export default Test;
