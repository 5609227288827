/***
**Module Name: usermanagement
 **File Name :  usermange.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains usermanagement details.
 ***/
import React, { useState, useEffect, useContext } from "react";


import Footer from "../../components/dashboard/footer";
import Header from "../../components/dashboard/header";
import Sidebar from "../../components/dashboard/sidebar";
import { useHistory, Link, useLocation } from "react-router-dom";
import axios from 'axios';
import tmdbApi from "../../api/tmdbApi";
import moment from "moment";

import SweetAlert from 'react-bootstrap-sweetalert';
import * as Config from "../../constants/Config";
import Loader from "../../components/loader";
import { contentContext } from "../../context/contentContext";
import SessionPopup from "../../pages/SessionPopup"
import TableLoader from "../../components/tableLoader";
import DataTable from 'react-data-table-component';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

let { lambda, appname } = window.app

const UserManagement = () => {
    const history = useHistory();
    const { state } = useLocation();
    const { search } = state || {};
    const [toggle, setToggle] = useState(false);
    // const [data, setData] = useState([]);
    // const [userdata, setUserData] = useState([]);
    const [user, setUser] = useState("");

    // const [perpage, setPerpage] = useState(10);
    // const [arrow, setArrow] = useState(0);
    const [perpage, setPerpage] = useState(10);

   // const [currentPage, setcurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(10);

    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
    const [showSessionPopupup, setShowSessionPopupup] = useState(false);
    const [tableErrMsg, setTableErrMsg] = useState(false);

    const [arrowdir, setArrowDir] = useState('down');
    const [num, setNum]= useState();
    // const [sortDirection, setSortDirection] = useState('asc');
         

     

    let id = localStorage.getItem("userId");

    const [flag, setFlag] = useState(false);

    // const [isLoading, setIsLoading] = useState(false); 
    const {searchedFlag, setSearchedFlag,  isLoading, setIsLoading,userData, setUserData, activeMenuObj, setActiveMenuObj, sortTableAlpha, arrow, usersearch, setUserSearch ,currentPage, setcurrentPage, data, setData,rowsPerPage, setRowsPerPage,currentPageNew, setCurrentPage,route, setRoute,usePrevious, setActiveMenuId,  GetTimeActivity} = useContext(contentContext)
    const validateObj = userData && userData.permissions && userData.permissions.length > 0 && userData.permissions.filter(eachItem => eachItem.menu == "User Management")
    const subValDashboard = validateObj && validateObj[0] && validateObj[0].dashboard
    // console.log('validate obj', validateObj)

    const prevRoute = usePrevious(route)
     useEffect(() => {
         if(prevRoute != undefined && prevRoute!=route){
             setCurrentPage(1)
             setRowsPerPage(15)
             setSearchedFlag(false);
             setUserSearch("")
         }
     }, [prevRoute]);
    //  console.log('prevRoute--->',prevRoute)
    //  console.log('currentRoute--->',route)
    const keyForRerender = `${rowsPerPage}_${data?.length}`;
     const columns = [
 
         {
             name: 'Name',
             selector: row => row?.name ?? "",
             sortable: true,
         },
         {
             name: 'User Type',
             selector: row => row?.type ?? "",
             sortable: true,
         },
         {
             name: 'Email id',
             selector: row => row?.emailid ?? "",
             sortable: true,
         },
         {
             name: 'Phone Number',
             selector: row => row?.phone ?? "",
             sortable: true,
         },
         {
            name: 'Status',
            selector: row => row?.status ?? "",
            sortable: true,
        },
        
 
         {
             name: <>{(subValDashboard && subValDashboard.view && subValDashboard.view.display === true) || (subValDashboard && subValDashboard.edit && subValDashboard.edit.display === true) ? 'Actions' : null}</>,
             cell: (props) => 
            //   {
                subValDashboard && subValDashboard.view && subValDashboard.edit && (subValDashboard.view.display === true || subValDashboard.edit.display === true) &&
            <div className="d-flex">
            {subValDashboard && subValDashboard.view && subValDashboard.view.display === true &&
                <a onClick={e => handleViewUser(e, props.userid)}
                    className={`${subValDashboard && subValDashboard.view && subValDashboard.view.enable === false ? 'pe-none' : ''} text-success action-button`}><i
                        className="mdi mdi-eye font-size-18"></i></a>}
            {subValDashboard && subValDashboard.edit && subValDashboard.edit.display === true && <>
                {props.userid === id ? <a
                    className={`${subValDashboard && subValDashboard.edit && subValDashboard.edit.enable === false ? 'pe-none' : ''} text-danger action-button`}><i
                        className="mdi mdi-pencil font-size-18"></i></a> : <a onClick={e => handleEditUser(e, props.userid)}
                            className={`${subValDashboard && subValDashboard.edit && subValDashboard.edit.enable === false ? 'pe-none' : ''} text-danger action-button`}><i
                                className="mdi mdi-pencil font-size-18"></i></a>}</>}
        </div>
            //  }
             ,
             ignoreRowClick: true,
             allowOverflow: true,
             button: true,
         },
     ];
 
     const handlePageChange = (page) => {
        GetTimeActivity()   
         setCurrentPage(page);
       };
     
       const handlePerRowsChange = (newPerPage) => {
        GetTimeActivity()   
         setRowsPerPage(newPerPage);
       };
     
// console.log('datattatatattatat',data)
    useEffect(() => {
        setRoute("user")
        GetUserData();
        setActiveMenuId(200005)
        setActiveMenuObj({
            "Client Management": false,
            "Contact Management":false,
            "Reports": false
        })
        userActivity();
    }, []);
    const userActivity = () => {
        let path = window.location.pathname.split("/");
        const pageName = path[path.length - 1];
        var presentTime = moment();
        let payload;

        payload = {
            "userid": localStorage.getItem("userId"),
            "pagename": pageName,
            "pageurl": window.location.href,
            "starttime": presentTime,
            "useragent": JSON.parse(localStorage.getItem("loc"))

        };


   const previousId = localStorage.getItem("previousid");
const urlLink = lambda + '/useractivity?appname=' + appname + (previousId ? "&previousid=" + previousId : "");


        axios({
            method: 'POST',
            url: urlLink,
            data: payload
        })
            .then(function (response) {
                if (response?.data?.statusCode === 200) {
                    localStorage.setItem("previousid", response?.data?.result)
                }
            });
    }
    const GetUserData = async () => {
        GetTimeActivity()   
        try {

            const response = await tmdbApi.getUserData({});

            if (response?.statusCode === 200) {

                setUserData(response?.result?.[0]);
                localStorage.setItem("ClientName", response?.result?.[0]?.name)
                localStorage.setItem("ClientType", response?.result?.[0]?.type)
                let userArr = response?.result?.[0]?.permissions

                const obj = userArr.reduce((acc, item) => {
                    if (item.submenus) acc[item.menu] = false;
                    return acc;
                }, {});
                // console.log('objjjjjjjj--->',obj)
                // setActiveMenuObj(obj)

                console.log('response?.result?.[0]', response?.result?.[0])
            }
        } catch {
            console.log("error");
        }
    };
 

    useEffect(() => {
        if (!localStorage.getItem("token")) {
            history.push("/");
        }
      
    }, []);

    useEffect(() => {
      

        console.log("trueeeeeeeeee",searchedFlag);
        if (searchedFlag) { 
            console.log("came") 
            handleSearch();
        } else {
            console.log("called get all deals") 
            User();
        }

    }, [searchedFlag]);

    const PageLoad = (e) => {
        const pagevalue = e.target.value;
        //setPerpage(pagevalue);
        setitemsPerPage(pagevalue);
        setcurrentPage(1);
        setmaxPageNumberLimit(5);
        setminPageNumberLimit(0);

    }


    const User = (e) => {
        GetTimeActivity()   
        setIsLoading(true)
        const token = localStorage.getItem("token")
        axios({
            method: 'GET',
            url: lambda + '/users?appname=' + appname + "&token=" + token,
        })
            .then(function (response) {
                console.log("response", response);
                if (response?.data?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                setUser(response?.data?.result);
                setData(response?.data?.result?.data);
                global.exportExcelData = response?.data?.result?.data
                      


                setIsLoading(false);
                setUserSearch("");
                setSearchedFlag(false);
                }
            }).catch((err) => {
                console.log('error', err);
                setTableErrMsg(true)
                setIsLoading(false);
            });
    }



  

    const handleSearch = (e,flagggg) => {
        GetTimeActivity()   
        if(flagggg){
            setcurrentPage(1);
          } 
        setmaxPageNumberLimit(5);
        setminPageNumberLimit(0);
        if(flagggg === "normalsearch"){
            setSearchedFlag(true)
       }
        setFlag(true)
        if (usersearch === "") {
            User();
        }
        else {
            setIsLoading(true)
            const token = localStorage.getItem("token")
            axios({
                method: 'GET',
                url: lambda + '/users?appname=' + appname + "&search=" + usersearch + "&token=" + token,
            })
                .then(function (response) {
                    if (response?.data?.result == "Invalid token or Expired") {
                        setShowSessionPopupup(true)
                    } else {
                        console.log("response", response);
                        setUser(response?.data?.result);
                        setData(response?.data?.result?.data);
                        global.exportExcelData = response?.data?.result?.data
                      


                        setIsLoading(false)
                    }
                }).catch((err) => {
                    console.log('error', err);
                    setTableErrMsg(true)
                    setIsLoading(false);
                });
        }
    }

    const handleChange = (e) => {
        if (e.target.value === "") {
         //   User();
            setFlag(false)
        }
        setUserSearch(e.target.value)
    }



    const handleKeypress = (e) => {
        //it triggers by pressing the enter key
        GetTimeActivity()   
        if ((e.key === "Enter")) {
            setTimeout(function () {
                handleSearch();
            }, 1000);
        }
    };
    const handleAddUser = (e) => {
        GetTimeActivity()   
        history.push("/adduser");
    }

    const handleEditUser = (e, paramuserid) => {
        console.log('paramuserid',paramuserid)
        GetTimeActivity()   
        history.push("/edituser/" + paramuserid);
    }
    const handleViewUser = (e, paramuserid) => {
        GetTimeActivity()   
        console.log('paramuserid',paramuserid)

        history.push("/viewuser/" + paramuserid);
    }

    const clearSearch = () => {
        GetTimeActivity()   
        setUserSearch("");
        User();
        setcurrentPage(1);
    }

    const customNoRecords = () => {
        return(
            
            <div className="empty-state-body empty-record"  >
                <div className="empty-state__message">
                    <span className="material-icons">summarize</span>
                    <p className="form-check font-size-16">No users were found for the searched keyword</p>
                </div> </div>
        )
    }
    const handleExport = () => {
     
              // let k = defaultData || exportExcelData
          const modifiedArray = global.exportExcelData.map(( each,i) => ({
                "S.NO":i+1,
                "NAME": each?.name ?? "",
                "USER TYPE": each?.type ?? "",
                "EMAIL ID":   typeof each?.emailid === "object" && each?.emailid !== null && each?.emailid.length > 1 ? each?.emailid.join(', ') : each?.emailid ?? "",
                "PHONE NUMBER": each?.phone ?? "" ,
                "STATUS": each?.status?? "",
              
              }));
         
            exportToExcel(modifiedArray, "data");
           
        }
        function exportToExcel(jsonData, fileName) {
            delete jsonData['_id'];
            const worksheet = XLSX.utils.json_to_sheet(jsonData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const data1 = new Blob([excelBuffer], { type: 'application/octet-stream' });
            //FileSaver.saveAs(data, `${fileName}.xlsx`);
            FileSaver.saveAs(data1, `UsersData.xlsx`);
            console.log('data22222--->',data)
        }
        const customSort = (rows, selector, direction) => {
            const sortedData = rows.slice().sort((rowA, rowB) => {
              // use the selector function to resolve your field names by passing the sort comparators
              // const aField = selector(rowA);
        const aField = String(selector(rowA)).toLowerCase();

              //  const bField = selector(rowB);
          const bField = String(selector(rowB)).toLowerCase();
          
              let comparison = 0;
          
              if (aField > bField) {
                comparison = 1;
              } else if (aField < bField) {
                comparison = -1;
              }
          
              return direction === 'desc' ? comparison * -1 : comparison;
            });
          
            // Log the sorted data to the console
            // setSortedRecomData(sortedData)
            global.exportExcelData = sortedData
            // console.log('Sorted Data------>:', sortedData);
          
            return sortedData;
          };    
    // console.log("showSessionPopupup",showSessionPopupup)
    return (
        <>
            {showSessionPopupup && <SessionPopup />}
            <div id="layout-wrapper">
                <Header />
                <Sidebar />

                {/* Start right Content here  */}
              
                    <div className="main-content user-management">

                        <div className="page-content">
                            <div className="container-fluid">



                                <div className="row mb-4 breadcrumb">
                                    <div className="col-lg-12">
                                        <div className="d-flex align-items-center">

                                            <div className="flex-grow-1">
                                                <h4 className="mb-2 card-title">user management</h4>

                                            </div>
                                            {subValDashboard && subValDashboard.Add && subValDashboard.Add.display === true &&
                                                <div>
                                                    <button className="btn btn-primary" onClick={handleAddUser} disabled={subValDashboard && subValDashboard.Add && subValDashboard.Add.enable === false}>ADD USER</button>
                                                </div>}
                                        </div>
                                    </div>
                                </div>

                                <div className="row table-data">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row mb-2">
                                                <div className="col-sm-4 button-block">
                                                <div className="dataTables_length" id="datatable_length">
                                                                         <button className={`fill_btn ${data.length <= 0 ?'disable pe-none':''}`} onClick={handleExport}><span className="material-icons">north_east</span>Export</button>
                                                                     </div>
                                                </div>
                                                    <div className="col-sm-8">
                                                        <div className="search-box mb-2 d-inline-block">
                                                            <div className="position-relative">
                                                                <input type="text" className="form-control" value={usersearch} onChange={(e) => handleChange(e)} placeholder="Search by Name or Email" onKeyPress={handleKeypress} />
                                                                <button className="fill_btn" onClick={(e)=>handleSearch(e,"normalsearch")}><span className="material-icons search-icon">search</span></button>
                                                            </div>
                                                            <div className="dataTables_length" id="datatable_length">
                                                                <button className="fill_btn" onClick={clearSearch}><span className="material-icons-outlined">sync</span>Reset</button>
                                                            </div>
                                                          



                                                        </div>
                                                        <div className="text-sm-end">

                                                        </div>
                                                    </div>
                                                </div>

                                                  < DataTable  key={keyForRerender}
                                                 // title=""
                                                 columns={columns}
                                                 // className="table align-middle table-nowrap table-check"
                                                 keyField='_id'
                                                 data={data}
                                                 direction="auto"
                                                 highlightOnHover
                                                 fixedHeaderScrollHeight="300px"
                                                 pagination
                                                 responsive
                                                 persistTableHead
                                                 // selectableRowsVisibleOnly
                                                 striped
                                                 // selectableRowsHighlight
                                                 // selectableRows
                                                 subHeaderAlign="right"
                                                 defaultSortField="name"
                                                 subHeaderWra
                                                 noDataComponent={customNoRecords()}
                                                 paginationTotalRows={data.length}
                                                 onChangeRowsPerPage={handlePerRowsChange}
                                                 onChangePage={handlePageChange}
                                                 paginationPerPage={rowsPerPage}
                                                 paginationDefaultPage={currentPageNew}
                                                paginationRowsPerPageOptions={[15,25,50,75,100]}
                                                sortFunction={customSort}

                                                 paginationComponentOptions={{
                                                     rowsPerPageText: 'Per page:',
                                                     rangeSeparatorText: 'of',
                                                     noRowsPerPage: false,
                                                     selectAllRowsItem: false,
                                                     selectAllRowsItemText: 'All',
                                                   }}
                                           
                                                 progressPending={isLoading}
                                                 progressComponent={<TableLoader />}
                                                   />

                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <SweetAlert show={tableErrMsg}
                         custom
                         confirmBtnText="ok"
                         confirmBtnBsStyle="primary"
                         title={"Something went wrong. Please reload again."}
                         onConfirm={e => setTableErrMsg(false)}
                     >
                     </SweetAlert>
                        <Footer />

                    </div>
             


            </div>
        </>
    );
};

export default UserManagement;
