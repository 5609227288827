/***
**Module Name: bidsDashboard
 **File Name :  Bidsdashboard.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains bids dashboard table details.
 ***/
import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/dashboard/footer";
import Header from "../../../components/dashboard/header";
import Sidebar from "../../../components/dashboard/sidebar";
import { useHistory, Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import tmdbApi from "../../../api/tmdbApi";
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import SessionPopup from "../../SessionPopup"
import TableLoader from "../../../components/tableLoader";
import { contentContext } from "../../../context/contentContext";
import DataTable from 'react-data-table-component';
let { appname, lambda } = window.app;

const ItemMange = () => {
    const history = useHistory();
    const location = useLocation();
    const [image, setImg] = useState('');
    const [loaderEnable, setLoaderEnable] = useState(false);
    const [lookup, setLookUp] = useState({});
    const [countryData, setCountryData] = useState({});
    const [regionsData, setRegionsData] = useState({});
    const [typesofrights, setTypesofrights] = useState([]);
    const [revenueData, setRevenueData] = useState([]);
    const [exclusivityData, setExclusivityData] = useState([]);
    const [showSessionPopupup, setShowSessionPopupup] = useState(false);
    const [popup, setShowPopup] = useState(false);
    const [resetKey, setResetKey] = useState(0);
    const [tableErrMsg, setTableErrMsg] = useState(false);
    //   const [keyForRerender, setKeyForRerender] = useState(0);
    const { searchedFlag, setSearchedFlag, isLoading, setIsLoading, userData, data, setData, rowsPerPage, setRowsPerPage, currentPageNew, setCurrentPage, route, setRoute, usePrevious, setActiveMenuObj, setActiveMenuId, itemsearch, setItemSearch, sortingPayload, setSortingPayload, totalRows, setTotalRows ,SubRoute, setSubRoute} = useContext(contentContext)

    const validateObj = userData && userData.permissions && userData.permissions.length > 0 && userData.permissions.filter(eachItem => eachItem.menu === "Auction Management")
    const subValDashboard = validateObj && validateObj[0] && validateObj[0].submenus && validateObj[0].submenus[1].dashboard
    // console.log('subValDashboard obj',subValDashboard)

    const prevRoute = usePrevious(route)
    const keyForRerender = `${rowsPerPage}_${data?.length}_${data}`;
    useEffect(() => {
        if (prevRoute != undefined && prevRoute != route) {
            setCurrentPage(1)
            setRowsPerPage(15)
            setItemSearch("");
            setSearchedFlag(false);
            setSortingPayload("")

        }
    }, [prevRoute]);
    console.log('prevRoute--->', prevRoute, route)
    const prevSubRoute = usePrevious(SubRoute)
    useEffect(() => {
        if (prevSubRoute != undefined && prevSubRoute != SubRoute) {
            setCurrentPage(1)
            setRowsPerPage(15)

        }
    }, [prevSubRoute]);

    useEffect(() => {
        if (!localStorage.getItem("token")) {
            history.push("/");
        }
        setRoute("item");
        setSubRoute("item")
        setActiveMenuId(300021)
        setActiveMenuObj({
            "Contact Management": false,
            "Client Management": false,
            "Reports": false,
            "Auction Management": true
        })
        // getTitleBids();
        GetContentNew(currentPageNew)
        GetLookUp();
    }, []);
    useEffect(() => {

        if (window.site === undefined) {
            setTimeout(() => {
                if (window.site && window.site.common && window.site.common.imagesCDN) {
                    setImg(window.site.common.imagesCDN)
                }
            }, 1000);
        }
        if (window.site && window.site.common && window.site.common.imagesCDN) {
            setImg(window.site.common.imagesCDN)

        }

    }, [window.site]);


    const columns = [

        {
            name: 'Image',
            cell: (props) => <img src={`${props.itemimage ?
                image+ props.itemimage :
                props.itemimage ? image + props.itemimage :
                    `${image}orasi/common/images/${props.mediaType === 'video' ? 'videoclip-defaulr' : props.mediaType === 'audio' ? 'musicfile-default' : props.mediaType === 'pdf' ? 'pdf-default' : props.mediaType === 'doc' ? 'doc-defaulr' : 'img-default'}.jpg`}?auto=compress,format&width=40`} alt='Image' />,
            sortable: false,
        },
        {
            name: 'Title',
            selector: row => row.title,
            sortable: true,
        },
        {
            name: '# bidded countries/regions',
            // cell: (props) =>  <div> {props && props.category && props.category.join(", ")}</div>,
            selector: row => row.countriescount,
            sortable: true,
        },
        {
            name: '# bidders',
            selector: row => row.bidderscount,
            sortable: true,
        },
        {
            name: 'start date',
            //    cell: (props) =>   <div>{JSON.stringify(props.featured)}</div>,
            selector: row => row.bidstartdate ? new Date(row.bidstartdate).toLocaleDateString('en-IN', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
            }) : null,
            sortable: true,
        },
        {
            name: 'end date',
            //    cell: (props) =>   <div>{JSON.stringify(props.featured)}</div>,
            selector: row => row.bidenddate ? new Date(row.bidenddate).toLocaleDateString('en-IN', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
            }) : null,
            sortable: true,
        },
        {
            name: 'STATUS',
            selector: row => row.status === "INPROGRESS" ? "IN PROGRESS" : row.status,
            sortable: false,
        },
        {
            name: <>{(subValDashboard && subValDashboard.view && subValDashboard.view.display === true) || (subValDashboard && subValDashboard.edit && subValDashboard.edit.display === true) ? 'Actions' : null}</>,
            cell: (props) =>
                //  {
                subValDashboard && subValDashboard.view && subValDashboard.edit && (subValDashboard.view.display === true || subValDashboard.edit.display === true) &&
                <div className="d-flex" >

                    {subValDashboard && subValDashboard.view && subValDashboard.view.display === true &&
                        <>{props.status === "ACTIVE" ?
                            <a onClick={e => handleBids(e, props.itemid, props.title)} title="View Bids" className={`${subValDashboard && subValDashboard.view && subValDashboard.view.enable === false ? 'pe-none' : ''} text-success action-button bids-btn`}><i className="mdi mdi-ballot-outline font-size-18"></i></a> :
                            <a className="text-success action-button bids-btn" title="View Bids" style={{ opacity: "0.5", cursor: "initial" }}><i className="mdi mdi-ballot-outline font-size-18"></i></a>}</>}



                    <a onClick={(e) => handleViewItems(e, props.itemid)} title="View item" className="text-success action-button"><i className="mdi mdi-eye font-size-18"></i></a>



                    {subValDashboard && subValDashboard.edit && subValDashboard.edit.display === true &&

                        <a onClick={e => handleEditContent(e, props.itemid)} title="Edit item" className={`${subValDashboard && subValDashboard.edit && subValDashboard.edit.enable === false ? 'pe-none' : ''} text-danger action-button`}><i className="mdi mdi-pencil font-size-18" ></i></a>}
                </div>
            //  }
            ,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const handleEditContent = (e, id) => {
        // if( subValDashboard && subValDashboard.edit && subValDashboard.edit.enable === true){
        history.push("/edititem/" + id);
        // }

    }
    const handleViewItems = (e, id) => {
        // if( subValDashboard && subValDashboard.edit && subValDashboard.edit.enable === true){
        history.push("/viewitem/" + id);
        // }

    }
    const handleBids = (e, id, title) => {
        localStorage.setItem("title", title);
        history.push("/itembids/" + id);
    }
    // const handlePageChange = (page) => {
    //     setCurrentPage(page);
    // };

    // const handlePerRowsChange = (newPerPage) => {
    //     setRowsPerPage(newPerPage);
    // };
    const handlePageChange = (page) => {
        console.log("page", page);
        setCurrentPage(page);
        GetContentNew(page)

    };

    const handlePerRowsChange = (newPerPage, page) => {
        setRowsPerPage(newPerPage);
        setIsLoading(true);
        let payload = {}
        // if (bidsSearch) {
        //     payload = { 'title': bidsSearch }
        // } else {
        //     payload = bidsAdvSearch
        // }
        const token = localStorage.getItem("token")
        const userid = localStorage.getItem("userId")
        const linkUrl = `${lambda}/biddingItems?appname=${appname}&token=${token}&userid=${userid}&pageNumber=${page}&assetcount=${newPerPage}&type=${sortingPayload.type}&sortBy=${sortingPayload.sortBy}`
        axios({
            method: 'GET',
            url: linkUrl,
            data: payload
        })
            .then(function (response) {
                if (response?.data?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                    setIsLoading(false);
                } else {
                    const dataArray = Object.values(response?.data?.result?.data).map((obj) => obj);
                    setData(dataArray);
                    setIsLoading(false);

                }
            }).catch((err) => {
                console.log('error', err);
                setTableErrMsg(true)
                setIsLoading(false);
            });
    };


    const handleSort = (column, sortDirection) => {

        console.log('handle sort', column)
        // console.log('sort direction', sortDirection)
        let name = column?.name.toLowerCase();
        name = name === "# bidded countries/regions" ? "countriescount" : name === "# bidders" ? "bidderscount" : name === "start date" ? "bidstartdate" : name === "end date" ? "bidenddate" : name === "max bid price (usd)" ? "maxbidprice" : name
        let direction = sortDirection === "asc" ? "ascending" : "descending"
        let payload = { "type": direction, "sortBy": name }
        setSortingPayload(payload)

    };
    useEffect(() => {
        if (sortingPayload) {
            GetContentNew(currentPageNew)
        }
    }, [sortingPayload])
    const GetContent = async (page) => {
        // console.log('get content function ')
        setIsLoading(true);
        const token = localStorage.getItem("token")
        const userid = localStorage.getItem("userId")
        const linkUrl = `${lambda}/biddingItems?appname=${appname}&token=${token}&userid=${userid}&pageNumber=${page}&assetcount=${rowsPerPage}`

        axios({
            method: 'GET',
            // url: lambda + '/content?appname=' + appname + "&token=" + token + "&userid=" + userid,
            url: linkUrl,

        })
            .then(function (response) {
                if (response?.data?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                    setIsLoading(false);
                } else {
                    const dataArray = Object.values(response?.data?.result?.data).map((obj) => obj);
                    setData(dataArray);
                    setIsLoading(false);
                    setTotalRows(response?.data?.result?.assetTotal);
                    setSearchedFlag(false);

                }
            }).catch((err) => {
                console.log('error', err);
                setTableErrMsg(true)
                setIsLoading(false);
            });
    };
    const GetContentNew = async (page) => {
        setIsLoading(true);
        const token = localStorage.getItem("token");
        const userid = localStorage.getItem("userId");
        let linkUrl = `${lambda}/biddingItems?appname=${appname}&token=${token}&userid=${userid}&pageNumber=${page}&assetcount=${rowsPerPage}`;
        let payload = {};

        if (sortingPayload) {
            linkUrl = `${lambda}/biddingItems?appname=${appname}&token=${token}&userid=${userid}&pageNumber=${page}&assetcount=${rowsPerPage}&type=${sortingPayload.type}&sortBy=${sortingPayload.sortBy}`;
        }
        if(itemsearch){
            linkUrl = `${lambda}/biddingItems?appname=${appname}&token=${token}&userid=${userid}&pageNumber=${page}&assetcount=${rowsPerPage}&search=${itemsearch?.trim()}`
        }
        if(itemsearch && sortingPayload){
            linkUrl=`${lambda}/biddingItems?appname=${appname}&token=${token}&userid=${userid}&pageNumber=${page}&assetcount=${rowsPerPage}&search=${itemsearch?.trim()}&type=${sortingPayload.type}&sortBy=${sortingPayload.sortBy}`
        }

        axios({
            method: 'GET',
            url: linkUrl,
            data: payload
        })
            .then(function (response) {
                if (response?.data?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true);
                    setIsLoading(false);
                } else {
                    const dataArray = Object.values(response?.data?.result?.data).map((obj) => obj);
                    setData(dataArray);
                    //setKeyForRerender(prevKey => prevKey + 1); 
                    setIsLoading(false);
                    setTotalRows(response?.data?.result?.totalcount);
                    setSearchedFlag(false);
                }
            })
            .catch((err) => {
                console.log('error', err);
                setTableErrMsg(true)
                setIsLoading(false);
            });
    };
    console.log("data", data);

    const GetLookUp = async (e) => {
        try {
            let arrayType = ["country", "genre", "videoformat", "resolution", "musicgenre", "rights", "cuisine", "sports", "certificate", "language", "territories", "region", "exclusivity", "revenue"];

            const response = await tmdbApi.getBiddingLookUp({
                type: arrayType,
                status: 'ACTIVE'

            });
            // console.log('response here', response);
            if (response.statusCode == 200) {
                let arrayType = ["country", "genre", "videoformat", "resolution", "musicgenre", "rights", "cuisine", "sports", "certificate", "language", "territories", "region", "exclusivity", "revenue"];

                let lookupsData = response.result?.data || []


                arrayType.map((eachItem) => {
                    let arr1 = []

                    lookupsData.map((item1) => {
                        if (item1.type == eachItem) {
                            arr1.push(item1.name)
                            if (item1.type == "rights") {
                                setTypesofrights(typesofrights => ([
                                    ...typesofrights,
                                    item1
                                ]));

                            }
                        }
                    });
                    lookup[eachItem] = arr1
                    setLookUp({ ...lookup });
                })
                //  let arrayType = ["country","genre","videoformat","resolution","musicgenre","rights","cuisine","sports","certificate"];

                const countryObj = lookup.country.map((eachItem) => { return { value: eachItem, label: eachItem }; })
                setCountryData(countryObj)
                const regionsObj = lookup.region.map((eachItem) => { return { value: eachItem, label: eachItem }; })
                setRegionsData(regionsObj)
                const exclusivityObj = lookup.exclusivity
                setExclusivityData(exclusivityObj)
                const revenueObj = lookup.revenue.map((eachItem) => { return { value: eachItem, label: eachItem }; })
                setRevenueData(revenueObj)

            }

        } catch {
            console.log("error");
        }
    }


    const handleChange = (e) => {
        if ((e.key === "Enter")) {
            setTimeout(function () {
                handleSearch("normalsearch");
            }, 100);
        }
        setItemSearch(e.target.value)
    }


    console.log("total", totalRows)

    const handleSearch = (e, flagggg) => {

        if (flagggg === "normalsearch") {
            setSearchedFlag(true)
        }
        if (flagggg) {
            setCurrentPage(1);
            setRowsPerPage(15);
        }
        // setFlag(true)

        if (itemsearch === "") {
            console.log("called get content");
            GetContent(1)
        }

        else {
            let payload
            setIsLoading(true);
            console.log("starting");

            const token = localStorage.getItem("token")
            const userid = localStorage.getItem("userId")
            let urlLink = `${lambda}/biddingItems?appname=${appname}&token=${token}&userid=${userid}&pageNumber=${1}&assetcount=${rowsPerPage}&search=${itemsearch?.trim()}`
            if(sortingPayload){
                urlLink = `${lambda}/biddingItems?appname=${appname}&token=${token}&userid=${userid}&pageNumber=${1}&assetcount=${rowsPerPage}&search=${itemsearch?.trim()}&type=${sortingPayload.type}&sortBy=${sortingPayload.sortBy}`
            }

            axios({
                method: 'GET',
                url: urlLink,

            })
                .then(function (response) {
                    if (response.data.result) {
                        if (response.data.result.data && response.data.result.data.length == 0) {
                            //  setSuccess(true);
                            setIsLoading(false)
                        } else if (response.data.result == "Invalid token or Expired") {
                            setShowSessionPopupup(true)
                        }
                        console.log("response", response.data.result);
                        setData(response.data.result.data);
                        setTotalRows(response?.data?.result?.totalcount);
                        setIsLoading(false);

                    }
                });
        }
    }


    const clearSearch = (e) => {
      setItemSearch("")
        // getTitleBids();
        setCurrentPage(1)
        GetContent(1)
    }
    const customNoRecords = () => {
        return (

            <div className="empty-state-body empty-record"  >
                <div className="empty-state__message">
                    <span className="material-icons">summarize</span>
                    <p className="form-check font-size-16">No bids were found for the searched keyword</p>
                </div> </div>
        )
    }

    const handleKeypress = (e) => {
        //it triggers by pressing the enter key

        if ((e.key === "Enter")) {
            setTimeout(function () {
                handleSearch("normalsearch")
            }, 10);
        }
    };
    const handleAddContent = () => {
        history.push("/additem/");
    }
    return (
        <>
            {showSessionPopupup && <SessionPopup />}
            <div id="layout-wrapper">
                <Header />
                <Sidebar />

                <div className="main-content user-management content-management  item-management">

                    <div className="page-content">
                        <div className="container-fluid">

                            <div className="row mb-4 breadcrumb">
                                <div className="col-lg-12">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <div className="title-block">
                                                <h4 className="mb-2 card-title">item management</h4>
                                                <div className="dropdown d-flex">

                                                    {subValDashboard && subValDashboard.add && subValDashboard.add.display === true &&
                                                        <button className={`${subValDashboard && subValDashboard.add && subValDashboard.add.enable === false ?
                                                            'disable' : ''
                                                            } btn-primary me-2`} type="button" disabled={subValDashboard && subValDashboard.add && subValDashboard.add.enable === false} onClick={handleAddContent}>
                                                            CREATE ITEM

                                                        </button>}

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row table-data">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row mb-2">

                                                <div className="col-sm-12">
                                                    <div className="search-box mb-2 d-inline-block">

                                                        <div className="position-relative">
                                                            <input type="text" className="form-control" placeholder="Search Title" onChange={(e) => handleChange(e)} 
                                                             value={itemsearch} 
                                                            onKeyPress={handleKeypress} />
                                                            <button className="fill_btn"
                                                                onClick={(e) => handleSearch(e, "normalsearch")} ><span className="material-icons search-icon"
                                                                >search</span></button>
                                                        </div>
                                                        <div className="dataTables_length" id="datatable_length">
                                                            <button className="fill_btn" onClick={clearSearch}><span className="material-icons-outlined">sync</span>Reset</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            < DataTable key={keyForRerender}
                                                // title=""
                                                columns={columns}
                                                // className="table align-middle table-nowrap table-check"
                                                keyField='_id'
                                                data={data}
                                                direction="auto"
                                                highlightOnHover
                                                fixedHeaderScrollHeight="300px"
                                                pagination
                                                paginationServer
                                                responsive
                                                persistTableHead
                                                // selectableRowsVisibleOnly
                                                striped
                                                // selectableRowsHighlight
                                                // selectableRows
                                                // subHeaderAlign="right"
                                                // defaultSortField="name"
                                                sortServer={true}
                                                defaultSortField="" // Ensure no default sorting field 
                                                subHeaderWrap
                                                noDataComponent={customNoRecords()}
                                                paginationTotalRows={totalRows}
                                                onChangeRowsPerPage={handlePerRowsChange}
                                                onChangePage={handlePageChange}
                                                paginationPerPage={rowsPerPage}
                                                paginationDefaultPage={currentPageNew}
                                                paginationRowsPerPageOptions={[15, 25, 50, 75, 100]}

                                                paginationComponentOptions={{
                                                    rowsPerPageText: 'Per page:',
                                                    rangeSeparatorText: 'of',
                                                    noRowsPerPage: false,
                                                    selectAllRowsItem: false,
                                                    selectAllRowsItemText: 'All',
                                                }}
                                                onSort={handleSort}
                                                // sortFunction={customSort}
                                                progressPending={isLoading}
                                                progressComponent={<TableLoader />}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                



                </div>

            </div>
        </>
    );
};
export default ItemMange;