/***
**Module Name: client
 **File Name :  client.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains clients table details.
 ***/
import React, { useState, useEffect, useContext } from "react";


import Footer from "../../components/dashboard/footer";
import Header from "../../components/dashboard/header";
import Sidebar from "../../components/dashboard/sidebar";
import tmdbApi from "../../api/tmdbApi";
import { useHistory, Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import SessionPopup from "../SessionPopup"
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import moment from "moment";
import Loader from "../../components/loader";
import TableLoader from "../../components/tableLoader";
import { contentContext } from "../../context/contentContext";
import Multiselect from 'multiselect-react-dropdown';
import { MultiSelect } from "react-multi-select-component";

import CurrencyInput from 'react-currency-input-field';

let { lambda, appname } = window.app;


const AddDeals = () => {
    const history = useHistory();
    let { id } = useParams();
    const [image, setImg] = useState('');
    const [allDealsData, setAllDealsData] = useState({ 'title': [] });
    const [companiesData, setCompaniesData] = useState([]);
    const [followUp, setFollowUp] = useState("");
    const [success, setSuccess] = useState(false);
    const [followUpData, setFollowUpData] = useState({});
    const [errors, setErrors] = useState({});
    const [buyerdata, setBuyerData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selecteddata, setSelectedData] = useState({});
    const [categoryName, setCategoryName] = useState([]);
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [lookup, setLookUp] = useState({});
    const [languagesData, setlanguagesData] = useState({});
    const [countryData, setCountryData] = useState({});
    const [videoformatData, setVideoformatData] = useState({});
    const [rightsData, setRightsData] = useState({});
    const [showSessionPopupup, setShowSessionPopupup] = useState(false)
    const [loaderEnable, setLoaderEnable] = useState(false);
    const [saveLoaderEnable, setSaveLoaderEnable] = useState(false);
    const [filter, setFilter] = useState([]);

    const [searchResult, setSearchResult] = useState({});
    const [isLoadingValues, setIsLoadingValues] = useState(false);

    const [dealContent, setDealContent] = useState({});
    const [Id, setId] = useState("");
    const [contentData, setContentData] = useState([]);
    const [datasuccess, setDataSuccess] = useState(false);
    const [limitReached, setLimitReached] = useState(false);
    const [catDeletePopup, setCatDeletePopup] = useState(false);

    const [titleerror, setTitleError] = useState("");
    const [commentsError, setCommentsError] = useState("");
    const [adderror, setAddError] = useState("");
    const [categoryerror, setCategoryError] = useState("");
    const [titleCount, setTitleCount] = useState(0);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [uniqueErr, setuniqueErr] = useState("");
    const [selected, setSelected] = useState([]);

    const [duplicateFlag, setDuplicateFlag] = useState(false);
    //const [contentData, setContentData] = useState([]);
    const { route, setRoute, setCurrentPage, activeTab, navigateToggle, saveLeadId, setSaveLeadId, leadData, setLeadData, flagg, setFlagg, setActiveMenuObj, setNavigateToggle, setActiveTab, setRowsPerPage, usePrevious, userData, setActiveMenuId, GetTimeActivity } = useContext(contentContext);

    const prevRoute = usePrevious(route)
    useEffect(() => {
        if (prevRoute != undefined && prevRoute != route) {
            setCurrentPage(1)
            setRowsPerPage(15)
        }
    }, [prevRoute]);

    useEffect(() => {
        if (!localStorage.getItem("token")) {
            history.push("/");
        }
        setRoute("deal")
        setActiveMenuId(300011)
        setActiveMenuObj({
            "Client Management": false,
            "Contact Management": true,
            "Reports": false
        })
        getBuyerList();
        Categories();
        GetLookUp();
        Companies();
        userActivity();
    }, []);
    useEffect(() => {
        if (saveLeadId?.length > 0) {
            const data = buyerdata?.filter((item) => item.name === leadData?.leadname)

            setFilterData(data)
            setAllDealsData({
                ...allDealsData,
                name: data?.[0]?.name,
                companyname: data?.[0]?.companyname,
                accountmanager: data?.[0]?.accountmanager ?? data?.[0]?.companyData?.[0]?.accountmanager

            })
        }

    }, [buyerdata]);


    const userActivity = () => {
        let path = window.location.pathname.split("/");
        const pageName = path[path.length - 1];
        var presentTime = moment();
        let payload;

        payload = {
            "userid": localStorage.getItem("userId"),
            "pagename": pageName,
            "pageurl": window.location.href,
            "starttime": presentTime,
            "useragent": JSON.parse(localStorage.getItem("loc"))

        };


        const previousId = localStorage.getItem("previousid");
        const urlLink = lambda + '/useractivity?appname=' + appname + (previousId ? "&previousid=" + previousId : "");


        axios({
            method: 'POST',
            url: urlLink,
            data: payload
        })
            .then(function (response) {
                if (response?.data?.statusCode === 200) {
                    localStorage.setItem("previousid", response?.data?.result)
                }
            });
    }
    // console.log('KIRAMMM',allDealsData)
    // useEffect(() => {

    //     getOptionData();
    // }, [allDealsData.companyid]);

    // useEffect(() => {
    //     if (allDealsData.requiredfromdate && allDealsData.requiredtodate) {

    //         if (allDealsData.requiredtodate < allDealsData.requiredfromdate) {
    //             setDataSuccess(true)
    //             // error["date"] ='To date cannot be earlier than From date';
    //             // formIsValid = false;
    //         }


    //     }
    // }, [allDealsData.requiredfromdate, allDealsData.requiredtodate]);

    // console.log('searchResult',searchResult)
    useEffect(() => {

        if (window.site === undefined) {
            setTimeout(() => {
                if (window.site && window.site.common && window.site.common.imagesCDN) {
                    setImg(window.site.common.imagesCDN)
                }
            }, 1000);
        }
        if (window.site && window.site.common && window.site.common.imagesCDN) {
            setImg(window.site.common.imagesCDN)
        }

    }, [window.site]);

    const Categories = async () => {
        GetTimeActivity()
        try {

            const response = await tmdbApi.getCategory({});
            if (response?.statusCode === 200) {
                if (response?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    setData(response?.result);
                    let arr = []
                    response?.result.data.forEach((item) => {
                        arr.push(item.name);
                    });

                    const arrOfObj = arr.map((item) => {
                        return { value: item, label: item };
                    });
                    setCategoryName(arrOfObj);
                }
            }
        } catch {
            console.log("error");
        }
    };

    const getBuyerList = (e) => {
        setLoaderEnable(true)
        GetTimeActivity()
        const token = localStorage.getItem("token")
        const userid = localStorage.getItem("userId")
        axios({
            method: 'GET',
            url: lambda + '/clientType?appname=' + appname + "&token=" + token + '&userId=' + userid,
        })
            .then(function (response) {
                if (response?.data?.result === "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {

                    setBuyerData(response?.data?.result?.data);
                    setLoaderEnable(false)
                }

            });
    }

    const handleChange = (e) => {
        GetTimeActivity()
        if (e.target.name === "comments") {
            setFilter({ ...filter, comments: e.target.value })
            setAllDealsData({ ...allDealsData, [e.target.name]: e.target.value });
            setCommentsError("")
        } else {

            setAllDealsData({ ...allDealsData, [e.target.name]: e.target.value });
        }
    }

    const handleBack = () => {
        GetTimeActivity()
        setActiveTab("details")
        // history.push("/dealmanagement");
        history.goBack()
    }

    function onConfirm() {
        GetTimeActivity()
        setSuccess(false);
        history.push('/editdeals/' + Id)
        // history.push("/dealmanagement");

    };


    function onConfirm1() {
        GetTimeActivity()
        setDataSuccess(false);
        // setAllDealsData({ ...allDealsData, requiredtodate: "" })

    };
    function onConfirm2() {
        GetTimeActivity()
        setLimitReached(false);
        // setAllDealsData({ ...allDealsData, requiredtodate: "" })

    };
    const returnArray = (arr) => {
        let arr2 = []
        //  console.log(arr);
        arr.map((eachItem) => {
            arr2.push(eachItem.value)
        })
        // console.log(arr2)
        return arr2
    }
    const returnArray1 = (arr) => {
        let arr2 = []
        //  console.log(arr);
        arr.map((eachItem) => {
            arr2.push(eachItem.value.toString())
        })
        // console.log(arr2)
        console.log("arr2", arr2);
        return arr2
    }
    const getOptionData = (selectedArray, getcompanyid) => {
        setIsLoadingValues(true)
        GetTimeActivity()
        setLoaderEnable(true);
        const token = localStorage.getItem("token")
        const userid = localStorage.getItem("userId")


        const linkUrl = `${lambda}/content?appname=${appname}&token=${token}&userid=${userid}&sortBy=title`;



        const categoryobj = {
            category: selectedArray,
            status: ["ACTIVE", "OFFLINE"]
        };

        if (getcompanyid && getcompanyid.length > 0) {
            categoryobj.companyid = getcompanyid;
        }

        axios({
            method: 'POST',
            url: linkUrl,
            data: categoryobj,
        })
            .then(function (response) {
                // console.log("res1", response)
                let result = response?.data?.result?.data
                //  setSearchResult(response?.data?.result?.data)
                const searchresult = result.map((eachItem) => { return { value: eachItem.title, label: eachItem.title, portraitimage: eachItem.portraitimage, landscapeimage: eachItem.landscapeimage, contentid: eachItem.contentid, category: eachItem.category }; })
                setSearchResult(searchresult);
                setLoaderEnable(false);
                setIsLoadingValues(false);
            }).catch((err) => { setIsLoadingValues(false); });
    }

    //   console.log('searchResult',selected)


    const Companies = async (e) => {
        GetTimeActivity()

        const token = localStorage.getItem("token")
        const userid = localStorage.getItem("userId")

        //  setcurrentPage(1);
        let obj = { sortby: 'companyname' }
        axios({
            method: 'POST',
            url: lambda + '/companies?appname=' + appname + "&token=" + token + "&userid=" + userid,
            data: obj
        })
            .then(function (response) {
                // console.log("response", response?.data?.result?.data);
                if (response?.data?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    setCompaniesData(response?.data?.result?.data);
                }
            }).catch((error) => {
                console.log(error);
            });
    }
    const handleCheckTitle = (selectedData) => {

        let valid = true
        GetTimeActivity()
        // console.log("selected------------>", selectedData, contentData);
        if (selectedData.title && selectedData.title.length > 0) {
            selectedData.title?.map((eachItem) => {
                // console.log("each", eachItem)
                contentData && contentData?.map((each) => {
                    if (each.title === eachItem.value) {
                        // console.log("eachhhhhhhhh", eachItem, each);
                        valid = false
                    }

                })

                // const filteredArray = contentData && contentData.length > 0 && contentData.filter((each) => each.title === eachItem.value);
                // setDuplicate(filteredArray)
                // console.log("filteredArray",filteredArray)
            })
        } else if (selectedData.title && selectedData.title.length <= 0) {
            valid = false
        }
        return valid;

    }
    const handleSelectTitles = () => {
        // setAllDealsData({...allDealsData,title:[...allDealsData?.title,...selected]})
        // Use Set to remove duplicates based on object content
        const A = allDealsData?.title
        const B = selected
        const combinedSet = new Set([...A, ...B].map(obj => JSON.stringify(obj)));

        // Convert Set back to array
        const combinedArray = [...combinedSet].map(str => JSON.parse(str));

        console.log(combinedArray);
        setAllDealsData({ ...allDealsData, title: combinedArray })
        setSelected([])
        setTitleError("")
        setuniqueErr("")
    }
    const handleRemoveTitle = (e, id) => {
        GetTimeActivity()
        const filteredArray = allDealsData?.title.filter((eachItem) => eachItem.contentid != id);
        setAllDealsData({ ...allDealsData, title: filteredArray });
        setTitleError("")
        setuniqueErr("")
    }
    // console.log('deals data',allDealsData)
    const handleChangeMultiSelect = (selected, key) => {
        console.log('seclectedin handle', selected)
        GetTimeActivity()
        let newContent = Object.assign({}, allDealsData);
        if (key == 'title') {
            // console.log("selected..", selected, newContent)
            if (flagg.length > 0) {
                setAllDealsData({ ...allDealsData, name: leadData?.leadname })
            } else {
                setAllDealsData({ ...newContent, [key]: selected });
            }

            setSelectedData(selected);
            setFilter({ ...filter, contenttitle: selected.value })
            setTitleError("")
            setCommentsError("")
            setuniqueErr("")
        }
        else if (key == 'buyer') {
            // console.log('selected-->',selected)
            setErrors({})
            setSelectedData(selected);
            setAllDealsData({ ...newContent, name: [selected.value], companyid: selected.companyid, companyname: [selected.companyname], email: selected.email, type: selected.type, clientid: selected.clientid, accountmanager: selected.accountmanager });
        }
        else if (key == 'companyid') {
            let selectedArray = returnArray1(selected);
            getOptionData(allDealsData.category, selectedArray);
            setFilter({ ...filter, [key]: selectedArray, contenttitle: [] });
            setSelected([])
            setuniqueErr("")
            setTitleError("")
        } else {

            let selectedArray = returnArray(selected);
            setAllDealsData({ ...newContent, [key]: selectedArray });

            if (key === "category") {
                setTitleError("")
                setuniqueErr("")
                // console.log("came")
                setFilter({ ...filter, category: selectedArray })
                getOptionData(selectedArray, filter.companyid);
                if (selectedArray <= 1) {
                    if (allDealsData?.title?.length > 0) {
                        setCatDeletePopup(true)
                    }
                    // setAllDealsData({ ...allDealsData, title: [] })
                    setSelected([])
                    setFilter({ ...filter, contenttitle: [], comments: "", category: [] })
                }
            }

        }
    }
    // console.log('dealss data',allDealsData)
    const handleSearch = (e) => {
        console.log('selected length', selected?.length)
        GetTimeActivity()
        const totalLength = e?.length + allDealsData?.title?.length + contentData?.length;

        if (totalLength > 100) {
            setLimitReached(true);
        } else {
            console.log('e.target==>', e)
            setSelected(e)
            let newContent = Object.assign({}, allDealsData);
            // setAllDealsData({ ...newContent, "title": e });
            setTitleError("")
            setuniqueErr("")
            setAddError("")
            setCommentsError("")
        }
    }
    const handleAdd = () => {

        let valid = handleCheckTitle(allDealsData);
        // console.log("valid......", valid)
        GetTimeActivity()
        setAddError("")
        if (allDealsData.category === undefined || allDealsData.category === "" || allDealsData.category.length <= 0) {
            setCategoryError("Please Select Category")
            setAllDealsData({ ...allDealsData, title: [] })
            setFilter({ ...filter, contenttitle: [] })
        }
        if (allDealsData?.title === undefined || allDealsData?.title === "") {
            console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaa')
            setTitleError("Please Select Titles")
        }

        // if((allDealsData?.title === undefined || allDealsData?.title === "" || allDealsData?.title.length <= 0) && (allDealsData?.comments === undefined || allDealsData?.comments.trim() === "" || allDealsData?.comments.length <= 0)){
        //     setCommentsError("Please add comments as titles have not been selected yet.")

        // }
        else {
            // console.log("add", allDealsData)
            if (allDealsData?.title !== undefined && allDealsData?.category != undefined && allDealsData?.category != "") {
                let obj = {}
                let arr = [...contentData]
                if (valid === true) {
                    allDealsData?.title?.map((eachItem) => {

                        // console.log("eachItem", eachItem, contentData)
                        obj = {
                            category: eachItem?.category,
                            contentid: eachItem?.contentid,
                            title: eachItem?.value,
                            portraitimage: eachItem?.portraitimage,
                            landscapeimage: eachItem?.landscapeimage,
                            comments: allDealsData?.addcomments,
                            clientid: allDealsData?.clientid
                        }
                        arr.push(obj);
                    })


                    //arr.push(obj);
                    if (allDealsData?.category !== "" && allDealsData?.category !== undefined) {
                        setContentData(arr);
                    }
                    // console.log("contentData", contentData)
                    setAllDealsData({ ...allDealsData, addcomments: "", contentid: "", portraitimage: "", title: [] })
                    setSelected([])
                    setFilter({ ...filter, contenttitle: [], addcomments: "" })
                    // setSearchResult({})
                    setTitleError("")
                    setuniqueErr("")
                    setCommentsError("")
                } else {
                    if (allDealsData?.title === undefined || allDealsData?.title === "" || allDealsData?.title.length <= 0) {
                        setTitleError("Please Select Titles");
                        return
                    } else {
                        setuniqueErr("Title(s) already exists. Please select unique title(s).")
                    }
                }
            }
        }

    }

    console.log("searchResult len----->", searchResult);
    console.log("selected len----->", selected);
    console.log("allDealsData len----->", allDealsData?.title);

    // console.log('allDealsData allDealsData',allDealsData)

    const handleDeleteDeal = (e, id) => {
        GetTimeActivity()
        const filteredArray = contentData.filter((eachItem) => eachItem.contentid != id);
        setContentData(filteredArray);
    }

    const GetLookUp = async (e) => {
        GetTimeActivity()
        try {
            let arrayType = ["country", "videoformat", "language", "territories"];

            const response = await tmdbApi.getLookUp({
                // type: arrayType

            });
            // console.log(response);
            if (response?.statusCode == 200) {
                let arrayType = ["country", "videoformat", "language", "territories", "rights"];
                let lookupsData = response?.result.data || []
                arrayType.map((eachItem) => {
                    let arr1 = []
                    lookupsData.map((item1) => {
                        if (item1.type == eachItem) {
                            arr1.push(item1.name)
                        }
                    });
                    lookup[eachItem] = arr1
                    setLookUp({ ...lookup });
                })
                // console.log('loookup', lookup)
                const countryObj = prepareObject(lookup.country)
                const territoriesObj = prepareObject(lookup.territories)
                // console.log("countryObj", countryObj)
                // console.log("territoriesObjt", territoriesObj)
                setCountryData([...countryObj, ...territoriesObj])
                const languagesObj = prepareObject(lookup.language)
                setlanguagesData(languagesObj)
                const videoformatObj = prepareObject(lookup.videoformat)
                setVideoformatData(videoformatObj)
                const rightsObj = prepareObject(lookup.rights)
                setRightsData(rightsObj)

            }

        } catch {
            console.log("error");
        }
    }
    const prepareObject = (item) => {
        const resultArr = item.map((eachItem) => { return { value: eachItem, label: eachItem }; })
        return resultArr
    }

    const validate = async () => {
        GetTimeActivity()
        let formIsValid = true;
        let error = { ...errors };
        let mandatoryFileds = [{ name: 'Buyer', key: 'name' },

        ];

        if (mandatoryFileds) {
            mandatoryFileds.forEach(function (item) {

                if (
                    (allDealsData[item.key] == "" ||
                        allDealsData[item.key] == undefined ||
                        allDealsData[item.key] == "undefined")
                ) {
                    error[item.key] = item.name + " is required";
                    formIsValid = false;
                }
            });

        }
        // if(allDealsData?.accountmanager === undefined || allDealsData?.accountmanager === ""){
        //     error.accountmanager = "For the selected buyer, the assigned company doesn't have an account manager.";
        //     formIsValid = false;

        // }else{
        //     delete error?.accountmanager
        // }
        // console.log('filter--->',filter)
        // if (contentData.length <= 0) {
        //     if (allDealsData.category === undefined || allDealsData.category === "") {
        //         setCategoryError("Please Select Category");
        //     }
        //     if (filter?.contenttitle?.length <= 0) {
        //         setTitleError("Please Select Title");
        //     }
        //     if (allDealsData?.title === undefined || allDealsData?.title === "" || allDealsData?.title.length <= 0) {
        //         setTitleError("Please Select Titles");
        //     }


        //     setAddError("Please Add Title");
        //     formIsValid = false;
        // }
        if ((allDealsData?.title.length > 0) && contentData.length <= 0) {
            const errorMsg = `${allDealsData?.title?.length > 1 ? "titles" : "title"}`
            setAddError("Please add the selected " + errorMsg);
            formIsValid = false;
        }
        if (((allDealsData?.title === undefined || allDealsData?.title === "" || allDealsData?.title.length <= 0) && contentData.length <= 0) && (allDealsData?.comments === undefined || allDealsData?.comments.trim() === "" || allDealsData?.comments.length <= 0)) {
            setCommentsError("Please add comments as titles have not been selected yet.")
            formIsValid = false;

        }
        if (allDealsData?.requiredtodate < allDealsData?.requiredfromdate) {
            setDataSuccess(true)
            formIsValid = false;
            // error["date"] ='To date cannot be earlier than From date';
            // formIsValid = false;
        }
        if (contentData.length > 100) {
            setLimitReached(true)
            formIsValid = false;
        }
        console.log("errors", error);
        setErrors(error);
        return formIsValid;
    }

    const handleSave = () => {
        GetTimeActivity()
        const isValid = validate();
        isValid.then(result => {
            if (result === true) {
                handleAddDeal()
            }
        }).catch(error => {
            console.log(error);
        });
    }

    const handleRemoveError = async () => {
        GetTimeActivity()
        setTitleError("")
        setuniqueErr("")
    }

    const handleCatRemoveError = async () => {
        GetTimeActivity()
        setCategoryError("")
    }

    const handleAddDeal = async () => {
        GetTimeActivity()
        // let obj = {

        // }
        setSaveLoaderEnable(true);
        setButtonDisabled(true);

        try {
            const response = await tmdbApi.AddDeal({
                content: contentData,
                clientid: allDealsData.clientid ?? filterData?.[0]?.clientid,
                clientname: allDealsData.name.toString(),
                clienttype: allDealsData.type ?? filterData?.[0]?.type,
                companyid: allDealsData.companyid ?? filterData?.[0]?.companyid,
                queries: allDealsData.queries ? allDealsData.queries?.trim() : "",
                request: allDealsData.request ? allDealsData.request?.trim() : "",
                rightsAvailable: allDealsData?.rightsavaliable ? allDealsData?.rightsavaliable : "",
                requiredtodate: allDealsData?.requiredtodate ? allDealsData?.requiredtodate : "",
                requiredfromdate: allDealsData?.requiredfromdate ? allDealsData?.requiredfromdate : "",
                exclusive: allDealsData.exclusivity ? allDealsData.exclusivity : "",
                regionsrequired: allDealsData.regions ? allDealsData.regions : "",
                totalbudget: allDealsData?.totalbudget ?? "",
                dubbinglanguagesrequired: allDealsData.dubbinglanguages ? allDealsData.dubbinglanguages : "",
                videoformatsrequired: allDealsData.videoformat ? allDealsData.videoformat : "",
                createdBy: { userid: userData.userid, username: userData.name },
                comments: allDealsData?.comments ? allDealsData?.comments?.trim() : ""
            });
            // console.log(response);
            if (response?.statusCode == 200) {
                if (response?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    // setSuccess(true);
                    // setAllDealsData({})
                    // setContentData({})
                    console.log('response?.result', response?.result)
                    const id = response?.result;
                    // setId(id)
                    setActiveTab("documents")
                    history.push('/editdeals/' + id)

                    // setAllDealsData({ ...allDealsData, name: "", rightsavaliable: "", requiredtodate: "", requiredfromdate: "", exclusivity: "", request: "", queries: "", dubbinglanguages: "", videoformat: "", regions: "", category: "", clientid: "", type: "", title: "", email: "", companyname: "", companyid: "", comments: "" })
                    setSelectedData({ ...selecteddata, companyname: "" })
                    setErrors({})
                    setSaveLoaderEnable(false);
                }
            }

        } catch {
            console.log("error");
        }



    };

    console.log('contentData', contentData)
    return (
        <>
            {showSessionPopupup && <SessionPopup />}

            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                {isLoading ?
                    <Loader />
                    :

                    <div className="main-content user-management add-client view-deals create-deals">

                        <div className="page-content ">
                            <div className="container-fluid">



                                <div className="row mb-4 breadcrumb">
                                    <div className="col-lg-12">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1">
                                                <h4 className="mb-2 card-title">CREATE DEALS</h4>
                                                <p className="menu-path">Deal Management / <b>Create Deals</b></p>
                                            </div>
                                            <div>
                                                <a onClick={handleBack} className="btn btn-primary">back</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="create-user-block">

                                    <div className="form-block">
                                        {loaderEnable && <TableLoader />}

                                        <div className="row">
                                            <h5 className="font-size-14"><i className="mdi mdi-arrow-right"></i> Buyer details</h5>
                                            <div className="col-md-4">
                                                <div className="mb-3 input-field">
                                                    <label className="form-label form-label">Select Buyer</label>
                                                    <Select isMulti={false}
                                                        placeholder='Select buyer'
                                                        onChange={(e) => handleChangeMultiSelect(e, 'buyer')}
                                                        options={buyerdata && buyerdata.length > 0 ? buyerdata.map((eachItem) => { return { value: eachItem.name, label: eachItem.name, type: eachItem.type, email: eachItem.personalemail, clientid: eachItem.clientid, companyname: eachItem.companyname, companyid: eachItem.companyid, accountmanager: eachItem?.companyData && eachItem?.companyData[0] && eachItem?.companyData[0]?.accountmanager ? eachItem?.companyData[0]?.accountmanager : eachItem?.accountmanager ? eachItem?.accountmanager : "" } }) : []}
                                                        value={allDealsData && allDealsData.name && allDealsData.name.length > 0 ?
                                                            { value: allDealsData.name, label: allDealsData.name } :null}
                                                        isDisabled={saveLeadId?.length > 0}
                                                    />

                                                    {errors.name ? <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.name}</span> :
                                                        errors.accountmanager ? <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.accountmanager}</span> : null}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="input-field">
                                                    <label htmlFor="example-text-input" className="col-form-label">Company Name</label>
                                                    <input className="form-control contact-number" type="text" name="title" value={allDealsData?.companyname} disabled placeholder="company name" id="example-email-input" />

                                                </div>
                                            </div>

                                            {allDealsData?.accountmanager !== undefined && allDealsData?.accountmanager !== "" &&
                                                <div className="col-md-4">
                                                    <div className="input-field">
                                                        <label htmlFor="example-text-input" className="col-form-label">Account manager</label>
                                                        <input className="form-control contact-number" type="text" name="accountmanager" value={allDealsData?.accountmanager} placeholder="account manager" id="example-email-input" disabled />

                                                    </div>
                                                </div>}
                                        </div>



                                        <div className="row table-data">
                                            <h5 className="font-size-14"><i className="mdi mdi-arrow-right"></i> Add Titles</h5>
                                            <div className="col-md-6">
                                                <div className="mb-3 input-field">
                                                    <label className="form-label form-label">Category</label>

                                                    <Select isMulti={true}
                                                        //  closeMenuOnSelect={false}
                                                        placeholder='Select category'
                                                        onChange={(e) => handleChangeMultiSelect(e, 'category')}
                                                        options={categoryName}

                                                        onFocus={handleCatRemoveError}
                                                        value={filter && filter.category && filter.category?.map((eachItem) => { return { value: eachItem, label: eachItem } })}
                                                    // }
                                                    />

                                                    {categoryerror != "" ?
                                                        <span className="errormsg" style={{
                                                            fontWeight: 'bold',
                                                            color: 'red',
                                                        }}>{categoryerror}</span> : ""
                                                    }
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="mb-3 input-field">
                                                    <label className="form-label form-label">Seller Name</label>
                                                    <Select
                                                        isMulti={true}
                                                        closeMenuOnSelect={false}
                                                        placeholder='Select Company name'
                                                        onChange={(e) => handleChangeMultiSelect(e, 'companyid')}
                                                        options={companiesData && companiesData.map((eachItem) => { return { value: eachItem.companyid, label: eachItem.companyname } })}
                                                        value={filter && filter.companyname && filter.companyname?.map((eachItem) => { return { value: eachItem.companyid, label: eachItem.companyname } })}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-11 search_title">
                                                <div className="input-field">
                                                    <label htmlFor="example-text-input" className="col-form-label">Search title</label>
                                                    {/* <Multiselect
                                                                        //   isObject={false}
                                                                        // isLoading={isLoadingValues}
                                                                        isDisabled={isLoadingValues}
                                                                         placeholder={isLoadingValues ? 'Loading...' : 'Select title'}

                                                                        displayValue="label"
                                                                        // placeholder="Select title"
                                                                        onKeyPressFn={function noRefCheck() { }}
                                                                        onRemove={(e) => handleChangeMultiSelect(e, 'title')}
                                                                        onSearch={function noRefCheck() { }}
                                                                        onSelect={(e) => handleChangeMultiSelect(e, 'title')}
                                                                        // options={searchResult && searchResult.length > 0 ? searchResult.map((eachItem) => { return { value: eachItem.title, label: eachItem.title, portraitimage: eachItem.portraitimage, landscapeimage: eachItem.landscapeimage, contentid: eachItem.contentid, category: eachItem.category } }) : []}

                                                                        options={searchResult && searchResult.length > 0 ? searchResult : []}
                                                                        showCheckbox
                                                                        selectedValues={filter && filter.contenttitle && filter.contenttitle.length > 0 && filter.contenttitle.map((eachItem) => { return { value: eachItem, label: eachItem } })}
                                                                    /> */}
                                                    <MultiSelect

                                                        options={searchResult && searchResult.length > 0 ? searchResult.filter((objA) => !allDealsData?.title?.some((objB) => objB.contentid === objA.contentid)) : []}
                                                        value={selected}
                                                        isLoading={isLoadingValues}
                                                        className="search-title"
                                                        // hasSelectAll={true}
                                                        selectAllLabel="Select All Titles"
                                                        // shouldToggleOnHover={true}
                                                        // onChange={setSelected}
                                                        onChange={(e) => handleSearch(e)}
                                                        labelledBy={"Select Title"}
                                                    // isCreatable={true}
                                                    />


                                                    {titleerror != "" ?
                                                        <span className="errormsg" style={{
                                                            fontWeight: 'bold',
                                                            color: 'red',
                                                        }}>{titleerror}</span> : ""
                                                    }

                                                </div>
                                            </div>
                                            <div className="col-md-1 ok_btn">
                                                <label className="form-label form-label">ok</label>
                                                <a className="btn btn-primary" onClick={handleSelectTitles}>ok</a>
                                            </div>
                                            <div className="col-md-12 ">
                                                <div className="mb-3 input-field">
                                                    <label className="form-label form-label">
                                                        Titles Selected {allDealsData?.title?.length > 0 ? (
                                                            <>
                                                                (Total:  {allDealsData?.title?.length})
                                                            </>
                                                        ) : null}
                                                    </label>
                                                    <div className="selected_titles">
                                                        {allDealsData?.title && allDealsData?.title?.map((eachItem, index) => (
                                                            <span key={index} className="titles">
                                                                {eachItem.value}
                                                                <span className="mdi mdi-close" onClick={(e) => handleRemoveTitle(e, eachItem.contentid)}></span>
                                                            </span>
                                                        ))}
                                                    </div>
                                                    {uniqueErr != "" ?
                                                        <span className="errormsg" style={{
                                                            fontWeight: 'bold',
                                                            color: 'red',
                                                        }}>{uniqueErr}</span> : ""
                                                    }

                                                </div>
                                            </div>
                                            {/* <div className="col-md-12">
                                                <a
                                                    className="btn btn-primary" onClick={handleSelectTitles}>ok</a>
                                            </div> */}

                                            <div className="col-md-12">
                                                <div className="mb-3 input-field">
                                                    <label className="form-label form-label">Additional Comments</label>
                                                    <textarea id="email" placeholder="Enter Additional Comments" name="addcomments" type="text" className="form-control" value={allDealsData.addcomments} onChange={(e) => handleChange(e)} />

                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <a onClick={handleAdd} className="btn btn-primary">add</a>
                                                {adderror != "" ?
                                                    <span className="errormsg" style={{
                                                        fontWeight: 'bold',
                                                        color: 'red',
                                                    }}>{adderror}</span> : ""
                                                }
                                            </div>

                                            <div className="col-md-12">
                                                <label className="form-label form-label">Titles Enquired {contentData?.length > 0 ? (
                                                    <>
                                                        (Total:  {contentData?.length})
                                                    </>
                                                ) : null}
                                                </label>
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table align-middle table-nowrap table-check">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="align-middle">S.NO</th>
                                                            <th className="align-middle">THUMBNAIL</th>
                                                            <th className="align-middle">TITLE</th>
                                                            <th className="align-middle">CATEGORY</th>
                                                            <th className="align-middle">ADDITIONAL COMMENTS</th>
                                                            <th className="align-middle">ACTIONS</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {contentData && contentData.length > 0 && contentData.map(function (item, i) {
                                                            let picName = item.mediaType === 'video' ? 'videoclip-defaulr' : item.mediaType === 'audio' ? 'musicfile-default' : item.mediaType === 'pdf' ? 'pdf-default' : item.mediaType === 'doc' ? 'doc-defaulr' : 'img-default';
                                                            let defaultImg = `${image}orasi/common/images/${picName}.jpg`;

                                                            let imgUrl = (item?.portraitimage && item.portraitimage !== "")
                                                            ? (item.portraitimage.startsWith("http") ? item.portraitimage : image + item.portraitimage)
                                                            : (item?.landscapeimage && item.landscapeimage !== "")
                                                              ? (item.landscapeimage.startsWith("http") ? item.landscapeimage : image + item.landscapeimage)
                                                              : defaultImg;

                                                            let clientUrl = window.site.client.siteUrl;
                                                            // console.log("clientUrl", clientUrl)
                                                            return (
                                                                <tr >
                                                                    <td>{i + 1}</td>
                                                                    <td><a href={clientUrl + "/moreinfo/" + item.contentid + "&showoffline=true"} target="_blank"><img src={imgUrl + "?auto=compress,format&width=40"} /></a></td>
                                                                    <td>{item.title}</td>
                                                                    {/* <td><button type="button" className="btn-primary btn-sm btn-rounded" data-bs-toggle="modal" data-bs-target="">{item && item.category.length > 1 ? item.category.join(", ") : item.category}</button></td> */}
                                                                    <td><div className="text-elipsis">{item && item?.category?.length > 1 ? item?.category?.join(", ") : item?.category}</div></td>

                                                                    <td><div className="text-elipsis">{item?.comments}</div></td>
                                                                    <td><div className="d-flex" onClick={(e) => handleDeleteDeal(e, item?.contentid)}><a className="text-danger action-button"><i className="mdi mdi-delete font-size-18"></i></a></div></td>


                                                                </tr>
                                                            );
                                                        })}


                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <h5 className="font-size-14"><i className="mdi mdi-arrow-right"></i> enquiry details</h5>
                                            <div className="col-md-4">
                                                <div className="mb-3 input-field">
                                                    <label className="form-label form-label">Required Rights</label>
                                                    {/* <input id="email" name="rightsavaliable" placeholder="Rights Available" type="text" className="form-control" onChange={(e) => handleChange(e)} value={allDealsData.rightsavaliable} /> */}

                                                    <Select isMulti={true}
                                                        placeholder='Required Rights'
                                                        onChange={(e) => handleChangeMultiSelect(e, 'rightsavaliable')}
                                                        options={rightsData}
                                                        value={allDealsData && allDealsData.rightsavaliable && allDealsData.rightsavaliable.length > 0 ? allDealsData.rightsavaliable?.map((eachItem) => { return { value: eachItem, label: eachItem } }) : []}

                                                        isClearable={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3 input-field">
                                                    <label className="form-label form-label">Required rights available From</label>
                                                    <input id="email" name="requiredfromdate" placeholder="Rights Available From Date" type="date" className="form-control" value={allDealsData.requiredfromdate} onChange={(e) => handleChange(e)} />
                                                    {errors.date && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.date}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3 input-field">
                                                    <label className="form-label form-label">Required rights available to</label>
                                                    <input id="email" name="requiredtodate" placeholder="Rights Available To Date" type="date" className="form-control" value={allDealsData.requiredtodate} onChange={(e) => handleChange(e)} />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3 input-field">
                                                    <label className="form-label form-label">video format</label>

                                                    <Select isMulti={true}
                                                        placeholder='Video Format'
                                                        onChange={(e) => handleChangeMultiSelect(e, 'videoformat')}
                                                        options={videoformatData}
                                                        value={allDealsData && allDealsData.videoformat && allDealsData.videoformat.length > 0 ? allDealsData.videoformat?.map((eachItem) => { return { value: eachItem, label: eachItem } }) : []}

                                                        isClearable={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 ">
                                                <div className="mb-3 input-field">
                                                    <label className="form-label form-label">dubbing languages</label>

                                                    <Select isMulti={true}
                                                        placeholder='Dubbing Languages'
                                                        onChange={(e) => handleChangeMultiSelect(e, 'dubbinglanguages')}
                                                        options={languagesData}
                                                        value={allDealsData && allDealsData.dubbinglanguages && allDealsData.dubbinglanguages.length > 0 ? allDealsData.dubbinglanguages?.map((eachItem) => { return { value: eachItem, label: eachItem } }) : []}

                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="mb-3 input-field">
                                                    <label className="form-label form-label">exclusivity</label>

                                                    {/* <input type="text" className="form-control for-clear" id="example-email-input" name="exclusivity" placeholder="Exclusivity" value={allDealsData.exclusivity} onChange={(e) => handleChange(e)} /> */}

                                                    <select className="colorselect capitalize form-control" name="exclusivity" onChange={(e) => handleChange(e)} value={allDealsData.exclusivity}>
                                                        <option value="">Select Type</option>

                                                        <option value="Exclusive">Exclusive</option>
                                                        <option value="Non-Exclusive">Non-Exclusive</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3 input-field">
                                                    <label className="form-label form-label">Regions Required</label>

                                                    <Select isMulti={true}
                                                        placeholder='Regions'
                                                        onChange={(e) => handleChangeMultiSelect(e, 'regions')}
                                                        options={countryData}
                                                        value={allDealsData && allDealsData.regions && allDealsData.regions.length > 0 ? allDealsData.regions?.map((eachItem) => { return { value: eachItem, label: eachItem } }) : []} />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3 input-field">
                                                    <label className="form-label form-label">Total Budget (USD)</label>
                                                    {/* <input id="email" name="totalbudget" placeholder="Enter Total Budget" className="form-control"
                                                                    
                                                                    type="text"
                                                                   
                                                                    value={formatAsCurrency(allDealsData?.totalbudget)}
                                                                    onChange={(e) => handleChange(e)} /> */}

                                                    <CurrencyInput

                                                        id="total-budget"
                                                        name="totalbudget"
                                                        className="form-control"
                                                        label="Total Budget (USD)"
                                                        value={allDealsData?.totalbudget}
                                                        placeholder="Enter Total Budget"
                                                        defaultValue={allDealsData?.totalbudget} // Optional initial value
                                                        decimalsLimit={2}  // Limit to 2 decimal places
                                                        prefix="$"          // Add a dollar sign prefix
                                                        onValueChange={(value, name, values) => {
                                                            // Handle value changes here
                                                            // console.log(value); // This will be the parsed number without formatting
                                                            // console.log(values.formatted); // This will be the formatted value as a string
                                                            setAllDealsData({ ...allDealsData, [name]: values.value }); // Update your state
                                                        }}
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3 input-field">
                                                    {/* <label className="form-label form-label">Total Budget (US$)</label>
                                                                    <input id="email" name="totalbudget" placeholder="Enter Total Budget" type="text" className="form-control" onChange={(e) => handleChange(e)} value={allDealsData?.totalbudget} /> */}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3 input-field">
                                                    <label className="form-label form-label">Request</label>
                                                    <textarea id="email" name="request" placeholder="Request" type="text" className="form-control" value={allDealsData.request} onChange={(e) => handleChange(e)} />

                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3 input-field">
                                                    <label className="form-label form-label">Queries</label>


                                                    <textarea id="email" name="queries" type="text" className="form-control" placeholder="Queries" spellCheck="false" value={allDealsData.queries} onChange={(e) => handleChange(e)} />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="mb-3 input-field">
                                                    <label className="form-label form-label">Comments</label>
                                                    <textarea id="email" name="comments" placeholder="Enter Comments" type="text" className="form-control" onChange={(e) => handleChange(e)} value={allDealsData.comments} />
                                                    {commentsError != "" ?
                                                        <span className="errormsg" style={{
                                                            fontWeight: 'bold',
                                                            color: 'red',
                                                        }}>{commentsError}</span> : ""
                                                    }
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row status">
                                            <div className="col-md-3 justify-content-between ps-0">

                                            </div>

                                            <div className="col-md-9 justify-content-end d-flex align-items-center">

                                                <button className={`btn btn-primary ${buttonDisabled ? 'pe-none' : ''}`} onClick={handleSave} >{saveLoaderEnable ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}NEXT</button>
                                            </div>

                                        </div>

                                    </div>

                                </div>


                            </div>
                        </div>


                        <Footer />
                        <SweetAlert show={datasuccess}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"To date cannot be earlier than From date"}
                            onConfirm={(e) => onConfirm1(e)}
                        >
                        </SweetAlert>
                        <SweetAlert show={success}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Deal Added"}
                            onConfirm={(e) => onConfirm(e)}
                        >
                        </SweetAlert>
                        <SweetAlert show={limitReached}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"A maximum of 100 titles can be selected."}
                            onConfirm={(e) => onConfirm2(e)}
                        >
                        </SweetAlert>
                    </div>
                }
                {/* {catDeletePopup && <Modal className="access-denied" show={true}>

<div className="modal-body enquiry-form">
    <div className="container">
        <button className="close-btn" onClick={e => setShowDocAlert(false)}><span className="material-icons">close</span></button>
        <span className="material-icons access-denied-icon">delete_outline</span>
        <h3>Delete</h3>
        <p>This action cannot be undone.</p>
        <p>Are you sure you want to remove the categoy?</p>
        <div className="popup-footer">
            <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e =>  {handleDeleteFile("e",activeDeleteId)}}> {isLoading ? (<img src="https://d9nwtjplhevo0.cloudfront.net/orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg" className="loading-icon" />) : null}Yes, Delete</button>
        </div>
    </div>
</div>

</Modal>} */}

            </div>

        </>
    );
};

export default AddDeals;
