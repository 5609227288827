import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Player } from 'bitmovin-player';
import { UIFactory } from 'bitmovin-player/bitmovinplayer-ui';
import 'bitmovin-player/bitmovinplayer-ui.css';
import Modal from 'react-bootstrap/Modal';

const PlayerInfo = (props) => {
    const history = useHistory();
    const [player, setPlayer] = useState(null);
    const [show, setShow] = useState(true);
    const [playerKey, setPlayerKey] = useState('');

    useEffect(() => {
        const checkPlayerKey = () => {
            if (window.site && window.site.client.player.bitmovin && window.site.client.player.bitmovin.playerKey) {
                setPlayerKey(window.site.client.player.bitmovin.playerKey);
            }
        };
        checkPlayerKey();
        const timer = setTimeout(checkPlayerKey, 1000);
        return () => clearTimeout(timer);
    }, []);

    const playerConfig = {
        key: playerKey,
        playback: { autoplay: true },
        textTrack: { showMenuSubtitleItem: true }
    };

    useEffect(() => {
        if (props.play && playerKey) {
            const playerSource = { hls: props.source };
            setupPlayer(playerSource, props.subtitles);
        }
    }, [props.play, playerKey, props.source]);

    const setupPlayer = async (playerSource, subtitles) => {
        if (!playerKey) return;
        setShow(true);
        const portalDiv = document.getElementById('player');
        if (!portalDiv) throw new Error("The element #player wasn't found");

        const playerInstance = new Player(portalDiv, playerConfig);
        UIFactory.buildDefaultUI(playerInstance);
        playerInstance.load(playerSource).then(() => {
            setPlayer(playerInstance);
            if (subtitles && subtitles.length > 0) {
                subtitles.forEach(subtitle => {
                    playerInstance.subtitles.add({
                        id: subtitle.uuid,
                        lang: subtitle.lang,
                        label: subtitle.label,
                        url: subtitle.path,
                        kind: "subtitle"
                    });
                });
            }
        }).catch(() => console.log('Error while loading source'));
    };

    useEffect(() => {
        return () => {
            if (player) {
                player.unload();
            }
        };
    }, [player]);

    const destroyPlayer = () => {
        if (player) {
            setShow(false);
            player.unload();
            setPlayer(null);
            props.setPlay(false);
        }
    };

    return (
        <>
            <Modal show={show} className="video-popup" onHide={destroyPlayer}>
                <button className="close-btn" onClick={destroyPlayer}>
                    <span className="material-icons">close</span>
                </button>
                <Modal.Body>
                    <div id="player" />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PlayerInfo;
