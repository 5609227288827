/***
**Module Name: Bidding lookup
 **File Name :  Biddinglookup.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains Bidding lookup table details.
 ***/
 import React, { useState, useEffect, useContext } from "react";


 import Footer from "../../../components/dashboard/footer";
 import Header from "../../../components/dashboard/header";
 import Sidebar from "../../../components/dashboard/sidebar";
 import tmdbApi from "../../../api/tmdbApi";
 import { useHistory, Link, useLocation } from "react-router-dom";
 import axios from 'axios';
 import SweetAlert from 'react-bootstrap-sweetalert';
 import * as Config from "../../../constants/Config";
 import TableLoader from "../../../components/tableLoader";
 import { contentContext } from "../../../context/contentContext";
 import SessionPopup from "../../SessionPopup"
 import DataTable from 'react-data-table-component';
 
 let { lambda, appname } = window.app;
 
 
 
 const BiddingLookUp = () => {
     const history = useHistory();
     const { state } = useLocation();
     const { search } = state || {};
     const [lookup, setLookUp] = useState("");
     const [regions , setRegions] = useState(null);
     const [countries , setCountries] = useState(null);
     // const [data, setData] = useState([]);
 
     const [dummy, setDummy] = useState([]);
     const [num, setNum] = useState();
 
 
 
     const [flag, setFlag] = useState(false);
     // const [isLoading, setIsLoading] = useState(false);
 
     const [showSessionPopupup, setShowSessionPopupup] = useState(false);
 
     // const [route, setRoute] = useState("lookup");
 
     let count = 0;
 
     const {searchedFlag, setSearchedFlag,  isLoading, setIsLoading,setActiveMenuObj,userData, sortTableAlpha, arrow,  setSelectedOptions,   data, setData,rowsPerPage, setRowsPerPage,currentPageNew, setCurrentPage,lookUpType, setlookUpType,lookupsearch, setLookupSearch,route, setRoute,usePrevious,sortedColumn, setSortedColumn,sortDirection, setSortDirection, setActiveMenuId} = useContext(contentContext);
 
 
 
 
     const validateObj = userData && userData.permissions && userData.permissions.length > 0 && userData.permissions.filter(eachItem => eachItem.menu === "Auction Management")
     const subValDashboard = validateObj && validateObj[0] && validateObj[0].submenus && validateObj[0].submenus[2].dashboard
     console.log("subValDashboard",subValDashboard)
     const prevRoute = usePrevious(route)
     
    
     // console.log('rowsPerPage',rowsPerPage)
     
     useEffect(() => {
         if(prevRoute != undefined && prevRoute!=route){
             setCurrentPage(1)
             setRowsPerPage(15)
             setSearchedFlag(false);
             setLookupSearch("")
         }
     }, [prevRoute]);
     console.log('prevRoute--->',prevRoute)
     console.log('currentRoute--->',route)
     const handlePageChange = (page) => {
        setCurrentPage(page);
      };
   
     const handlePerRowsChange = (newPerPage) => {
       setRowsPerPage(newPerPage);
     };
     const handleSort = (column, direction) => {
        
         const columnKey = column.name.toLowerCase();
         console.log('Column Key:', columnKey);
         console.log('Direction:', direction);
         setSortedColumn(columnKey);
         setSortDirection(direction);
     
         setData((prevData) => {
           const sorted = [...prevData];
     
           sorted.sort((a, b) => {
             if (direction === 'asc') {
               return a[columnKey] - b[columnKey];
             } else {
               return b[columnKey] - a[columnKey];
             }
           });
     
           return sorted;
         });
       };
     const columns = [
 
         {
             name: 'Name',
             selector: row => row.name,
             sortable: true,
         },
         {
             name: 'Type',
             selector: row => row.type,
             sortable: true,
         },
         {
             name: 'Status',
             // cell: (props) => <img src={props.image} width={60} alt='Image' />,
             selector: row => row.status,
             sortable: true,
 
         },
 
         {
             name: <>{(subValDashboard && subValDashboard.view && subValDashboard.view.display === true) || (subValDashboard && subValDashboard.edit && subValDashboard.edit.display === true) ? 'Actions' : null}</>,
             // cell: (props) => <button onClick={(e) => handleButtonClick(e, props)}>Action</button>,
             cell: (props) => 
             // {
                 subValDashboard && subValDashboard.view && subValDashboard.edit && (subValDashboard.view.display === true || subValDashboard.edit.display === true) &&
                 
                 <div className="d-flex">
                     {subValDashboard && subValDashboard.view && subValDashboard.view.display === true &&
 
                         <a onClick={e => handleViewLookup(e, props.lookupcode)} className={`${subValDashboard && subValDashboard.view && subValDashboard.view.enable === false ? 'pe-none' : ''} text-success action-button`}><i className="mdi mdi-eye font-size-18"></i></a>}
                     {subValDashboard && subValDashboard.edit && subValDashboard.edit.display === true &&
                         <a onClick={e => handleEditLookup(e, props.lookupcode)} className={`${subValDashboard && subValDashboard.edit && subValDashboard.edit.enable === false ? 'pe-none' : ''} text-danger action-button`}><i className="mdi mdi-pencil font-size-18"></i></a>
                         
                         }
                 </div>
             // }
             ,
             ignoreRowClick: true,
             allowOverflow: true,
             button: true,
         },
     ];
 
 
     // useEffect(() => {
     //     if (sortedColumn && sortDirection) {
     //       localStorage.setItem('sortedColumn', sortedColumn);
     //       localStorage.setItem('sortDirection', sortDirection);
     //     }
     //   }, [sortedColumn, sortDirection]);
 
     useEffect(() => {
         setRoute("biddingLookup")
         setActiveMenuId(300023)
         setActiveMenuObj({
             "Contact Management": false,
             "Client Management": false,
             "Reports": false,
             "Auction Management":true
         })
         // const storedColumn = localStorage.getItem('sortedColumn');
         // const storedDirection = localStorage.getItem('sortDirection');
         // if (storedColumn && storedDirection) {
         //     setSortedColumn(storedColumn);
         //     setSortDirection(storedDirection);
         //   }    
         if (!localStorage.getItem("token")) {
             history.push("/");
         }
         // if (search === true) {
         //     handleSearch();
         // } else {
         //     GetLookUp();
         // }
         setSelectedOptions([])
          
     }, []);
 
     useEffect(() => {
       
 
         console.log("trueeeeeeeeee",searchedFlag);
         if (searchedFlag) { 
             console.log("came") 
             handleSearch();
         } else {
             console.log("called get all deals") 
             GetBiddingLookUp();
         }
 
     }, [searchedFlag]);
     // const storedColumn = localStorage.getItem('sortedColumn');
     // const storedDirection = localStorage.getItem('sortDirection');
     // console.log('storedColumn',storedColumn)
     // console.log('storedDirection',storedDirection)
     // useEffect(() => {
     //     // setCurrentPage(1)
     // }, [data]);
 
 
     const GetBiddingLookUp = async (lookupTypeVal) => {
         console.log("lookUpType", lookUpType);
         let payload = lookUpType === "all-type" || lookUpType === "" ? {} : { type: [lookUpType] }
         if(lookupTypeVal){
            payload={}
        }
         setIsLoading(true)
         const token = localStorage.getItem("token")
         let linkUrl
         if (lookupsearch && flag) {
             linkUrl = lambda + '/biddingLookups?appname=' + appname + 
             // "&assetcount=" + rowsPerPage + "&pageNumber=" + currentPageNew + 
             "&search=" + lookupsearch + "&token=" + token + "&userid=" + localStorage.getItem("userId")
         } else {
             linkUrl = lambda + '/biddingLookups?appname=' + appname +
             //  "&assetcount=" + rowsPerPage + "&pageNumber=" + currentPageNew +
               "&token=" + token + "&userid=" + localStorage.getItem("userId")
         }
         axios({
             method: 'POST',
             url: linkUrl,
             data: payload,
         })
             .then(function (response) {
                 console.log("res1")
                 if (response.data.result == "Invalid token or Expired") {
                     setShowSessionPopupup(true)
                 } else {
                   
                     // setTimeout(function () { setData(response.data.result.data); setIsLoading(false); }, 112000)
                     setData(response.data.result.data);
                     setLookUp(response.data.result);
                     if(response.data.result.data.length>0){
                        let resData=response.data.result.data;
                        let regionsData=resData.filter(item=>{
                            if(item.type === 'region'){
                                return item
                            }
                        })
                        let countriesData=resData.filter(item=>{
                            if(item.type === 'country'){
                                item['value']=item.name;
                                item['label']=item.name;
                                return item
                            }
                        })

                        
                        setRegions(regionsData)
                        setCountries(countriesData)
                    }
                     setDummy(response.data.result.data);
                     setIsLoading(false)
                     setLookupSearch("");
                     setSearchedFlag(false);
                 }
             });
     }
     const GetLookUp2 = async (item) => {
         console.log("lookUpType", item);
         let payload = item === "all-type" || item === "" ? {} : { type: [item] }
         setIsLoading(true)
         const token = localStorage.getItem("token")
         let linkUrl
         if (lookupsearch && flag) {
             linkUrl = lambda + '/biddingLookups?appname=' + appname + 
             // "&assetcount=" + rowsPerPage + "&pageNumber=" + currentPageNew + 
             "&search=" + lookupsearch + "&token=" + token + "&userid=" + localStorage.getItem("userId")
         } else {
             linkUrl = lambda + '/biddingLookups?appname=' + appname +
             //  "&assetcount=" + rowsPerPage + "&pageNumber=" + currentPageNew +
               "&token=" + token + "&userid=" + localStorage.getItem("userId")
         }
         axios({
             method: 'POST',
             url: linkUrl,
             data: payload,
         })
             .then(function (response) {
                 console.log("res1")
                 if (response.data.result == "Invalid token or Expired") {
                     setShowSessionPopupup(true)
                 } else {
                   
                     // setTimeout(function () { setData(response.data.result.data); setIsLoading(false); }, 112000)
                     setData(response.data.result.data);
                     setLookUp(response.data.result);
                     setDummy(response.data.result.data);
                     setIsLoading(false)
                     setLookupSearch("");
                 }
             });
     }
 
     const handleLookUpType = async (e) => {
         setlookUpType(e.target.value);
         const value = e.target.value
         setCurrentPage(1)
         setRowsPerPage(15)
         GetLookUp2(value)
       
         // let arr1 = [];
         // if (e.target.value === "all-type") {
         //     setData(dummy);
         //     setTypeCount(0)
         // } else {
         //     dummy.map((item1) => {
         //         if (item1.type == e.target.value) {
         //             arr1.push(item1)
         //         }
         //     });
 
         //     setData(arr1);
         //     setTypeCount(arr1.length);
 
 
         // }
 
 
     }
 
 
 
 
     const handleSearch = (e, flagggg) => {
         if(flagggg === "normalsearch"){
             setSearchedFlag(true)
        }
         if (flagggg) {
             setCurrentPage(1);
         }
       
         if (lookupsearch === "") {
            GetBiddingLookUp();
         }
         else {
             const token = localStorage.getItem("token")
             setIsLoading(true)
             let lookupobj = lookUpType.length > 0 && lookUpType !== "all-type" ? lookUpType : "";
             axios.post(lambda + '/biddingLookups?appname=' + appname + 
             // "&assetcount=" + rowsPerPage + "&pageNumber=" + currentPageNew + 
             "&search=" + lookupsearch?.trim() + "&token=" + token + "&userid=" + localStorage.getItem("userId"), {
                 type: lookupobj ? [lookupobj] : ""
             })
                 .then(function (response) {
 
                   
                    if (response.data.result == "Invalid token or Expired") {
                         setShowSessionPopupup(true)
                     } else {
                         console.log("response", response.data.result);
                         setData(response.data.result.data);
                         setLookUp(response.data.result);
                         setIsLoading(false)
                     }
                 });
         }
     }
  
     const handleChange = (e) => {
         // if (e.target.value === "") {
         //     GetLookUp();
         // }
         setLookupSearch(e.target.value);
         //setCurrentPage(1);
     }
 
     const handleAddLookUp = (e) => {
         history.push("/addbiddinglookup",{
            regions:regions,
            countries:countries
        });
     }
     const handleEditLookup = (e, id) => {
        history.push("/editbiddinglookup/" + id,{
            regions:regions,
            countries:countries
        });
    }
 
     const handleViewLookup = (e, id) => {
         history.push("/viewbiddinglookup/" + id);
     }
 
     const handleKeypress = (e) => {
         //it triggers by pressing the enter key
 
         if ((e.key === "Enter")) {
             setTimeout(function () {
                 handleSearch();
             }, 1000);
         }
     };
 
     const clearSearch = () => {
        setlookUpType('all-type');
        setLookupSearch("");
        GetBiddingLookUp('all-type');
        setCurrentPage(1);
        setRowsPerPage(15);
     }
     const customNoRecords = () => {
         return(
             
             <div className="empty-state-body empty-record"  >
                 <div className="empty-state__message">
                     <span className="material-icons">summarize</span>
                     <p className="form-check font-size-16">No lookups were found for the searched keyword</p>
                 </div> </div>
         )
     }
 
 
 console.log('sort--->',currentPageNew)
     return (
         <>
             {showSessionPopupup && <SessionPopup />}
             <div id="layout-wrapper">
                 <Header />
                 <Sidebar />
 
                 <div className="main-content user-management lookups">
 
                     <div className="page-content">
                         <div className="container-fluid">
 
 
 
                             <div className="row mb-4 breadcrumb">
                                 <div className="col-lg-12">
                                     <div className="d-flex align-items-center">
 
                                         <div className="flex-grow-1">
                                             <h4 className="mb-2 card-title">bidding lookups</h4>
 
                                         </div>
                                         {subValDashboard && subValDashboard.add && subValDashboard.add.display === true &&
                                             <div>
                                                 <button onClick={handleAddLookUp} className="btn btn-primary" disabled={subValDashboard && subValDashboard.add && subValDashboard.add.enable === false}>add Bidding lookups</button>
                                             </div>
                                         }
                                     </div>
                                 </div>
                             </div>
 
                             <div className="row table-data">
                                 <div className="col-12">
                                     <div className="card">
                                         <div className="card-body">
                                             <div className="row mb-2">
                                                 <div className="col-sm-4">
                                                     <select name="Type-search" value={lookUpType} id="dropdown" className="custom-select custom-select-sm form-control form-control-sm form-select form-select-sm" onChange={e => handleLookUpType(e)}>
                                                         <option value="all-type">All Types</option>
                                                         <option value="country">Country</option>
                                                            <option value="exclusivity">Exclusivity</option>
                                                            <option value="region">Region</option>
                                                            <option value="revenue">Revenue</option>
                                                            <option value="rights">Rights</option>   
                                                     </select>
                                                 </div>
                                                 <div className="col-sm-8">
                                                     <div className="search-box mb-2 d-inline-block">
                                                         <div className="position-relative">
                                                             <input type="text" className="form-control" value={lookupsearch} onChange={(e) => handleChange(e)} onKeyPress={handleKeypress} placeholder="Search by LookUp Name" />
                                                             <button className="fill_btn" onClick={(e) => handleSearch(e, "normalsearch")}><span className="material-icons search-icon">search</span></button>
                                                         </div>
                                                         <div className="dataTables_length" id="datatable_length">
                                                             <button className="fill_btn" onClick={clearSearch}><span className="material-icons-outlined">sync</span>Reset</button>
                                                         </div>
                                                      
                                                     </div>
                                                     <div className="text-sm-end">
 
                                                     </div>
                                                 </div>
                                             </div>
 
                                           
                                             < DataTable
                                                 // title=""
                                                 columns={columns}
                                                 // className="table align-middle table-nowrap table-check"
                                                 keyField='_id'
                                                 data={data}
                                                 onSort={handleSort}
                                                 direction="auto"
                                                 highlightOnHover
                                                 // sortedColumn={sortedColumn}
                                                 // sortDirection={sortDirection}
                                                 fixedHeaderScrollHeight="300px"
                                                 pagination
                                                 responsive
                                                 persistTableHead
                                                 // selectableRowsVisibleOnly
                                                 striped
                                                 // selectableRowsHighlight
                                                 // selectableRows
                                                 subHeaderAlign="right"
                                                 defaultSortField="name"
                                                 subHeaderWra
                                                 noDataComponent={customNoRecords()}
                                                 paginationTotalRows={data.length}
                                                 onChangeRowsPerPage={handlePerRowsChange}
                                                 onChangePage={handlePageChange}
                                                 paginationPerPage={rowsPerPage}
                                                 paginationDefaultPage={currentPageNew}
                                          
                                                 paginationComponentOptions={{
                                                     rowsPerPageText: 'Per page:',
                                                     rangeSeparatorText: 'of',
                                                     noRowsPerPage: false,
                                                     selectAllRowsItem: false,
                                                     selectAllRowsItemText: 'All',
                                                   }}
                                           
                                                 progressPending={isLoading}
                                                 progressComponent={<TableLoader />}
                                                   />
                                               
                                         </div>
 
                                     </div>
                                 </div>
                             </div>
 
 
                         </div>
                     </div>
 
                   
                     <Footer />
                 </div>
 
 
 
             </div>
         </>
     );
 };
 
 export default BiddingLookUp;
 