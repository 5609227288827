/***
**Module Name: not found
 **File Name :  notfound.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains page not found details.
 ***/
import React, { useState, useEffect, useContext, useRef } from "react";
import Footer from "../../../components/dashboard/footer";
import Header from "../../../components/dashboard/header";
import Sidebar from "../../../components/dashboard/sidebar";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import $ from 'jquery';
import { contentContext } from "../../../context/contentContext";
import TableLoader from "../../../components/tableLoader";
import tmdbApi from "../../../api/tmdbApi";
import moment from "moment";
import asyncLoop from "node-async-loop";
import { updateContentData, AddItem, getContentFiles, updateBiddingItems, getItemFiles, CountryCombination, getCombination, SaveCombination, UpdateCombination, getCombinationSearch, getCombinationAdvSearch, setDefault } from "../../../utils/reducer";
import PlayerInfo from "../../../components/player";
import FileViewer from "../../../components/docViewer";
import Modal from 'react-bootstrap/Modal';
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from "../../../components/loader";
import SessionPopup from "../../SessionPopup";
import ReactQuill from 'react-quill';
import DataTable from 'react-data-table-component';
import 'react-quill/dist/quill.snow.css';
let { lambda, appname } = window.app;

const useQuillMixin = (defaultValue = '') => {
    const [value, setValue] = useState(defaultValue);
    const [isValidEditor, setIsValid] = useState(true);
    useEffect(() => {
        // Perform any additional setup or side effects here
        console.log('Quill setup completed');
    }, []);

    const handleChange = (content, delta, source, editor) => {
        setValue(content);
        if (content && content.length > 0) {
            var tmpdetails = content
            tmpdetails = tmpdetails.replace("<p>", "").trim()
            tmpdetails = tmpdetails.replace("</p>", "").trim()
            console.log("tmpdetails", tmpdetails);
            if (tmpdetails == "<br>" || tmpdetails == "") {
                setIsValid(true)
            } else {
                setIsValid(false)
            }
        }
    };


    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'list': 'bullet' }],
            ['bold', 'italic', 'underline', 'strike', 'link'],
        ],
    };

    const formats = [
        // Add your formats here if needed
        // Example: 'bold', 'italic', 'underline', 'strike'
    ];

    const quillElement = (
        <ReactQuill
            theme="snow"
            // modules={{ toolbar: true }}
            formats={['header', 'list', 'bold', 'italic', 'underline', 'strike', 'link']}
            value={value}
            onChange={handleChange}
            modules={modules}
        //  formats={formats}
        />
    );

    return { quillElement, value, setValue, isValidEditor };
};

const EditItem = () => {
    let { id } = useParams();
    let videoClipcategories = ['VIDEOS',
        'THUMBNAILS',
        'DOCUMENTS',
    ]
    const history = useHistory();
    const ref = useRef();

    const [editcontent, setEditContent] = useState({});
    const [contentTitle, setContentTitle] = useState("");
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedOptionsgenre, setSelectedOptionsgenre] = useState([]);
    const [lookup, setLookUp] = useState({});
    const [selectedOptionscountryOfOrigin, setSelectedOptionscountryOfOrigin] = useState([]);
    const [countryData, setCountryData] = useState({});
    const [regionsData, setRegionsData] = useState({});
    const [revenueData, setRevenueData] = useState([]);
    const [typesofrights, setTypesofrights] = useState([]);
    const [exclusivityData, setExclusivityData] = useState([]);
    const [BtnLoader, setBtnLoader] = useState(false);
    const [BtnLoader1, setBtnLoader1] = useState(false);
    const { categoryName, Categories, catBasedContentFields } = useContext(contentContext);
    const [image, setImg] = useState('');
    const [filesData, setFilesData] = useState([]);
    const [validateFiles, setValidateFiles] = useState(false);
    const [errors, setErrors] = useState({});
    const [play, setPlay] = useState(false);
    const [playContent, setPlayContent] = useState({});
    const [showPreview, setShowPreview] = useState(false);
    const [previewImg, setPreviewImg] = useState("");
    const [showDoc, setshowDoc] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [subSuccess, setSubSuccess] = useState(false);
    const [AddSuccess, setAddSuccess] = useState(false);
    const [excededfile, setExcededFile] = useState(false);
    const [excededdocumentfile, setDocumentExcededFile] = useState(false);
    const [videoexcededfile, setVideoExcededFile] = useState(false);
    const [isdelete, setDelete] = useState(false);
    const [isdeleteImage, setIsdeleteImage] = useState(false);
    const [item, setItem] = useState("");
    const [fileCategory, setFileCategory] = useState("");
    const [lookupSuccess, setLookupSuccess] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [fileName, setFileName] = useState("");
    const [showUpload, setShowUpload] = useState(false);
    const [showSessionPopupup, setShowSessionPopupup] = useState(false);
    const [invalidContent, setInvalidContent] = useState(false);
    const [type, setType] = useState(false);
    const [activeTab, setActiveTab] = useState('info');
    const [isInfoFormValid, setIsInfoFormValid] = useState(false);

    const [uploadType, setUploadType] = useState('');
    const [activeTrailerId, setActiveTrailerId] = useState('');
    const [playerSubTitles, setPlayerSubTitles] = useState('');

    const [delPopup, setDelPopup] = useState(false);
    const [delSuccess, setDelSuccess] = useState(false);
    const [delSeller, setDelSeller] = useState(false);

    const [uploadsuccess, setUploadSuccess] = useState(false);
    const [isCompExist, setIsCompExist] = useState(false);
    const [isTitleExist, setIsTitleExist] = useState(false);
    const [uploadObj, setUploadedObj] = useState({});

    const [countryArray, setCountryArray] = useState([]);
    const [initialFlag, setInitialFlag] = useState(false);
    const [addComb, setAddComb] = useState(false);
    const [updateComb, setUpdateComb] = useState(false);
    const [combinationpopup, setShowCombinationPopup] = useState(false);
    const [advancedsearchpopup, setShowAdvancedSearchPopup] = useState(false);
    const [enableEdit, setEnableEdit] = useState(false);
    const [combinationArray, setCombinationArray] = useState(null);
    const [tableChange, setTableChange] = useState(false);
    const [search, setSearch] = useState({});
    const [percentage, setPercentage] = useState(0);
    const [refreshTable, setRefreshTable] = useState(false);
    const [advancedSearch, setAdvancedSearch] = useState({});
    const [editorFlag, setEditorFlag] = useState(false);
    // const [itemId, setItemId] = useState("");
    const [combinationData, setCombinationData] = useState("");
    const [previousstatus, setPreviousStatus] = useState("");
    const [comparecombination, setCompareCombination] = useState([]);
    const [cancelCombinationpopup, setCancelCombinationPopup] = useState(false);
    const [fileCheck, setFileCheck] = useState("");
    const [selectCategoryName, setSelectCategoryName] = useState("select All");
    const [selectRegionName, setSelectRegionName] = useState("select All");
    const [defaultShow, setDefaultShow] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);
    const [docError, setDocError] = useState(false);
    const [visibleData, setVisibleData] = useState([]);
    const [ischeck, setIsCheck] = useState(false);
    const [showResetPopup, SetShowResetPopup] = useState(false);
    const itemsPerPage1 = 30;
    const currentPage1 = useRef(1);
    const isLoading1 = useRef(false);
    const tableRef = useRef(null);
    const [checkExist, setCheckExist] = useState(false);
    const [checkCombination, setCheckCombination] = useState([]);
    const [minbidprice, setMinBidPrice] = useState("");
    const [resetKey, setResetKey] = useState(0);
    const [iserrorpopup, setIsErrorPopup] = useState(false);
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [combinationLoader, setCombinationLoader] = useState(false);
    const [itemid, setItemId] = useState("");
    const [statusFlag, setStatusFlag] = useState(false);
    const [deleteComb, setDeleteComb] = useState(false);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [deleteAlert1, setDeleteAlert1] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const { quillElement, value, setValue, isValidEditor } = useQuillMixin();
    // console.log('catBasedContentFieldscatBasedContentFields',catBasedContentFields)
    const { route, setRoute, setCurrentPage, setActiveMenuObj, setRowsPerPage, usePrevious, userData, setActiveMenuId, rowsPerPage, currentPageNew, isLoading, setIsLoading, currentCPageNew, setCurrentCPage, rowsPerCPage, setRowsPerCPage } = useContext(contentContext);
    // let fields = [];

    const prevRoute = usePrevious(route)
    useEffect(() => {
        if (prevRoute != undefined && prevRoute != route) {
            setCurrentCPage(1)
            setRowsPerCPage(15)
        }
    }, [route]);
    console.log('prevRoute--->', prevRoute)
    console.log('currentRoute--->', route)
    useEffect(() => {
        console.log("excuted");
        if (!(localStorage.getItem("token"))) {
            console.log('tokaen excte');
            history.push("/");
            localStorage.setItem("check", id);
        }
        console.log('content excte');
        setRoute("item")
        setActiveMenuId(300021)
        setActiveMenuObj({
            "Contact Management": false,
            "Client Management": false,
            "Reports": false,
            "Auction Management": true
        })
        Categories();

        GetLookUp();

        if (!id) {
            var ObjectID = require("bson-objectid");
            var id = new ObjectID().toString();
            setItemId(id);
            localStorage.setItem("itemid", id)
        }
        //  renderDynamicCategoryFields();


    }, []);
    useEffect(() => {
        if (id) {
            getBiddingItem();
            //setCombinationData([])
        }
        console.log('before calling');

        if (id) {
            getItemFiles(id).then((data) => {
                // console.log("getClientcontent data", data);
                if (data.statusCode == 200) {
                    //  console.log(data.result, "---")
                    if (data.result == "Invalid token or Expired") {
                        setShowSessionPopupup(true)
                    } else {
                        setFilesData(data.result);
                        setBtnLoader(false)
                    }

                }
            }).catch((error) => {

                console.log(error);
            })
        }

        if (id) {
            setIsLoading2(true)
            console.log("use eff", isLoading);
            getCombination(id).then((data) => {
                console.log("getClientcontent data", data);
                if (data.statusCode == 200) {
                    console.log(data, "data------------->")
                    if (data.result == "Invalid token or Expired") {
                        setShowSessionPopupup(true)
                        setIsLoading2(false)
                    } else {
                        if (data.result.data.length > 0) {
                            setCurrentCPage(1)
                            setRowsPerCPage(15)
                            let tmpData = data.result.data.map((obj) => ({
                                ...obj,
                                isSelected: false
                            }));
                            setCombinationData(tmpData);
                            setCheckExist(true)
                            const keysToFilter = ['country', 'typesofrights', 'exclusivity', 'revenuetype'];

                            const newArray = tmpData.map(obj => {
                                const filteredObj = {};

                                keysToFilter.forEach(key => {
                                    if (obj.hasOwnProperty(key)) {
                                        filteredObj[key] = obj[key];
                                    }
                                });

                                return filteredObj;
                            });
                            // console.log("newArray",newArray);
                            setCompareCombination(newArray);

                        }
                        setIsLoading2(false)

                    }
                }
            }).catch((error) => {
                console.log(error);
            })

        }
    }, [id]);
    useEffect(() => {

        if (window.site === undefined) {
            setTimeout(() => {
                if (window.site && window.site.common && window.site.common.imagesCDN) {
                    setImg(window.site.common.imagesCDN)
                }
            }, 1000);
        }
        if (window.site && window.site.common && window.site.common.imagesCDN) {
            setImg(window.site.common.imagesCDN)
        }

    }, [window.site]);
    // useEffect(() => {
    const handleScroll = () => {
        const table = tableRef.current;
        if (table) {
            const isAtBottom = Math.round(table.scrollHeight - table.scrollTop) === table.clientHeight;
            console.log("isAtBottom", isAtBottom)
            if (isAtBottom && !isLoading1.current) {
                console.log("called loadmore")
                // Load more data when at the bottom of the table 
                loadMoreData();

            }
        }
    };

    //     if (tableChange && checkCombination && checkCombination.length > 29) {
    //         console.log("add")
    //         tableRef?.current?.addEventListener('scroll', handleScroll);
    //     }

    //     return () => {
    //         if (tableChange && checkCombination && checkCombination.length <= 29) {
    //             console.log("remove")
    //             tableRef?.current?.removeEventListener('scroll', handleScroll);
    //         }
    //     };

    // }, [checkCombination && checkCombination.length > 30]);

    //  useEffect(() => {
    //      if (id) {
    //          getBiddingItem();
    //      }
    //  }, [activeTab === "info"]);
    useEffect(() => {
        setEnableEdit(false);
    }, [combinationArray && combinationArray.length <= 0]);

    const handleFocus = () => {
        setEditorFlag(true)
    }
    const handleBlur = () => {
        setEditorFlag(false)
    }




    // console.log('delSuccess', delSuccess)
    const imageDeleteFun = (e) => {
        // let newContent = Object.assign({}, editcontent);
        // delete newContent[item];
        // setEditContent(newContent)
        let itemId;
        if (!id) {
            itemId = localStorage.getItem("itemid")
        } else {
            itemId = id
        }
        const obj = {
            "contentid": itemId,
            [item]: "",
        }
        updateContentData(itemId, obj).then((data) => {
            if (data.statusCode == 200) {
                if (data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    setBtnLoader(false)
                    setDelSuccess(true);
                    setIsdeleteImage(false)
                    //history.push("/contentmanagement");
                    if (id) {
                        getBiddingItem();
                    }

                    // setBtnLoader(false);


                }
            }
        }).catch((error) => {
            setBtnLoader(false)
            setBtnLoader(false);
            console.log(error);
        })
    }
    // console.log('error----->',errors)
    const handleSaveItem = async (e) => {
        submit();
    }

    const submit = async (e) => {
        // e.preventDefault();
        const isValid = validateMandatoryFields();
        console.log("isValid", isValid)
        if (isValid) {
            setBtnLoader(true);
            if (id) {

                if (editcontent.status != "CLOSED") {
                    editcontent["bidenddate"] = "";
                }

                delete editcontent["createdBy"];
                delete editcontent["updatedBy"];
                let addObj = { ...editcontent, synopsis: value }
                updateBiddingItems(id, addObj).then((data) => {
                    if (data.statusCode == 200) {
                        if (data.result == "Invalid token or Expired") {
                            setShowSessionPopupup(true)
                        } else if (data.result == "Same title aready exists") {
                            setBtnLoader(false);
                            setIsTitleExist(true)
                            setIsLoading(false);
                            // setCombinationLoader(false)
                        }
                        else if (data.result == "updated successfully") {
                            setSuccess(true);
                            setIsLoading(false);
                            // setCombinationLoader(false)
                            //history.push("/contentmanagement");
                            //  getBiddingItem();

                            setBtnLoader(false);
                        }
                    }
                }).catch((error) => {
                    setBtnLoader(false);
                    console.log(error);
                })

            } else {
                setButtonClicked(true);
                let itemStatus;
                if (editcontent.status === undefined) {
                    itemStatus = editcontent && editcontent.bidstartdate && editcontent.bidstartdate === new Date().toISOString().slice(0, 10) ? editcontent.status : (editcontent && editcontent.bidstartdate === undefined || editcontent && editcontent.bidstartdate === "") ? editcontent.status : "COMING SOON"
                }
                console.log("itemStatus", itemStatus);

                if ((editcontent.status === undefined) && (editcontent.bidstartdate === undefined)) {
                    itemStatus = "INACTIVE"
                } else if (editcontent.status) {
                    itemStatus = editcontent.status
                } else if (editcontent.status === undefined && editcontent.bidstartdate === new Date().toISOString().slice(0, 10) && (combinationData && combinationData.length > 0) && (filesData && filesData.length > 0 && (filesData.some(item => item.contentType === "THUMBNAILS")))) {
                    itemStatus = "ACTIVE"
                }
                else if (editcontent.status === undefined && editcontent.bidstartdate === new Date().toISOString().slice(0, 10)) {
                    itemStatus = "INACTIVE"
                }

                let addObj = { ...editcontent, itemid: itemid, synopsis: value, createdBy: { userid: userData.userid, username: userData.name }, bidderscount: 0, countriescount: 0, status: itemStatus }
                AddItem(addObj).then((data) => {
                    if (data.statusCode == 200) {
                        if (data.result == "Invalid token or Expired") {
                            setShowSessionPopupup(true)
                        } else if (data.result === "Same title already exists") {
                            setBtnLoader(false);
                            setIsTitleExist(true)
                        }
                        else if (data.result) {
                            setAddSuccess(true);
                            // let id = data.result;
                            // setItemId(data.result)
                            // history.push("/edititem/" + id);
                            setContentTitle(editcontent.title);
                            setBtnLoader(false);
                        }
                    }
                }).catch((error) => {
                    setBtnLoader(false);
                    console.log(error);
                })

            }
            // 
            // console.log("loader disable");
        } else {
            console.log('form in valid');
        }
    }
    console.log("setButtonClicked", new Date()?.toISOString()?.split('T')[0] >= editcontent.bidstartdate?.split('T')[0]);
    const handleBack = (e) => {
        if (id) {
            history.push({
                pathname: "/itemmanagement",
                state: { search: true }
            });
        } else {
            history.push("/itemmanagement")
        }
    }

    const getBiddingItem = (e) => {
        setIsLoading(true)
        const token = localStorage.getItem("token")
        axios({
            method: 'GET',
            url: lambda + '/biddingItems?appname=' + appname + '&itemid=' + id + "&token=" + token,
        })
            .then(function (response) {
                if (response.data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                    setIsLoading(false)
                } else {
                    let result = response.data.result.data[0]
                    if (response.data.result.data.length > 0) {

                        setEditContent({ ...editcontent, ...result });
                        setContentTitle(result.title)
                        setValue(result.synopsis);
                        if (result.status === "ACTIVE" || result.status === "CLOSED") {
                            setStatusFlag(true);
                        }
                        setIsLoading(false)
                    }
                    else {
                        setInvalidContent(true)
                    }
                }
            });

    }

    console.log("status", statusFlag);
    const handleChange = (e, editor) => {
        if (!["ACTIVE", "COMING SOON"].includes(e.target.value)) {
            let error = Object.assign({}, errors);
            delete error['itemimage'];
            delete error['synopsis'];
            delete error['combinationlist'];
            delete error['availability']
            delete error['bidstartdate']
            delete error["bidactivemsg"];
            setErrors(error);
        }
        if (!!errors[e.target.name]) {
            let error = Object.assign({}, errors);

            delete error[e.target.name];
            setErrors(error);
        }
        if (e.target.name === "bidstartdate") {
            console.log("came--------");
            let error = Object.assign({}, errors);
            delete error["bidenddate"];
            delete error["bidstartdate"];
            delete error["bidactivemsg"];
            setErrors(error);
        }
        if (e.target.name === "status") {
            delete errors["bidactivemsg"];
            delete errors["bidenddate"];
            delete errors['combinationlist'];
        }
        if (e.target.name === "title") {
            setIsCheck(true);
            setEditContent({ ...editcontent, [e.target.name]: e.target.value });
        } else if (e.target.name === "bidstartdate") {

            setEditContent({ ...editcontent, [e.target.name]: e.target.value });
        } else {
            setEditContent({ ...editcontent, [e.target.name]: e.target.value });
        }

    }
    const handleEditorChange = (newContent) => {
        if (errors["synopsis"]) {
            delete errors["synopsis"];
        }

        // if (editorFlag === true) {
        //     //setEditContent({ ...editcontent, synopsis: newContent });
        //     setEditContent((prevState) => {
        //         return { ...prevState, synopsis: newContent };
        //       });
        // }

        setValue(newContent);
        // You can perform any other logic here based on the changed content
    };



    const returnArray = (arr) => {
        let arr2 = []
        arr.map((eachItem) => {
            arr2.push(eachItem.value)
        })
        // console.log(arr2)
        return arr2
    }

    const handleChangeMultiSelectCategory = (selected, key) => {

        if (key === "region") {
            if (countryArray[key]?.length <= 1) {
                setSelectRegionName("select All")
            }
        }
        if (key === "countries") {
            if (countryArray[key]?.length <= 1) {
                setSelectCategoryName("select All")
            }
        }

        setCountryArray({ ...countryArray, [key]: selected })
    }

    const handleSelectAll = (e, name) => {
        if (name === "select All") {
            setSelectCategoryName("unselect All")
            setCountryArray({ ...countryArray, countries: countryData })
        } else {
            setSelectCategoryName("select All")
            setCountryArray({ ...countryArray, countries: [] })
        }

    };
    const handleSelectAllRegion = (e, name) => {
        if (name === "select All") {
            setSelectRegionName("unselect All")
            setCountryArray({ ...countryArray, region: regionsData })
        } else {
            setSelectRegionName("select All")
            setCountryArray({ ...countryArray, region: [] })
        }

    };
    const handleChangeMultiSelectAdvSearch = (selected, key) => {
        if (key === "typesofrights") {
            setResetKey((prevKey) => (prevKey + 1) % 2);
            if (selected === null) {
                const updatedObject = { ...advancedSearch };
                delete updatedObject[key];
                delete updatedObject["revenuetype"];
                setAdvancedSearch(updatedObject);
            } else {
                setAdvancedSearch({ ...advancedSearch, [key]: selected.value, revenuetype: selected.revenuetype });
            }
        } else {
            let selectedArray = returnArray(selected)
            setAdvancedSearch({ ...advancedSearch, [key]: selectedArray })
        }
    }
    const handleadvSearchChange = (e, key) => {
        setAdvancedSearch({ ...advancedSearch, [e.target.name]: e.target.value });
    }



    const GetLookUp = async (e) => {
        try {
            let arrayType = ["country", "genre", "videoformat", "resolution", "musicgenre", "rights", "cuisine", "sports", "certificate", "language", "territories", "region", "exclusivity", "revenue"];

            const response = await tmdbApi.getBiddingLookUp({
                type: arrayType,
                status: 'ACTIVE'

            });
            // console.log('response here', response);
            if (response.statusCode == 200) {
                let arrayType = ["country", "genre", "videoformat", "resolution", "musicgenre", "rights", "cuisine", "sports", "certificate", "language", "territories", "region", "exclusivity", "revenue"];

                let lookupsData = response.result?.data || []

                arrayType.map((eachItem) => {
                    let arr1 = []
                    lookupsData.map((item1) => {
                        if (item1.type == eachItem) {
                            arr1.push(item1.name)
                            if (item1.type == "rights") {
                                setTypesofrights(typesofrights => ([
                                    ...typesofrights,
                                    item1
                                ]));

                            }
                        }
                    });
                    lookup[eachItem] = arr1
                    setLookUp({ ...lookup });
                })
                //  let arrayType = ["country","genre","videoformat","resolution","musicgenre","rights","cuisine","sports","certificate"];

                const countryObj = lookup.country.map((eachItem) => { return { value: eachItem, label: eachItem }; })
                setCountryData(countryObj)
                const regionsObj = lookup.region.map((eachItem) => { return { value: eachItem, label: eachItem }; })
                setRegionsData(regionsObj)
                const exclusivityObj = lookup.exclusivity
                setExclusivityData(exclusivityObj)
                const revneueObj = lookup.revenue.map((eachItem) => { return { value: eachItem, label: eachItem }; })
                setRevenueData(revneueObj)

            }

        } catch {
            console.log("error");
        }
    }

    const handlePlayer = (e, content) => {
        let inn = content && content.subtitles && content.subtitles.FRENCH
        if (content.filetype === "VIDEO" && content?.video?.m3u8?.proxyUrl) {
            let source = window.site.common.proxiesCloudFront + content?.video?.m3u8?.proxyUrl
            // let subtitlePath = window.site.common.proxiesCloudFront +"/"+ content?.subtitles


            setPlayContent(source);

            setPlay(true);
            console.log("playercontent", content);
        }
        if (content.filetype === "AUDIO") {
            let source = window.site.common.proxiesCloudFront + content?.video?.m3u8?.proxyUrl
            setPlayContent(source);
            setPlay(true);
            console.log("playercontent", content);
        }

    }
    const openFileViewer = (e, content) => {
        console.log('contenttt item', content)
        let source;
         source = 
        (content?.filetype === "JPG" || content?.filetype === "PNG" || content?.filetype === "JPEG" || content?.filetype === "JFIF")
        ? image + content.sourcepath 
        : window.site.common.resourcesCDN+"/" + content.sourcepath;
        setPlayContent(source);
        setType(content.mimetype);
        setshowDoc(true);
        console.log("source content", content);
        console.log("source content",);
    }

    const handleAddFile = (e, item, type) => {
        console.log("item", item);
        console.log("type", type);
        setFileCategory(item);
        setUploadType(type);
        setShowUpload(true);
    }



    const handleClose = (e) => {
        if (BtnLoader1) {
            setShowPopup(true)
        } else {
            setShowUpload(false);
        }
        setShowUpload(false);
        setValidateFiles(false)
        setFileName("");
        ref.current.value = "";
        setExcededFile(false)
        setDocumentExcededFile(false)
        setVideoExcededFile(false)
    }

    const handleDeleteFile = (e, fileid, type) => {
        if (type == 'seller') setDelSeller(true)
        setDelete(true);
        console.log('fileid', fileid)
        setItem(fileid);
    }
    const handleDeleteImage = (e, field) => {
        console.log('fileidfileidfileid', field)
        setIsdeleteImage(true);
        setItem(field)
        ContentFiles();
    }
    const onCancelDelete = () => {
        setIsdeleteImage(false)
        setItem("")
    }
    const confirmDeleteImage = (e) => {

        setBtnLoader(true)

        imageDeleteFun()
    }
    // console.log('render editcontent',editcontent)
    const handleDelete = (e) => {
        let itemId;
        if (!id) {
            itemId = localStorage.getItem("itemid")
        } else {
            itemId = id
        }
        const token = localStorage.getItem("token")
        const userid = localStorage.getItem("userId")

        setBtnLoader1(true)
        axios({
            method: 'DELETE',
            url: lambda + '/biddingFiles?appname=' + appname + "&itemid=" + itemId + "&itemfileid=" + item + "&token=" + token + "&userid=" + userid,
        })
            .then(function (response) {
                if (response) {
                    if (response.data.result == "Invalid token or Expired") {
                        setShowSessionPopupup(true)
                    } else {

                        getItemFiles(itemId).then((data) => {
                            // console.log("getClientcontent data", data);
                            if (data.statusCode == 200) {
                                //  console.log(data.result, "---")
                                if (data.result == "Invalid token or Expired") {
                                    setShowSessionPopupup(true)
                                } else {
                                    setFilesData(data.result);

                                    setDelete(false)
                                    setBtnLoader1(false)
                                    setDelPopup(true)
                                }

                            }
                        }).catch((error) => {
                            setBtnLoader1(false)
                            setDelete(false)
                            console.log(error);
                        })
                        // setDelete(true);
                    }
                }
            });

    }

    function onCancel() {
        setDelete(false)
        setDelSeller(false)
    }

    const ContentFiles = (e) => {
        let itemId;
        if (!id) {
            itemId = localStorage.getItem("itemid")
        } else {
            itemId = id
        }
        console.log("id", itemId);
        getItemFiles(itemId).then((data) => {
            console.log("getClientcontent data", data);
            if (data.statusCode == 200) {
                // console.log(data.result, "---")
                if (data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    setFilesData(data.result);
                    console.log('data.result--------->', data.result)
                    if (activeTrailerId != "" && activeTrailerId != undefined) {
                        const k = data && data.result && data.result.length > 0 && data.result.filter(eachItem => eachItem.contentfileid === activeTrailerId)
                        // console.log('kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk',k)
                        // setActiveSubTitles(k && k[0] && k[0]?.subtitles)
                    }


                }
            }
        }).catch((error) => {
            console.log(error);
        })
    }




    const uploadS3 = async (e, type) => {
        console.log("type---------", type[2]);
        // setBtnLoader(true);
        let checkformat = e.target.files[0]?.name.split(".");
        var checkfileformat = checkformat[checkformat.length - 1]
        console.log("fileCategory@@@@@@@@@@", fileCategory === "DOCUMENTS" && checkfileformat !== "pdf")
        setBtnLoader1(true)
        var fileData = new FormData();
        var file = e.target.files[0];

        if ((fileCategory === "THUMBNAILS" && file.size >= 10000000)) {
            setExcededFile(true)
            setBtnLoader1(false);
        } else if ((fileCategory === "DOCUMENTS" && file.size >= 100000000)) {
            setDocumentExcededFile(true)
            setBtnLoader1(false);
        } else if (fileCategory != "VIDEOS" && file.size >= 1200000000) {
            setVideoExcededFile(true);
            setBtnLoader1(false);
        } else if (fileCategory === "DOCUMENTS" && checkfileformat !== "pdf") {
            setDocError(true);
            setBtnLoader1(false);
        } else if (
            (fileCategory === "VIDEOS") &&
            !(file.type.startsWith("video/")) // Check if the file is not a video
        ) {
            // Alert when a non-video file is being uploaded for specified categories

            setBtnLoader1(false);

            setValidateFiles(true)
        } else if (fileCategory === "THUMBNAILS" && !(file.type.startsWith("image/"))) {
            setBtnLoader1(false);

            setValidateFiles(true);
        } else {
            console.log("file---------->", file);
            var uploadFilePath = "";
            var filename = e.target.files[0].name;
            var s3file = e.target.files[0];
            fileData.append(type[1], s3file);
            var bucket;

            var reader = new FileReader();
            reader.readAsDataURL(s3file);
            reader.onload = function (e) {
                var image = new Image();
                image.src = e.target.result;
                var ObjectID = require("bson-objectid");
                var fileid = new ObjectID().toString();
                var videoId = type[1]

                let format = file.name.split(".");
                var fileformat = format[format.length - 1]
                console.log('file------>', file)
                console.log('formatttt------>', format)
                console.log('fileformat------>', fileformat)
                const timestamp = Date.now();
                console.log("format", format)
                console.log("fileformat", fileCategory)
                let allowedVideoFiles = ["avi", "divx", "f4v", "flv", "mts", "m2t", "m4v", "mkv", "mov", "mp4", "mpeg", "mpg", "mxf", "r3d", "wmv"]
                let audio = ["aif", "aiff", "m4a", "mp3", "ogg", "wav"]
                let extension;
                if (allowedVideoFiles.includes(fileformat)) {
                    extension = "VIDEO"
                } else if (audio.includes(fileformat)) {
                    extension = "AUDIO"
                } else {
                    extension = fileformat.toUpperCase()
                }


                if (extension === "VIDEO") {
                    bucket = window.site.common.sourceBucket;
                } else if (extension === "AUDIO") {

                    bucket = window.site.common.sourceBucket;
                } else {
                    bucket = window.site.common.resourceBucket;
                }
                var path;
                if (itemid) {
                    path = "bidding/" + itemid;
                } else {
                    path = "bidding/" + id;
                }

                console.log("type", file.type)




                uploadFilePath = appname + "/" + path + "/" + fileid + "_" + fileCategory + "_" + timestamp + "." + fileformat;

                console.log("uploadFilePath", uploadFilePath);
                // let imagePath = window.site && window.site.common && window.site.common.imagesCDN;
                var data = { source_bucket: bucket, sourcepath: uploadFilePath }
                setUploadedObj(data)
                const token = localStorage.getItem("token")
                const userid = localStorage.getItem("userId")



                axios.post(lambda + '/uploadFiles?appname=' + appname + "&token=" + token + "&userid=" + userid, data, { type: 'application/json' })
                    .then((response) => {
                        if (response.data && response.data.result) {
                            var url = response.data.result;

                            console.log("url", url);
                            axios.put(url, file, {
                                "headers": {
                                    "Content-Type": "multipart/form-data",
                                    "Accept": "/",
                                    "Cache-Control": "no-cache",
                                    "Accept-Encoding": "gzip, deflate",
                                    "Connection": "keep-alive",
                                    "cache-control": "no-cache"
                                }
                            })
                                .then((response) => {
                                    if (response && response.status === 200) {

                                        let imageUploadPath = uploadFilePath;
                                        console.log("parth", imageUploadPath);

                                        const now = new Date();
                                        const year = now.getFullYear();
                                        const month = (now.getMonth() + 1).toString().padStart(2, '0');
                                        const day = now.getDate().toString().padStart(2, '0');
                                        const hours = now.getHours().toString().padStart(2, '0');
                                        const minutes = now.getMinutes().toString().padStart(2, '0');
                                        const seconds = now.getSeconds().toString().padStart(2, '0');

                                        const currentDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

                                        // const current = new Date();
                                        // const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;


                                        let allowedVideoFiles = ["avi", "divx", "f4v", "flv", "mts", "m2t", "m4v", "mkv", "mov", "mp4", "mpeg", "mpg", "mxf", "r3d", "wmv"]
                                        let audio = ["aif", "aiff", "m4a", "mp3", "ogg", "wav"]
                                        let extension;
                                        if (allowedVideoFiles.includes(fileformat)) {
                                            extension = "VIDEO"
                                        } else if (audio.includes(fileformat)) {
                                            extension = "AUDIO"
                                        } else {
                                            extension = fileformat.toUpperCase()
                                        }
                                        if (fileCategory === "THUMBNAILS") {
                                            delete errors["itemimage"];
                                        }
                                        let uploadObj = {};

                                        uploadObj.appname = appname;

                                        uploadObj.itemfileid = fileid;
                                        if (!id) {
                                            uploadObj.itemid = itemid;
                                        } else {
                                            uploadObj.itemid = id;
                                        }

                                        uploadObj.name = fileName;
                                        uploadObj.contentType = fileCategory;
                                        uploadObj.filename = file.name;
                                        uploadObj.sourcepath = imageUploadPath;
                                        uploadObj.mimetype = fileformat;
                                        // uploadObj.created = date.replaceAll("/", "-");
                                        uploadObj.created = currentDateTime;
                                        uploadObj.filetype = extension;
                                        uploadObj.createdBy = { userid: localStorage.getItem("userId"), username: localStorage.getItem("ClientName") }

                                        setBtnLoader1(false);
                                        const userid = localStorage.getItem("userId")

                                        console.log("obj", uploadObj)
                                        axios({
                                            method: 'PUT',
                                            url: lambda + '/biddingFiles?appname=' + appname + "&token=" + token + "&userid=" + userid,
                                            data: uploadObj,
                                        })
                                            .then(function (response) {
                                                if (response) {
                                                    if (editcontent.defaultimage === undefined && fileCategory === "THUMBNAILS") {
                                                        setEditContent({ ...editcontent, itemimage: imageUploadPath })
                                                    }
                                                    setBtnLoader1(false)
                                                    setShowUpload(false);
                                                    setSuccess(true)
                                                    ContentFiles();
                                                    setShowPopup(false);
                                                    setFileName("");
                                                    ref.current.value = "";



                                                }
                                            }).catch((err) => {
                                                setBtnLoader1(false)
                                                console.log(err);
                                            });





                                    }
                                })
                                .catch((err) => {
                                    console.error.bind(err);
                                })
                        }
                    })
                    .catch((err) => {
                        console.error.bind(err);
                    })

            }
        }

    }



    const previewImage = (e, value) => {
        console.log('valueeeee', value)
        setShowPreview(true)
        setPreviewImg(value)
    }
    const defaultImage = (e, path, fileid) => {
        let itemId;
        if (!id) {
            itemId = localStorage.getItem("itemid")
        } else {
            itemId = id
        }
        setDefaultShow(true)
        setFileCheck(fileid);
        delete errors["itemimage"];
        let defaultObj = {
            "itemimage": path,
            "defaultimage": fileid
        }
        setDefault(itemId, defaultObj).then((data) => {
            if (data.statusCode == 200) {
                console.log(data.result, "---add")
                if (data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                }
                else if (data.result === "updated successfully") {
                    setEditContent({ ...editcontent, itemimage: path, defaultimage: fileid })
                    setSuccess(true)
                }
            }
        }).catch((error) => {
            setBtnLoader(false);
            console.log(error);
        })
    }
    // function onUploadCancel(e) {
    //     uploadObj.abort();
    //     setShowPopup(false)
    // }
    // const handleUploadFile = () => {
    //     setBtnLoader1(false)
    //     setShowUpload(false)
    //     setShowPopup(false)
    // }
    const filteredArray = filesData && filesData.filter(obj => obj.contentType === 'THUMBNAILS' && obj.status != 'archive');
    console.log("filteredArray", filteredArray)
    const filesTab = () => {

        return (
            <><div className={`tab-pane ${activeTab == 'files' ? 'active show' : ''}`} role="tabpanel" id="videoclips">

                {videoClipcategories?.map((eachItem, key) => {

                    const items = filesData.filter(item => item.contentType.indexOf(eachItem) !== -1);
                    return (
                        <>

                            {items.length > 0 ?

                                <div className="row" key={key}>

                                    <div className="col-md-3 d-flex align-items-center">
                                        <h5 className="font-size-14">{eachItem}</h5>

                                    </div>
                                    <div className="col-md-9">

                                    </div>
                                    {filesData?.map((item, key) => {


                                        return (
                                            eachItem == item.contentType && <><div className={item.contentType === "DOCUMENTS" ? "thumbnail-block pdf-block" : "thumbnail-block"}>

                                                {(editcontent.defaultimage === item.itemfileid) || (filteredArray.length <= 1 && filesData.length === 1) ? "" : <span className="material-icons-outlined thumbnail-cls-icon" onClick={(e) => handleDeleteFile(e, item.itemfileid, 'file')}>highlight_off</span>}
                                                <div className="asset-card">
                                                    {item.filetype == 'VIDEO' && item.status === "AVAILABLE" && <div className="play-icon">
                                                        <span className="material-icons-outlined">play_circle</span>
                                                    </div>}
                                                    <a className="spinner-class" onClick={(e) => (item.filetype == 'VIDEO') || (item.filetype == 'AUDIO') ? handlePlayer(e, item) : item.filetype != 'AUDIO' ? openFileViewer(e, item) : null}>
                                                        {item.contentType === "VIDEOS" && <img src={editcontent.itemimage ? image + editcontent.itemimage : image+"orasi/common/images/img-default.jpg"} />} {item.contentType === "THUMBNAILS" && <img src={image + item.sourcepath} />}
                                                        {item.contentType === "DOCUMENTS" && <div className="position-relative">
                                                            <div className="pdf-document">
                                                                <img src={image+"orasi/admin/resources/orasiv1/images/pdf.png?auto=compress,format"} />
                                                            </div>
                                                        </div>}
                                                    </a>
                                                    <p style={{ clear: 'both', display: "inline-block", overflow: 'hidden', whiteSpace: 'nowrap' }}>{item.name ? item.name : item.filename}</p>

                                                </div>
                                                {item.contentType === "THUMBNAILS" &&
                                                    <div className="edit-info black-gradient landscape-btns">
                                                        <div className="form-check font-size-16">
                                                            {defaultShow === true ? <input className="form-check-input" type="radio" value={item.source} onClick={(e) => defaultImage(e, item.sourcepath, item.itemfileid)} checked={fileCheck === item.itemfileid} /> :
                                                                <input className="form-check-input" type="radio" value={item.source} onClick={(e) => defaultImage(e, item.sourcepath, item.itemfileid)} checked={editcontent.defaultimage === item.itemfileid} />}
                                                        </div>
                                                    </div>}
                                            </div>

                                            </>

                                        );

                                    })}
                                    <div className="add-block" onClick={(e) => handleAddFile(e, eachItem)}>
                                        <span className="material-icons-outlined">add</span>
                                        add
                                    </div>

                                </div>
                                : <div className="row">
                                    <div className="col-md-3 d-flex align-items-center">
                                        <h5 className="font-size-14">{eachItem}</h5>

                                    </div>
                                    <div className="col-md-9">

                                    </div>
                                    {/* <p>No Files Found</p> */}
                                    <div className="basic-info-img">

                                        <div className="add-block" onClick={(e) => handleAddFile(e, eachItem)}>
                                            <span className="material-icons-outlined">add</span>
                                            add
                                        </div>
                                    </div>
                                </div>}
                        </>
                    );



                    // }
                })}
                {play ?

                    <PlayerInfo source={playContent} play={play} setPlay={setPlay} subtitles={playerSubTitles} />
                    : null}
                {showDoc && <FileViewer source={playContent} type={type} close={setshowDoc} />}



            </div>
                <div className="row status">
                    <div className="col-md-3 justify-content-between ps-0">
                        <><label className="col-form-label">status<span className="required">*</span></label><select className="form-select" name="status" placeholder="Select Type" onChange={(e) => handleChange(e)} value={editcontent.status}>
                            {statusFlag ?
                                <><option value="CLOSED">CLOSED</option><option value="ACTIVE">ACTIVE</option><option value="INACTIVE">INACTIVE</option></> :
                                <><option value="INACTIVE">INACTIVE</option><option value="COMING SOON">COMING SOON</option><option value="ACTIVE">ACTIVE</option></>}

                        </select></>
                    </div>


                    {!isLoading2 && !combinationLoader && <div className="col-md-9 justify-content-end d-flex align-items-center">
                        {id ? <a className="btn btn-primary" onClick={submit}>{BtnLoader ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}UPDATE</a> :
                            <button className="btn btn-primary" onClick={(e) => handleSaveItem(e)} disabled={isButtonClicked}>{BtnLoader ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}SAVE</button>
                        }
                    </div>}


                </div>
                {errors.title && <><span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.title}</span><br /></>}
                {errors.availability && <><span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.availability}</span><br /></>}
                {errors.bidstartdate && <><span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.bidstartdate}</span><br /></>}
                {(errors.bidenddate && editcontent.status === 'CLOSED') && <><span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.bidenddate}</span><br /></>}
                {errors.status && <><span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.status}</span><br /></>}
                {errors.synopsis && <><span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.synopsis}</span><br /></>}
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {errors.itemimage && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.itemimage}</span>}
                    {errors.combinationlist && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.combinationlist}</span>}
                    {errors.bidactivemsg && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.bidactivemsg}</span>}
                </div>
            </>

        )
    }

    // const removeDuplicates = (arr1, arr2) => {
    //     console.log("updatedArrayOfObjects", arr1, "comparecombination", arr2);
    //     setIsLoading(true)
    //     let res = [];
    //     res = arr1.filter(el => {
    //         return !arr2.find(element => {
    //             return element.country === el.country && element.typesofrights === el.typesofrights && element.revenuetype === el.revenuetype && element.exclusivity === el.exclusivity;
    //         });
    //     });
    //     setIsLoading(false)
    //     return res;

    // }
    const removeDuplicates = (arr1, arr2) => {
        // Define a helper function to compare the specified fields
        const isDuplicate = (a, b) => {
            return a.country === b.country &&
                a.typesofrights === b.typesofrights &&
                a.exclusivity === b.exclusivity;
        };

        // Filter out items from arr2 that match any item in arr1
        const filteredArray2 = arr1.filter(item2 =>
            !arr2.some(item1 => isDuplicate(item1, item2))
        );

        return filteredArray2;
    };

    function checkObjectProperties(obj) {
        for (const key in obj) {
            if (countryArray[key].length > 0) {
                return false
            }

        }
        return true; // All key-value pairs exist in the object
    }
    function checkAdvObjectProperties(obj) {
        for (const key in obj) {
            if (advancedSearch && advancedSearch[key]?.length > 0) {
                return false
            }

        }
        return true; // All key-value pairs exist in the object
    }
    console.log("combbbbbbbb", checkCombination);
    const handleCreateCombination = (e, countries) => {
        currentPage1.current = 1;
        isLoading1.current = false;
        setCombinationData("");
        setVisibleData([]);
        setVisibleData(prevState => ([]));
        setCheckCombination([]);
        setCheckCombination(prevState => ([]));
        setTableChange(true)
        setInitialFlag(true);
        setRefreshTable(true);
        setBtnLoader(true)
        setIsLoading(true)
        let mycountry = [];

        countryArray && countryArray.countries?.length > 0 && countryArray.countries.map((eachItem) => {
            mycountry.push(eachItem.value);
        })

        let myregion = [];
        countryArray && countryArray.region?.length > 0 && countryArray.region.map((eachItem) => {
            myregion.push(eachItem.value);
        })
        //console.log("country",mycountry , myregion);
        let payload = {};
        if (mycountry && mycountry?.length > 0) {
            payload = {
                "countries": mycountry,
            }
        } else if (myregion && myregion?.length > 0) {
            payload = {
                "region": myregion,
            }
        }



        console.log("country", payload);
        CountryCombination(payload).then(async (data) => {
            console.log("getClientcontent data", data);
            if (data.statusCode == 200) {
                //  console.log(data.result, "---ssssssssssssssss")
                let objKeys = Object.keys(data.result)
                function arrayLoop(array, array2) {
                    let filteredValues = [];
                    if (array.length > 0 && array2.length > 0) {
                        array.forEach((item) => {
                            array2.forEach((item2) => {
                                let object = { ...item, ...item2 }
                                filteredValues.push(object)
                            });

                        });
                        return filteredValues
                    } else {
                        filteredValues = array
                        return filteredValues
                    }
                }
                setCheckCombination([])
                var asyncLoop = require('node-async-loop');
                let loopvalarray = []
                asyncLoop(objKeys, function (item, next) {
                    console.log('item!', data.result[item]);
                    loopvalarray = arrayLoop(data.result[item], loopvalarray)

                    next();

                }, function (err) {
                    // console.log('loopvalarray!', loopvalarray);
                    // console.log('loopvalarray!!!!!!!!!!!', JSON.stringify(loopvalarray));
                    // console.log('Finished!');

                });

                const updatedArrayOfObjects = loopvalarray.map((obj) => ({
                    ...obj,
                    period: 1,
                    minbidprice: parseInt(minbidprice),
                    itemid: id,
                }));

                updatedArrayOfObjects.sort((a, b) => {
                    const nameA = a.country.toUpperCase(); // Convert names to uppercase for case-insensitive sorting
                    const nameB = b.country.toUpperCase();

                    if (nameA < nameB) {
                        return -1; // a should come before b in the sorted order
                    }
                    if (nameA > nameB) {
                        return 1; // a should come after b in the sorted order
                    }
                    return 0; // a and b are equal
                });

                console.log("payload", updatedArrayOfObjects)

                const uniqueArray = await removeDuplicates(updatedArrayOfObjects, comparecombination);
                console.log('uniqueArray!', uniqueArray);

                setCombinationData(uniqueArray);
                setCheckCombination(uniqueArray);
                setVisibleData(uniqueArray.slice(0, itemsPerPage1));
                setShowCombinationPopup(false);

                setBtnLoader(false);
                setCountryArray({})
                setSelectRegionName("select All")
                setSelectCategoryName("select All")
                delete errors["combinationlist"]
                setMinBidPrice("")
                setIsLoading(false)
            }
        }).catch((error) => {
            console.log(error);
        })
    }
    console.log("checkCombination", checkCombination)
    const loadMoreData = () => {
        console.log("checkCombination1", checkCombination)
        if (checkCombination && checkCombination.length > 30) {
            console.log("checkCombination2", checkCombination)
            isLoading1.current = true;
            const nextPageStart = currentPage1.current * itemsPerPage1;
            const nextPageEnd = nextPageStart + itemsPerPage1;
            const nextData = checkCombination.slice(nextPageStart, nextPageEnd);
            console.log("nextData", nextData)
            if (nextData.length > 0 && checkCombination && checkCombination.length > 30) {
                setVisibleData((prevVisibleData) => [...prevVisibleData, ...nextData]);
                currentPage1.current++;
                isLoading1.current = false;
            }
        }
    };

    console.log("visible", visibleData);

    const handlePageChange = (page) => {
        setCurrentCPage(page);
    };

    const handlePerRowsChange = (newPerPage) => {
        setRowsPerCPage(newPerPage);
    };
    const handleShowPopup = () => {
        setShowCombinationPopup(true)
    }

    const handleShowAdvancedSearchPopup = () => {
        setShowAdvancedSearchPopup(true)
    }
    const handleCancelCombination = () => {

        setCancelCombinationPopup(true)
    }
    const handleSaveCombination = (e) => {
        e.preventDefault();
        if (!isButtonDisabled) {
            setButtonDisabled(true);

            const newArray = combinationData.map(obj => ({ ...obj, title: editcontent.title, itemid: id ? id : itemid, itemimage: editcontent.itemimage }));
            const filteredData = newArray.filter(item => item.hasOwnProperty('minbidprice') && !isNaN(item["minbidprice"]) && item.minbidprice > 0);
            console.log("filteredData", filteredData)
            setCombinationLoader(true)
            let itemId;
            if (!id) {
                itemId = localStorage.getItem("itemid")
            } else {
                itemId = id
            }
            SaveCombination(itemId, filteredData).then((data) => {

                if (data.statusCode == 200) {
                    console.log(data, "mydata------------->")
                    if (data.result == "Invalid token or Expired") {
                        setShowSessionPopupup(true)

                    } else {
                        setAddComb(true)
                        setCombinationLoader(false)
                        setIsLoading(true)
                        delete errors["combinationlist"];
                        getCombination(itemId).then((data) => {
                            console.log("getClientcontent data", data);
                            if (data.statusCode == 200) {
                                console.log(data, "data------------->")
                                if (data.result == "Invalid token or Expired") {
                                    setShowSessionPopupup(true)
                                } else {
                                    if (data.result.data.length > 0) {

                                        let tmpData = data.result.data.map((obj) => ({
                                            ...obj,
                                            isSelected: false
                                        }));
                                        setCombinationData(tmpData);
                                        setCheckExist(true)
                                        const keysToFilter = ['country', 'typesofrights', 'exclusivity', 'revenuetype'];

                                        const newArray = tmpData.map(obj => {
                                            const filteredObj = {};

                                            keysToFilter.forEach(key => {
                                                if (obj.hasOwnProperty(key)) {
                                                    filteredObj[key] = obj[key];
                                                }
                                            });

                                            return filteredObj;
                                        });
                                        // console.log("newArray",newArray);
                                        setCompareCombination(newArray);
                                        setPercentage("")
                                    }
                                    if (previousstatus && editcontent.status === "INACTIVE") {
                                        delete editcontent["createdBy"];
                                        delete editcontent["updatedBy"];
                                        let addObj = { ...editcontent, status: previousstatus }
                                        updateBiddingItems(id, addObj).then((data) => {
                                            if (data.statusCode == 200) {
                                                if (data.result == "Invalid token or Expired") {
                                                    setShowSessionPopupup(true)
                                                }
                                                else if (data.result == "updated successfully") {
                                                    //setSuccess(true);   
                                                    getBiddingItem();
                                                }
                                            }
                                        }).catch((error) => {
                                            setBtnLoader(false);
                                            console.log(error);
                                        })
                                    }

                                }
                            }
                        }).catch((error) => {
                            console.log(error);
                        })


                    }
                }
            }).catch((error) => {
                console.log(error);
                setBtnLoader(false)
            })
            setTimeout(() => {
                setButtonDisabled(false);
            }, 30000);
        }
    }
    const handleUpdateCombination = () => {

        const result = combinationData.filter((item1) => {
            // Check if item1.id is not present in array2
            return combinationArray ? combinationArray.some((item2) => item2.combinationid === item1.combinationid) : [];
        });


        const filteredData = result.map(({ combinationid, minbidprice }) => ({ combinationid, minbidprice }));
        console.log("filteredData", filteredData);
        let isMinBidPriceZero = filteredData.some(item => item.minbidprice === 0 || item.minbidprice === "");

        if (isMinBidPriceZero) {
            setIsErrorPopup(true)
        } else {
            let itemId;
            if (!id) {
                itemId = localStorage.getItem("itemid")
            } else {
                itemId = id
            }
            UpdateCombination(itemId, filteredData).then((data) => {
                setBtnLoader1(true)
                if (data.statusCode == 200) {
                    if (data.result == "Invalid token or Expired") {
                        setShowSessionPopupup(true)

                    } else {
                        setUpdateComb(true);
                        setBtnLoader1(false)
                        setInitialFlag(false);
                        getCombination(itemId).then((data) => {
                            console.log("getClientcontent data", data);
                            if (data.statusCode == 200) {
                                console.log(data, "data------------->")
                                if (data.result == "Invalid token or Expired") {
                                    setShowSessionPopupup(true)
                                } else {
                                    if (data.result.data.length > 0) {

                                        let tmpData = data.result.data.map((obj) => ({
                                            ...obj,
                                            isSelected: false
                                        }));
                                        setCombinationData(tmpData);
                                        setCheckExist(true)
                                        const keysToFilter = ['country', 'typesofrights', 'exclusivity', 'revenuetype'];

                                        const newArray = tmpData.map(obj => {
                                            const filteredObj = {};

                                            keysToFilter.forEach(key => {
                                                if (obj.hasOwnProperty(key)) {
                                                    filteredObj[key] = obj[key];
                                                }
                                            });

                                            return filteredObj;
                                        });
                                        // console.log("newArray",newArray);
                                        setCompareCombination(newArray);
                                        document.getElementById("orderidcheck01").checked = false;
                                    }

                                }
                            }
                        }).catch((error) => {
                            console.log(error);
                        })


                    }
                }
            }).catch((error) => {
                console.log(error);
                setBtnLoader(false);
            })
        }
    }
    const handleSearch = () => {
        document.getElementById("orderidcheck01").checked = false;
        setIsLoading(true)
        setCombinationArray(null)
        setAdvancedSearch({})
        let itemId;
        if (!id) {
            itemId = localStorage.getItem("itemid")
        } else {
            itemId = id
        }
        getCombinationSearch(itemId, search).then((data) => {
            console.log("getClientcontent data", data);
            setIsLoading(false)
            if (data.statusCode == 200) {
                console.log(data, "data------------->")
                if (data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {

                    let tmpData = data.result.data.map((obj) => ({
                        ...obj,
                        isSelected: false
                    }));
                    setCombinationData(tmpData);
                }
            }
        }).catch((error) => {
            console.log(error);
        })

    }
    const handleAdvSearch = (e) => {
        setCombinationArray(null)
        for (const key in advancedSearch) {
            if (advancedSearch.hasOwnProperty(key)) {

                if (advancedSearch[key] === null || advancedSearch[key] === undefined || advancedSearch[key] === '' || advancedSearch[key].length <= 0) {
                    delete advancedSearch[key];
                }
            }
        }
        if (advancedSearch["min"] != undefined) {
            advancedSearch["min"] = parseInt(advancedSearch["min"]);
        }
        if (advancedSearch["max"] != undefined) {
            advancedSearch["max"] = parseInt(advancedSearch["max"]);
        }
        if (advancedSearch["period"] != undefined) {
            advancedSearch["period"] = parseInt(advancedSearch["period"]);
        }

        setBtnLoader1(true)
        setSearch({ ...search, country: "" })
        console.log("advancedSearch", advancedSearch)
        let itemId;
        if (!id) {
            itemId = localStorage.getItem("itemid")
        } else {
            itemId = id
        }
        getCombinationAdvSearch(itemId, advancedSearch).then((data) => {
            setBtnLoader1(false)
            console.log("getClientcontent data", data);
            setShowAdvancedSearchPopup(false)
            if (data.statusCode == 200) {
                console.log(data, "data------------->")
                if (data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {

                    let tmpData = data.result.data.map((obj) => ({
                        ...obj,
                        isSelected: false
                    }));
                    setCombinationData(tmpData);
                    document.getElementById("orderidcheck01").checked = false;
                }
            }
        }).catch((error) => {
            console.log(error);
        })

    }

    const customNoRecords = () => {
        return (

            <div className="empty-state-body empty-record"  >
                <div className="empty-state__message">
                    <span className="material-icons">summarize</span>
                    <p className="form-check font-size-16">No combinations were found for the searched keyword</p>
                </div> </div>
        )
    }
    const customNoRecords1 = () => {
        return (

            <div className="empty-state-body empty-record"  >
                <div className="empty-state__message">
                    <span className="material-icons">summarize</span>
                    <p className="form-check font-size-16">Combinations already exist for the searched keyword</p>
                </div> </div>
        )
    }
    console.log("editcontent", combinationData)
    const handleCalculate = (e) => {


        if (percentage > 0) {
            const keyToUpdate = "minbidprice";
            const updatedArray = combinationData.map((obj) => {
                // Create a copy of the object to avoid mutating it directly
                const updatedObject = { ...obj };

                // Check if the object has the specified key
                if (updatedObject.hasOwnProperty(keyToUpdate)) {
                    // Update the value for the key
                    let value = updatedObject[keyToUpdate] * percentage / 100;

                    updatedObject[keyToUpdate] = Math.round(updatedObject[keyToUpdate] + value)
                }

                return updatedObject;
            });
            setCombinationData(updatedArray);
            setVisibleData(updatedArray)
            setRefreshTable(true);
            setPercentage("")

        }
        if (percentage < 0) {
            const keyToUpdate = "minbidprice";
            const updatedArray = combinationData.map((obj) => {
                const updatedObject = { ...obj };

                if (updatedObject.hasOwnProperty(keyToUpdate)) {
                    let value = updatedObject[keyToUpdate] * (-percentage) / 100;
                    updatedObject[keyToUpdate] = Math.round(updatedObject[keyToUpdate] - value);

                }

                return updatedObject;
            });
            setCombinationData(updatedArray);
            setVisibleData(updatedArray)
            setRefreshTable(true);
            setPercentage("")
        }
    }

    const handleEditCalculate = (e) => {

        if (percentage > 0) {
            const keyToUpdate = "minbidprice";
            const updatedArray = combinationData.map((obj) => {
                // Create a copy of the object to avoid mutating it directly
                const updatedObject = { ...obj };

                // Check if the object has the specified key
                if (updatedObject.hasOwnProperty(keyToUpdate) && updatedObject.hasOwnProperty('isSelected') && updatedObject.isSelected === true) {
                    // Update the value for the key
                    let value = updatedObject[keyToUpdate] * percentage / 100;
                    console.log(value)
                    updatedObject[keyToUpdate] = Math.round(updatedObject[keyToUpdate] + value)
                }

                return updatedObject;
            });
            //   console.log("updatedObject",updatedArray);

            setCombinationData(updatedArray);
            //   setRefreshTable(true);
            setPercentage("");

        }
        if (percentage < 0) {
            const keyToUpdate = "minbidprice";
            const updatedArray = combinationData.map((obj) => {
                const updatedObject = { ...obj };

                if (updatedObject.hasOwnProperty(keyToUpdate)) {
                    let value = updatedObject[keyToUpdate] * (-percentage) / 100;
                    console.log(value)
                    updatedObject[keyToUpdate] = Math.round(updatedObject[keyToUpdate] - value);

                }

                return updatedObject;
            });
            //  console.log("updatedArray", updatedArray)

            setCombinationData(updatedArray);
            //  setRefreshTable(true);
            setPercentage("");
        }
    }

    const handleChangePrice = (e, key, index) => {
        let updatedObject = {}
        if (refreshTable === true) {
            setRefreshTable(false)
        }
        let numberValue = e.target.value?.replace(/\D/g, '');
        //let numberValue =parseInt(e.target.value)
        if (numberValue) {
            updatedObject = { ...key, minbidprice: parseInt(numberValue) };
        } else {
            updatedObject = { ...key, minbidprice: '' };
        }
        console.log("index ", index)
        let newArr = [...combinationData]; // copying the old datas array
        newArr[index] = updatedObject; // replace e.target.value with whatever you want to change it to
        setCombinationData(newArr);
    }

    const handleChangePrice1 = (e, key, index, page) => {
        let updatedObject = {}
        if (refreshTable === true) {
            setRefreshTable(false)
        }
        let numberValue = e.target.value?.replace(/\D/g, '');
        //let numberValue =parseInt(e.target.value)
        if (numberValue) {
            updatedObject = { ...key, minbidprice: parseInt(numberValue) };
        } else {
            updatedObject = { ...key, minbidprice: '' };
        }

        let cindex
        if (page === 1) {
            cindex = index
        } else {
            cindex = index + (15 * (page - 1))
        }
        console.log("index ", cindex)
        let newArr = [...combinationData]; // copying the old datas array
        console.log("newArr@@@ ", newArr)
        newArr[cindex] = updatedObject; // replace e.target.value with whatever you want to change it to
        setCombinationData(newArr);
    }




    const onUpdateConfirm = () => {
        setEnableEdit(false);
        setCombinationArray(null);
        setUpdateComb(false)
    }

    const handleEnableEdit = () => {
        setEnableEdit(true)
    }
    const handleEnableDelete = () => {
        // let filteredIds = combinationData.filter(item => item.isSelected)
        // console.log("filteredIds", filteredIds)
        // if (filteredIds.length === combinationData.length && editcontent.status === "ACTIVE") {
        //     setDeleteAlert1(true)
        // }
        // else {
        setDeleteComb(true)
        //}
    }
    const onCancel1 = () => {
        //setDeleteAlert1(false);
        document.getElementById("orderidcheck01").checked = false;
        setCombinationLoader(true)
        getCombination(id).then((data) => {
            console.log("getClientcontent data", data);
            if (data.statusCode == 200) {
                console.log(data, "data------------->")
                if (data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                    setIsLoading2(false)
                } else {
                    if (data.result.data.length > 0) {

                        let tmpData = data.result.data.map((obj) => ({
                            ...obj,
                            isSelected: false
                        }));
                        setCombinationData(tmpData);
                        setCheckExist(true)
                        const keysToFilter = ['country', 'typesofrights', 'exclusivity', 'revenuetype'];

                        const newArray = tmpData.map(obj => {
                            const filteredObj = {};

                            keysToFilter.forEach(key => {
                                if (obj.hasOwnProperty(key)) {
                                    filteredObj[key] = obj[key];
                                }
                            });

                            return filteredObj;
                        });
                        // console.log("newArray",newArray);
                        setCompareCombination(newArray);

                    }
                    setCombinationLoader(false)

                }
            }
        }).catch((error) => {
            console.log(error);
        })
    }
    const onCancelUpdate = () => {
        document.getElementById("orderidcheck01").checked = false;
        setEnableEdit(false);
        setCombinationArray(null);
        let itemId;
        if (!id) {
            itemId = localStorage.getItem("itemid")
        } else {
            itemId = id
        }
        getCombination(itemId).then((data) => {
            console.log("getClientcontent data", data);
            if (data.statusCode == 200) {
                console.log(data, "data------------->")
                if (data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    if (data.result.data.length > 0) {

                        let tmpData = data.result.data.map((obj) => ({
                            ...obj,
                            isSelected: false
                        }));
                        setCombinationData(tmpData);
                        setCheckExist(true)
                        const keysToFilter = ['country', 'typesofrights', 'exclusivity', 'revenuetype'];

                        const newArray = tmpData.map(obj => {
                            const filteredObj = {};

                            keysToFilter.forEach(key => {
                                if (obj.hasOwnProperty(key)) {
                                    filteredObj[key] = obj[key];
                                }
                            });

                            return filteredObj;
                        });
                        // console.log("newArray",newArray);
                        setCompareCombination(newArray);

                    }

                }
            }
        }).catch((error) => {
            console.log(error);
        })
    }
    const clearAdvSearch = () => {
        setResetKey((prevKey) => (prevKey + 1) % 2);
        setAdvancedSearch({ ...advancedSearch, country: "", region: "", typesofrights: "", revenuetype: "", exclusivity: "", min: "", max: "" })
        setBtnLoader(false)
    }
    const resetPopup = () => {
        SetShowResetPopup(true);

    }

    const clearSearch = () => {
        setBtnLoader(true)
        if (initialFlag === true) {
            setBtnLoader(true)
            console.log("came in")
            const updatedArray = checkCombination.map(obj => {
                // Clone the original object to avoid mutating state directly
                const modifiedObj = { ...obj };
                if (modifiedObj.hasOwnProperty("minbidprice") && modifiedObj.minbidprice > 0) {
                    // Set the value of the specified key to empty ('' or null, depending on your preference)
                    modifiedObj["minbidprice"] = ""
                }
                return modifiedObj;
            });

            const inputBoxes = document.querySelectorAll('input[type="number"]');

            inputBoxes.forEach((input) => {
                input.value = '';
            });
            updateStateWithArray(updatedArray);
            const updatedArray1 = combinationData.map(obj => {
                // Clone the original object to avoid mutating state directly
                const modifiedObj = { ...obj };
                if (modifiedObj.hasOwnProperty("minbidprice") && modifiedObj.minbidprice > 0) {
                    // Set the value of the specified key to empty ('' or null, depending on your preference)
                    modifiedObj["minbidprice"] = ""
                }
                return modifiedObj;
            });
            //  setVisibleData(updatedArray.slice(0, itemsPerPage1));
            setCombinationData(updatedArray1);
            // setVisibleData(updatedArray);
            setRefreshTable(true);
            setPercentage(0);
            SetShowResetPopup(false)
            setBtnLoader(false)
        } else {
            setBtnLoader(true);
            SetShowResetPopup(false)
            document.getElementById("orderidcheck01").checked = false;
            setAdvancedSearch({ ...advancedSearch, country: "", region: "", typesofrights: "", revenuetype: "", exclusivity: "", min: "", max: "" })
            setCombinationArray(null)
            setSearch({ ...search, country: "" })
            setIsLoading(true)
            let itemId;
            if (!id) {
                itemId = localStorage.getItem("itemid")
            } else {
                itemId = id
            }
            getCombination(itemId).then((data) => {
                setIsLoading(false)
                console.log("getClientcontent data", data);
                if (data.statusCode == 200) {
                    console.log(data, "data------------->")
                    if (data.result == "Invalid token or Expired") {
                        setShowSessionPopupup(true)
                    } else {
                        if (data.result.data.length > 0) {

                            let tmpData = data.result.data.map((obj) => ({
                                ...obj,
                                isSelected: false
                            }));
                            setCombinationData(tmpData);
                            setCheckExist(true)
                            const keysToFilter = ['country', 'typesofrights', 'exclusivity', 'revenuetype'];

                            const newArray = tmpData.map(obj => {
                                const filteredObj = {};

                                keysToFilter.forEach(key => {
                                    if (obj.hasOwnProperty(key)) {
                                        filteredObj[key] = obj[key];
                                    }
                                });

                                return filteredObj;
                            });
                            // console.log("newArray",newArray);
                            setCompareCombination(newArray);

                        }

                    }
                }
            }).catch((error) => {
                console.log(error);
            })
            setBtnLoader(false)
        }
    }
    const customSort = (rows, selector, direction) => {
        console.log("selector", selector);
        const sortedData = rows.slice().sort((rowA, rowB) => {
            // use the selector function to resolve your field names by passing the sort comparators
            // const aField = selector(rowA);
            const aField = String(selector(rowA) || '').toLowerCase();
            const bField = String(selector(rowB) || '').toLowerCase();

            let comparison = 0;

            if (aField > bField) {
                comparison = 1;
            } else if (aField < bField) {
                comparison = -1;
            }

            return direction === 'desc' ? comparison * -1 : comparison;
        });

        // Log the sorted data to the console
        // setSortedRecomData(sortedData)
        global.exportExcelData = sortedData
        // console.log('Sorted Data------>:', sortedData);

        return sortedData;
    };
    const updateStateWithArray = newArray => {
        setCheckCombination(newArray);
        // setVisibleData(newArray)
        currentPage1.current = 1;
        setVisibleData(newArray.slice(0, itemsPerPage1));
    };

    const onCancelCombination = () => {
        setCombinationLoader(true)
        setVisibleData([]);
        setVisibleData(prevState => ([]));
        setCheckCombination([]);
        setCheckCombination(prevState => ([]));
        currentPage1.current = 0;
        //  isLoading1.current = false;
        //  tableRef.current = null;
        setPercentage("")
        // setEnableEdit(false)
        setCancelCombinationPopup(false)
        setRefreshTable(false)
        setInitialFlag(false);
        setTableChange(false);
        let itemId;
        if (!id) {
            itemId = localStorage.getItem("itemid")
        } else {
            itemId = id
        }
        getCombination(itemId).then((data) => {
            console.log("getClientcontent data", data);
            if (data.statusCode == 200) {
                console.log(data, "data------------->")
                if (data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true);

                } else {
                    // if (data.result.data.length > 0) {

                    let tmpData = data.result.data.map((obj) => ({
                        ...obj,
                        isSelected: false
                    }));
                    setCombinationData(tmpData);
                    if (tmpData && tmpData.length > 0) {
                        setCheckExist(true)
                    }
                    const keysToFilter = ['country', 'typesofrights', 'exclusivity', 'revenuetype'];

                    const newArray = tmpData.map(obj => {
                        const filteredObj = {};

                        keysToFilter.forEach(key => {
                            if (obj.hasOwnProperty(key)) {
                                filteredObj[key] = obj[key];
                            }
                        });

                        return filteredObj;
                    });
                    // console.log("newArray",newArray);
                    setCompareCombination(newArray);
                    setCheckCombination(tmpData)
                    setCombinationLoader(false)
                    // }

                }
            }
        }).catch((error) => {
            console.log(error);
        })

    }

    let selectedItems = [];
    const onSelected = (e, row) => {
        let obj = {
            "combinationid": row?.combinationid,
            "minbidprice": row.minbidprice
        }
        let selectedItemsCopy = [...selectedItems];
        if (e.target.checked) {
            selectedItemsCopy.push(obj);
            if (combinationArray && combinationArray.length > 0) {
                setCombinationArray((prevArray) => [...prevArray, obj]);
            } else {
                setCombinationArray([obj]);
            }
        } else {
            //  setCombinationArray([]);
            let filterObj = [];
            filterObj = combinationArray && combinationArray.filter(function (el) { return el.combinationid != obj.combinationid });
            setCombinationArray(filterObj);
        }
        selectedItems = [...selectedItemsCopy];
        let tmpData = [...combinationData];
        tmpData = tmpData.map((itm) => {
            if (itm?.combinationid === row.combinationid) {
                itm.isSelected = e.target.checked
            }
            return itm;
        });
        setCombinationData(tmpData)
    }

    const onSelectAll = (e) => {
        selectedItems = [];
        let tmpData = [...combinationData];
        let isSelected = e.target.checked;
        if (isSelected) {
            tmpData.forEach(item => {
                let obj = {
                    "combinationid": item?.combinationid,
                    "minbidprice": item.minbidprice
                }
                selectedItems.push(obj)
            })
        }
        tmpData = tmpData.map((obj) => ({
            ...obj,
            isSelected: isSelected
        }));
        setCombinationData(tmpData);
        // console.log("tmpData",selectedItems);
        setCombinationArray(selectedItems);
    }
    const handleKeyDown = (event) => {
        // Prevent the minus sign from being entered
        if (event.key === '-' || event.key === '–') {
            event.preventDefault();
        }
    };
    // console.log("edit@@@",enableEdit)
    // console.log("combinationArray", combinationData)

    const columns = [
        {
            name: 'country',
            selector: row => row.country,
            sortable: !initialFlag,
        },
        {
            name: 'region',
            selector: row => row.region && row.region.length > 1 ? row.region.join(", ") : row.region,
            sortable: !initialFlag,
        },
        {
            name: 'types of rights',
            selector: row => row.typesofrights,
            sortable: !initialFlag,
        },
        {
            name: 'revenue',
            // cell: (props) => <img src={props.image} width={60} alt='Image' />,
            selector: row => row.revenuetype,
            sortable: !initialFlag,

        },

        {
            name: 'exclusivity',
            selector: row => row.exclusivity,
            // cell: (props) => <div className="text-elipsis">{props.comments}</div>,
            sortable: !initialFlag,
        },
        {
            name: 'period',
            // selector: row => moment(row.updated).utc().format("DD-MMM-YYYY HH:mm"),
            selector: row => row.period + " year",

        },


        {
            name: <>{'min price (usd)'}</>,
            selector: (row, index) => {


                if (initialFlag === true) {
                    return (<div className="d-flex price-input">
                        {/* <span className="input-group-text">$</span> */}
                        <input type="number" class="form-control" inputMode="numeric" min="1" aria-label="Amount (to the nearest dollar)" onKeyDown={handleKeyDown} onChange={(e) => handleChangePrice(e, row, index)} value={refreshTable ? row.minbidprice : null} />
                    </div>)
                }
                else if (enableEdit === true && row.isSelected) {
                    return (
                        <div className="d-flex price-input">
                            {/* <span className="input-group-text">$</span> */}
                            <input type="number" class="form-control" inputMode="numeric" min="1" aria-label="Amount (to the nearest dollar)"
                                value={row.minbidprice}
                                onKeyDown={handleKeyDown} onChange={(e) => handleChangePrice1(e, row, index, currentCPageNew)} />
                        </div>
                    )
                } else if (refreshTable) {
                    return (<div className="d-flex price-input">
                        {/* <span className="input-group-text">$</span> */}
                        <input type="number" class="form-control" inputMode="numeric" min="1" aria-label="Amount (to the nearest dollar)" onKeyUp={(e) => handleChangePrice(e, row, index)} onKeyDown={handleKeyDown} value={row.minbidprice} />
                    </div>)
                }
                else {
                    return (<><div>
                        {/* <span className="input-group-text">$ */}
                        {row.minbidprice}
                        {/* </span> */}
                    </div></>)
                }
            }
            ,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];
    if (initialFlag === false) {
        columns.unshift({
            name: <div class="form-check font-size-16">
                <input class="form-check-input" type="checkbox" id="orderidcheck01" onClick={(e) => onSelectAll(e)} />
                <label class="form-check-label" for="orderidcheck01"></label>
            </div>,
            selector: (row, index) => <div class="form-check font-size-16">
                <input class="form-check-input" type="checkbox" checked={row?.isSelected} onClick={(e) => onSelected(e, row)} />
                <label class="form-check-label" for="orderidcheck01"></label>
            </div>,
        });
    }
    const handleCloseCombinationPopup = () => {
        setSelectRegionName("select All")
        setSelectCategoryName("select All")
        setShowCombinationPopup(false);
        delete countryArray.countries
        delete countryArray.region
        setMinBidPrice("")
        // setCountryArray({ ...countryArray, countries: "", region: "" })
    }
    const handleCloseAdvacedSearchPopup = () => setShowAdvancedSearchPopup(false);
    const handleChangeSearch = (e) => {
        setSearch({ ...search, "country": [e.target.value] })
    }
    const handleCheck = (e) => {
        let keyExists = true;
        if (isButtonDisabled) {
            keyExists = false;
        } else {

            for (const obj of combinationData) {
                if ((obj.hasOwnProperty("minbidprice") && (obj["minbidprice"] > 0) && obj["minbidprice"] != "")) {
                    keyExists = false;
                    break; // Exit the loop as soon as the key is found in one object
                }
            }
        }
        return keyExists;
    }
    const styles = {
        overflowY: 'scroll',
        height: '400px'
        // Add more styles as needed
    };

    const cancelResetPopup = () => {
        SetShowResetPopup(false)
    }
    const onCancelComb = () => {
        setDeleteComb(false)
    }
    const handleDeleteComb = (e) => {
        let filteredIds = combinationData.filter(item => item.isSelected)
            .map(item => item.combinationid);
        console.log("filteredIds", filteredIds)
        const token = localStorage.getItem("token")
        const userid = localStorage.getItem("userId")
        let payload = {
            "combinations": filteredIds
        }
        setBtnLoader(true)
        axios({
            method: 'DELETE',
            url: lambda + '/bidcombinationDelete?appname=' + appname + "&token=" + token + "&userid=" + userid,
            data: payload,
        })
            .then(function (response) {
                if (response.data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                    setBtnLoader(false)
                } else if (response.data.result === "already bideed for this selected combinations.so please,uncheck the bidded combination") {
                    setDeleteComb(false);
                    setBtnLoader(false)
                    document.getElementById("orderidcheck01").checked = false;
                    setCombinationArray(null);
                    setDeleteAlert(true)
                    getCombination(id).then((data) => {
                        console.log("getClientcontent data", data);
                        if (data.statusCode == 200) {
                            console.log(data, "data------------->")
                            if (data.result == "Invalid token or Expired") {
                                setShowSessionPopupup(true)
                                setIsLoading2(false)
                            } else {
                                if (data.result.data.length > 0) {

                                    let tmpData = data.result.data.map((obj) => ({
                                        ...obj,
                                        isSelected: false
                                    }));
                                    setCombinationData(tmpData);
                                    setCheckExist(true)
                                    const keysToFilter = ['country', 'typesofrights', 'exclusivity', 'revenuetype'];

                                    const newArray = tmpData.map(obj => {
                                        const filteredObj = {};

                                        keysToFilter.forEach(key => {
                                            if (obj.hasOwnProperty(key)) {
                                                filteredObj[key] = obj[key];
                                            }
                                        });

                                        return filteredObj;
                                    });
                                    // console.log("newArray",newArray);
                                    setCompareCombination(newArray);

                                }
                                setIsLoading2(false)

                            }
                        }
                    }).catch((error) => {
                        console.log(error);
                    })

                } else {
                    setCheckExist(false)
                    setDeleteComb(false);
                    setDeleteSuccess(true);
                    setBtnLoader(false)
                    setIsLoading2(true)
                    console.log("use eff", isLoading);
                    document.getElementById("orderidcheck01").checked = false;
                    setCombinationArray(null);
                    getCombination(id).then((data) => {
                        console.log("getClientcontent data", data);
                        if (data.statusCode == 200) {
                            console.log(data, "data------------->")
                            if (data.result == "Invalid token or Expired") {
                                setShowSessionPopupup(true)
                                setIsLoading2(false)
                            } else {
                                if (data.result.data.length > 0) {

                                    let tmpData = data.result.data.map((obj) => ({
                                        ...obj,
                                        isSelected: false
                                    }));
                                    setCombinationData(tmpData);
                                    setCheckExist(true)
                                    const keysToFilter = ['country', 'typesofrights', 'exclusivity', 'revenuetype'];

                                    const newArray = tmpData.map(obj => {
                                        const filteredObj = {};

                                        keysToFilter.forEach(key => {
                                            if (obj.hasOwnProperty(key)) {
                                                filteredObj[key] = obj[key];
                                            }
                                        });

                                        return filteredObj;
                                    });
                                    // console.log("newArray",newArray);
                                    setCompareCombination(newArray);

                                } else {
                                    setCompareCombination([]);
                                    setCombinationData([]);
                                    setPreviousStatus(editcontent.status)
                                    delete editcontent["createdBy"];
                                    delete editcontent["updatedBy"];
                                    let addObj = { ...editcontent, status: "INACTIVE" }
                                    updateBiddingItems(id, addObj).then((data) => {
                                        if (data.statusCode == 200) {
                                            if (data.result == "Invalid token or Expired") {
                                                setShowSessionPopupup(true)
                                            }
                                            else if (data.result == "updated successfully") {
                                                //setSuccess(true);   
                                                getBiddingItem()
                                            }
                                        }
                                    }).catch((error) => {
                                        setBtnLoader(false);
                                        console.log(error);
                                    })
                                }
                                setIsLoading2(false)

                            }
                        }
                    }).catch((error) => {
                        console.log(error);
                    })
                }
            });

    }
    const handleKeypress = (e) => {
        //it triggers by pressing the enter key

        if ((e.key === "Enter")) {
            setTimeout(function () {
                handleSearch();
            }, 10);
        }
    };

    //console.log("combinationArray", visibleData)
    console.log("combination", combinationData)
    console.log("loading", isLoading, isLoading2, combinationLoader)
    const combinationTab = () => {
        return (<div className={`tab-pane ${activeTab == 'combination' ? 'active show' : ''}`} role="tabpanel">
            <div className="user-management clients deals create-combination">
                <div className="row table-data">
                    <div className="col-12">
                        <div className="card">

                            <div className="card-body">
                                <div className="row mb-2">
                                    <div className="col-sm-4">
                                        <div className="dataTables_length" id="datatable_length">
                                            <button className="fill_btn combination-btn"
                                                onClick={handleShowPopup}
                                            >Create / Edit combination</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-8">
                                        <div className="search-box mb-2 d-inline-block">
                                            {combinationArray && combinationArray.length > 0 &&
                                                <>
                                                    <div className="dataTables_length" id="datatable_length">
                                                        <button className="fill_btn" onClick={handleEnableEdit}><i className="mdi mdi-pencil font-size-18" ></i>Edit</button>
                                                    </div>
                                                    {id && <div className="dataTables_length" id="datatable_length">
                                                        <button className="fill_btn" onClick={handleEnableDelete}><i className="mdi mdi-delete font-size-18" ></i>Delete</button>
                                                    </div>}
                                                </>
                                            }
                                            {initialFlag === false && <><div className="position-relative">
                                                <input type="text" className="form-control"
                                                    value={search.country}
                                                    onChange={(e) => handleChangeSearch(e)}
                                                    onKeyPress={handleKeypress}
                                                    placeholder="Search by country" />
                                                <button className="fill_btn"><span className="material-icons search-icon" onClick={handleSearch} >search</span></button>
                                            </div>
                                                <div className="dataTables_length" id="datatable_length">
                                                    <button className="fill_btn"
                                                        onClick={handleShowAdvancedSearchPopup}
                                                    >Advanced Search</button>
                                                </div></>}
                                            <div className="dataTables_length" id="datatable_length">
                                                <button className="fill_btn" onClick={resetPopup}><span className="material-icons-outlined">sync</span>Reset</button>
                                            </div>

                                        </div>
                                        <div className="text-sm-end">

                                        </div>
                                    </div>
                                </div>


                                {tableChange === true ? <div className="addCombination" ref={tableRef}
                                    style={styles} onScroll={handleScroll} ><DataTable
                                        // title=""

                                        columns={columns}
                                        // className="table align-middle table-nowrap table-check"
                                        keyField='_id'
                                        data={visibleData}
                                        direction="auto"
                                        highlightOnHover
                                        fixedHeaderScrollHeight="300px"
                                        scrollHeight="100vh !important"
                                        // pagination
                                        responsive
                                        persistTableHead
                                        // selectableRowsVisibleOnly
                                        striped
                                        // selectableRowsHighlight
                                        // selectableRows
                                        subHeaderAlign="right"
                                        defaultSortField="name"
                                        subHeaderWra
                                        noDataComponent={customNoRecords1()}
                                        // paginationTotalRows={data && data.length}
                                        // onChangeRowsPerPage={handlePerRowsChange}
                                        // onChangePage={handlePageChange}
                                        // paginationPerPage={rowsPerPage}
                                        //  paginationDefaultPage={currentPageNew}

                                        // paginationComponentOptions={{
                                        //     rowsPerPageText: 'Per page:',
                                        //     rangeSeparatorText: 'of',
                                        //     noRowsPerPage: false,
                                        //     selectAllRowsItem: false,
                                        //     selectAllRowsItemText: 'All',
                                        // }}

                                        progressPending={isLoading}
                                        progressComponent={<TableLoader />}
                                    /> </div> : <div className="viewCombination"><DataTable
                                        // title=""
                                        columns={columns}
                                        // className="table align-middle table-nowrap table-check"
                                        keyField='_id'
                                        data={combinationData}
                                        direction="auto"
                                        highlightOnHover
                                        fixedHeaderScrollHeight="300px"
                                        pagination
                                        responsive
                                        persistTableHead
                                        // selectableRowsVisibleOnly
                                        striped
                                        // selectableRowsHighlight
                                        // selectableRows
                                        subHeaderAlign="right"
                                        //  defaultSortField="name"
                                        sortFunction={customSort}
                                        subHeaderWra
                                        noDataComponent={customNoRecords()}
                                        paginationTotalRows={combinationData && combinationData.length}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        onChangePage={handlePageChange}
                                        paginationPerPage={rowsPerCPage}
                                        paginationDefaultPage={currentCPageNew}
                                        paginationRowsPerPageOptions={[15, 25, 50, 75, 100]}
                                        paginationComponentOptions={{
                                            rowsPerPageText: 'Per page:',
                                            rangeSeparatorText: 'of',
                                            noRowsPerPage: false,
                                            selectAllRowsItem: false,
                                            selectAllRowsItemText: 'All',
                                        }}

                                        progressPending={combinationLoader}
                                        progressComponent={<TableLoader />}
                                    /> </div>}



                                {initialFlag && !isLoading &&
                                    <> <div className="row mt-2">
                                        <div className="col-md-7"></div>
                                        <div className="col-md-5 increase-btn">
                                            <div className="d-flex align-items-center">
                                                {/* <label for="example-text-input" class="col-form-label">Increase/decrease by</label> */}
                                                <input type="number" className="form-control"
                                                    value={percentage} onChange={(e) => setPercentage(e.target.value)} disabled={handleCheck()} />
                                                <button className="fill_btn" onClick={handleCalculate}><span className="percentage">%</span><span className="material-icons-outlined search-icon">check</span></button>
                                                <div className="combination-btn-grp">
                                                    <button className="fill_btn" onClick={e => handleCancelCombination()}>Close</button>
                                                    <button className={`fill_btn combination-btn ${handleCheck() ? "disable" : ""}`} disabled={handleCheck()} onClick={(e) => handleSaveCombination(e)}>{combinationLoader ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}Create & Done</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    </>}
                                {combinationArray && combinationArray.length > 0 && enableEdit === true &&
                                    <div className="row mt-2">
                                        <div className="col-md-7"></div>
                                        <div className="col-md-5 increase-btn">
                                            <div className="d-flex align-items-center">
                                                {/* <label for="example-text-input" class="col-form-label">Increase/decrease by</label> */}
                                                <input type="number" className="form-control"
                                                    value={percentage} onChange={(e) => setPercentage(e.target.value)} />
                                                <button className="fill_btn" onClick={handleEditCalculate}><span className="percentage">%</span><span className="material-icons-outlined search-icon">check</span></button>
                                                <div className="combination-btn-grp">
                                                    <button className="fill_btn" onClick={e => onCancelUpdate()}>cancel</button>
                                                    <button className="fill_btn combination-btn" onClick={handleUpdateCombination}>{BtnLoader1 ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}Update</button>

                                                </div>
                                            </div>

                                        </div>
                                    </div>}

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {initialFlag === false && <div className="row status">

                <div className="col-md-3 justify-content-between ps-0">
                    <><label className="col-form-label">status<span className="required">*</span></label><select className="form-select" name="status" placeholder="Select Type" onChange={(e) => handleChange(e)} value={editcontent.status}>
                        {statusFlag ?
                            <><option value="CLOSED">CLOSED</option><option value="ACTIVE">ACTIVE</option><option value="INACTIVE">INACTIVE</option></> :
                            <><option value="INACTIVE">INACTIVE</option><option value="COMING SOON">COMING SOON</option><option value="ACTIVE">ACTIVE</option></>}

                    </select></>


                </div>
                {!isLoading2 && !combinationLoader && <div className="col-md-9 justify-content-end d-flex align-items-center">
                    {id ? <a className="btn btn-primary" onClick={submit}>{BtnLoader ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}UPDATE</a> :
                        <button className="btn btn-primary" onClick={(e) => handleSaveItem(e)} disabled={isButtonClicked}>{BtnLoader ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}SAVE</button>
                    }
                </div>}
                {errors.title && <><span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.title}</span><br /></>}
                {errors.availability && <><span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.availability}</span><br /></>}
                {errors.bidstartdate && <><span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.bidstartdate}</span><br /></>}
                {(errors.bidenddate && editcontent.status === 'CLOSED') && <><span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.bidenddate}</span><br /></>}
                {errors.status && <><span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.status}</span><br /></>}
                {errors.synopsis && <><span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.synopsis}</span><br /></>}
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {errors.itemimage && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.itemimage}</span>}
                    {errors.combinationlist && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.combinationlist}</span>}
                    {errors.bidactivemsg && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.bidactivemsg}</span>}
                </div>


            </div>}

        </div>)



    }
    const historyTab = () => {
        return (<div className={`tab-pane ${activeTab == 'history' ? 'active show' : ''}`} role="tabpanel">

            <div className="row">
                <div className="col-md-12">
                    <label>Created By  :</label> <span>{editcontent && editcontent.createdBy && editcontent.createdBy.username}</span><br />
                    <label>Updated Time :</label> <span>{new Date(editcontent.created).toLocaleDateString('en-IN', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                    })}</span>

                </div>

            </div>


        </div>)
    }


    const handlePreview = () => {
        setShowPreview(false)
    }
    //  var myDate = new Date(editcontent.releasedate);



    const cardWidth = $(".basic-info-img").width();

    const handleBread = () => {
        history.push("/itemmanagement")
    }

    const onClickSwitchTab = (e, typeOfTab) => {
        setActiveTab(typeOfTab)

    }
    const onclickInvalid = () => {
        setInvalidContent(false)
        history.push('/itemmanagement')
    }
    // console.log('sellerContent',sellerContent)
    const closeSuccess = () => {
        setAddSuccess(false);
        setBtnLoader(false);
        history.push("/edititem/" + itemid);
        const token = localStorage.getItem("token")
        axios({
            method: 'GET',
            url: lambda + '/biddingItems?appname=' + appname + '&itemid=' + itemid + "&token=" + token,
        })
            .then(function (response) {
                if (response.data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    let result = response.data.result.data[0]
                    if (response.data.result.data.length > 0) {

                        setEditContent({ ...editcontent, ...result });
                        setContentTitle(result.title)
                        setValue(result.synopsis);

                    }
                    else {
                        setInvalidContent(true)
                    }
                }
            });
    }
    const validateMandatoryFields = () => {
        let flag = true;

        let error = { ...errors }
        let k;
        if (editcontent.status === "COMING SOON" || editcontent.status === "ACTIVE") {
            k = [{ name: 'Title', key: 'title' },
            { name: 'Availability', key: 'availability' },
            { name: 'Bid start date', key: 'bidstartdate' },

            ];
        } else {
            k = [{ name: 'Title', key: 'title' },
            ];
        }
        if (editcontent.status === 'CLOSED') {
            k.push({ name: 'Bid end date', key: 'bidenddate' }, { name: 'Bid start date', key: 'bidstartdate' })
        }

        console.log("k", k);

        if (flag) {
            k.forEach(function (item) {
                // console.log(' k flag 11111')
                if (editcontent[item.key] == "" || editcontent[item.key] == undefined || editcontent[item.key] == "undefined") {
                    // console.log(' k flag 22222222')
                    error[item.key] = item.name + " is required";
                    flag = false;
                }

            });
        }

        if (editcontent.status === "COMING SOON" || editcontent.status === "ACTIVE") {
            if (value != undefined) {
                var tmpdetails = value
                tmpdetails = tmpdetails.replace("<p>", "").trim()
                tmpdetails = tmpdetails.replace("</p>", "").trim()
                console.log("tmpdetails", tmpdetails);
                if (tmpdetails == "<br>" || tmpdetails == "") {
                    error["synopsis"] = "Details is required";
                    flag = false;
                }
            }
            if ((!editcontent.itemimage) || (filteredArray && filteredArray.length <= 0)) {
                error["itemimage"] = "Please upload Image in files tab";
                flag = false;
            }
            console.log("checkExist", checkExist)
            if (checkExist === false && editcontent.status != "COMING SOON") {
                error["combinationlist"] = "Please create combinations in combination tab";
                flag = false;
            }


        }
        if (editcontent.bidenddate || editcontent.bidstartdate) {
            if (editcontent.status != "CLOSED") {

            } else {
                let startDateTemp = editcontent.bidstartdate?.includes("T00:00:00.000Z") ? editcontent.bidstartdate : `${editcontent.bidstartdate}T00:00:00.000Z`;
                console.log("startDateTemp", editcontent.bidstartdate)
                let endDateTemp = editcontent.bidenddate?.includes("T00:00:00.000Z") ? editcontent.bidenddate : `${editcontent.bidenddate}T00:00:00.000Z`;
                if (new Date(startDateTemp) >= new Date(endDateTemp)) {
                    error["bidenddate"] = "Bid End date must be Greater than Bid start date";
                    flag = false;
                }

            }
        }
        console.log("ttt", editcontent.bidstartdate?.split('T')[0] > new Date()?.toISOString()?.split('T')[0] && editcontent.status === "ACTIVE")
        if (editcontent.bidstartdate?.split('T')[0] > new Date()?.toISOString()?.split('T')[0] && editcontent.status === "ACTIVE") {
            error["bidactivemsg"] = "BID can not be activated until the  start date commits";
            flag = false;
        }

        // if (editcontent.bidstartdate < moment(new Date().toISOString()).format('YYYY-MM-DD')) {
        //     error["bidstartdate"] = "bidstartdate must be future data";
        //     flag = false;
        // }


        console.log("error", error)
        setErrors(error)


        return flag;
    }
    console.log("checkExist", editcontent.status);

    const customStyles = {
        control: (styles) => ({
            ...styles,
            // Add custom control styles if needed
        }),
        // Add more custom styles for other components as needed
    };

    console.log("ad", advancedSearch)
    return (
        <>
            {showSessionPopupup && <SessionPopup />}
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <SweetAlert show={invalidContent}
                    custom
                    confirmBtnText="ok"
                    confirmBtnBsStyle="primary"
                    title={"Items Not Found"}
                    onConfirm={e => onclickInvalid()}
                >
                </SweetAlert>
                {!invalidContent &&
                    <div className="main-content create-user edit-content add-client">

                        <div className="page-content ">
                            <div className="container-fluid">



                                <div className="row mb-4 breadcrumb">
                                    <div className="col-lg-12">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1">
                                                <h4 className="mb-2 card-title">{id === undefined ? "Add Item" : (contentTitle ? contentTitle : '')}</h4>
                                                <p className="menu-path"><span onClick={handleBread}>Item  Management</span> / <b>{id === undefined ? "Add Item" : (contentTitle ? contentTitle : '')}</b></p>
                                            </div>
                                            <div>
                                                <a onClick={handleBack} className="btn btn-primary">back</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="create-user-block content_edit edit-item">
                                    <div className="form-block">
                                        <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <a className={`nav-link ${activeTab == 'info' ? 'active' : ''}`} style={{ cursor: 'pointer' }} data-bs-toggle="tab" role="tab" onClick={(e) => onClickSwitchTab(e, "info")}>
                                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                                    <span className="d-none d-sm-block">item details</span>
                                                </a>
                                            </li>
                                            {/* {(id !== undefined || isInfoFormValid == true) && <li className="nav-item" role="presentation">
                                                   <button className={`nav-link ${activeTab == 'seller' ? 'active' : ''}`}
                                                       style={{ cursor: 'pointer' }}
                                                       data-bs-toggle="tab" role="tab" onClick={(e) => onClickSwitchTab(e, "seller")}
                                                   //   disabled={!isValidInfo}
                                                   >
                                                       <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                                       <span className="d-none d-sm-block">SELLER</span>
                                                   </button>
                                               </li>} */}


                                            <li className="nav-item" role="presentation">
                                                <a className={`nav-link ${activeTab == 'files' ? 'active' : ''}`} style={{ cursor: 'pointer' }} data-bs-toggle="tab" role="tab" onClick={(e) => onClickSwitchTab(e, "files")} >
                                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                                    <span className="d-none d-sm-block">Files</span>
                                                </a>
                                            </li>

                                            <li className="nav-item" role="presentation">
                                                <a className={`nav-link ${activeTab == 'combination' ? 'active' : ''}`} style={{ cursor: 'pointer' }} data-bs-toggle="tab" role="tab" onClick={(e) => onClickSwitchTab(e, "combination")} >
                                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                                    <span className="d-none d-sm-block">combination</span>
                                                </a>
                                            </li>
                                            {/* {id !== undefined ?
  
                                                  <li className="nav-item" role="presentation">
                                                      <a className={`nav-link ${activeTab == 'history' ? 'active' : ''}`} style={{ cursor: 'pointer' }} data-bs-toggle="tab" role="tab" onClick={(e) => onClickSwitchTab(e, "history")} >
                                                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                                          <span className="d-none d-sm-block">history</span>
                                                      </a>
                                                  </li>
                                                  : null} */}




                                            {/* <li className="nav-item" role="presentation">
                                               <a className="nav-link" data-bs-toggle="tab" href="#images" role="tab" aria-selected="false" tabIndex="-1">
                                                   <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                                   <span className="d-none d-sm-block">IMAGES</span>
                                               </a>
                                           </li> */}


                                        </ul>
                                        <div className="tab-content p-3 text-muted">
                                            {activeTab == "info" && <div className={`tab-pane ${activeTab == 'info' ? 'active show' : ''}`} role="tabpanel" id="home1">
                                                {(id === undefined || Object.keys(editcontent).length > 0 && isLoading2 === false) ?
                                                    <>
                                                        <div className="row">
                                                            <h5 className="font-size-14"><i className="mdi mdi-arrow-right"></i> item details</h5>
                                                            <div className="col-md-12">
                                                                <div className="row basic-details">

                                                                    <div className="col-md-6">
                                                                        <div className="input-field">
                                                                            <label for="example-text-input" className="col-form-label">Item name<span className="required">*</span></label>
                                                                            <input className="form-control contact-number" type="text" name="title" placeholder="Enter title name" id="example-email-input" onChange={(e) => handleChange(e)} value={editcontent.title} />
                                                                            {errors.title && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.title}</span>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="input-field">
                                                                            <label for="example-text-input" className="col-form-label">availability{((editcontent.status === "ACTIVE" || editcontent.status === "COMING SOON")) && <span className="required">*</span>}</label>
                                                                            <input className="form-control contact-number" type="text" name="availability" placeholder="Enter availability" id="example-email-input" onChange={(e) => handleChange(e)} value={editcontent.availability} />
                                                                            {errors.availability && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.availability}</span>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="input-field">
                                                                            <label className="form-label form-label">bid start Date{((editcontent.status === "ACTIVE" || editcontent.status === "COMING SOON" || editcontent.status === "CLOSED")) && <span className="required">*</span>}</label>
                                                                            <input type="date" name="bidstartdate" placeholder="bidstartdate" className="form-control" min={new Date().toISOString().split('T')[0]} onChange={(e) => handleChange(e)} value={editcontent && editcontent.bidstartdate && moment(new Date(editcontent.bidstartdate)).format('YYYY-MM-DD')} />
                                                                            {errors.bidstartdate && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.bidstartdate}</span>}
                                                                        </div>
                                                                    </div>
                                                                    {editcontent && editcontent.status === 'CLOSED' && <div className="col-md-6">
                                                                        <div className="input-field">
                                                                            <label className="form-label form-label">Bid End Date<span className="required">*</span></label>
                                                                            <input type="date" name="bidenddate" placeholder="bidenddate" className="form-control" min={new Date().toISOString().split('T')[0]} onChange={(e) => handleChange(e)} value={editcontent && editcontent.bidenddate && moment(new Date(editcontent.bidenddate)).format('YYYY-MM-DD')} />
                                                                            {errors.bidenddate && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.bidenddate}</span>}
                                                                        </div>
                                                                    </div>}

                                                                    <div className="col-md-6">
                                                                        {/* <div className="input-field">
                                                                            {id ? <><label className="col-form-label">status<span className="required">*</span></label><select className="form-select" name="status" placeholder="Select Type" onChange={(e) => handleChange(e)} value={editcontent.status}>
                                                                                <option value="COMING SOON">COMING SOON</option>
                                                                                <option value="CLOSED">CLOSED</option>
                                                                                <option value="ACTIVE">ACTIVE</option>
                                                                                <option value="INACTIVE">INACTIVE</option>

                                                                            </select></> : null

                                                                            }
                                                                            {errors.status && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.status}</span>}
                                                                        </div> */}
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="input-field">
                                                                            <label className="col-form-label">details{((editcontent.status === "ACTIVE" || editcontent.status === "COMING SOON")) && <span className="required">*</span>}</label>
                                                                            {/* <ReactQuill theme="snow" value={value} onChange={setValue} /> */}
                                                                            {quillElement}
                                                                            {isValidEditor === false ? delete errors["synopsis"] : null}
                                                                            {errors.synopsis && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.synopsis}</span>}
                                                                        </div>
                                                                    </div>




                                                                </div>

                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <div className="create-user-block"><div className="form-block">
                                                        <div className="tab-content p-3 text-muted">
                                                            <div className="tab-pane active show" id="home1" role="tabpanel">
                                                                <div className="row"><Loader /></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                }
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    {errors.status && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.status}</span>}
                                                    {errors.itemimage && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.itemimage}</span>}
                                                    {errors.combinationlist && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.combinationlist}</span>}
                                                    {errors.bidactivemsg && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.bidactivemsg}</span>}
                                                </div>




                                                <div className="row status">

                                                    <div className="col-md-3 justify-content-between ps-0">
                                                        <><label className="col-form-label">status<span className="required">*</span></label><select className="form-select" name="status" placeholder="Select Type" onChange={(e) => handleChange(e)} value={editcontent.status}>
                                                            {statusFlag ?
                                                                <><option value="CLOSED">CLOSED</option><option value="ACTIVE">ACTIVE</option><option value="INACTIVE">INACTIVE</option></> :
                                                                <><option value="INACTIVE">INACTIVE</option><option value="COMING SOON">COMING SOON</option>
                                                                    <option value="ACTIVE">ACTIVE</option></>
                                                            }

                                                        </select></>


                                                    </div>

                                                    {!isLoading2 && !combinationLoader && <div className="col-md-9 justify-content-end d-flex align-items-center">


                                                        {id == undefined ? <button className="btn btn-primary" onClick={(e) => handleSaveItem(e)} disabled={isButtonClicked}>{BtnLoader ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}SAVE</button> :
                                                            <a className="btn btn-primary" onClick={submit} disabled={isButtonDisabled}>{BtnLoader ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}UPDATE</a>}

                                                    </div>}
                                                    {(errors.landscapeimage || errors.portraitimage) && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>Please upload a portrait or landscape image in the files tab to activate the content.</span>}

                                                </div>




                                            </div>}
                                            {activeTab == "files" && filesTab()}
                                            {/* {activeTab == "seller" && sellersTab()} */}
                                            {activeTab == "combination" && combinationTab()}
                                            {/* {activeTab == "history" && historyTab()} */}


                                            <div className="tab-pane" id="images" role="tabpanel">
                                                <div className="row">
                                                    <h5 className="font-size-14"><i className="mdi mdi-arrow-right"></i>Asset Thumbnails</h5>
                                                    <div className="uploadPics">
                                                        <div className="upload">
                                                            <div className="img_block">
                                                                <img src={image+"orasi/client/resources/orasiv1/images//hero_thumb1.jpg"} />

                                                            </div>
                                                            <div className="size">Landscape  (300*600)</div>
                                                        </div>

                                                        <div className="upload portrait">
                                                            <div className="img_block u-potrait">
                                                                <img src={image+"orasi/client/resources/orasiv1/images//hero_thumb1.jpg"} />

                                                            </div>
                                                            <div className="size">Portrait (600*300)</div>
                                                        </div>
                                                        <div className="add-block">
                                                            <input type="file" name="upload" className="udisplay-none" id="upload" />
                                                            <span className="material-icons-outlined">add</span>
                                                            add
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>



                            </div>
                        </div>

                        {/* <Modal className="seller-pop" show={showupdateSeller} onHide={handleClosePopup}>
                               <Modal.Header closeButton>
                                   <Modal.Title>{sellerContent && sellerContent.clientcontentid ? 'Update ' : 'Add '} Seller</Modal.Title>
                               </Modal.Header>
                               <Modal.Body>{updateSeller()}</Modal.Body>
   
                           </Modal> */}
                        <Modal className="seller-pop preview-pop" show={showPreview}>
                            <Modal.Header>
                                <button className="close-btn" onClick={handlePreview}><span className="material-icons">close</span></button>
                                <Modal.Title>Preview</Modal.Title>
                            </Modal.Header>
                            <Modal.Body><img src={previewImg} className="w-100" /></Modal.Body>

                        </Modal>
                        {/* <Modal className="seller-pop new-look-up" show={showAddLookup} onHide={setShowAddLookup}>
                               <Modal.Header closeButton>
                                   <Modal.Title>Add New lookup</Modal.Title>
                               </Modal.Header>
                               <Modal.Body>{AddLookUp()}</Modal.Body>
   
                           </Modal> */}
                        <Footer />
                        <SweetAlert show={success}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Updated successfully"}
                            onConfirm={e => { setSuccess(false); setBtnLoader(false) }}
                        ></SweetAlert>
                        <SweetAlert show={deleteAlert}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Cannot Delete, Some of the selected combinations have Bids"}
                            onConfirm={e => { setDeleteAlert(false); }}
                        ></SweetAlert>
                        <SweetAlert show={deleteSuccess}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Combination Deleted Successfully"}
                            onConfirm={e => { setDeleteSuccess(false); }}
                        ></SweetAlert>
                        <SweetAlert show={subSuccess}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Subtitle added successfully"}
                            onConfirm={e => { setSubSuccess(false); setBtnLoader(false) }}
                        ></SweetAlert>
                        <SweetAlert show={delSuccess}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Image deleted successfully"}
                            onConfirm={e => { setDelSuccess(false); setBtnLoader(false) }}
                        >
                        </SweetAlert>
                        <SweetAlert show={lookupSuccess}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Added successfully"}
                            onConfirm={e => { setLookupSuccess(false); setBtnLoader(false) }}
                        ></SweetAlert>
                        {/* <SweetAlert show={isdelete}
                                custom
                                confirmBtnText="ok"
                                confirmBtnBsStyle="primary"
                                title={"File Deleted successfully"}
                                onConfirm={e => setDelete(false)}
                            >
                            </SweetAlert> */}
                        <SweetAlert show={AddSuccess}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Item details saved"}
                            onConfirm={e => { closeSuccess() }}
                        >
                        </SweetAlert>
                        <SweetAlert show={uploadsuccess}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Image uploaded successfully"}
                            onConfirm={e => { setUploadSuccess(false); setBtnLoader(false) }}
                        >
                        </SweetAlert>
                        <SweetAlert show={delPopup}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Deleted successfully"}
                            onConfirm={e => { setDelPopup(false); setBtnLoader(false) }}
                        >
                        </SweetAlert>
                        <SweetAlert show={isCompExist}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Company already exists"}
                            onConfirm={e => { setIsCompExist(false); setBtnLoader(false) }}
                        >
                        </SweetAlert>
                        <SweetAlert show={isTitleExist}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"This title already exists"}
                            onConfirm={e => { setIsTitleExist(false); setBtnLoader(false); history.push("/itemmanagement") }}
                        >
                        </SweetAlert>


                        <SweetAlert show={addComb}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Combination Saved SuccessFully"}
                            onConfirm={e => { setAddComb(false); setInitialFlag(false); setEnableEdit(false); setRefreshTable(false); setTableChange(false); setIsLoading(false) }}
                        >
                        </SweetAlert>
                        <SweetAlert show={updateComb}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Combination updated SuccessFully"}
                            onConfirm={e => onUpdateConfirm()}
                        >
                        </SweetAlert>
                        <SweetAlert show={docError}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Please upload pdf files only"}
                            onConfirm={e => setDocError(false)}
                        >
                        </SweetAlert>

                        <Modal className="access-denied" show={isdelete}>

                            <div className="modal-body enquiry-form">
                                <div className="container">
                                    <button className="close-btn" onClick={e => onCancel()}><span className="material-icons">close</span></button>
                                    <span className="material-icons access-denied-icon">delete_outline</span>
                                    <h3>Delete</h3>
                                    <p>This action cannot be undone.</p>
                                    <p>{`Are you sure you want to delete the ${delSeller ? 'seller' : 'File'}?`}</p>
                                    <div className="popup-footer">
                                        <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => handleDelete()}>{BtnLoader ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}Yes, Delete</button>
                                    </div>
                                </div>
                            </div>

                        </Modal>
                        <Modal className="access-denied" show={deleteAlert1}>

                            <div className="modal-body enquiry-form">
                                <div className="container">
                                    <button className="close-btn" onClick={e => onCancel1()}><span className="material-icons">close</span></button>
                                    <span className="material-icons access-denied-icon">delete_outline</span>
                                    <h3>Delete</h3>
                                    <p>This action cannot be undone.</p>
                                    <p>Atleast  One combination is required for active Title</p>
                                    {/* <div className="popup-footer">
                                        <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => handleDelete()}>{BtnLoader ? (<img src="https://d9nwtjplhevo0.cloudfront.net/orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg" className="loading-icon" />) : null}Yes, Delete</button>
                                    </div> */}
                                </div>
                            </div>

                        </Modal>
                        <Modal className="access-denied" show={isdeleteImage}>

                            <div className="modal-body enquiry-form">
                                <div className="container">
                                    <button className="close-btn" onClick={e => onCancelDelete()}><span className="material-icons">close</span></button>
                                    <span className="material-icons access-denied-icon">delete_outline</span>
                                    <h3>Delete</h3>
                                    <p>This action cannot be undone.</p>
                                    <p>{`Are you sure you want to delete ${item == 'portraitimage' ? 'portrait image' : 'landscape image'}?`}</p>
                                    <div className="popup-footer">
                                        <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => confirmDeleteImage()}>{BtnLoader1 ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}Yes, Delete</button>
                                    </div>
                                </div>
                            </div>

                        </Modal>

                        <Modal className="access-denied" show={cancelCombinationpopup}>

                            <div className="modal-body enquiry-form">
                                <div className="container">
                                    <button className="close-btn" onClick={e => setCancelCombinationPopup(false)}><span className="material-icons">close</span></button>
                                    <span className="material-icons access-denied-icon">delete_outline</span>
                                    <h3>Close</h3>
                                    <p>This action cannot be undone.</p>
                                    <p>Are you sure do you want close combination</p>
                                    <div className="popup-footer">
                                        <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => onCancelCombination()}>{BtnLoader ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}Yes, Close</button>
                                    </div>
                                </div>
                            </div>

                        </Modal>
                        <Modal className="access-denied" show={deleteComb}>

                            <div className="modal-body enquiry-form">
                                <div className="container">
                                    <button className="close-btn" onClick={e => onCancelComb()}><span className="material-icons">close</span></button>
                                    <span className="material-icons access-denied-icon">delete_outline</span>
                                    <h3>Delete</h3>
                                    <p>This action cannot be undone.</p>
                                    <p>{`Are you sure you want to delete the Selected Combination?`}</p>
                                    <div className="popup-footer">
                                        <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={(e) => handleDeleteComb(e)} >{BtnLoader ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}Yes, Delete</button>
                                    </div>
                                </div>
                            </div>

                        </Modal>
                        <Modal className="access-denied" show={showResetPopup}>

                            <div className="modal-body enquiry-form">
                                <div className="container">
                                    <button className="close-btn" onClick={e => SetShowResetPopup(false)}><span className="material-icons">close</span></button>
                                    <span className="material-icons access-denied-icon">delete_outline</span>
                                    <h3>Reset</h3>
                                    <p>This action cannot be undone.</p>
                                    <p>Are you sure do you want {tableChange === false ? "to reset search filters" : "to clear minimum bid price for combinations"} </p>
                                    <div className="popup-footer">
                                        <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => clearSearch()}>{BtnLoader ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}Yes, Cancel</button>
                                    </div>
                                </div>
                            </div>

                        </Modal>
                        <Modal className="access-denied" show={iserrorpopup}>

                            <div className="modal-body enquiry-form">
                                <div className="container">
                                    <button className="close-btn" onClick={e => setIsErrorPopup(false)}><span className="material-icons">close</span></button>
                                    {/* <span className="material-icons access-denied-icon">delete_outline</span> */}
                                    <h3>Alert</h3>
                                    <p>Some of combination having minbidprice 0 or empty. please enter non-zero values and update </p>
                                    <div className="popup-footer">
                                        <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => setIsErrorPopup(false)}>OKAY</button>
                                    </div>
                                </div>
                            </div>

                        </Modal>

                        {showUpload &&
                            <Modal show={true} className="seller-pop new-look-up add-document" >
                                <Modal.Header >
                                    <Modal.Title>{fileCategory === "VIDEOS" ? "Upload Video" : fileCategory === "THUMBNAILS" ? "Upload Thumbnail" : "Upload Document"}</Modal.Title>
                                    <button className="close-btn" onClick={handleClose}><span className="material-icons">close</span></button>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row">
                                        <div className="col-md-12 documents">
                                            <div className="mb-3 input-field">
                                                {/* <label className="form-label form-label">{fileCategory === "CONTENT_IMAGE" ? "Thumbnail Name" :"File Name"}</label> */}
                                                <input name="filename" placeholder="Enter File Name" type="text" className="form-control" onChange={(e) => { setFileName(e.target.value); setValidateFiles(false) }} value={fileName} />
                                            </div>
                                            <div className="mb-3 input-field btn-gray">
                                                {fileCategory === "DOCUMENTS" ?
                                                    <input type="file" name="upload" accept=".pdf" className="udisplay-none" id="upload"
                                                        onChange={e => uploadS3(e, ["1920*1080", "Image", "landscape_logo_image"])}
                                                        onClick={(e) => { e.target.value = ''; setValidateFiles(false) }} ref={ref} />
                                                    : fileCategory === "THUMBNAILS" ?
                                                        <input type="file" name="upload" accept="image/png, image/jpeg" className="udisplay-none" id="upload"
                                                            onChange={e => uploadS3(e, ["1920*1080", "Image", uploadType])}
                                                            onClick={(e) => { e.target.value = ''; setValidateFiles(false) }} ref={ref} />
                                                        :
                                                        <input type="file" name="upload" accept="video/*,.mkv,.avi, .divx, .f4v, .flv, .mts, .m2t, .m4v, .mkv, .mov, .mp4, .mpeg, .mpg, .mxf, .r3d, .wmv" className="udisplay-none" id="upload"
                                                            onChange={e => uploadS3(e, ["1920*1080", "Image", "landscape_logo_image"])}
                                                            onClick={(e) => {
                                                                e.target.value = ''; setValidateFiles(false)

                                                            }} ref={ref} />
                                                }

                                                {BtnLoader1 ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : <span className="material-icons">upload</span>}{fileCategory === "THUMBNAILS" ? "Upload Image" : fileCategory === "DOCUMENTS" ? "Upload File" : "Upload Video"}</div>
                                            {/* <p>Only pdf and word document allowed.</p> */}
                                            {fileCategory != "DOCUMENTS" && fileCategory != "THUMBNAILS" ? <p className={videoexcededfile ? "uploaderror" : ""}><span style={{ color: "red", fontSize: "16px" }}>*</span>Please upload file size less than 1.2 GB</p> : (fileCategory === "THUMBNAILS") ? <p className={excededfile ? "uploaderror" : ""}><span style={{ color: "red", fontSize: "16px" }}>*</span>Please upload file size less than 10MB</p> : (fileCategory === "DOCUMENTS") ? <p className={excededdocumentfile ? "uploaderror" : ""}><span style={{ color: "red", fontSize: "16px" }}>*</span>Please upload file size less than 100 MB</p> : ""}
                                            {validateFiles && (
  <>
    {fileCategory === "THUMBNAILS"&& validateFiles===true ? (
      <p className="uploaderror">Please upload image files only</p>
    ) : fileCategory === "VIDEOS"&&validateFiles===true ? (
      <p className="uploaderror">Please upload video files only</p>
    ) : null}
  </>
)}


                                            {/* <a className="btn btn-primary">done</a> */}
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>


                        }
                        {/* <Modal className="access-denied" show={showPopup} >

                            <div className="modal-body enquiry-form upload_file">
                                <div className="container">
                                    <button className="close-btn" onClick={e => onUploadCancel(e)}><span className="material-icons">close</span></button>


                                    <p>Do you want cancel upload File?</p>
                                    <div className="popup-footer">
                                        <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => handleUploadFile(e)}>{BtnLoader == "sessionLoad" ? (<img src="https://d9nwtjplhevo0.cloudfront.net/orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg" className="loading-icon" />) : null}Yes</button>
                                        <button className="fill_btn" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => onUploadCancel(e)}>No</button>
                                    </div>
                                </div>
                            </div>

                        </Modal> */}
                        {combinationpopup &&
                            <Modal className="advance-search search-popup create-combination-pop" show={true} onHide={handleCloseCombinationPopup} backdrop="static">
                                <Modal.Header closeButton>
                                    <Modal.Title>Create / Edit combination</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div>
                                        {/* <div className="row">
                                             <div className="col-md-12">
                                                 <div className="form-group">
                                                     <div class="title-block">
                                                         <label className="form-label form-label">select regions</label>
                                                         {countryArray && countryArray.countries && countryArray.countries.length > 0 ? null : <button onClick={(e) => handleSelectAllRegion(e, selectRegionName)}>{selectRegionName}</button>}
                                                     </div>
                                                     <Select isMulti={true}
                                                         placeholder='Select regions'
                                                         styles={customStyles}
                                                         isClearable={false}
                                                         onChange={(e) => handleChangeMultiSelectCategory(e, "region")}
                                                         isDisabled={countryArray && countryArray.countries && countryArray.countries.length > 0 ? true : false}
                                                         options={regionsData}
                                                         value={countryArray && countryArray.region && countryArray.region.length > 0 && countryArray.region?.map((eachItem) => { return { value: eachItem.value, label: eachItem.label } })}
                                                     />
                                                 </div>
                                             </div>
 
                                         </div>
 
                                         <p className="or-divider"><span className="or">or</span></p> */}
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <div class="title-block">
                                                        <label className="form-label form-label">select countries</label>
                                                        {countryArray && countryArray.region && countryArray.region.length > 0 ? null : <button onClick={(e) => handleSelectAll(e, selectCategoryName)}>{selectCategoryName}</button>}
                                                    </div>
                                                    <Select isMulti={true}
                                                        placeholder='Select countries'
                                                        styles={customStyles}
                                                        isClearable={false}
                                                        isDisabled={countryArray && countryArray.region && countryArray.region.length > 0 ? true : false}
                                                        onChange={(e) => handleChangeMultiSelectCategory(e, "countries")}
                                                        options={countryData}

                                                        value={countryArray && countryArray.countries && countryArray.countries.length > 0 && countryArray.countries?.map((eachItem) => { return { value: eachItem.value, label: eachItem.label, buyerid: eachItem.buyerid } })}
                                                    />

                                                </div>
                                            </div>

                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <div class="title-block">
                                                        <label className="form-label form-label">DEFAULT MIN BID PRICE</label>

                                                    </div>

                                                    <input type="number" class="form-control" inputMode="numeric" min="1" aria-label="Amount (to the nearest dollar)" value={minbidprice} onChange={(e) => setMinBidPrice(e.target.value)} onKeyDown={handleKeyDown} />

                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </Modal.Body>
                                <Modal.Footer>

                                    <div className="adv_footer">
                                        <button className="fill_btn yellow-gradient reset-btn" onClick={handleCloseCombinationPopup}>cancel</button>

                                        <button
                                            onClick={(e) => handleCreateCombination(e, countryArray)}
                                            className={`fill_btn yellow-gradient float-end ${checkObjectProperties(countryArray) ? "disable" : ""}`} disabled={checkObjectProperties(countryArray)}
                                        >
                                            {BtnLoader ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}
                                            create
                                        </button>

                                        <div className="clearfix"></div>
                                    </div>
                                </Modal.Footer>
                            </Modal>

                        }
                        <Modal className="advance-search search-popup bid-advance-search" show={advancedsearchpopup} onHide={handleCloseAdvacedSearchPopup} backdrop="static">
                            <Modal.Header closeButton>
                                <Modal.Title>Advanced Search</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="adv_body">
                                    <div className="adv_body-inner">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label form-label">country</label>
                                                    <Select isMulti={true}
                                                        key={resetKey}
                                                        placeholder='Select countries'
                                                        className="clear"
                                                        isClearable={true}
                                                        onChange={(e) => handleChangeMultiSelectAdvSearch(e, "country")}
                                                        options={countryData}
                                                        value={advancedSearch && advancedSearch.country && advancedSearch.country.length > 0 && advancedSearch.country?.map((eachItem) => { return { value: eachItem, label: eachItem } })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label form-label">Region</label>
                                                    <Select isMulti={true}
                                                        key={resetKey}
                                                        className="clear"
                                                        isClearable={true}
                                                        placeholder='Select regions'
                                                        onChange={(e) => handleChangeMultiSelectAdvSearch(e, "region")}
                                                        options={regionsData}
                                                        value={advancedSearch && advancedSearch.region && advancedSearch.region.length > 0 && advancedSearch.region?.map((eachItem) => { return { value: eachItem, label: eachItem } })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label form-label">type of rights</label>

                                                    <Select isMulti={false}
                                                        key={resetKey}
                                                        className="clear"
                                                        placeholder='Select types of rights'
                                                        isClearable={true}
                                                        onChange={(e) => handleChangeMultiSelectAdvSearch(e, "typesofrights")}
                                                        options={typesofrights && typesofrights.map((eachItem) => { return { value: eachItem.name, label: eachItem.name, revenuetype: eachItem.revenue } })}
                                                        value={advancedSearch && advancedSearch.typesofrights && advancedSearch.typesofrights.length > 0 && advancedSearch.typesofrights.split(",")?.map((eachItem) => { return { value: eachItem, label: eachItem } })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">

                                                <div className="form-group">
                                                    <label htmlFor="example-text-input" className="col-form-label">revenue type</label>

                                                    <Select isMulti={true}
                                                        key={resetKey}
                                                        className="clear"
                                                        placeholder='Select revenue type'
                                                        onChange={(e) => handleChangeMultiSelectAdvSearch(e, "revenuetype")}
                                                        options={advancedSearch.typesofrights === "Digital" ? revenueData : []}
                                                        value={advancedSearch && advancedSearch.revenuetype && advancedSearch.revenuetype.length > 0 && advancedSearch.revenuetype?.map((eachItem) => { return { value: eachItem, label: eachItem } })}
                                                    />

                                                </div>
                                            </div>

                                            <div className="col-md-6">

                                                <div className="form-group">
                                                    <label htmlFor="example-text-input" className="col-form-label">exclusivity</label>


                                                    <select name="exclusivity" className="colorselect capitalize form-control clear" onChange={(e) => handleadvSearchChange(e, "exclusivity")} value={advancedSearch.exclusivity}>
                                                        <option value="">select exclusivity</option>
                                                        {
                                                            exclusivityData && exclusivityData.map((item, index) => <option key={index} value={item}>{item}</option>)
                                                        }

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label for="example-text-input" className="col-form-label">min price range</label>
                                                            <input type="number" class="form-control clear" id="example-email-input" placeholder="min price" name="min" onChange={(e) => handleadvSearchChange(e)} value={advancedSearch.min} />
                                                        </div>

                                                        <div className="col-md-6">
                                                            <label for="example-text-input" className="col-form-label text-white">max price range</label>
                                                            <input type="number" class="form-control clear" id="example-email-input" placeholder="max price" name="max" onChange={(e) => handleadvSearchChange(e)} value={advancedSearch.max} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>

                                <div className="adv_footer">
                                    <button className="fill_btn yellow-gradient reset-btn"
                                        onClick={clearAdvSearch}
                                    ><span className="material-icons-outlined">sync</span>Reset</button>

                                    <button
                                        onClick={(e) => handleAdvSearch(e, "click")}
                                        className={`fill_btn yellow-gradient float-end ${checkAdvObjectProperties(advancedSearch) ? "disable" : ""}`} disabled={checkAdvObjectProperties(advancedSearch)}
                                    >
                                        {BtnLoader1 ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}
                                        SEARCH
                                    </button>

                                    <div className="clearfix"></div>
                                </div>
                            </Modal.Footer>
                        </Modal>
                    </div>


                }


            </div>

        </>
    );
};

export default EditItem;
