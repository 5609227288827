/***
**Module Name: bidsDashboard
 **File Name :  Bidsdashboard.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains bids dashboard table details.
 ***/
import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/dashboard/footer";
import Header from "../../../components/dashboard/header";
import Sidebar from "../../../components/dashboard/sidebar";
import { useHistory, Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import tmdbApi from "../../../api/tmdbApi";
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import SessionPopup from "../../SessionPopup"
import TableLoader from "../../../components/tableLoader";
import { contentContext } from "../../../context/contentContext";
import DataTable from 'react-data-table-component';
let { appname, lambda } = window.app;

const AllBids = () => {
    const history = useHistory();
    const location = useLocation();
    const [image, setImg] = useState('');
    const [loaderEnable, setLoaderEnable] = useState(false);
    const [lookup, setLookUp] = useState({});
    const [countryData, setCountryData] = useState({});
    const [regionsData, setRegionsData] = useState({});
    const [typesofrights, setTypesofrights] = useState([]);
    const [revenueData, setRevenueData] = useState([]);
    const [exclusivityData, setExclusivityData] = useState([]);
    const [showSessionPopupup, setShowSessionPopupup] = useState(false);
    const [popup, setShowPopup] = useState(false);
    const [resetKey, setResetKey] = useState(0);
    const [tableErrMsg, setTableErrMsg] = useState(false);
 //   const [keyForRerender, setKeyForRerender] = useState(0);
    const { searchedFlag, setSearchedFlag, isLoading, setIsLoading, userData, data, setData, rowsPerPage, setRowsPerPage, currentPageNew, setCurrentPage, route, setRoute, usePrevious, setActiveMenuObj, setActiveMenuId, bidsSearch, setBidsSearch, bidsAdvSearch, setBidsAdvSearch,sortingPayload, setSortingPayload,totalRows, setTotalRows } = useContext(contentContext)

    const validateObj = userData && userData.permissions && userData.permissions.length > 0 && userData.permissions.filter(eachItem => eachItem.menu === "Auction Management")
    const subValDashboard = validateObj && validateObj[0] && validateObj[0].submenus && validateObj[0].submenus[1].dashboard
    // console.log('subValDashboard obj',subValDashboard)

    const prevRoute = usePrevious(route)
   const keyForRerender = `${rowsPerPage}_${data?.length}_${data}`;
    useEffect(() => {
        if (prevRoute != undefined && prevRoute != route) {
            setCurrentPage(1)
            setRowsPerPage(15)
            setBidsSearch("");
            setSearchedFlag(false); 
            setBidsAdvSearch({})
            setSortingPayload("")

        }
    }, [prevRoute]);
      console.log('prevRoute--->',prevRoute,route)
     console.log('bidsSearch--->',bidsSearch)
    
     useEffect(() => {
        if (!localStorage.getItem("token")) {
            history.push("/");
        }
        setRoute("bids")
        setActiveMenuId(300022)
        setActiveMenuObj({
            "Contact Management": false,
            "Client Management": false,
            "Reports": false,
            "Auction Management":true
        })
       // getTitleBids();
       GetContentNew(currentPageNew)
        GetLookUp();
    }, []);
    useEffect(() => {

        if (window.site === undefined) {
            setTimeout(() => {
                if (window.site && window.site.common && window.site.common.imagesCDN) {
                    setImg(window.site.common.imagesCDN)
                }
            }, 1000);
        }
        if (window.site && window.site.common && window.site.common.imagesCDN) {
            setImg(window.site.common.imagesCDN)

        }

    }, [window.site]);


    const columns = [
        {
            name: 'Image',
            cell: (props) => <img src={props.itemimage ?
                image + props.itemimage + '?auto=compress,format&width=80' :
                `${image}orasi/common/images/img-default.jpg?auto=compress,format&width=80`} alt='Image' />,
            sortable: false,
        },

        {
            name: 'title',
            selector: row => row?.title ?? "",
            sortable: true,

        },
       
        {
            name: 'Country',
            selector: row => row.country,
            sortable: true,
        },
        {
            name: 'Region',
            selector: row => row.region && row.region.length > 1 ? row.region.join(", "): row.region,
            sortable: true,
        },
        {
            name: 'Type of rights',
            selector: row => row.typesofrights, 
            sortable: true,
        },
        {
            name: 'revenue type',
            selector: row => row.revenuetype,
            sortable: true,
        },
        {
            name: 'exclusivity',
            selector: row => row.exclusivity,

            sortable: true,
        },
        {
            name: 'min bid price (usd)',
            selector: row => row.minbidprice,
            //    selector: row => moment(row.login_time).utc().format("DD-MMM-YYYY HH:mm"),
            sortable: true,
        },
        {
            name: 'max bid price (usd)',
            selector: row => row.maxbidprice,
            sortable: true,
        },
        {
            name: 'last bid date',
            selector: row => row.lastbiddedon ? new Date(row.lastbiddedon).toLocaleDateString('en-IN', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
            }) : null,
            sortable: true,
        },
        {
            name: '# bidders',
            selector: row => row.bidderscount,
            sortable: true,
        },

        {
            name: 'status',
            selector: row => <div>{row.status}</div>,
            sortable: true,
        },


        {
            name: 'view',
            cell: (props) =>
                <div className="d-flex">

                    <a onClick={(e) => handleViewBids(e, props)} className="text-success action-button"><i className="mdi mdi-eye font-size-18"></i></a>

                </div>
            //  }
            ,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const handleViewBids = (e, item) => {
        // if (item.status === "ACTIVE") {
        //     localStorage.setItem("item", JSON.stringify(item));
        //     localStorage.setItem("itemid", item.itemid);
        //     history.push("/viewtitlebids/" + item.combinationid);
        // } else {
        //     localStorage.setItem("item", JSON.stringify(item));
        //     localStorage.setItem("itemid", item.itemid);
        //     history.push("/viewbidsummary/" + item.combinationid);
        // }
        if (item.status === "APPROVED" || item.status === "AWARDED") {
            localStorage.setItem("item", JSON.stringify(item));
            localStorage.setItem("itemid", item.itemid);
            history.push("/viewbidsummary/" + item.combinationid);
        }else{
            localStorage.setItem("item", JSON.stringify(item));
            localStorage.setItem("itemid", item.itemid);
            history.push("/viewtitlebids/" + item.combinationid);
        }
    }
    // const handlePageChange = (page) => {
    //     setCurrentPage(page);
    // };

    // const handlePerRowsChange = (newPerPage) => {
    //     setRowsPerPage(newPerPage);
    // };
    const handlePageChange = (page) => {
        console.log("page",page);
        setCurrentPage(page);
        GetContentNew(page)

    };

    const handlePerRowsChange = (newPerPage, page) => {
        setRowsPerPage(newPerPage);
        setIsLoading(true);
        let payload = {}
        if (bidsSearch) {
            payload = { 'title': bidsSearch }
        } else {
            payload = bidsAdvSearch
        }
        let linkUrl;
        const token = localStorage.getItem("token")
        const userid = localStorage.getItem("userId")
        if(sortingPayload){
            linkUrl = `${lambda}/combinationlist?appname=${appname}&token=${token}&userid=${userid}&pageNumber=${page}&assetcount=${newPerPage}&isbidder=true&type=${sortingPayload.type}&sortBy=${sortingPayload.sortBy}`
        }else{
            linkUrl = `${lambda}/combinationlist?appname=${appname}&token=${token}&userid=${userid}&pageNumber=${page}&assetcount=${newPerPage}&isbidder=true`
        }
        axios({
            method: 'POST',
            url: linkUrl,
            data: payload
        })
            .then(function (response) {
                if (response?.data?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                    setIsLoading(false);
                } else {
                    const dataArray = Object.values(response?.data?.result?.data).map((obj) => obj);
                    setData(dataArray);
                    setIsLoading(false);

                }
            }).catch((err) => {
               console.log('error', err);
               setTableErrMsg(true)
               setIsLoading(false);
           });
    };


    const handleSort = (column, sortDirection) => {
 
        console.log('handle sort', column)
        // console.log('sort direction', sortDirection)
        let name = column?.name.toLowerCase();
        name = name === "last bid date" ? "lastbiddedon" : name === "revenue type" ? "revenuetype" :name === "type of rights" ? "typesofrights" :name === "min bid price (usd)" ? "minbidprice" :name === "max bid price (usd)" ? "maxbidprice" :name === "# bidders" ? "bidderscount" : name
        let direction = sortDirection === "asc" ? "ascending" : "descending"
        let payload = { "type": direction, "sortBy": name }
        setSortingPayload(payload)

    };
    useEffect(() => {
        if (sortingPayload) {
            GetContentNew(currentPageNew)
        }
    }, [sortingPayload])
    const GetContent = async (page) => {
        // console.log('get content function ')
        setIsLoading(true);
        const token = localStorage.getItem("token")
        const userid = localStorage.getItem("userId")
        const linkUrl = `${lambda}/combinationlist?appname=${appname}&token=${token}&userid=${userid}&pageNumber=${page}&assetcount=${rowsPerPage}&isbidder=true`
        
        axios({
            method: 'POST',
            // url: lambda + '/content?appname=' + appname + "&token=" + token + "&userid=" + userid,
            url: linkUrl,
         
        })
            .then(function (response) {
                if (response?.data?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                    setIsLoading(false);
                } else {
                    const dataArray = Object.values(response?.data?.result?.data).map((obj) => obj);
                    setData(dataArray);
                    setIsLoading(false);
                    setTotalRows(response?.data?.result?.assetTotal);
                    setSearchedFlag(false);

                }
            }).catch((err) => {
               console.log('error', err);
               setTableErrMsg(true)
               setIsLoading(false);
           });
    };
    const GetContentNew = async (page) => {
        setIsLoading(true);
        const token = localStorage.getItem("token");
        const userid = localStorage.getItem("userId");
        let linkUrl = `${lambda}/combinationlist?appname=${appname}&token=${token}&userid=${userid}&pageNumber=${page}&assetcount=${rowsPerPage}&isbidder=true`;
        let payload = {};

        if (bidsSearch) {
            // console.log("contentsearch", contentsearch);
            payload = { 'title': bidsSearch };
        } else {
            payload = bidsAdvSearch;
        }

        if (sortingPayload) {
            linkUrl =`${lambda}/combinationlist?appname=${appname}&token=${token}&userid=${userid}&pageNumber=${page}&assetcount=${rowsPerPage}&isbidder=true&type=${sortingPayload.type}&sortBy=${sortingPayload.sortBy}`;
        }

        axios({
            method: 'POST',
            url: linkUrl,
            data: payload
        })
            .then(function (response) {
                if (response?.data?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true);
                    setIsLoading(false);
                } else {
                    const dataArray = Object.values(response?.data?.result?.data).map((obj) => obj);
                    setData(dataArray);
                    //setKeyForRerender(prevKey => prevKey + 1); 
                    setIsLoading(false);
                    setTotalRows(response?.data?.result?.assetTotal);
                    setSearchedFlag(false);
                }
            })
            .catch((err) => {
                console.log('error', err);
                setTableErrMsg(true)
                setIsLoading(false);
            });
    };
    console.log("data",data);

    const GetLookUp = async (e) => {
        try {
            let arrayType = ["country", "genre", "videoformat", "resolution", "musicgenre", "rights", "cuisine", "sports", "certificate", "language", "territories", "region", "exclusivity","revenue"];

            const response = await tmdbApi.getBiddingLookUp({
                type: arrayType,
                status: 'ACTIVE'

            });
            // console.log('response here', response);
            if (response.statusCode == 200) {
                let arrayType = ["country", "genre", "videoformat", "resolution", "musicgenre", "rights", "cuisine", "sports", "certificate", "language", "territories", "region", "exclusivity","revenue"];

                let lookupsData = response.result?.data || []


                arrayType.map((eachItem) => {
                    let arr1 = []

                    lookupsData.map((item1) => {
                        if (item1.type == eachItem) {
                            arr1.push(item1.name)
                            if (item1.type == "rights") {
                                setTypesofrights(typesofrights => ([
                                    ...typesofrights,
                                    item1
                                ]));

                            }
                        }
                    });
                    lookup[eachItem] = arr1
                    setLookUp({ ...lookup });
                })
                //  let arrayType = ["country","genre","videoformat","resolution","musicgenre","rights","cuisine","sports","certificate"];

                const countryObj = lookup.country.map((eachItem) => { return { value: eachItem, label: eachItem }; })
                setCountryData(countryObj)
                const regionsObj = lookup.region.map((eachItem) => { return { value: eachItem, label: eachItem }; })
                setRegionsData(regionsObj)
                const exclusivityObj = lookup.exclusivity
                setExclusivityData(exclusivityObj)
                const revenueObj = lookup.revenue.map((eachItem) => { return { value: eachItem, label: eachItem }; })
                setRevenueData(revenueObj)

            }

        } catch {
            console.log("error");
        }
    }

    function checkAdvObjectProperties(obj) {
        for (const key in obj) {
            if (bidsAdvSearch[key].length > 0) {
                return false
            }

        }
        return true; // All key-value pairs exist in the object
    }

    const handleShowPopup = () => {
        setShowPopup(true);
    };
    const handleClosePopup = () => setShowPopup(false);

    const handleChange = (e) => {
        if ((e.key === "Enter")) {
            setTimeout(function () {
                handleSearch("normalsearch");
            }, 100);
        }
        setBidsSearch(e.target.value)
    }

    const handleSearch = (e, flagggg) => {
        let payload;
     
        if (flagggg === "normalsearch" || flagggg === "click") {
            setSearchedFlag(true)
        }

        if (flagggg === "" || flagggg === undefined) {
            setIsLoading(true)
        }
        if (bidsSearch === "" && bidsAdvSearch.length <= 0
        ) {
            GetContent(1);
        }
        else {
            if(bidsSearch){
                payload = {
                    "title": bidsSearch?.trim()
                }
                setIsLoading(true)
            }
            if(bidsAdvSearch && (bidsSearch === ""|| bidsSearch === undefined)){
            
                for (const key in bidsAdvSearch) {
                    if (bidsAdvSearch[key] === null || bidsAdvSearch[key] === undefined || bidsAdvSearch[key] === '' || bidsAdvSearch[key].length <= 0) {
                        delete bidsAdvSearch[key];
                    }
                }
                payload = { ...bidsAdvSearch }
                setIsLoading(true)
                setLoaderEnable(true)
            }
            
            const token = localStorage.getItem("token")
            const userid = localStorage.getItem("userId")
            axios({
                method: 'POST',
                url: `${lambda}/combinationlist?appname=${appname}&token=${token}&userid=${userid}&pageNumber=${1}&assetcount=${rowsPerPage}&isbidder=true`,
                data: payload,
            })
                .then(function (response) {
                    if (response.data.result == "Invalid token or Expired") {
                        setShowSessionPopupup(true)
                        setIsLoading(false)
                        setShowPopup(false)
                    } else {
                        const dataArray = Object.values(response?.data?.result?.data).map((obj) => obj);
                        setData(dataArray);
                        setTotalRows(response?.data?.result?.assetTotal);
                        setCurrentPage(1)
                        setIsLoading(false);
                        setShowPopup(false)
                        setLoaderEnable(false);
                        if (flagggg === "click") {
                            setBidsSearch("")
                        }
                    }
                });
        }
    }
    console.log("total",totalRows)

    const returnArray = (arr) => {
        let arr2 = []
        arr.map((eachItem) => {
            arr2.push(eachItem.value)
        })
        // console.log(arr2)
        return arr2
    }

    const handleChangeMultiSelectAdvSearch = (selected, key) => {
        if (key === "typesofrights") {
            setResetKey((prevKey) => (prevKey + 1) % 2);
            if (selected === null) {
                const updatedObject = { ...bidsAdvSearch };
                delete updatedObject[key];
                delete updatedObject["revenuetype"];
                setBidsAdvSearch(updatedObject);
            } else {
                setBidsAdvSearch({ ...bidsAdvSearch, [key]: selected.value, revenuetype: selected.revenuetype });
            }
        } else {
            let selectedArray = returnArray(selected)
            setBidsAdvSearch({ ...bidsAdvSearch, [key]: selectedArray })
        }
    }
    const handleadvSearchChange = (e, key) => {
        setBidsAdvSearch({ ...bidsAdvSearch, [e.target.name]: e.target.value });
    }
    const clearAdvSearch = (e) => {
        setResetKey((prevKey) => (prevKey + 1) % 2);
        setBidsAdvSearch({ ...bidsAdvSearch, title: "", country: "", region: "", typesofrights: "", revenuetype: "", exclusivity: "", status: "" })
    }
    const clearSearch = (e) => {
        setBidsSearch("");
        setBidsAdvSearch({ ...bidsAdvSearch, title: "", country: "", region: "", typesofrights: "", revenuetype: "", exclusivity: "", status: "" })
       // getTitleBids();
        setCurrentPage(1)
        GetContent(1)
    }
    const customNoRecords = () => {
        return (

            <div className="empty-state-body empty-record"  >
                <div className="empty-state__message">
                    <span className="material-icons">summarize</span>
                    <p className="form-check font-size-16">No bids were found for the searched keyword</p>
                </div> </div>
        )
    }
    
      const handleKeypress = (e) => {
        //it triggers by pressing the enter key

        if ((e.key === "Enter")) {
            setTimeout(function () {
                handleSearch("normalsearch")
            }, 10);
        }
    };
    console.log("adv",bidsSearch)
    return (
        <>
            {showSessionPopupup && <SessionPopup />}
            <div id="layout-wrapper">
                <Header />
                <Sidebar />

                <div className="main-content user-management clients clients-search combination">

                    <div className="page-content">
                        <div className="container-fluid">

                            <div className="row mb-4 breadcrumb">
                                <div className="col-lg-12">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <div className="title-block">
                                                <h4 className="mb-2 card-title">BIDS</h4>
                                                <div></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row table-data">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row mb-2">
                                            <div className="col-sm-4"></div>
                                                <div className="col-sm-8">

                                                    <div className="search-box mb-2 d-inline-block">
                                                        <div className="position-relative">
                                                            <input type="text" className="form-control"
                                                                value={bidsSearch}
                                                                onChange={(e) => handleChange(e)}
                                                                onKeyPress={handleKeypress}
                                                                // onKeyPress={handleKeypress}
                                                                placeholder="Search by Title" />
                                                            <button className="fill_btn"
                                                               onClick={(e) => handleSearch(e,"normalsearch")}
                                                            ><span className="material-icons search-icon" >search</span></button>
                                                        </div>
                                                        <div className="dataTables_length" id="datatable_length">
                                                            <button className="fill_btn"
                                                                onClick={handleShowPopup}
                                                            >Advanced Search</button>
                                                        </div>
                                                        <div className="dataTables_length" id="datatable_length">
                                                            <button className="fill_btn"
                                                                onClick={clearSearch}
                                                            ><span className="material-icons-outlined">sync</span>Reset</button>
                                                        </div>


                                                    </div>
                                                    <div className="text-sm-end">

                                                    </div>
                                                </div>
                                            </div>

                                            {/* <DataTable 
                                                // title=""
                                                columns={columns}
                                                // className="table align-middle table-nowrap table-check"
                                                keyField='_id'
                                                data={data}
                                                direction="auto"
                                                highlightOnHover
                                                fixedHeaderScrollHeight="300px"
                                                pagination
                                                paginationServer
                                                responsive
                                                persistTableHead
                                                // selectableRowsVisibleOnly
                                                striped
                                                // selectableRowsHighlight
                                                // selectableRows
                                                subHeaderAlign="right"
                                                defaultSortField="name"
                                                // sortFunction={customSort}
                                                onSort={handleSort}
                                                subHeaderWrap
                                                noDataComponent={customNoRecords()}
                                                paginationTotalRows={totalRows}
                                                onChangeRowsPerPage={handlePerRowsChange}
                                                onChangePage={handlePageChange}
                                                paginationPerPage={rowsPerPage}
                                                paginationDefaultPage={currentPageNew}
                                                paginationRowsPerPageOptions={[15, 25, 50, 75, 100]}
                                                paginationComponentOptions={{
                                                    rowsPerPageText: 'Per page:',
                                                    rangeSeparatorText: 'of',
                                                    noRowsPerPage: false,
                                                    selectAllRowsItem: false,
                                                    selectAllRowsItemText: 'All',
                                                }} progressPending={isLoading}
                                                progressComponent={<TableLoader />}
                                            /> */}
                                             < DataTable  key={keyForRerender}
                                                             // title=""
                                                             columns={columns}
                                                             // className="table align-middle table-nowrap table-check"
                                                             keyField='_id'
                                                             data={data}
                                                             direction="auto"
                                                             highlightOnHover
                                                             fixedHeaderScrollHeight="300px"
                                                             pagination
                                                             paginationServer
                                                             responsive
                                                             persistTableHead
                                                             // selectableRowsVisibleOnly
                                                             striped
                                                             // selectableRowsHighlight
                                                             // selectableRows
                                                            // subHeaderAlign="right"
                                                            // defaultSortField="name"
                                                            sortServer={true}
                                                             defaultSortField="" // Ensure no default sorting field 
                                                            subHeaderWrap
                                                             noDataComponent={customNoRecords()}
                                                             paginationTotalRows={totalRows}
                                                             onChangeRowsPerPage={handlePerRowsChange}
                                                             onChangePage={handlePageChange}
                                                             paginationPerPage={rowsPerPage}
                                                             paginationDefaultPage={currentPageNew}
                                                             paginationRowsPerPageOptions={[15, 25, 50, 75, 100]}
 
                                                             paginationComponentOptions={{
                                                                 rowsPerPageText: 'Per page:',
                                                                 rangeSeparatorText: 'of',
                                                                 noRowsPerPage: false,
                                                                 selectAllRowsItem: false,
                                                                 selectAllRowsItemText: 'All',
                                                             }}
                                                             onSort={handleSort}
                                                             // sortFunction={customSort}
                                                             progressPending={isLoading}
                                                             progressComponent={<TableLoader />}
                                                         />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                    <Modal className="advance-search search-popup bid-advance-search" show={popup} onHide={handleClosePopup} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>Advanced Search</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="adv_body">
                                <div className="adv_body-inner">
                                    <div className="row">
                                        <div className="col-md-6">

                                            <div className="form-group">
                                                <label htmlFor="example-text-input" className="col-form-label">Title</label>

                                                <input
                                                    type="text"
                                                    className="form-control for-clear"
                                                    id="example-email-input"
                                                    placeholder="title"
                                                    name="title"
                                                    onChange={(e) => handleadvSearchChange(e)} value={bidsAdvSearch.title}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label form-label">country</label>
                                                <Select isMulti={true}
                                                key={resetKey}
                                                    placeholder='Select countries'
                                                    isClearable={true}
                                                    onChange={(e) => handleChangeMultiSelectAdvSearch(e, "country")}

                                                    options={countryData}

                                                    value={bidsAdvSearch && bidsAdvSearch.country && bidsAdvSearch.country.length > 0 && bidsAdvSearch.country?.map((eachItem) => { return { value: eachItem, label: eachItem } })}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label form-label">Region</label>
                                                <Select isMulti={true}
                                                key={resetKey}
                                                    placeholder='Select regions'
                                                    isClearable={true}
                                                    onChange={(e) => handleChangeMultiSelectAdvSearch(e, "region")}
                                                    options={regionsData}
                                                    value={bidsAdvSearch && bidsAdvSearch.region && bidsAdvSearch.region.length > 0 && bidsAdvSearch.region?.map((eachItem) => { return { value: eachItem, label: eachItem } })}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label form-label">type of rights</label>

                                                <Select isMulti={false}
                                                key={resetKey}
                                                    placeholder='Select types of rights'
                                                    isClearable={true}
                                                    onChange={(e) => handleChangeMultiSelectAdvSearch(e, "typesofrights")}
                                                    options={typesofrights && typesofrights.map((eachItem) => { return { value: eachItem.name, label: eachItem.name, revenuetype: eachItem.revenue } })}
                                                    value={bidsAdvSearch && bidsAdvSearch.typesofrights && bidsAdvSearch.typesofrights.length > 0 && bidsAdvSearch.typesofrights.split(",")?.map((eachItem) => { return { value: eachItem, label: eachItem } })}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">

                                            <div className="form-group">
                                                <label htmlFor="example-text-input" className="col-form-label">revenue type</label>

                                                {/* <input
                                                        type="text"
                                                        className="form-control for-clear"
                                                        id="example-email-input"
                                                        placeholder="revenue type"
                                                        name="revenuetype"
                                                        onChange={(e) => handleadvSearchChange(e)} value={advancedSearch.revenuetype === undefined ? "" : advancedSearch.revenuetype}
                                                    /> */}
                                                <Select isMulti={true}
                                                key={resetKey}
                                                    placeholder='Select revenue type'
                                                    isClearable={true}
                                                    onChange={(e) => handleChangeMultiSelectAdvSearch(e, "revenuetype")}
                                                    options={bidsAdvSearch.typesofrights === "Digital" ? revenueData:[]}
                                                    value={bidsAdvSearch && bidsAdvSearch.revenuetype && bidsAdvSearch.revenuetype.length > 0 && bidsAdvSearch.revenuetype?.map((eachItem) => { return { value: eachItem, label: eachItem } })}
                                                />

                                            </div>
                                        </div>

                                        <div className="col-md-6">

                                            <div className="form-group">
                                                <label htmlFor="example-text-input" className="col-form-label">exclusivity</label>

                                                <select name="exclusivity" className="colorselect capitalize form-control"
                                                    onChange={(e) => handleadvSearchChange(e, "exclusivity")} value={bidsAdvSearch.exclusivity}
                                                >
                                                    <option value="">select exclusivity</option>
                                                    {
                                                        exclusivityData && exclusivityData.map((item, index) => <option key={index} value={item}>{item}</option>)
                                                    }

                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">

                                            <div className="form-group">
                                                <label htmlFor="example-text-input" className="col-form-label">status</label>

                                                <select name="status" className="colorselect capitalize form-control"
                                                    onChange={(e) => handleadvSearchChange(e, "status")} value={bidsAdvSearch.status}
                                                >
                                                    <option value="">select status</option>
                                                    <option value="ACTIVE">ACTIVE</option>
                                                    <option value="APPROVED">APPROVED</option>
                                                    <option value="AWARDED">AWARDED</option>
                                                </select>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="adv_footer">
                                <button className="fill_btn yellow-gradient reset-btn"
                                    onClick={clearAdvSearch}
                                ><span className="material-icons-outlined">sync</span>Reset</button>

                                <button
                                    onClick={(e) => handleSearch(e, "click")}
                                    className={`fill_btn yellow-gradient float-end ${checkAdvObjectProperties(bidsAdvSearch) ? "disable" : ""}`} disabled={checkAdvObjectProperties(bidsAdvSearch)}
                                >
                                    {loaderEnable ? (<img src={image + "orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}
                                    SEARCH
                                </button>

                                <div className="clearfix"></div>
                            </div>
                        </Modal.Footer>
                    </Modal>



                </div>

            </div>
        </>
    );
};
export default AllBids;