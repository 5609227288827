/***
**Module Name: edit user
 **File Name :  edituser.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains edit user details.
 ***/
import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/dashboard/footer";
import Header from "../../../components/dashboard/header";
import Sidebar from "../../../components/dashboard/sidebar";
import { useHistory, Link } from "react-router-dom";
import { useParams } from 'react-router-dom'
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import SessionPopup from "../../SessionPopup"
import { updateBiddingLookup, addBiddingLookup } from "../../../utils/reducer";
import { contentContext } from "../../../context/contentContext";
import Select from 'react-select';
import Loader from "../../../components/loader";

let { lambda, country, appname } = window.app;

const EditBiddingLookUp = (props) => {
    let { id } = useParams();
    const history = useHistory();
    const [editlookup, setEditLookUp] = useState({});
    const [success, setSuccess] = useState(false);
    const [add, setAdd] = useState(false);
    const [lookupErrors, setLookupErrors] = useState({});
    const [showSessionPopupup, setShowSessionPopupup] = useState(false);
    const [invalidContent, setInvalidContent] = useState(false);
    const [country, setCountry] = useState([]);
    // const regions = props?.location?.state?.regions || [];
    const [existAlert, setExistAlert] = useState(false);
    // const countries = props?.location?.state?.countries || [];
    const [countrydrop, setCountrires] = useState([]);
    const [regions, setRegions] = useState([]);
    const [revenues, setRevenues] = useState([]);
    const [typesrights, setTypesRights] = useState([]);
    const [revenue, setRevenue] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const { route, setRoute, setCurrentPage,setActiveMenuObj, setRowsPerPage, usePrevious, userData, setActiveMenuId } = useContext(contentContext);

    const prevRoute = usePrevious(route)
    useEffect(() => {
        if (prevRoute != undefined && prevRoute != route) {
            setCurrentPage(1)
            setRowsPerPage(15)
        }
    }, [prevRoute]);
    console.log('prevRoute--->', prevRoute)
    console.log('currentRoute--->', route)

    useEffect(() => {
        setRoute("biddingLookup")
        //setActiveMenuId(200009)
        setActiveMenuId(300023)
        setActiveMenuObj({
            "Contact Management": false,
            "Client Management": false,
            "Reports": false,
            "Auction Management":true
        })
        if (!localStorage.getItem("token")) {
            history.push("/");
        }
        if (id) {
            setAdd(false);
            getLookupData();

        } else {
            setAdd(true);
        }
    }, []);
    // console.log('currentRoute--->',route)
    const handleBack = (e) => {
        if (add === false) {
            history.push({
                pathname: "/biddinglookups",
                state: { search: true }
            });
        } else {
            history.push("/biddinglookups")
        }
    }


    const GetLookUp2 = async (item) => {
        console.log("lookUpType", item);
        let payload = item === "all-type" || item === "" ? {} : { type: [item] }

        const token = localStorage.getItem("token")
        let linkUrl

        linkUrl = lambda + '/biddingLookups?appname=' + appname +
            //  "&assetcount=" + rowsPerPage + "&pageNumber=" + currentPageNew +
            "&token=" + token + "&userid=" + localStorage.getItem("userId")

        axios({
            method: 'POST',
            url: linkUrl,
            data: payload,
        })
            .then(function (response) {
                console.log("res1")
                if (response.data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {

                    if (item === "country") {
                        setCountrires(response.data.result.data)
                    }
                    if (item === "region") {
                        setRegions(response.data.result.data)
                    }
                    if (item === "revenue")
                        setRevenues(response.data.result.data)
                    if (item === "rights")
                        setTypesRights(response.data.result.data)
                }
            });
    }

    const getLookupData = (e) => {
        const token = localStorage.getItem("token")
        axios({
            method: 'POST',
            url: lambda + '/biddingLookups?appname=' + appname + '&lookupcode=' + id + "&token=" + token + "&userid=" + localStorage.getItem("userId"),
        })
            .then(function (response) {
                console.log("res", response)
                if (response.data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    if (response.data.result.data.length > 0) {
                        let result = response.data.result.data[0];
                        setCountry(result.country || [])
                        setRevenue(result.revenue || [])
                        setEditLookUp({ ...result });
                        if (id) {
                            if (result.type === "country") {
                                GetLookUp2("region");
                            } else if (result.type === "region") {
                                GetLookUp2("country");
                            } else if (result.type === "rights") {
                                GetLookUp2('revenue')
                            } else if (result.type === "revenue") {
                                GetLookUp2('rights')
                            }
                            if (result.ismanageable === false) {
                                setIsDisabled(true)
                            }
                        }
                    } else {
                        setInvalidContent(true)
                    }
                }
            });
    }

    const onclickInvalid = () => {
        setInvalidContent(false)
        history.push('/biddingLookups')
    }
    console.log("before on change", editlookup.rights)
    const returnArray = (arr) => {
        let arr2 = []
        arr.map((eachItem) => {
            arr2.push(eachItem.value)
        })
        // console.log(arr2)
        return arr2
    }
    const handleChange = (e, keyname) => {
        console.log(e, keyname)
        if (!!lookupErrors[keyname]) {
            let error = Object.assign({}, lookupErrors);
            delete error[keyname];
            setLookupErrors(error);

        }
        let name = '';
        let value = '';
        if (keyname === 'country') {
            // let newArray = e.reduce(function (accumulator, curValue) {
            //     if (accumulator.indexOf(curValue.label) === -1) {
            //         accumulator.push(curValue.label)
            //     }
            //     return accumulator
            // }, [])
            // value = [... new Set(newArray)];
            // setCountry(value);
            let selectedArray = returnArray(e)
            console.log("selectedArray", selectedArray)
            setEditLookUp({ ...editlookup, [keyname]: selectedArray })
        }else if (keyname === 'region') {
           
            let selectedArray = returnArray(e)
            console.log("selectedArray", selectedArray)
            setEditLookUp({ ...editlookup, [keyname]: selectedArray })
        }
        else if (keyname === 'revenue') {
            // let newArray = e.reduce(function (accumulator, curValue) {
            //     if (accumulator.indexOf(curValue.label) === -1) {
            //         accumulator.push(curValue.label)
            //     }
            //     return accumulator
            // }, [])
            // value = [... new Set(newArray)];
            // setRevenue(value);
            let selectedArray = returnArray(e)
            console.log("selectedArray", selectedArray)
            setEditLookUp({ ...editlookup, [keyname]: selectedArray })
        }
        else if (keyname === "type") {
            if (e.target.value === "region") {
                GetLookUp2('country')
            } else if (e.target.value === "country") {
                GetLookUp2('region')
            } else if (e.target.value === "rights") {
                GetLookUp2('revenue')
            } else if (e.target.value === "revenue") {
                GetLookUp2('rights')
            }
            setEditLookUp({ ...editlookup, [keyname]: e.target.value });
        } else {
            console.log("e.target.name", e.target.name)

            name = e.target.name;
            value = e.target.value;
            console.log(e, "---")
            setEditLookUp({ ...editlookup, [name]: value });

        }

    }

    const validate = (e) => {
        let formIsValid = true;
        let error = { ...lookupErrors };
        let mandatoryFileds = []
        if (editlookup.type === "region") {
            mandatoryFileds = [{ name: 'Country', key: 'country' }, { name: 'Name', key: 'name' }, { name: 'Type', key: 'type' }, { name: 'Status', key: 'status' }]

        } else {
            mandatoryFileds = [{ name: 'Name', key: 'name' }, { name: 'Type', key: 'type' }, { name: 'Status', key: 'status' }]
        }
        if (mandatoryFileds) {
            mandatoryFileds.forEach(function (item) {

                if (
                    (editlookup[item.key] == "" ||
                        editlookup[item.key] == undefined ||
                        editlookup[item.key] == "undefined")
                ) {
                    error[item.key] = item.name + " is required";
                    formIsValid = false;
                }

            });
        }
        // if (editlookup.type === "region") {
        //     if (country === "" || country === undefined || country.length <= 0) {
        //         error["country"] = "Country is required";
        //         formIsValid = false;
        //     }
        // }
        console.log("errors", error);
        setLookupErrors(error)
        return formIsValid;
    }

    const handleSubmit = (e) => {
        if (add === true) {
            const isValid = validate();
            if (isValid) {
                handleSave();
            }
        } else {
            const isValid = validate();
            if (isValid) {
                handleUpdate();
            }
        }
    }
    const handleSave = (e) => {
        if (editlookup.type === 'country') {
            delete editlookup.revenue;
            delete editlookup.rights;
            delete editlookup.country;
        } else if (editlookup.type === 'region') {
            delete editlookup.revenue;
            delete editlookup.rights;
            delete editlookup.region;
        }
        else if (editlookup.type === 'revenue') {
            delete editlookup.region;
            delete editlookup.revenue;
            delete editlookup.country;
        } else if (editlookup.type === 'rights') {
            delete editlookup.region;
            delete editlookup.rights;
            delete editlookup.country;
        }
        console.log("editlookup....>", editlookup);

        let obj = { ...editlookup, createdBy: { userid: userData.userid, username: userData.name } };
        addBiddingLookup(obj).then((data) => {
            console.log("getClientcontent data", data);
            if (data.statusCode == 200) {
                console.log(data.result, "---")
                if (data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                }else if(data.result == "Lookup already exists"){
                    setExistAlert(true)
                } else {
                    setSuccess(true)
                }
            }
        }).catch((error) => {
            console.log(error);
        })

    }

    const handleUpdate = (e) => {
        if (editlookup.type === "country") {
            editlookup['country'] = [editlookup.name];
        }
        if (editlookup.type === "region") {
            editlookup['region'] = [editlookup.name];

        }
        delete editlookup["createdBy"];
        updateBiddingLookup(id, editlookup).then((data) => {
            console.log("getClientcontent data", data);
            if (data.statusCode == 200) {
                console.log(data.result, "---")
                if (data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                }else if(data.result == "Lookup already exists"){
                    setExistAlert(true)
                } else {
                    setSuccess(true)

                }
            }
        }).catch((error) => {
            console.log(error);
        })

        // if (editlookup._id) {
        //     delete editlookup['_id'];
        // }
        // console.log("editlookup", editlookup);
        // axios.post(lambda + '/updatelookups?appname=' + appname + '&lookupcode=' + id, {

        //     editlookup
        // }
        // )
        //     .then(function (response) {
        //         console.log(response)

        //     });

    }

    function onConfirm() {
        if (add) {
            history.push("/biddinglookups")
        } else {
            setSuccess(false);
        }

    };
    function onConfirm1() {
            setExistAlert(false);
    };

    console.log("region", regions)
    console.log("editlookup", editlookup)
    return (
        <>
            {showSessionPopupup && <SessionPopup />}
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <SweetAlert show={invalidContent}
                    custom
                    confirmBtnText="ok"
                    confirmBtnBsStyle="primary"
                    title={"Lookups Not Found"}
                    onConfirm={e => onclickInvalid()}
                >
                </SweetAlert>
                {!invalidContent &&

                    <div className="main-content user-management create-user edit-lookups">

                        <div className="page-content ">
                            <div className="container-fluid">

                                <div className="row mb-4 breadcrumb">
                                    <div className="col-lg-12">
                                        <div className="d-flex align-items-center">

                                            <div className="flex-grow-1">
                                                <h4 className="mb-2 card-title">{add === true ? "ADD" : "EDIT"} Bidding lookups</h4>
                                                <p className="menu-path">Bidding Lookups / <b>{add === true ? "Add" : "Edit"} Bidding Lookups</b></p>
                                            </div>
                                            <div>
                                                <a onClick={handleBack} className="btn btn-primary">back</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {Object.keys(editlookup).length > 0 || add === true ?
                                    <><div className="create-user-block">
                                        <div className="form-block">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="col-md-12">

                                                        <div className="mb-3 input-field">
                                                            <label className="form-label form-label">NAME <span className="required">*</span></label>
                                                            <input id="email" name="name" type="text" placeholder="Name" className="form-control form-control" aria-invalid="false" value={editlookup.name} onChange={(e) => handleChange(e, "name")} disabled={isDisabled} />
                                                            {lookupErrors.name && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{lookupErrors.name}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="mb-3 input-field">
                                                            <label className="form-label form-label">TYPE<span className="required">*</span></label>
                                                            {add === false ? <select name="type" className="colorselect capitalize form-control" value={editlookup.type} onChange={(e) => handleChange(e, "type")} disabled >
                                                                <option value="">Select Type</option>
                                                                <option value="country">Country</option>
                                                                <option value="exclusivity">Exclusivity</option>
                                                                <option value="region">Region</option>
                                                                <option value="revenue">Revenue</option>
                                                                <option value="rights">Rights</option>
                                                            </select> : <select name="type" className="colorselect capitalize form-control" value={editlookup.type} onChange={(e) => handleChange(e, "type")} >
                                                                <option value="">Select Type</option>
                                                                <option value="country">Country</option>
                                                                {/* <option value="exclusivity">Exclusivity</option> */}
                                                                <option value="region">Region</option>
                                                                <option value="revenue">Revenue</option>
                                                                <option value="rights">Rights</option>
                                                            </select>}

                                                            {lookupErrors.type && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{lookupErrors.type}</span>}

                                                        </div>
                                                    </div>

                                                    {editlookup.type === "country" && <div className="col-md-12">
                                                        <div className="mb-3 input-field">
                                                            <label className="form-label form-label">Region</label>
                                                            <Select
                                                            isMulti={true}
                                                            placeholder={"Select Region"}
                                                            onChange={(e) => handleChange(e, 'region')}
                                                            options={regions && regions.map((eachItem) => { return { value: eachItem.name, label: eachItem.name } })}
                                                            value={editlookup && editlookup.region && editlookup.region.length > 0 && editlookup.region?.map((eachItem) => { return { value: eachItem, label: eachItem } })}

                                                        />
                                                            {/* <select name="region" className="colorselect capitalize form-control" value={editlookup.region} onChange={(e) => handleChange(e, "region")}>
                                                                <option value="">Select Region</option>
                                                                {
                                                                    region && region.map((item, index) => <option key={index} value={item.name}>{item.name}</option>)
                                                                }

                                                            </select> */}
                                                        </div>
                                                    </div>}

                                                    {editlookup.type === "region" && <div className="col-md-12">
                                                        <div className="mb-3 input-field">
                                                            <label className="form-label form-label">Country<span className="required">*</span></label>

                                                            <Select
                                                                isMulti={true}
                                                                placeholder={"Select Country"}
                                                                onChange={(e) => handleChange(e, 'country')}
                                                                options={countrydrop && countrydrop.map((eachItem) => { return { value: eachItem.name, label: eachItem.name } })}
                                                                defaultValue={editlookup.country && editlookup.country.length > 0 ? editlookup.country?.map((eachItem) => { return { value: eachItem, label: eachItem } }) : []}

                                                            />
                                                            {lookupErrors.country && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{lookupErrors.country}</span>}
                                                        </div>
                                                    </div>}
                                                    {editlookup.type === "revenue" && <div className="col-md-12">
                                                        <div className="mb-3 input-field">
                                                            <label className="form-label form-label">Rights</label>
                                                            <select name="rights" className="colorselect capitalize form-control" value={editlookup.rights} onChange={(e) => handleChange(e, "rights")}>
                                                                <option value="">Select Rights</option>
                                                                {
                                                                    typesrights && typesrights.map((item, index) => <option key={index} value={item.name}>{item.name}</option>)
                                                                }

                                                            </select>
                                                        </div>
                                                    </div>}
                                                    {editlookup.type === "rights" && <div className="col-md-12">
                                                        <div className="mb-3 input-field">
                                                            <label className="form-label form-label">Revenue</label>

                                                            <Select
                                                                isMulti={true}
                                                                placeholder={"Select revenue"}
                                                                onChange={(e) => handleChange(e, 'revenue')}
                                                                options={revenues && revenues.map((eachItem) => { return { value: eachItem.name, label: eachItem.name } })}
                                                                defaultValue={editlookup.revenue && editlookup.revenue.length > 0 ? editlookup.revenue?.map((eachItem) => { return { value: eachItem, label: eachItem } }) : []}

                                                            />
                                                        </div> </div>
                                                    }


                                                    <div className="col-md-12">
                                                        <div className="mb-3 input-field">
                                                            <label className="form-label form-label">status<span className="required">*</span></label>
                                                            {add === true ?
                                                                <select name="status" className="colorselect capitalize form-control" value={editlookup.status} onChange={(e) => handleChange(e, "status")}>

                                                                    <option value="">Select status</option>
                                                                    <option value="ACTIVE">ACTIVE</option>
                                                                    <option value="INACTIVE">INACTIVE</option>
                                                                </select> :
                                                                <select name="status" className="colorselect capitalize form-control" value={editlookup.status} onChange={(e) => handleChange(e, "status")}>

                                                                    <option value="ACTIVE">ACTIVE</option>
                                                                    <option value="INACTIVE">INACTIVE</option>
                                                                </select>
                                                            }
                                                            {lookupErrors.status && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{lookupErrors.status}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">

                                                </div>

                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                {add === true ? <button className="btn btn-primary btn-block " type="submit" onClick={handleSubmit}>Save</button> :
                                                    <button className="btn btn-primary btn-block " type="submit" onClick={handleSubmit}>update</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                    : <div className="create-user-block"><div className="form-block">
                                        <div className="tab-content p-3 text-muted">
                                            <div className="tab-pane active show" id="home1" role="tabpanel">
                                                <div className="row"><Loader /></div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                }

                            </div>
                        </div>
                        <SweetAlert show={existAlert}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Lookup Already Exists"}
                            onConfirm={e => onConfirm1()}
                        >
                        </SweetAlert>
                        <SweetAlert show={success}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={add === true ? "Saved SuccessFully" : "Updated SuccessFully"}
                            onConfirm={e => onConfirm()}
                        >
                        </SweetAlert>

                        <Footer />
                    </div>
                }

            </div>

        </>
    );
};

export default EditBiddingLookUp;
