/***
**Module Name: edit user
 **File Name :  edituser.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains edit user details.
 ***/
import React, { useState, useEffect, useContext } from "react";
import Footer from "../../components/dashboard/footer";
import Header from "../../components/dashboard/header";
import Sidebar from "../../components/dashboard/sidebar";
import { useHistory, Link } from "react-router-dom";
import { useParams } from 'react-router-dom'
import moment from "moment";
import Loader from "../../components/loader";
import Select from 'react-select';
import axios from 'axios';
import * as Config from "../../constants/Config";
import SweetAlert from 'react-bootstrap-sweetalert';
import SessionPopup from "../SessionPopup"
import { updateLookup, addLookup } from "../../utils/reducer";
import { contentContext } from "../../context/contentContext";
let { lambda, country, appname } = window.app;

const EditLookUp = () => {
    let { id } = useParams();
    const history = useHistory();
    const [editlookup, setEditLookUp] = useState({});
    const [success, setSuccess] = useState(false);
    const [popupMsg, setPopupMsg] = useState(false);
    const [image, setImg] = useState('');
    const [add, setAdd] = useState(false);
    const [lookupErrors, setLookupErrors] = useState({});
    const [countrydrop, setCountrires] = useState([]);
    const [territories, setTerritories] = useState([]);
    // const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showSessionPopupup, setShowSessionPopupup] = useState(false);
    const [invalidContent, setInvalidContent] = useState(false);


    const { route, setRoute, setCurrentPage, setActiveMenuObj, setRowsPerPage, usePrevious, lookupsearch, setLookupSearch, userData, setActiveMenuId, GetTimeActivity } = useContext(contentContext);


    useEffect(() => {
        setRoute("lookup")
        setActiveMenuId(200009)
        setActiveMenuObj({
            "Client Management": false,
            "Contact Management": false,
            "Reports": false
        })
        if (!localStorage.getItem("token")) {
            history.push("/");
        }
        if (id) {
            setAdd(false);
            getLookupData();

        } else {
            setAdd(true);
        }
        userActivity();
    }, []);
    const userActivity = () => {
        let path = window.location.pathname.split("/");
        const pageName = id != undefined ? path[path.length - 2] : path[path.length - 1];
        var presentTime = moment();
        let payload;

        payload = {
            "userid": localStorage.getItem("userId"),
            "pagename": pageName,
            "pageurl": window.location.href,
            "starttime": presentTime,
            "useragent": JSON.parse(localStorage.getItem("loc"))

        };


        const previousId = localStorage.getItem("previousid");
        const urlLink = lambda + '/useractivity?appname=' + appname + (previousId ? "&previousid=" + previousId : "");


        axios({
            method: 'POST',
            url: urlLink,
            data: payload
        })
            .then(function (response) {
                if (response?.data?.statusCode === 200) {
                    localStorage.setItem("previousid", response?.data?.result)
                }
            });
    }
    const prevRoute = usePrevious(route)
    useEffect(() => {
        if (prevRoute != undefined && prevRoute != route) {
            setCurrentPage(1)
            setRowsPerPage(15)
        }
    }, [route]);
    useEffect(() => {

        if (window.site === undefined) {
            setTimeout(() => {
                if (window.site && window.site.common && window.site.common.imagesCDN) {
                    setImg(window.site.common.imagesCDN)
                }
            }, 1000);
        }
        if (window.site && window.site.common && window.site.common.imagesCDN) {
            setImg(window.site.common.imagesCDN)
        }

    }, [window.site]);
    // console.log('currentRoute--->',route)
    const handleBack = (e) => {
        GetTimeActivity();
        if (add === false) {
            history.push({
                pathname: "/lookups",
                state: { search: true }
            });
        } else {
            history.push("/lookups")
        }
    }
    const getLookupData = (e) => {
        const token = localStorage.getItem("token")
        axios({
            method: 'POST',
            url: lambda + '/lookups?appname=' + appname + '&lookupcode=' + id+"&page="+"marketplace" + "&token=" + token + "&userid=" + localStorage.getItem("userId"),
        })
            .then(function (response) {
                console.log("res", response)
                if (response?.data?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    if (response?.data?.result?.data.length > 0) {
                        let result = response?.data?.result?.data[0];
                        setEditLookUp({ ...result });
                        if (id) {
                            if (result.type === "country") {
                                GetLookUp("territories");
                            } else if (result.type === "territories") {
                                GetLookUp("country");
                            }
                        }
                    } else {
                        setInvalidContent(true)
                    }
                }
            });
    }
    const GetLookUp = async (item) => {
        GetTimeActivity();
        let payload = item === "all-type" || item === "" ? {} : { type: [item] }


        // setIsLoading(true)

        const token = localStorage.getItem("token");
        const linkUrl = `${lambda}/lookups?appname=${appname}&token=${token}&userid=${localStorage.getItem("userId")}`;

        axios({
            method: 'POST',
            url: linkUrl,
            data: payload,
        })
            .then(function (response) {
                if (response?.data?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {

                    // setData(response?.data?.result?.data);
                    global.exportExcelData = response?.data?.result?.data


                    if (item === "country") {
                        setCountrires(response.data.result.data?.filter((item) => item.type === "country"))
                    }
                    if (item === "territories") {
                        setTerritories(response.data.result.data?.filter((item) => item.type === "territories"))
                    }

                    // setLookUp(response?.data?.result);
                    // setDummy(response?.data?.result?.data);
                    // setIsLoading(false)


                }
            });
    }
    const onclickInvalid = () => {
        setInvalidContent(false)
        history.push('/lookups')
    }
    const returnArray = (arr) => {
        let arr2 = []
        arr.map((eachItem) => {
            arr2.push(eachItem.value)
        })
        // console.log(arr2)
        return arr2
    }
    const handleChange = (e, keyname) => {
        console.log(e, keyname)
        if (!!lookupErrors[keyname]) {
            let error = Object.assign({}, lookupErrors);
            delete error[keyname];
            setLookupErrors(error);

        }
        let name = '';
        let value = '';
        if (keyname === 'country' || keyname === 'territories') {
            // let newArray = e.reduce(function (accumulator, curValue) {
            //     if (accumulator.indexOf(curValue.label) === -1) {
            //         accumulator.push(curValue.label)
            //     }
            //     return accumulator
            // }, [])
            // value = [... new Set(newArray)];
            // setCountry(value);
            let selectedArray = returnArray(e)
            console.log("selectedArray", selectedArray)
            setEditLookUp({ ...editlookup, [keyname]: selectedArray })
        }

        else if (keyname === "type") {
            if (e.target.value === "territories") {
                GetLookUp('country')
            } else if (e.target.value === "country") {
                GetLookUp('territories')
            }
            setEditLookUp({ ...editlookup, [keyname]: e.target.value });
        } else {
            console.log("e.target.name", e.target.name)

            name = e.target.name;
            value = e.target.value;
            console.log(e, "---")
            setEditLookUp({ ...editlookup, [name]: value });

        }

    }

    const validate = (e) => {
        let formIsValid = true;
        let error = { ...lookupErrors };
        let mandatoryFileds = []

        mandatoryFileds = [{ name: 'Name', key: 'name' }, { name: 'Type', key: 'type' }, { name: 'Status', key: 'status' }]


        if (mandatoryFileds) {
            mandatoryFileds.forEach(function (item) {

                if (
                    (editlookup[item.key] == "" ||
                        editlookup[item.key] == undefined ||
                        editlookup[item.key] == "undefined")
                ) {
                    error[item.key] = item.name + " is required";
                    formIsValid = false;
                }

            });
        }
        console.log("errors", error);
        setLookupErrors(error)
        return formIsValid;
    }

    const handleSubmit = (e) => {

        GetTimeActivity();
        if (add === true) {
            const isValid = validate();
            if (isValid) {
                setIsLoading(true)
                handleSave();
            }
        } else {
            const isValid = validate();
            if (isValid) {
                setIsLoading(true)
                handleUpdate();
            }
        }
    }
    const handleSave = (e) => {
        GetTimeActivity();
        if (editlookup.type === 'country') {

            delete editlookup.country;
        } else if (editlookup.type === 'territories') {

            delete editlookup.territories;
        }
        let obj = { ...editlookup, createdBy: { userid: userData.userid, username: userData.name } }
        if (editlookup?.type !== "subtitlelanguage") {
            obj.ismanagable = true;
        }
        addLookup(obj).then((data) => {
            console.log("getClientcontent data", data);
            if (data.statusCode == 200) {
                console.log(data.result, "---")
                if (data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                    setIsLoading(false)
                } else if (data.result === "Lookup already exists") {
                    setPopupMsg(true)
                    setIsLoading(false)
                }
                else {
                    setSuccess(true)
                    setIsLoading(false)
                }
            }
        }).catch((error) => {
            console.log(error);
        })

    }

    const handleUpdate = (e) => {
        if (editlookup.type === "country") {
            editlookup['country'] = [editlookup.name];
        }
        if (editlookup.type === "territories") {
            editlookup['territories'] = [editlookup.name];

        }
        GetTimeActivity();
        updateLookup(id, editlookup).then((data) => {
            console.log("getClientcontent data", data);
            if (data.statusCode == 200) {
                console.log(data.result, "---")
                if (data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                    setIsLoading(false)
                } else {
                    setSuccess(true)
                    setIsLoading(false)
                }
            }
        }).catch((error) => {
            console.log(error);
        })



    }

    function onConfirm() {
        if (add) {
            history.push("/lookups")
        } else {
            setSuccess(false);
        }

    };
    function onConfirm1() {

        setPopupMsg(false);


    };

    return (
        <>
            {showSessionPopupup && <SessionPopup />}
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <SweetAlert show={invalidContent}
                    custom
                    confirmBtnText="ok"
                    confirmBtnBsStyle="primary"
                    title={"Lookups Not Found"}
                    onConfirm={e => onclickInvalid()}
                >
                </SweetAlert>
                {!invalidContent &&

                    <div className="main-content user-management create-user edit-lookups">

                        <div className="page-content ">
                            <div className="container-fluid">

                                <div className="row mb-4 breadcrumb">
                                    <div className="col-lg-12">
                                        <div className="d-flex align-items-center">

                                            <div className="flex-grow-1">
                                                <h4 className="mb-2 card-title">{add === true ? "ADD" : "EDIT"} lookups</h4>
                                                <p className="menu-path">Lookups / <b>{add === true ? "Add" : "Edit"} Lookups</b></p>
                                            </div>
                                            <div>
                                                <a onClick={handleBack} className="btn btn-primary">back</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="create-user-block">
                                    {Object.keys(editlookup).length > 0 || add === true ?
                                        <>
                                            <div className="form-block">
                                                <div className="row">

                                                    <div className="col-md-6">
                                                        <div className="col-md-12">

                                                            <div className="mb-3 input-field">
                                                                <label className="form-label form-label">NAME <span className="required">*</span></label>
                                                                <input id="email" name="name" type="text" placeholder="Name" className="form-control form-control" aria-invalid="false" value={editlookup.name} onChange={(e) => handleChange(e, "name")} disabled={id === undefined ? false : !editlookup.ismanagable} />
                                                                {lookupErrors.name && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{lookupErrors.name}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="mb-3 input-field">
                                                                <label className="form-label form-label">TYPE<span className="required">*</span></label>
                                                                <select name="type" className="colorselect capitalize form-control" value={editlookup.type} onChange={(e) => handleChange(e, "type")} disabled={add === false} >
                                                                    <option value="">Select Type</option>
                                                                    <option value="certificate">Certificate</option>
                                                                    <option value="country">Country</option>
                                                                    <option value="cuisine">Cuisine</option>
                                                                    <option value="genre">Genre</option>
                                                                    <option value="language">Language</option>
                                                                    <option value="musicgenre">Musicgenre</option>
                                                                    <option value="rights">Rights</option>
                                                                    <option value="resolution">Resolution</option>
                                                                    <option value="sports">Sports</option>
                                                                    <option value="territories">Territories</option>
                                                                    <option value="videoformat">Video Format</option>
                                                                    {add === false && <option value="subtitlelanguage">Subtitle Language</option>}

                                                                </select>

                                                                {lookupErrors.type && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{lookupErrors.type}</span>}

                                                            </div>
                                                        </div>
                                                        {editlookup.type === "country" && <div className="col-md-12">
                                                            <div className="mb-3 input-field">
                                                                <label className="form-label form-label">Territories</label>
                                                                <Select
                                                                    isMulti={true}
                                                                    placeholder={"Select Territories"}
                                                                    onChange={(e) => handleChange(e, 'territories')}
                                                                    options={territories && territories.map((eachItem) => { return { value: eachItem.name, label: eachItem.name } })}
                                                                    value={editlookup && editlookup.territories && editlookup.territories.length > 0 ? editlookup.territories?.map((eachItem) => { return { value: eachItem, label: eachItem } }) : []}

                                                                />
                                                                {/* <select name="region" className="colorselect capitalize form-control" value={editlookup.region} onChange={(e) => handleChange(e, "region")}>
                                                                <option value="">Select Region</option>
                                                                {
                                                                    region && region.map((item, index) => <option key={index} value={item.name}>{item.name}</option>)
                                                                }

                                                            </select> */}
                                                            </div>
                                                        </div>}

                                                        {editlookup.type === "territories" && <div className="col-md-12">
                                                            <div className="mb-3 input-field">
                                                                <label className="form-label form-label">Country</label>

                                                                <Select
                                                                    isMulti={true}
                                                                    placeholder={"Select Country"}
                                                                    onChange={(e) => handleChange(e, 'country')}
                                                                    options={countrydrop && countrydrop.map((eachItem) => { return { value: eachItem.name, label: eachItem.name } })}
                                                                    defaultValue={editlookup.country && editlookup.country.length > 0 ? editlookup.country?.map((eachItem) => { return { value: eachItem, label: eachItem } }) : []}

                                                                />

                                                            </div>
                                                        </div>}
                                                        {editlookup.type === "subtitlelanguage" && <div className="col-md-12">
                                                            <div className="mb-3 input-field">
                                                                <label className="form-label form-label">language code<span className="required">*</span></label>
                                                                <input id="email" name="alpha2" placeholder="language code" type="text" className="form-control form-control" disabled aria-invalid="false" value={editlookup.languagecode} />

                                                            </div>
                                                        </div>}
                                                        {editlookup.type === "country" &&
                                                            <><div className="col-md-12">
                                                                <div className="mb-3 input-field">
                                                                    <label className="form-label form-label">ALPAHA2</label>
                                                                    <input id="email" name="alpha2" placeholder="Enter Alpha2" type="text" className="form-control form-control" aria-invalid="false" value={editlookup.alpha2} onChange={(e) => handleChange(e)} />
                                                                    {lookupErrors.alpha2 && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{lookupErrors.alpha2}</span>}
                                                                </div>
                                                            </div><div className="col-md-12">
                                                                    <div className="mb-3 input-field">
                                                                        <label className="form-label form-label">ALPAHA3</label>
                                                                        <input id="email" name="alpha3" placeholder="Enter Alpha3" type="text" className="form-control form-control" aria-invalid="false" value={editlookup.alpha3} onChange={(e) => handleChange(e)} />
                                                                        {lookupErrors.alpha3 && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{lookupErrors.alpha3}</span>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="mb-3 input-field">
                                                                        <label className="form-label form-label">Country Code</label>
                                                                        <input id="email" name="countrycode" placeholder="Enter countrycode" type="text" className="form-control form-control" aria-invalid="false" value={editlookup.countrycode} onChange={(e) => handleChange(e)} />

                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                        {editlookup.type === "resolution" &&
                                                            <div className="col-md-12">
                                                                <div className="mb-3 input-field">
                                                                    <label className="form-label form-label">quality</label>
                                                                    <select name="quality" className="colorselect capitalize form-control" value={editlookup.quality} onChange={(e) => handleChange(e)}>
                                                                        <option value="">Select Quality</option>
                                                                        <option value="4K">4K</option>
                                                                        <option value="HD">HD</option>

                                                                    </select>
                                                                    {lookupErrors.quality && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{lookupErrors.quality}</span>}

                                                                </div>
                                                            </div>
                                                        }

                                                        <div className="col-md-12">
                                                            <div className="mb-3 input-field">
                                                                <label className="form-label form-label">status<span className="required">*</span></label>
                                                                {add === true ?
                                                                    <select name="status" className="colorselect capitalize form-control" value={editlookup.status} onChange={(e) => handleChange(e, "status")}>

                                                                        <option value="">Select status</option>
                                                                        <option value="ACTIVE">ACTIVE</option>
                                                                        <option value="INACTIVE">INACTIVE</option>
                                                                    </select> :
                                                                    <select name="status" className="colorselect capitalize form-control" value={editlookup.status} onChange={(e) => handleChange(e, "status")}>

                                                                        <option value="ACTIVE">ACTIVE</option>
                                                                        <option value="INACTIVE">INACTIVE</option>
                                                                    </select>
                                                                }
                                                                {lookupErrors.status && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{lookupErrors.status}</span>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">

                                                    </div>

                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    {add === true ? <button className="btn btn-primary btn-block " type="submit" onClick={handleSubmit}>{isLoading ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}Save</button> :
                                                        <button className="btn btn-primary btn-block " type="submit" onClick={handleSubmit}>{isLoading ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}update</button>
                                                    }
                                                </div>
                                            </div>

                                        </>
                                        :

                                        <div className="form-block">
                                            <div className="tab-content p-3 text-muted">
                                                <div className="tab-pane active show" id="home1" role="tabpanel">
                                                    <div className="row"><Loader />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    }
                                </div>


                            </div>
                        </div>

                        <SweetAlert show={success}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={add === true ? "Saved SuccessFully" : "Updated SuccessFully"}
                            onConfirm={e => onConfirm()}
                        >
                        </SweetAlert>
                        <SweetAlert show={popupMsg}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Lookup already exists"}
                            onConfirm={e => onConfirm1()}
                        >
                        </SweetAlert>

                        <Footer />
                    </div>
                }

            </div>

        </>
    );
};

export default EditLookUp;