/***
**Module Name: Company details
 **File Name :  Company.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains compant tables details.
 ***/
 import React, { useState, useEffect, useContext } from "react";


 import Footer from "../../../components/dashboard/footer";
 import Header from "../../../components/dashboard/header";
 import Sidebar from "../../../components/dashboard/sidebar";
 import tmdbApi from "../../../api/tmdbApi";
 import { useHistory, Link } from "react-router-dom";
 import { useParams } from 'react-router-dom';
 import moment from "moment";
 import axios from 'axios';
 import { contentContext } from "../../../context/contentContext";
 let { lambda, appname } = window.app;
 
 
 
 const ViewClientSearchDynamic = (props) => {
     const history = useHistory();
     const [recommendData, setRecommendData] = useState("");
     const [searchData, setSearchData] = useState("");
     const [FilterCount, setFilterCount] = useState(0);
 
     const { route, setRoute, setCurrentPage, setRowsPerPage, usePrevious, setActiveMenuId, setActiveMenuObj, GetTimeActivity } = useContext(contentContext);
 
     const prevRoute = usePrevious(route)
     useEffect(() => {
         if (prevRoute != undefined && prevRoute != route) {
             setCurrentPage(1)
             setRowsPerPage(15)
         }
     }, [prevRoute]);
 
     useEffect(() => {
         if (!localStorage.getItem("token")) {
             history.push("/");
         }
         setRoute("clientsearch")
         setActiveMenuId(300050)
         setActiveMenuObj({
             "Client Management": false,
             "Contact Management": false,
             "Reports": true
         })
         setSearchData(props.data)
         setFilterCount(props?.data?.contentSearch ? Object.keys(props?.data?.contentSearch)?.length : props?.data?.clientcontentSearch ? Object.keys(props?.data?.clientcontentSearch)?.length : 0)
 
     }, []);
 
 
     console.log("dataaaaaaaa--------->",props?.data);
    //  console.log("Object.keys(props?.data?.contentSearch)?.length--------->",Object.keys(props?.data?.contentSearch)?.length);
    //  console.log("Object.keys(props?.data?.clientcontentSearch)?.length--------->",Object.keys(props?.data?.clientcontentSearch)?.length);

 
     const handleBack = (e) => {
         GetTimeActivity()
         props.setClick(false);
     }
 
     return (
         <>
 
             <div id="layout-wrapper">
 
 
                 <div className="main-content user-management viwe-client-search">
 
                     <div className="page-content">
                         <div className="container-fluid">
 
 
 
                             <div className="row mb-4 breadcrumb">
                                 <div className="col-lg-12">
                                     <div className="d-flex align-items-center">
                                         <div className="flex-grow-1">
                                             <h4 className="mb-2 card-title">View Client Search</h4>
                                             <p className="menu-path">Reports / <b>View Client Search</b></p>
                                         </div>
                                         <div>
                                             <a onClick={handleBack} className="btn btn-primary">back</a>
                                         </div>
 
                                     </div>
                                 </div>
                             </div>
 
 
                             <div className="row table-data">
                                 <div className="col-12">
                                     <div className="card">
                                         <div className="card-body">
                                             <div className="row mb-2">
                                                 <div className="col-sm-12">
                                                     <div className="d-flex align-items-center justify-content-center">
                                                         <p className="menu-path">Client Name<b>{searchData.clientname}</b></p>
                                                         {/* <p className="lg-badge badge">{searchData.type === "BOTH" ? searchData.showmycontent === true ? "SELLER" : "BUYER" : searchData.type}</p> */}
                                                         <p className="lg-badge badge">{searchData?.type}</p>
                                                     </div>
                                                 </div>
 
 
                                             </div>
 
                                             <div className="table-responsive">
                                                 <table className="table align-middle table-nowrap table-check">
 
                                                  
                                                     <tbody>
                                                         {[
                                                             { label: 'Company Name', value: searchData?.companyname },
                                                             
                                                             { label: 'Login Time', value: new Date(searchData?.login_time).toLocaleDateString('en-IN', { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }) },
                                                             
                                                             { label: 'Searched On', value: new Date(searchData?.created).toLocaleDateString('en-IN', { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }) },
                                                             
                                                             { label: 'Total Content', value: searchData?.assetTotal == 0 ? "0" : searchData?.assetTotal ?? ""},
                                                             
                                                             { label: 'Page Result', value: searchData?.pageResult ?? ""},
                                                            
                                                             { label: 'Page Number', value: searchData?.contentSearch?.pageNumber ? searchData?.contentSearch?.pageNumber  : searchData?.pageNumber ?? "" },
                                                             
                                                             { label: 'Per Page', value: searchData?.contentSearch?.perPage ? searchData?.contentSearch?.perPage : searchData?.perPage ?? ""},
                                                            
                                                             { label: 'Show My Content', value: searchData.type === "BOTH" ? searchData?.showmycontent ? 'TRUE' : 'FALSE' :""},
       
                                                         ].filter(item => !!item.value).map((item, index) => (
                                                             <tr key={index}>
                                                                 <td className="align-middle">{item.label}</td>
                                                                 <td className="align-middle">{item.value}</td>
                                                             </tr>
                                                         ))}
                                                     </tbody> 
 
 
 
                                                 </table>
                                             </div>
                                           {FilterCount > 0 ? 
                                           <>   <div className="row mb-2">
                                                 <div className="col-sm-12">
                                                     <div className="d-flex align-items-center justify-content-center">
                                                         <p className="menu-path"><b>Filters</b></p>
                                                     </div>
                                                 </div>
 
 
                                             </div>
                                             <div className="table-responsive">
                                                 <table className="table align-middle table-nowrap table-check">
 
                                                   
                                                     <tbody>
                                                         {[
                                                           
                                                             { label: 'Category', value:searchData?.contentSearch?.category ? searchData?.contentSearch?.category?.join(', ') : searchData?.category?.join(', ') ?? ""},
                                                             
                                                            
                                                             { label: 'Title', value: searchData?.contentSearch?.title ? searchData?.contentSearch?.title : searchData?.title ?? ""},
                                                             
                                                             { label: 'Country of Origin', value: searchData?.contentSearch?.countryorigin ? searchData?.contentSearch?.countryorigin?.join(', ') : searchData?.countryorigin?.join(', ') ?? ""},
                                                             
                                                             { label: 'Certificate', value: searchData?.contentSearch?.certificate ? searchData?.contentSearch?.certificate?.join(', ') : searchData?.certificate?.join(', ') ?? "" },
                                                             
                                                             { label: 'Cast', value: searchData?.contentSearch?.cast ? searchData?.contentSearch?.cast : searchData?.cast ?? "" },
                                                             
                                                             { label: 'Release Year', value: searchData?.contentSearch?.releaseyear ? searchData?.contentSearch?.releaseyear : searchData?.releaseyear ?? ""},
                                                             
                                                             { label: 'Director', value: searchData?.contentSearch?.director ? searchData?.contentSearch?.director  : searchData?.director ?? "" },
                                                             
                                                             { label: 'Dubbing Languages', value: searchData?.clientcontentSearch?.dubbinglanguages ? searchData?.clientcontentSearch?.dubbinglanguages?.join(', ') : searchData?.dubbinglanguages?.join(', ') ?? ""},
                                                             
                                                             { label: 'Genre', value: searchData?.contentSearch?.genre ? searchData?.contentSearch?.genre?.join(', ') : searchData?.genre?.join(', ') ?? ""},

                                                             { label: 'IMDB Rating', value: searchData?.contentSearch?.IMDBrating ? searchData?.contentSearch?.IMDBrating : searchData?.IMDBrating ?? ""},
                                                             
                                                             { label: 'Language', value: searchData?.clientcontentSearch?.languages ? searchData?.clientcontentSearch?.languages?.join(', ') : searchData?.languages?.join(', ') ?? "" },
                                                             
                                                             { label: 'Subtitle Languages', value: searchData?.contentSearch?.subtitleslanguages ?  searchData?.contentSearch?.subtitleslanguages?.join(', '):  searchData?.subtitleslanguages?.join(', ') ?? ""},
                                                             
                                                             { label: 'Territories Available', value: searchData?.clientcontentSearch?.territoriesavailable ? searchData?.clientcontentSearch?.territoriesavailable?.join(', ') : searchData?.territoriesavailable?.join(', ') ?? "" },
                                                             
                                                             { label: 'Lead Artist', value: searchData?.contentSearch?.leadartist ?  searchData?.contentSearch?.leadartist :  searchData?.leadartist ?? "" },
                                                             
                                                             { label: 'League/Tournament', value: searchData?.contentSearch?.league_tournament ? searchData?.contentSearch?.league_tournament : searchData?.league_tournament ?? "" },
                                                             
                                                             { label: 'Lyricist', value: searchData?.contentSearch?.lyricist ? searchData?.contentSearch?.lyricist : searchData?.lyricist ?? "" },
                                                             
                                                             { label: 'Anchor', value: searchData?.contentSearch?.anchor ? searchData?.contentSearch?.anchor : searchData?.anchor ?? ""},
                                                             
                                                             { label: 'Other Artists', value: searchData?.contentSearch?.otherartists ? searchData?.contentSearch?.otherartists : searchData?.otherartists ?? ""},
                                                             
                                                             { label: 'Band Name', value: searchData?.contentSearch?.['band name'] ? searchData?.contentSearch?.['band name']  : searchData?.['band name'] ??""},
                                                             
                                                             { label: 'Chef', value: searchData?.contentSearch?.chef ? searchData?.contentSearch?.chef :  searchData?.chef ?? "" },
                                                             
                                                             { label: 'Meal Type', value: searchData?.contentSearch?.mealtype ? searchData?.contentSearch?.mealtype : searchData?.mealtype ?? ""},
                                                             
                                                             { label: 'No of seasons', value: searchData?.contentSearch?.noofseasons ? searchData?.contentSearch?.noofseasons : searchData?.noofseasons ?? ""},
                                                             
                                                             { label: 'No of episodes', value: searchData?.contentSearch?.noofepisodes ? searchData?.contentSearch?.noofepisodes : searchData?.noofepisodes ?? ""},
                                                             
                                                             { label: 'Music Genre', value: searchData?.contentSearch?.musicgenre ? searchData?.contentSearch?.musicgenre : searchData?.musicgenre ?? ""},
                                                             
                                                             { label: 'Cuisine', value: searchData?.contentSearch?.cuisine ?  searchData?.contentSearch?.cuisine :  searchData?.cuisine ?? "" },
                                                             
                                                             { label: 'Performance Type', value: searchData?.contentSearch?.performancetype ? searchData?.contentSearch?.performancetype: searchData?.performancetype??"" },
                                                             
                                                             { label: 'Sport', value: searchData?.contentSearch?.sport ? searchData?.contentSearch?.sport?.join(', ') : searchData?.sport?.join(', ') ?? "" },
                                                             
                                                             { label: 'Sports Star', value: searchData?.contentSearch?.sportsstars ? searchData?.contentSearch?.sportsstars : searchData?.sportsstars ?? ""},
                                                             
                                                             { label: 'Type of Rights', value: searchData?.clientcontentSearch?.typeofrights ? searchData?.clientcontentSearch?.typeofrights?.join(', ') : searchData?.typeofrights?.join(', ') ?? ""},
                                                             
                                                             { label: 'Video Quality', value: searchData?.contentSearch?.videoquality ? searchData?.contentSearch?.videoquality?.join(', ')  : searchData?.videoquality?.join(', ') ?? ""},
                                                             
                                                             { label: 'Resolution', value: searchData?.clientcontentSearch?.resolution ? searchData?.clientcontentSearch?.resolution?.join(', ')  : searchData?.resolution?.join(', ')  ?? ""},
                                                             
                                                             { label: 'Restriction Country', value: searchData?.clientcontentSearch?.restrictioncountry ?  searchData?.clientcontentSearch?.restrictioncountry?.join(', ')  :  searchData?.restrictioncountry?.join(', ') ?? ""},
                                                             
                                                             { label: 'Rights Available From Date', value: searchData?.clientcontentSearch?.rightsavailablefromdate ? 
                                                             new Date(searchData?.clientcontentSearch?.rightsavailablefromdate).toLocaleDateString('en-IN', { day: 'numeric', month: 'short', year: 'numeric' }) :  
                                                             searchData?.rightsavailablefromdate ?  new Date(searchData?.rightsavailablefromdate).toLocaleDateString('en-IN',{ day: 'numeric', month: 'short', year: 'numeric' }) : "" },
                                                             
                                                             { label: 'Rights Available To Date', value: searchData?.clientcontentSearch?.rightsavailabletodate ?
                                                              new Date(searchData?.clientcontentSearch?.rightsavailabletodate).toLocaleDateString('en-IN', { day: 'numeric', month: 'short', year: 'numeric' }) : searchData?.rightsavailabletodate ?
                                                              new Date(searchData?.rightsavailabletodate).toLocaleDateString('en-IN', 
                                                              { day: 'numeric', month: 'short', year: 'numeric' }) : ""},
                                                             
                                                              
                                                           
                                                            
                                                             { label: 'Analytics', value: searchData?.contentSearch?.analytics ? searchData?.contentSearch?.analytics : searchData?.analytics ?? ""},
                                                             { label: 'Availability of scripts', value: searchData?.contentSearch?.availabilityofscripts === true ? "True" : 
                                                             searchData?.contentSearch?.availabilityofscripts === false ? "false" :
                                                             searchData?.availabilityofscripts === true ? "True" : searchData?.availabilityofscripts === false ? "False" : "" },

                                                             { label: 'Availability of M&E', value: searchData?.contentSearch?.isMEavailable === true ? "True" :
                                                             searchData?.contentSearch?.isMEavailable === false ? "false" :  searchData?.isMEavailable  === true ? "True" :  searchData?.isMEavailable === false ? "false" : "" },
                                                             
                                                             { label: 'Keywords', value: searchData?.contentSearch?.keywords ? searchData?.contentSearch?.keywords : searchData?.keywords ?? ""},
                                                             { label: 'Production house', value: searchData?.contentSearch?.productionhouse ? searchData?.contentSearch?.productionhouse : searchData?.productionhouse ?? ""},
                                                             { label: 'Production year', value: searchData?.contentSearch?.productionyear ? searchData?.contentSearch?.productionyear : searchData?.productionyear ?? ""},
                                                             { label: 'Release Date', value: searchData?.contentSearch?.releasedate ?
                                                             
                                                             new Date(searchData?.contentSearch?.releasedate).toLocaleDateString('en-IN', { day: 'numeric', month: 'short', year: 'numeric' }) : searchData?.releasedate ?
                                                             new Date(searchData?.releasedate).toLocaleDateString('en-IN', 
                                                             { day: 'numeric', month: 'short', year: 'numeric' }) : ""},
                                                             { label: 'Synopsis', value: searchData?.contentSearch?.synopsis ? searchData?.contentSearch?.synopsis : searchData?.synopsis ?? ""},
                                                             { label: 'Writer', value: searchData?.contentSearch?.writer ? searchData?.contentSearch?.writer : searchData?.writer ?? ""},
                                                             { label: 'Remarks', value: searchData?.contentSearch?.remarks ? searchData?.contentSearch?.remarks : searchData?.remarks ?? ""},
                                                             { label: 'Producer', value: searchData?.contentSearch?.producer ? searchData?.contentSearch?.producer : searchData?.producer ?? ""},
                                                             { label: 'Music Director', value: searchData?.contentSearch?.musicdirector ? searchData?.contentSearch?.musicdirector : searchData?.musicdirector ?? ""},
                                                             { label: 'Foreign Title', value: searchData?.contentSearch?.foreigntitle ? searchData?.contentSearch?.foreigntitle : searchData?.foreigntitle ?? ""},
                                                             { label: 'Audio details', value: searchData?.contentSearch?.audiodetails ? searchData?.contentSearch?.audiodetails : searchData?.audiodetails ?? ""},
                                                             { label: 'Meal course type', value: searchData?.contentSearch?.mealcoursetype ? searchData?.contentSearch?.mealcoursetype : searchData?.mealcoursetype ?? ""},
                                                             { label: 'Recipe name', value: searchData?.contentSearch?.recipename ? searchData?.contentSearch?.recipename : searchData?.recipename ?? ""},
                                                             { label: 'Ingredients', value: searchData?.contentSearch?.ingredients ? searchData?.contentSearch?.ingredients : searchData?.ingredients ?? ""},
                                                             { label: 'Awards & recognition', value: searchData?.contentSearch?.awards_recognition ? searchData?.contentSearch?.awards_recognition : searchData?.awards_recognition ?? ""},
                                                             { label: 'Duration', value: searchData?.contentSearch?.duration ? searchData?.contentSearch?.duration : searchData?.duration ?? ""},
                                                             { label: 'Cusine', value: searchData?.contentSearch?.Cusine ? searchData?.contentSearch?.Cusine : searchData?.Cusine ?? ""},
                                                             { label: 'Location', value: searchData?.contentSearch?.location ? searchData?.contentSearch?.location : searchData?.location ?? ""},
                                                           
                                                             
                                                            //  { label: 'Show My Content', value: searchData?.showmycontent ? 'TRUE' : 'FALSE' },
                                                         ].filter(item => !!item.value).map((item, index) => (
                                                                <tr key={index}>
                                                                    <td className="align-middle">{item.label}</td>
                                                                    <td className="align-middle">{item.value}</td>
                                                                </tr>
                                                            ) )}
                                                     </tbody> 
 
 
 
                                                 </table>
                                             </div>
                                             </>:null}
                                         </div>
                                     </div>
                                 </div>
                             </div>
 
                         </div>
                     </div>
 
 
 
 
                 </div>
             </div>
         </>
     );
 };
 
 export default ViewClientSearchDynamic;
 