/***
**Module Name: sidebar
 **File Name :  sidebar.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains sidebar details.
 ***/
import React, { useState, useEffect, useContext } from "react";

import { useHistory, Link } from "react-router-dom";
import moment from "moment";
import { contentContext } from "../../context/contentContext";


const Sidebar = () => {

    const [manage, setManage] = useState(false);
    const [report, setReport] = useState(false);
    const [settings, setSettings] = useState(false);
    const [auction, setAuction] = useState(false);
    // const [activeMenuObj, setActiveMenuObj] = useState({});
    const history = useHistory();


    const { isLoading, setIsLoading, setActiveTab, flagg, setData, setBidsAdvSearch, setSaveLeadId, setlookUpType, setBidsSearch, setLookupSearch, setClientNameSearch, setFlagg, setActivePageName, setUserSearch, setCompanyAdvSearch, setCompanySearch, setClientMangeSearch, setDealSearch, setClientAdvSearch, setDealsAdvSearch, userData, setContent, setSearchPayload, setCategorySearch, setContentSearch, leadSearch, setFilterLeads, setLeadSearch, setActiveSearch, setUserData, activeMenuObj, setActiveMenuObj, activeMenuId, GetTimeActivity, toggleMenu, setToggleMenu, setActiveMenuId, setSortingPayload, setItemSearch } = useContext(contentContext)


    const handleMenu = (e, item) => {
        if (!isLoading && activeMenuId !== item.menuid) {
            setActiveTab("details")
            // console.log('handleMenu-------------->',item.menuid)

            setActiveMenuId(item.menuid)
            let id = item.menu.split(" ").join("").toLowerCase()
            GetTimeActivity()
            setFlagg("")
            setLeadSearch("")
            setActiveSearch("")
            setFilterLeads("")
            setContentSearch("")
            setCategorySearch("")
            setSearchPayload({})
            setClientAdvSearch({})
            setBidsSearch("")
            setItemSearch("");
            setDealSearch("")
            setSortingPayload("")
            setlookUpType("")
            setClientNameSearch("")
            setSaveLeadId("")
            setDealsAdvSearch({})
            setData([])
            setLookupSearch("")
            setClientMangeSearch("")
            setBidsAdvSearch({})
            setCompanyAdvSearch({})
            setCompanySearch("")
            setUserSearch("")
            setActivePageName("")
            setContent([])
            if (id == "servicesconfig") {
                history.push("/settings/serviceconfig");
            } else if (id == "appconfig") {
                history.push("/settings/" + id);
            }
            else {
                history.push("/" + id);
            }

        }
    }

    const handleAuction = (e) => {
        setAuction(!auction);
    }

    const handleItem = (e) => {
        history.push("/itemmanagement")
    }
    const handleBid = (e) => {
        history.push("/bids")
    }
    const handleBiddingLookup = (e) => {
        history.push("/biddinglookup")
    }
    const handleTerms = (e) => {
        history.push("/biddingterms")
    }
    function sortPermissions(permissions) {
        return permissions.sort((a, b) => a.menuid - b.menuid);
    }

    const handleActiveMenuObj = (e, name) => {
        GetTimeActivity()
        setActiveTab("details")
        setFlagg("")
        setLeadSearch("")
        setActiveSearch("")
        setFilterLeads("")
        setCategorySearch("")
        setDealsAdvSearch({})
        setContent([])
        setDealSearch("")
        setClientMangeSearch("")

        setBidsAdvSearch({})
        setClientNameSearch("")
        setLookupSearch("")
        setClientAdvSearch({})
        setCompanySearch("")
        setSearchPayload({})
        setlookUpType("")
        setCompanyAdvSearch({})
        setActivePageName("")
        setUserSearch("")
        // console.log('handleActiveMenuObj------------>',name)
        setActiveMenuObj({ ...activeMenuObj, [name]: !activeMenuObj[name] })

    }

    return (
        <>

            <div className={`vertical-menu ${toggleMenu ? 'menu-show' : 'menu-hide'}`}>

                <div data-simplebar className="h-100">

                    <div id="sidebar-menu">
                        <ul className="metismenu list-unstyled" id="side-menu">
                            {userData && userData.permissions && userData.permissions.length > 0 ? sortPermissions(userData.permissions).map((eachItem) => {
                                let isDisplay = eachItem.display;
                                let isEnable = eachItem.enable;
                                // console.log('active menu id',eachItem.menuid )
                                return (
                                    <>
                                        {isDisplay == true &&
                                            <li key={eachItem.menuid} className={`${eachItem.menuid === activeMenuId ? 'active' : ''} ${isLoading ? 'pe-none' : ''}`} onClick={(e) => eachItem.submenus == undefined ? handleMenu(e, eachItem) : handleActiveMenuObj(e, eachItem.menu)}>
                                                <a className={`${eachItem.submenus ? 'has-arrow' : ''} waves-effect`}>
                                                    <i className="material-symbols-outlined"> {eachItem.icon} </i>
                                                    <span key="t-dashboards">{eachItem.menu}</span>
                                                </a>
                                                {eachItem.submenus && eachItem.submenus.length > 0 &&
                                                    <ul className={activeMenuObj[eachItem.menu] ? "sub-menu mm-show active" : "sub-menu mm-collapse"} aria-expanded="false">
                                                        {eachItem.submenus.map((eachSubmenu) => <li key={eachSubmenu.menuid} className={`${eachSubmenu.menuid === activeMenuId ? 'active' : ''} ${isLoading ? 'pe-none' : ''}`} onClick={(e) => handleMenu(e, eachSubmenu)}><a>{eachSubmenu.menu}</a></li>)}
                                                    </ul>
                                                }
                                            </li>
                                        }
                                    </>
                                );
                            }) : null}
                            {/* <li onClick={handleAuction}>
        <a className="has-arrow waves-effect">
            <i className="material-symbols-outlined"> gavel </i>
            <span key="t-dashboards">Auction Management</span>
        </a>
        <ul className={auction ? "sub-menu mm-show" : "sub-menu mm-collapse"} aria-expanded="false">
            <li><a onClick={handleItem} key="t-tui-calendar">Item Management</a></li>
            <li><a onClick={handleBid} key="t-full-calendar">Bids</a></li>
            <li><a className="nav-link" onClick={handleBiddingLookup}>Bidding Lookups</a></li>
            <li><a onClick={handleTerms} key="t-full-calendar">Terms and Conditions</a></li>
        </ul>
    </li> */}
                        </ul>

                    </div>
                    <p className="activity_time">Action: {localStorage.getItem("timeActivity")}</p>

                </div>
            </div>

        </>
    );
};

export default Sidebar;
