/***
**Module Name: add/edit company 
 **File Name :  updatecompany.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains add or edit company details.
 ***/
import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/dashboard/footer";
import Header from "../../../components/dashboard/header";
import Sidebar from "../../../components/dashboard/sidebar";
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory, Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import SessionPopup from "../../SessionPopup"
import axios from "axios";
import moment from "moment";
import Loader from "../../../components/loader";
import FileViewer from "../../../components/docViewer";
import * as Config from "../../../constants/Config";
import tmdbApi from "../../../api/tmdbApi";
import { getListSubheaderUtilityClass } from "@mui/material";
import { contentContext } from "../../../context/contentContext";
let { lambda, country, appname } = window.app



const ViewCompany = () => {
    const history = useHistory();
    let { id } = useParams();
    const [nda, setNda] = useState(false);
    const [agreement, setAgreement] = useState(false);
    const [show, setShow] = useState(true);

    const [add, setAdd] = useState(false);
    const [countries, setCountries] = useState('');
    const [playContent, setPlayContent] = useState({});
    const [showDoc, setshowDoc] = useState(false);
    const [fileDocName, setFileDocName] = useState("");
    const [type, setType] = useState(false);
    const [image, setImg] = useState('');
    //  const [folowupcontent, setFollowupContent] = useState([]);
    const [user, setUser] = useState([]);
    const [uploadContent, setUploadContent] = useState({});

    const [account, setAccount] = useState([]);
    const [showSessionPopupup, setShowSessionPopupup] = useState(false);
    const [folowupcontent, setFollowupContent] = useState([]);
    const [editCompany, setEditCompany] = useState({ companyname: '', companytype: '', contenttype: '', address: '', region: '', city: '', country: '', zipcode: '', legalname: '', legalnumber: '', legalemail: '', accountname: '', accountemail: '', accountnumber: '', technicalname: '', technicalemail: '', technicalnumber: '', ctoname: '', ctoemail: '', ctonumber: '', ceoname: '', ceoemail: '', ceonumber: '', acquisitionsname: '', acquisitionsemail: '', acquisitionsnumber: '', syndicationname: '', syndicationemail: '', syndicationnumber: '', commission: '', commissiontype: '', accountmanager: '', status: 'INACTIVE', followup: '' });
    const [invalidContent, setInvalidContent] = useState(false);

    const { route, setRoute, setCurrentPage, setRowsPerPage, usePrevious, setActiveMenuId, setActiveMenuObj ,GetTimeActivity} = useContext(contentContext);

    const prevRoute = usePrevious(route)
    useEffect(() => {
        if (prevRoute != undefined && prevRoute != route) {
            setCurrentPage(1)
            setRowsPerPage(15)
        }
    }, [prevRoute]);

    useEffect(() => {

        if (window.site === undefined) {
            setTimeout(() => {
                if (window.site && window.site.common && window.site.common.imagesCDN) {
                    setImg(window.site.common.imagesCDN)
                }
            }, 1000);
        }
        if (window.site && window.site.common && window.site.common.imagesCDN) {
            setImg(window.site.common.imagesCDN)
        }

    }, [window.site]);

    const GetCountries = async () => {
        try {
            console.log(tmdbApi);
            const response = await tmdbApi.getLookUp({
                "type": ["country"],
                 "sortBy": "alpha3",
                "projection":"tiny"
            });

            if (response?.result.data == "Invalid token or Expired") {
                setShowSessionPopupup(true)
            } else {
                setCountries(response?.result.data);
            }
        } catch {
            console.log("error");
        }
    };
    useEffect(() => {
        if (!localStorage.getItem("token")) {
            history.push("/");
        }
        setRoute("company")
        setActiveMenuId(300002)
        setActiveMenuObj({
            "Client Management": true,
            "Contact Management":false,
            "Reports": false
        })
        if (id) {
            setAdd(false);
            getclient();
            getUsers();
            getFollowup();
        } else {
            setAdd(true);
        }
        GetCountries();
        userActivity();
    }, []);
    const userActivity = () => {
        let path = window.location.pathname.split("/");
        const pageName = path[path.length - 2];
        var presentTime = moment();
        let payload;

        payload = {
            "userid": localStorage.getItem("userId"),
            "pagename": pageName,
            "pageurl": window.location.href,
            "starttime": presentTime,
            "useragent": JSON.parse(localStorage.getItem("loc"))

        };


   const previousId = localStorage.getItem("previousid");
const urlLink = lambda + '/useractivity?appname=' + appname + (previousId ? "&previousid=" + previousId : "");


        axios({
            method: 'POST',
            url: urlLink,
            data: payload
        })
            .then(function (response) {
                if (response?.data?.statusCode === 200) {
                    localStorage.setItem("previousid", response?.data?.result)
                }
            });
    }
    const getUsers = (e) => {
        GetTimeActivity() 
        const token = localStorage.getItem("token")
        axios({
            method: 'GET',
            url: lambda + '/companyUsers?appname=' + appname + '&companyid=' + id + "&token=" + token,
        })
            .then(function (response) {
                if (response?.data?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    setUser(response?.data?.result?.data);
                }
            });
    }

    const getclient = (e) => {
        GetTimeActivity() 
        const token = localStorage.getItem("token")
        axios({
            method: 'GET',
            url: lambda + '/company?appname=' + appname + '&companyid=' + id + "&token=" + token,
        })
            .then(function (response) {
           
                if (response?.data?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    if (response?.data?.result.length > 0) {
                        // setEditCompany(response?.data?.result?.[0]);
                        const filterObj = response?.data?.result?.[0]

                        if (filterObj?.phone === "" || filterObj?.phone === undefined || filterObj?.phone === null || filterObj?.phone.trim() === "") {
                            const idcValue = `${filterObj?.phone === "" || filterObj?.phone === undefined || filterObj?.phone === null || filterObj?.phone.trim() === ""? "" : filterObj?.idc}`
                            const formvalues = { ...filterObj, idc: idcValue }
                           
                            setEditCompany(formvalues);
                        } else {
                            setEditCompany(filterObj);

                        }
                        setUploadContent(response?.data?.result?.[0]?.documents)
                        if (response?.data?.result?.[0]?.entity === "INDIVIDUAL") {
                            setShow(true);
                        }
                    } else {
                        setInvalidContent(true)
                    }
                }

            });
    }

    const onclickInvalid = () => {
        GetTimeActivity() 
        setInvalidContent(false)
        history.push('/company')
    }

    const getFollowup = (e) => {
        GetTimeActivity() 
        const token = localStorage.getItem("token")
        axios({
            method: 'GET',
            url: lambda + '/followup?appname=' + appname + '&companyid=' + id + "&token=" + token,
        })
            .then(function (response) {
                if (response?.data?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    setFollowupContent(response?.data?.result?.data);
                }


            });
    }


const countryCodeFun = (alphaValue)=>{
    let k =  countries && countries?.length>0 && countries?.filter(eachItem=>eachItem.alpha3===alphaValue)
    let Code = k&&k[0]&&k[0].countrycode != undefined ? (k&&k[0]&&k[0].countrycode).toString() : ""
    const idcValue = (alphaValue != "" && alphaValue != undefined ) ? alphaValue +''+ Code : "select";
    return idcValue
}


const handleOpen = (e, content) => {
    GetTimeActivity() 
    let source;
    source = window.site.common.imagesCDN + "/" + content.path;
    console.log("source", content, source)
    setFileDocName(content?.name)
    setPlayContent(source);
    setshowDoc(true);
    setType(content.type);
    console.log("playercontent==========>", source);
}


    const handleBack = () => {
        GetTimeActivity() 
        history.push({
            pathname: "/company",
            state: { search: true }
        });
    }


    return (
        <>
            {showSessionPopupup && <SessionPopup />}
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <SweetAlert show={invalidContent}
                    custom
                    confirmBtnText="ok"
                    confirmBtnBsStyle="primary"
                    title={"Company Not Found"}
                    onConfirm={e => onclickInvalid()}
                >
                </SweetAlert>
                {!invalidContent &&
                    <div className="main-content create-user edit-content add-client">

                        <div className="page-content ">
                            <div className="container-fluid">



                                <div className="row mb-4 breadcrumb">
                                    <div className="col-lg-12">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1">
                                                <h4 className="mb-2 card-title">VIEW COMPANY</h4>
                                                <p className="menu-path">Company Management / <b>View Company</b></p>
                                            </div>
                                            <div>
                                                <a onClick={handleBack} className="btn btn-primary">back</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="create-user-block">

                                    <div className="form-block">
                                        <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <a className="nav-link active" data-bs-toggle="tab" href="#home1" role="tab" aria-selected="true">
                                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                                    <span className="d-none d-sm-block">DETAILS</span>
                                                </a>
                                            </li>
                                            {add === false ?
                                                <><li className="nav-item" role="presentation">
                                                    <a className="nav-link" data-bs-toggle="tab" href="#profile1" role="tab" aria-selected="false" tabIndex="-1">
                                                        <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                                        <span className="d-none d-sm-block">DOCUMENTS</span>
                                                    </a>
                                                </li>
                                                    <li className="nav-item" role="presentation">
                                                        <a className="nav-link" data-bs-toggle="tab" href="#messages1" role="tab" aria-selected="false" tabIndex="-1">
                                                            <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                                                            <span className="d-none d-sm-block">FOLLOW UP</span>
                                                        </a>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <a className="nav-link" data-bs-toggle="tab" href="#users" role="tab" aria-selected="false" tabIndex="-1">
                                                            <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                                                            <span className="d-none d-sm-block">USERS</span>
                                                        </a>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <a className="nav-link" data-bs-toggle="tab" href="#acmhistory" role="tab" aria-selected="false" tabIndex="-1">
                                                            <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                                                            <span className="d-none d-sm-block">ACM HISTORY</span>
                                                        </a>
                                                    </li>
                                                </>
                                                : ""}


                                        </ul>
                                        <div className="tab-content p-3 text-muted">
                                            <div className="tab-pane active show" id="home1" role="tabpanel">
                                            {Object.keys(editCompany).length > 0 && countries?.length >0 ? 
                                                <>
                                                <div className="row">
                                                    <h5 className="font-size-14"><i className="mdi mdi-arrow-right"></i>company</h5>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">Company Name</label>
                                                            <input className="form-control" type="text" name="companyname" value={editCompany?.companyname} disabled id="example-text-input" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">Contact Name</label>
                                                            <input className="form-control" disabled placeholder="Enter Contact Name" type="text" name="contactname" 
                                                              value={editCompany?.contactname} id="example-text-input" />
                                                            </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">Company Email</label>

                                                            <input className="form-control" placeholder="Enter Company Email" type="text" name="emailid" value={editCompany?.emailid} id="example-text-input" disabled />



                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">Company phone</label>
                                                            <div className="country-code">
                                                            <input className="form-control phonenumber" style={{
                                                                    width: "40%",
                                                                    borderRadius: "5px 0px 0px 5px"
                                                                }} type="email" value={countryCodeFun(editCompany?.idc)} id="example-email-input" disabled />

                                                            
                                                             <input className="form-control numberfiled" type="tel" name="personalphone" value={editCompany?.phone} maxLength="10" id="example-tel-input" disabled />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">Company type</label>
                                                            <input className="form-control" type="text" name="companyname" value={editCompany?.companytype} disabled id="example-text-input" />

                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">content type</label>
                                                            <input className="form-control" type="text" name="companyname" value={editCompany?.contenttype} disabled id="example-text-input" />

                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">Company Code</label>
                                                            <input className="form-control contact-number" name="companycode" type="text" disabled value={editCompany?.companycode === undefined || editCompany?.companycode === "" ? "" : editCompany?.companycode} id="example-email-input" />
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">address</label>
                                                            <input className="form-control contact-number" name="address" type="text" disabled value={editCompany?.address} id="example-email-input" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">region</label>
                                                            <input className="form-control contact-number" name="region" type="text" disabled value={editCompany?.region} id="example-email-input" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">city</label>
                                                            <input className="form-control contact-number" name="city" type="text" disabled value={editCompany?.city} id="example-email-input" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">country</label>


                                                            <input className="form-control numberfiled" type="tel" name="personalphone" value={editCompany?.country} maxLength="10" id="example-tel-input" disabled />

                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">zipcode</label>
                                                            <input className="form-control contact-number" type="text" disabled name="zipcode" value={editCompany?.zipcode} id="example-email-input" />
                                                        </div>
                                                    </div>
                                                    {editCompany?.companyLogo !== undefined && editCompany?.companyLogo !== "" &&
                                                    <div className="col-md-4 add_logo">
                                                        <div className="input-field">
                                                            <label className="col-form-label">logo</label>
                                                            <div className="d-flex align-items-center">
                                                          

                                                               
                                                              <div className="logo_img">
                                                              <img
                                                              src={window?.site?.common?.resourcesUrl + editCompany?.companyLogo}
                                                           
                                                              
                                                              alt="Image"/>
                                                              </div>
                                                         
                                                
                                                            </div>
                                                            </div>
                                                    </div> } 
                                                    <div className="col-md-4 add_partners">
                                                        <div className="input-field">
                                                        <label className="col-form-label">partners</label>
                                                        <div className="d-flex align-items-center switch-buttons mt-2">
                                                        <label class="switch">
                                                        <input type="checkbox" name="isPartner" checked={editCompany?.isPartner} disabled/>
                                                        <span class="slider round"></span>
                                                        </label>
                                                          
                                                            </div>
                                                        </div>
                                                      
                                                    </div>
                                                   
                                                </div>



                                                <div className="row">
                                                    <h5 className="font-size-14"><i className="mdi mdi-arrow-right"></i>legal contact</h5>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">legal contact name</label>
                                                            <input className="form-control" type="text" name="legalname" disabled value={editCompany?.legalname} id="example-text-input" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">legal contact email</label>
                                                            <input className="form-control contact-number" type="email" name="legalemail" disabled value={editCompany?.legalemail} id="example-email-input" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">legal contact number</label>
                                                            <div className="country-code">
                                                                <input className="form-control phonenumber" style={{
                                                                    width: "40%",
                                                                    borderRadius: "5px 0px 0px 5px"
                                                                }} type="email" value={countryCodeFun(editCompany?.legalcontactnumberidc)} id="example-email-input" disabled />


                                                                <input className="form-control numberfiled" type="tel" name="legalcontactnumber" value={editCompany?.legalcontactnumber} maxLength="10" id="example-tel-input" disabled />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div><div className="row">
                                                    <h5 className="font-size-14"><i className="mdi mdi-arrow-right"></i>Accounting Contact</h5>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">account contact name</label>
                                                            <input className="form-control" type="text" name="accountname" disabled value={editCompany?.accountname} id="example-text-input" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">account contact email</label>
                                                            <input className="form-control contact-number" type="email" name="accountemail" disabled value={editCompany?.accountemail} id="example-email-input" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">account contact number</label>
                                                            <div className="country-code">

                                                                <input className="form-control phonenumber" style={{
                                                                    width: "40%",
                                                                    borderRadius: "5px 0px 0px 5px"
                                                                }} type="email" value={countryCodeFun(editCompany?.accountcontactnumberidc)} id="example-email-input" disabled />


                                                                <input className="form-control numberfiled" type="tel" name="accountcontactnumber" value={editCompany?.accountcontactnumber} maxLength="10" id="example-tel-input" disabled />


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div><div className="row">
                                                    <h5 className="font-size-14"><i className="mdi mdi-arrow-right"></i>Technical Contact</h5>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">technical contact name</label>
                                                            <input className="form-control" type="text" name="technicalname" disabled value={editCompany?.technicalname} id="example-text-input" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">technical contact email</label>
                                                            <input className="form-control contact-number" type="email" name="technicalemail" disabled value={editCompany?.technicalemail} id="example-email-input" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">technical contact number</label>
                                                            <div className="country-code">

                                                                <input className="form-control phonenumber" style={{
                                                                    width: "40%",
                                                                    borderRadius: "5px 0px 0px 5px"
                                                                }} type="email" value={countryCodeFun(editCompany?.technicalcontactnumberidc)} id="example-email-input" disabled />


                                                                <input className="form-control numberfiled" type="tel" name="technicalcontactnumber" value={editCompany?.technicalcontactnumber} maxLength="10" id="example-tel-input" disabled />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div><div className="row">
                                                    <h5 className="font-size-14"><i className="mdi mdi-arrow-right"></i>CTO</h5>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">cto name</label>
                                                            <input className="form-control" type="text" name="ctoname" disabled value={editCompany?.ctoname} id="example-text-input" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">cto email</label>
                                                            <input className="form-control contact-number" type="email" name="ctoemail" disabled value={editCompany?.ctoemail} id="example-email-input" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">CTO Number</label>
                                                            <div className="country-code">



                                                                <input className="form-control phonenumber" style={{
                                                                    width: "40%",
                                                                    borderRadius: "5px 0px 0px 5px"
                                                                }} type="email" value={countryCodeFun(editCompany?.ctonumberidc)} id="example-email-input" disabled />


                                                                <input className="form-control numberfiled" type="tel" name="ctonumber" value={editCompany?.ctonumber} maxLength="10" id="example-tel-input" disabled />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div><div className="row">
                                                    <h5 className="font-size-14"><i className="mdi mdi-arrow-right"></i>CEO</h5>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">ceo name</label>
                                                            <input className="form-control" type="text" name="ceoname" disabled value={editCompany?.ceoname} id="example-text-input" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">ceo email</label>
                                                            <input className="form-control contact-number" type="email" name="ceoemail" disabled value={editCompany?.ceoemail} id="example-email-input" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">ceo number</label>
                                                            <div className="country-code">
                                                                <input className="form-control phonenumber" style={{
                                                                    width: "40%",
                                                                    borderRadius: "5px 0px 0px 5px"
                                                                }} type="email" value={countryCodeFun(editCompany?.ceonumberidc)} id="example-email-input" disabled />


                                                                <input className="form-control numberfiled" type="tel" name="ceonumber" value={editCompany?.ceonumber} maxLength="10" id="example-tel-input" disabled />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div><div className="row">
                                                    <h5 className="font-size-14"><i className="mdi mdi-arrow-right"></i>Head of Acquisitions</h5>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">Head of Acquisitions Name</label>
                                                            <input className="form-control" type="text" name="acquisitionsname" disabled value={editCompany?.acquisitionsname} id="example-text-input" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">Head of Acquisitions Emai</label>
                                                            <input className="form-control contact-number" type="email" name="acquisitionsemail" disabled value={editCompany?.acquisitionsemail} id="example-email-input" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">Head of Acquisitions Number</label>
                                                            <div className="country-code">

                                                                <input className="form-control phonenumber" style={{
                                                                    width: "40%",
                                                                    borderRadius: "5px 0px 0px 5px"
                                                                }} type="email" value={countryCodeFun(editCompany?.acquisitionsnumberidc)} id="example-email-input" disabled />


                                                                <input className="form-control numberfiled" type="tel" name="acquisitionsnumber" value={editCompany?.acquisitionsnumber} maxLength="10" id="example-tel-input" disabled />



                                                            </div>
                                                        </div>
                                                    </div>
                                                </div><div className="row">
                                                    <h5 className="font-size-14"><i className="mdi mdi-arrow-right"></i>Head of Syndication</h5>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">Head of Syndication Name</label>
                                                            <input className="form-control" type="text" name="syndicationname" disabled value={editCompany?.syndicationname} id="example-text-input" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">Head of Syndication Email</label>
                                                            <input className="form-control contact-number" type="email" name="syndicationemail" disabled value={editCompany?.syndicationemail} id="example-email-input" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="input-field">
                                                            <label className="col-form-label">Head of Syndication Number</label>
                                                            <div className="country-code">

                                                                <input className="form-control phonenumber" style={{
                                                                    width: "40%",
                                                                    borderRadius: "5px 0px 0px 5px"
                                                                }} type="email" value={countryCodeFun(editCompany?.syndicationnumberidc)} id="example-email-input" disabled />


                                                                <input className="form-control numberfiled" type="tel" name="syndicationnumber" value={editCompany?.syndicationnumber} maxLength="10" id="example-tel-input" disabled />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="row">
                                                    <h5 className="font-size-14"><i className="mdi mdi-arrow-right"></i>Commission</h5>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label className="col-form-label">commission</label>
                                                            <input className="form-control contact-number" type="number" name="commission" disabled value={editCompany?.commission} id="example-email-input" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field">
                                                            <label className="col-form-label">commission type</label>
                                                            <input className="form-control" type="text" name="commissiontype" disabled value={editCompany?.commissiontype} id="example-text-input" />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row-separte">
                                                    <div className="row inner-separate">
                                                        <h5 className="font-size-14"><i className="mdi mdi-arrow-right"></i>Account Manager</h5>
                                                        <div className="col-md-12">
                                                            <div className="input-field">
                                                                <label className="col-form-label">account manager</label>
                                                                <input className="form-control" type="text" name="accountmanager" disabled value={editCompany?.accountmanager} id="example-text-input" />


                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row inner-separate">
                                                        <h5 className="font-size-14"><i className="mdi mdi-arrow-right"></i>Signed</h5>
                                                        <div className="col-md-12 switch-buttons-block">
                                                            <div className="input-field switch-buttons  pe-none d-flex align-items-center">
                                                                <label className="col-form-label">NDA</label>
                                                                <label className="switch disable"><input type="checkbox" className="disable pe-none" checked={editCompany?.signednda} disabled /><span className="slider round"></span></label>
                                                            </div>


                                                            <div className="input-field switch-buttons pe-none d-flex align-items-center">
                                                                <label className="col-form-label">Agreement</label>
                                                                <label className="switch disable"><input type="checkbox"  checked={editCompany?.agreement} className="disable pe-none" disabled /><span className="slider round"></span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="row comment-section created">
                                                        {editCompany?.created &&
                                                            <div className="col-md-4">
                                                                <label className="col-form-label">Created On</label>
                                                                <input className="form-control" placeholder="Enter Name" type="text" value={new Date(editCompany?.created).toLocaleDateString('en-IN', {
                                                                    day: 'numeric',
                                                                    month: 'short',
                                                                    year: 'numeric',
                                                                    hour: 'numeric',
                                                                    minute: 'numeric',
                                                                })} id="example-text-input" disabled />
                                                            </div>
                                                        }
                                                        {editCompany?.activatedOn &&
                                                            <div className="col-md-4">
                                                                <label className="col-form-label">Actived On</label>
                                                                <input className="form-control" placeholder="Enter Name" type="text" value={new Date(editCompany?.activatedOn).toLocaleDateString('en-IN', {
                                                                    day: 'numeric',
                                                                    month: 'short',
                                                                    year: 'numeric',
                                                                    hour: 'numeric',
                                                                    minute: 'numeric',
                                                                })} id="example-text-input" disabled />
                                                            </div>
                                                        }

                                                    </div>

                                                <div className="row status">

                                                    <div className="col-md-3 justify-content-between ps-0">

                                                        <div className="input-field d-flex align-items-center">

                                                            <label className="col-form-label">Status</label>

                                                            <input className="form-control" type="text" name="status" disabled value={editCompany?.status} id="example-text-input" />



                                                        </div>

                                                    </div>

                                                    <div className="col-md-3 justify-content-between ps-0">
                                                    </div>


                                                </div>
                                                </>
                                                :  <div className="row"><Loader /></div>}
                                            </div>
                                            <div className="tab-pane" id="profile1" role="tabpanel">
                                               
                                                <div className="row mb-3 document-list">
                                                {uploadContent && uploadContent.length > 0 ?
                                                    <div className="row mb-3 document-list">
                                                        {/* <div>No Documents Found</div> */}
                                                        {uploadContent && uploadContent.length > 0 && uploadContent.map(function (item, i) {
                                                            return (
                                                                <div className="column5">
                                                                    <div className="document-cont">
                                                                        <div className="document-img" onClick={(e) => handleOpen(e, item)}>
                                                                            <a><img className="documentlogo" src={image+"orasi/common/images/doc-defaulr1.jpg?" + Config.compress}  /></a>
                                                                        </div>
                                                                        <div className="documentTitle">
                                                                            <h3>{item.name}</h3>
                                                                            <p>updated on {item.uploaded_on} </p>
                                                                            {/* <button type="button" className="btn-success btn-rounded waves-effect waves-light mb-2 me-2" onClick={(e) => handleDeleteDoc(e,item.id)}>
                                                                                <span className="material-icons-outlined"> delete_outline</span></button> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}

                                                    </div>
                                                    : <div className="no-documents">
                                                        <span className="material-icons-outlined">description</span>
                                                        <p>No document has been found yet !</p>
                                                    </div>


                                                }
                                                    {/* <div div className="followups"><div className="no-documents">
                                                        <span className="material-icons-outlined">description</span>
                                                        <p>No document has been found yet !</p>
                                                    </div></div> */}
                                                  
                                                </div>


                                            </div>
                                            <div className="tab-pane" id="messages1" role="tabpanel">
                                                <div className="mb-3 row">

                                                    {folowupcontent && folowupcontent.length > 0 ?
                                                        <div className="col-md-12">
                                                            <label >FOLLOW UP</label>
                                                            {folowupcontent && folowupcontent.length > 0 && folowupcontent.map(function (item, i) {

                                                                return (
                                                                    <div className="comments-block" key={i}>
                                                                        <p className="time">{item.created ? new Date(item.created).toLocaleDateString('en-IN', {
                                                                            day: 'numeric',
                                                                            month: 'short',
                                                                            year: 'numeric',
                                                                            hour: 'numeric',
                                                                            minute: 'numeric',
                                                                        }) : ""}</p>
                                                                        <p className="comments">{item.followup}</p>
                                                                    </div>
                                                                )
                                                            })}

                                                        </div> : <div div className="followups"><div className="no-documents">
                                                            <span className="material-icons-outlined">comment</span>
                                                            <p>No follow-ups were found</p>
                                                        </div></div>}



                                                </div>
                                            </div>
                                            <div className="tab-pane" id="users" role="tabpanel">
                                                {user && user.length > 0 ?
                                                <div className="table-responsive">
                                                    <table className="table align-middle table-nowrap table-check">
                                                        <thead className="table-light">
                                                            <tr>

                                                                <th className="align-middle">NAME</th>
                                                                <th className="align-middle">Email</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {user && user.length > 0 && user.map(function (item, i) {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>{item.name}</td>
                                                                        <td>{item.personalemail}</td>
                                                                    </tr>
                                                                )
                                                            })}

                                                        </tbody>

                                                    </table>
                                                    </div>
                                                    : <div div className="followups"><div className="no-documents">
                                                        <span className="material-icons-outlined"><span className="material-icons-outlined">people</span></span>
                                                        <p>No users were found</p>
                                                    </div></div>}
                                            </div>
                                            <div className="tab-pane" id="acmhistory" role="tabpanel">
                                                {editCompany?.acmHistory &&editCompany?.acmHistory.length > 0 ?
                                                    <div className="table-responsive">
                                                        <table className="table align-middle table-nowrap table-check">
                                                            <thead className="table-light">
                                                                <tr>

                                                                    <th className="align-middle">NAME</th>
                                                                    <th className="align-middle">Activate From</th>
                                                                    <th className="align-middle">Activate To</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {editCompany?.acmHistory &&editCompany?. acmHistory.length > 0 && editCompany?.acmHistory
                                                                    .sort((a, b) => new Date(b.activedFrom) - new Date(a.activedFrom))
                                                                    .map((item, i) => {
                                                                        return (
                                                                            <tr key={i}>
                                                                                <td>{item.accountManagerName}</td>
                                                                                <td>
                                                                                    {item.activedFrom
                                                                                        ? new Date(item.activedFrom).toLocaleDateString('en-IN', {
                                                                                            day: 'numeric',
                                                                                            month: 'short',
                                                                                            year: 'numeric',
                                                                                        })
                                                                                        : ''}
                                                                                </td>
                                                                                <td>
                                                                                    {item.activedTo
                                                                                        ? new Date(item.activedTo).toLocaleDateString('en-IN', {
                                                                                            day: 'numeric',
                                                                                            month: 'short',
                                                                                            year: 'numeric',
                                                                                        })
                                                                                        : ''}
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })}

                                                            </tbody>

                                                        </table>
                                                    </div>

                                                    : <div div className="followups"><div className="no-documents">
                                                        <span className="material-icons-outlined"><span className="material-icons-outlined">people</span></span>
                                                        <p>No history were found</p>
                                                    </div></div>}
                                            </div>
                                        </div>

                                    </div>

                                </div>


                            </div>
                        </div>

                        <Footer />
                        {showDoc && <FileViewer source={playContent} type={type} close={setshowDoc} name={fileDocName}/>}
                    </div>
                }



            </div>
        </>
    );
};

export default ViewCompany;
