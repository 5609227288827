import React from "react";
import { Route, Switch } from "react-router-dom";
import AddUser from "../pages/usermanagement/addUser";
import ContentMange from "../pages/contentmanagement/contentmanage";
import CreatePassword from "../pages/createPassword";
import EditUser from "../pages/usermanagement/editUser";
import NotFound from "../pages/NotFound";
import SignIn from "../pages/signin";
import UserManagement from "../pages/usermanagement/userMange";
import ViewUser from "../pages/usermanagement/viewUser";
import ContentUpload from "../pages/contentmanagement/contentupload";
import Client from "../pages/clientmanagement/client";

import Profile from "../pages/profile";
import Company from "../pages/clientmanagement/company/company";
import EditClient from "../pages/clientmanagement/editclient";
import MapContent from "../pages/contentmanagement/mapcontentnew";
import EditContent from "../pages/contentmanagement/editContent";
import ViewClient from "../pages/clientmanagement/viewClient";
import UpdateCompany from "../pages/clientmanagement/company/updateCompany";
import ViewCompany from "../pages/clientmanagement/company/viewCompany";
import Category from "../pages/categorymanagement/category";
import LookUp from "../pages/lookup/lookup";
import EditLookUp from "../pages/lookup/editLookUp";
import ClientSearch from "../pages/reports/clientSearch/clientSearch"
import ViewClientSearch from "../pages/reports/clientSearch/viewClientSearch";

import AllDeals from "../pages/dealmanagement/allDeals";
import Recommend from "../pages/reports/recommended/recommendNew";
import Contact from "../pages/reports/contactus/contactus";
import VideoRequest from "../pages/reports/videoRequests/videoRequest";
import AMReport from "../pages/reports/AMReport/AMReport";
import EditDeals from "../pages/dealmanagement/editDeal";
import BlockBuyer from "../pages/blockBuyer/blockBuyer";
import FrontendSettings from "../pages/frontendSettings/frontendSettings";
import LandingPage from "../pages/frontendSettings/landingPage";
import AdvancedSettings from "../pages/frontendSettings/advancedSettings";
import AppConfig from '../pages/settings/appConfig';
import Privacy from "../pages/frontendSettings/privacy";
import Terms from "../pages/frontendSettings/terms";
import AddDeals from "../pages/dealmanagement/addDeal";
import EditCategory from "../pages/categorymanagement/editCategory";
import ViewContent from "../pages/contentmanagement/viewContent";
import ViewCategory from "../pages/categorymanagement/viewCategory";
import ViewDeals from "../pages/dealmanagement/viewDeals";
import Team from "../pages/frontendSettings/team";
import Aboutus from "../pages/frontendSettings/aboutus";
import ViewLookUp from "../pages/lookup/viewLookup";
import ViewTerms from "../pages/frontendSettings/viewTerms";
import ViewPrivacy from "../pages/frontendSettings/ViewPrivacy";
import ViewTeam from "../pages/frontendSettings/ViewTeam";
import ViewAboutus from "../pages/frontendSettings/ViewAboutus";
import ViewAdvancedSettings from "../pages/frontendSettings/ViewAdvancedSettings";
import ViewLandingPage from "../pages/frontendSettings/ViewLandingPage";
import EditBlockBuyer from "../pages/blockBuyer/editBlockBuyer";
import Changecategoryorder from "../pages/categorymanagement/changeCategoryOrder";
import EmailLogs from "../pages/reports/emailLogs/emailLogs";
import ClientActivity from "../pages/reports/ClientActivity/ClientActivity";
import ClientSessions from "../pages/reports/ClientSessions/ClientSessions";
import TitleAnalysis from "../pages/reports/TitleAnalysis/TitleAnalysis";
// import ContentMange2 from "../pages/contentmanagement/contentManage2";

import AdminSessions from "../pages/reports/AdminSessions/AdminSessions";
import ViewClientActivity from "../pages/reports/ClientActivity/ViewClientActivity";
import AllLeads from "../pages/leadmanagement/allLeads.jsx";
import DashBoard from "../pages/dashboard/dashboard.jsx";

import ItemManage from "../pages/bidding/itemmanagement/itemmanagement";
import EditItem from "../pages/bidding/itemmanagement/edititem";
import BidsDashBoard from "../pages/bidding/itemmanagement/bidsdashboard";
import BiddingLookUp from "../pages/bidding/biddinglookups/biddingLookup";
import Test from "../pages/bidding/test";
import EditBiddingLookUp from "../pages/bidding/biddinglookups/editlookup";
import ViewBiddingLookUp from "../pages/bidding/biddinglookups/viewbiddinglookup";
import BiddingTerms from "../pages/bidding/biddingterms";
import ViewTitleBids from "../pages/bidding/itemmanagement/viewTitleBids";
import AllBids from "../pages/bidding/bids/bids";
import ViewBidsSummary from "../pages/bidding/bids/bidssummary";
import ViewItem from "../pages/bidding/itemmanagement/viewitem";
import EditItemV2 from "../pages/bidding/itemmanagement/edititemV2";
import LeadReports from "../pages/reports/LeadReports/leadReports.jsx";
import News from "../pages/news/news.jsx";

import EditNews from "../pages/news/editNews.jsx";

const Routes = () => {
  return (
    <Switch>

      <Route path={'/'} exact component={SignIn} />
      <Route path={`/dashboard`} component={DashBoard} />
      <Route path={`/createpassword`} component={CreatePassword} />
      <Route path={`/login`} component={SignIn} />

      <Route path={`/company`} component={Company} />
      <Route path={`/editcompany/:id`} component={UpdateCompany} />
      <Route path={`/viewcompany/:id`} component={ViewCompany} />
      <Route path={`/addcompany`} component={UpdateCompany} />


      <Route path={`/clientsearch`} component={ClientSearch} />
      <Route path={`/leadsreports`} component={LeadReports} />
      <Route path={`/team`} component={Team} />
      <Route path={`/viewclientsearch/:id`} component={ViewClientSearch} />

      <Route path={`/recommended`} component={Recommend} />
      <Route path={`/emaillogs`} component={EmailLogs} />
      <Route path={`/contactus`} component={Contact} />
      <Route path={`/accountmanagers`} component={AMReport} />
      <Route path={`/videorequests`} component={VideoRequest} />
      <Route path={`/clientactivity`} component={ClientActivity} />
      <Route path={`/viewclientactivity/:id`} component={ViewClientActivity} />
      <Route path={`/clientsessions`} component={ClientSessions} />
      <Route path={`/adminsessions`} component={AdminSessions} />
      <Route path={`/titleanalysis`} component={TitleAnalysis} />

      <Route path={`/categorymanagement`} component={Category} />
      <Route path={`/editcategory/:id`} component={EditCategory} />
      <Route path={`/viewcategory/:id`} component={ViewCategory} />
      <Route path={`/addcategory`} component={EditCategory} />
      <Route path={`/blockbuyer`} component={BlockBuyer} />
      <Route path={`/addblockbuyer`} component={EditBlockBuyer} />
      <Route path={`/editblockbuyer`} component={EditBlockBuyer} />

      <Route path={`/usermanagement`} component={UserManagement} />
      <Route path={`/createuser`} component={AddUser} />
      <Route path={`/adduser`} component={EditUser} />

      <Route path={`/edituser/:id`} component={EditUser} />
      <Route path={`/viewuser/:id`} component={ViewUser} />

      <Route path={`/contentmanagement`} component={ContentMange} />
      {/* <Route path={`/contentmanagement2`} component={ContentMange2} /> */}
      <Route path={`/contentupload`} component={ContentUpload} />
      <Route path={`/editcontent/:id`} component={EditContent} />
      <Route path={`/viewcontent/:id`} component={ViewContent} />

      <Route path={`/addcontent`} component={EditContent} />
      <Route path={`/contentmap`} component={MapContent} />

      <Route path={`/settings/:id`} component={AppConfig} />


      <Route path={`/dealmanagement`} component={AllDeals} />
      <Route path={`/leadmanagement`} component={AllLeads} />
      <Route path={`/editdeals/:id`} component={EditDeals} />
      <Route path={`/viewdeals/:id`} component={ViewDeals} />
      <Route path={`/createdeal`} component={AddDeals} />
      <Route path={`/frontendsettings`} component={FrontendSettings} />
      <Route path={`/landingpage`} component={LandingPage} />
      <Route path={`/advancedsearch`} component={AdvancedSettings} />
      <Route path={`/privacypolicy`} component={Privacy} />
      <Route path={`/terms`} component={Terms} />
      <Route path={`/viewterms`} component={ViewTerms} />
      <Route path={`/viewprivacypolicy`} component={ViewPrivacy} />
      <Route path={`/viewteam`} component={ViewTeam} />
      <Route path={`/viewaboutus`} component={ViewAboutus} />
      <Route path={`/viewlandingpage`} component={ViewLandingPage} />
      <Route path={`/viewadvancedsearch`} component={ViewAdvancedSettings} />
      <Route path={`/aboutus`} component={Aboutus} />

      <Route path={`/profile`} component={Profile} />
      <Route path={`/changeCategoryOrder`} component={Changecategoryorder} />

      <Route path={`/newsmanagement`} component={News} />
      <Route path={`/editnews/:id`} component={EditNews} />
      <Route path={`/addnews`} component={EditNews} />

      <Route path={`/clients`} component={Client} />
      <Route path={`/editclient/:id`} component={EditClient} />
      <Route path={`/viewclient/:id`} component={ViewClient} />
      <Route path={`/addclient`} component={EditClient} />

      <Route path={`/additem`} component={EditItem} />
      <Route path={`/itemmanagement`} component={ItemManage} />
      <Route path={`/edititem/:id`} component={EditItem} />
      <Route path={`/edititemV1/:id`} component={EditItemV2} />
      <Route path={`/viewitem/:id`} component={ViewItem} />
      <Route path={`/itembids/:id`} component={BidsDashBoard} />
      <Route path={`/biddinglookups`} component={BiddingLookUp} />
      <Route path={`/termsandconditions`} component={BiddingTerms} />
      <Route path={`/viewtitlebids/:id`} component={ViewTitleBids} />
      <Route path={`/test`} component={Test} />

      <Route path={`/lookups`} component={LookUp} />
      <Route path={`/editlookup/:id`} component={EditLookUp} />
      <Route path={`/viewlookup/:id`} component={ViewLookUp} />
      <Route path={`/addlookup`} component={EditLookUp} />
      <Route path={`/bids`} component={AllBids} />
      <Route path={`/viewbidsummary/:id`} component={ViewBidsSummary} />

      <Route path={`/editbiddinglookup/:id`} component={EditBiddingLookUp} />
      <Route path={`/addbiddinglookup`} component={EditBiddingLookUp} />
      <Route path={`/viewbiddinglookup/:id`} component={ViewBiddingLookUp} />


      <Route path={`*`} component={NotFound} />




    </Switch>
  );
};

export default Routes;
