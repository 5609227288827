/***
**Module Name: not found
 **File Name :  notfound.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains page not found details.
 ***/
import React, { useState, useEffect, useContext, useRef } from "react";
import Footer from "../../components/dashboard/footer";
import Header from "../../components/dashboard/header";
import Sidebar from "../../components/dashboard/sidebar";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import $ from 'jquery';
import { contentContext } from "../../context/contentContext";
import TableLoader from "../../components/tableLoader";
import tmdbApi from "../../api/tmdbApi";
import moment from "moment";
import asyncLoop from "node-async-loop";
import { updateContentData, AddItem, getContentFiles, updateBiddingItems, getItemFiles, CountryCombination, getCombination, SaveCombination, UpdateCombination, getCombinationSearch, getCombinationAdvSearch, setDefault } from "../../utils/reducer";
import PlayerInfo from "../../components/player";
import FileViewer from "../../components/docViewer";
import Modal from 'react-bootstrap/Modal';
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from "../../components/loader";
import SessionPopup from "../SessionPopup";
import ReactQuill from 'react-quill';
import DataTable from 'react-data-table-component';
import 'react-quill/dist/quill.snow.css';
let { lambda, appname } = window.app;

const useQuillMixin = (defaultValue = '') => {
    const [value, setValue] = useState(defaultValue);
    const [isValidEditor, setIsValid] = useState(true);

    useEffect(() => {
        // Perform any additional setup or side effects here
        console.log('Quill setup completed');
    }, []);

    const handleChange = (content, delta, source, editor) => {
        setValue(content);
        if (content && content.length > 0) {
            var tmpdetails = content
            tmpdetails = tmpdetails.replace("<p>", "").trim()
            tmpdetails = tmpdetails.replace("</p>", "").trim()
            console.log("tmpdetails", tmpdetails);
            if (tmpdetails == "<br>" || tmpdetails == "") {
                setIsValid(true)
            } else {
                setIsValid(false)
            }
        }
    };


    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'list': 'bullet' }],
            ['bold', 'italic', 'underline', 'strike', 'link'],
        ],
    };

    const formats = [
        // Add your formats here if needed
        // Example: 'bold', 'italic', 'underline', 'strike'
    ];

    const quillElement = (
        <ReactQuill
            theme="snow"
            // modules={{ toolbar: true }}
            formats={['header', 'list', 'bold', 'italic', 'underline', 'strike', 'link']}
            value={value}
            onChange={handleChange}
            modules={modules}
        //  formats={formats}
        />
    );

    return { quillElement, value, setValue, isValidEditor };
};

const EditNews = () => {
    let { id } = useParams();
    let videoClipcategories = ['VIDEOS',
        'THUMBNAILS',
        'DOCUMENTS',
    ]
    const history = useHistory();
    const ref = useRef();

    const [editcontent, setEditContent] = useState({});
    const [contentTitle, setContentTitle] = useState("");
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedOptionsgenre, setSelectedOptionsgenre] = useState([]);
    const [lookup, setLookUp] = useState({});
    const [selectedOptionscountryOfOrigin, setSelectedOptionscountryOfOrigin] = useState([]);
    const [countryData, setCountryData] = useState({});
    const [regionsData, setRegionsData] = useState({});
    const [revenueData, setRevenueData] = useState([]);
    const [typesofrights, setTypesofrights] = useState([]);
    const [exclusivityData, setExclusivityData] = useState([]);
    const [BtnLoader, setBtnLoader] = useState(false);
    const [BtnLoader1, setBtnLoader1] = useState(false);
    const { categoryName, Categories, catBasedContentFields } = useContext(contentContext);
    const [image, setImg] = useState('');
    const [filesData, setFilesData] = useState([]);
    const [errors, setErrors] = useState({});
    const [play, setPlay] = useState(false);
    const [playContent, setPlayContent] = useState({});
    const [showPreview, setShowPreview] = useState(false);
    const [previewImg, setPreviewImg] = useState("");
    const [showDoc, setshowDoc] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [subSuccess, setSubSuccess] = useState(false);
    const [AddSuccess, setAddSuccess] = useState(false);
    const [excededfile, setExcededFile] = useState(false);
    const [excededdocumentfile, setDocumentExcededFile] = useState(false);
    const [videoexcededfile, setVideoExcededFile] = useState(false);
    const [isdelete, setDelete] = useState(false);
    const [isdeleteImage, setIsdeleteImage] = useState(false);
    const [item, setItem] = useState("");
    const [fileCategory, setFileCategory] = useState("");
    const [lookupSuccess, setLookupSuccess] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [fileName, setFileName] = useState("");
    const [showUpload, setShowUpload] = useState(false);
    const [showSessionPopupup, setShowSessionPopupup] = useState(false);
    const [invalidContent, setInvalidContent] = useState(false);
    const [type, setType] = useState(false);
    const [activeTab, setActiveTab] = useState('info');
    const [isInfoFormValid, setIsInfoFormValid] = useState(false);

    const [uploadType, setUploadType] = useState('');
    const [activeTrailerId, setActiveTrailerId] = useState('');
    const [playerSubTitles, setPlayerSubTitles] = useState('');

    const [delPopup, setDelPopup] = useState(false);
    const [delSuccess, setDelSuccess] = useState(false);
    const [delSeller, setDelSeller] = useState(false);

    const [uploadsuccess, setUploadSuccess] = useState(false);
    const [isCompExist, setIsCompExist] = useState(false);
    const [isTitleExist, setIsTitleExist] = useState(false);
    const [uploadObj, setUploadedObj] = useState({});

    const [countryArray, setCountryArray] = useState([]);
    const [initialFlag, setInitialFlag] = useState(false);
    const [addComb, setAddComb] = useState(false);
    const [updateComb, setUpdateComb] = useState(false);
    const [validateFiles, setValidateFiles] = useState(false);
    const [advancedsearchpopup, setShowAdvancedSearchPopup] = useState(false);
    const [enableEdit, setEnableEdit] = useState(false);
    const [combinationArray, setCombinationArray] = useState(null);
    const [tableChange, setTableChange] = useState(false);
    const [search, setSearch] = useState({});
    const [percentage, setPercentage] = useState(0);
    const [refreshTable, setRefreshTable] = useState(false);
    const [advancedSearch, setAdvancedSearch] = useState({});
    const [editorFlag, setEditorFlag] = useState(false);
    // const [itemId, setItemId] = useState("");
    const [combinationData, setCombinationData] = useState("");
    const [previousstatus, setPreviousStatus] = useState("");
    const [comparecombination, setCompareCombination] = useState([]);

    const [fileCheck, setFileCheck] = useState("");
    const [selectCategoryName, setSelectCategoryName] = useState("select All");
    const [selectRegionName, setSelectRegionName] = useState("select All");
    const [defaultShow, setDefaultShow] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);
    const [docError, setDocError] = useState(false);
    const [visibleData, setVisibleData] = useState([]);
    const [ischeck, setIsCheck] = useState(false);
    const [showResetPopup, SetShowResetPopup] = useState(false);
    const itemsPerPage1 = 30;
    const currentPage1 = useRef(1);
    const isLoading1 = useRef(false);
    const tableRef = useRef(null);
    const [checkExist, setCheckExist] = useState(false);
    const [checkCombination, setCheckCombination] = useState([]);
    const [minbidprice, setMinBidPrice] = useState("");
    const [resetKey, setResetKey] = useState(0);
    const [iserrorpopup, setIsErrorPopup] = useState(false);
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [combinationLoader, setCombinationLoader] = useState(false);
    const [itemid, setItemId] = useState("");
    const [images, setImages] = useState([]);
    const [defaultImg, setDefaultImage] = useState("");
    const [statusFlag, setStatusFlag] = useState(false);
    const [deleteComb, setDeleteComb] = useState(false);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [deleteAlert1, setDeleteAlert1] = useState(false);
    const[itemToDelete,setitemToDelete]=useState("")
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const { quillElement, value, setValue, isValidEditor } = useQuillMixin();
    // console.log('catBasedContentFieldscatBasedContentFields',catBasedContentFields)
    const { route, setRoute, setCurrentPage, setActiveMenuObj, setRowsPerPage, usePrevious, userData, setActiveMenuId, rowsPerPage, currentPageNew, isLoading, setIsLoading, currentCPageNew, setCurrentCPage, rowsPerCPage, setRowsPerCPage } = useContext(contentContext);
    // let fields = [];

    const prevRoute = usePrevious(route)
    useEffect(() => {
        if (prevRoute != undefined && prevRoute != route) {
            setCurrentCPage(1)
            setRowsPerCPage(15)
        }
    }, [route]);
    console.log('prevRoute--->', prevRoute)
    console.log('currentRoute--->', route)
    useEffect(() => {
        console.log("excuted");
        if (!(localStorage.getItem("token"))) {
            console.log('tokaen excte');
            history.push("/");
            localStorage.setItem("check", id);
        }
        //  console.log('content excte');
        //  setRoute("item")
        //  setActiveMenuId(300021)
        //  setActiveMenuObj({
        //      "Contact Management": false,
        //      "Client Management": false,
        //      "Reports": false,
        //      "Auction Management": true
        //  })


        if (!id) {
            var ObjectID = require("bson-objectid");
            var id = new ObjectID().toString();
            setItemId(id);
            localStorage.setItem("itemid", id)
        }
        //  renderDynamicCategoryFields();


    }, []);

    useEffect(() => {
        if (id) {
            getNews()
        }
        console.log('before calling');


    }, [id]);
    useEffect(() => {

        if (window.site === undefined) {
            setTimeout(() => {
                if (window.site && window.site.common && window.site.common.imagesCDN) {
                    setImg(window.site.common.imagesCDN)
                }
            }, 1000);
        }
        if (window.site && window.site.common && window.site.common.imagesCDN) {
            setImg(window.site.common.imagesCDN)
        }

    }, [window.site]);

    useEffect(() => {
        setEnableEdit(false);
    }, [combinationArray && combinationArray.length <= 0]);




    const getNews = async () => {
        const token = localStorage.getItem("token");
        const userid = localStorage.getItem("userId");

        try {
            setIsLoading2(true); // Start loading

            // Make API call
            const response = await axios.post(`${lambda}/news?appname=${appname}&token=${token}&userid=${userid}&newsid=${id}`);

            console.log("S3 Response: ", response);

            if (response?.data?.result === "Invalid token or Expired") {
                setShowSessionPopupup(true); // Handle invalid token or expired session
            } else {
                // Set content data if response is valid
                const newsData = response?.data?.result?.data?.[0];
                setEditContent(newsData);
                setValue(newsData?.description);
                setDefaultImage(newsData?.defaultimage);
            }

            setIsLoading2(false); // Stop loading after API call
        } catch (error) {
            console.error("Error during API call:", error);
            setIsLoading2(false); // Stop loading in case of error
        }
    };






    const handleBack = (e) => {
        if (id) {
            history.push({
                pathname: "/newsmanagement",
                state: { search: true }
            });
        } else {
            history.push("/newsmanagement")
        }
    }



    console.log("status", statusFlag);
    const handleChange = (e, editor) => {

        if (!!errors[e.target.name]) {
            let error = Object.assign({}, errors);

            delete error[e.target.name];
            setErrors(error);
        }


       if (e.target.name === "linkedin") {
            setEditContent({
                ...editcontent,
                socialmedia: {
                    ...editcontent.socialmedia,
                    linkedin: e.target.value,
                },
            });
        }else if (e.target.name === "linkedin") {
            setEditContent({
                ...editcontent,
                socialmedia: {
                    ...editcontent.socialmedia,
                    linkedin: e.target.value,
                },
            });
        }else if (e.target.name === "facebook") {
            setEditContent({
                ...editcontent,
                socialmedia: {
                    ...editcontent.socialmedia,
                    facebook: e.target.value,
                },
            });
        }else if (e.target.name === "twitter") {
            setEditContent({
                ...editcontent,
                socialmedia: {
                    ...editcontent.socialmedia,
                    twitter: e.target.value,
                },
            });
        }else if (e.target.name === "youtube") {
            setEditContent({
                ...editcontent,
                socialmedia: {
                    ...editcontent.socialmedia,
                    youtube: e.target.value,
                },
            });
        }
         else {
            setEditContent({ ...editcontent, [e.target.name]: e.target.value });
        }

    }




    const returnArray = (arr) => {
        let arr2 = []
        arr.map((eachItem) => {
            arr2.push(eachItem.value)
        })
        // console.log(arr2)
        return arr2
    }






    const handleAddFile = (e, item) => {
        console.log("item", item);
        console.log("type", type);
        setFileCategory(item);
        setUploadType(type);
        setShowUpload(true);
        setFileName("")
    }    



    const handleClose = (e) => {
        if (BtnLoader1) {
            setShowPopup(true)
        } else {
            setShowUpload(false);
        }
        setShowUpload(false);
        setValidateFiles(false)
        setFileName("");
        ref.current.value = "";
        setExcededFile(false)
        setDocumentExcededFile(false)
        setVideoExcededFile(false)
    }


    const onCancelDelete = () => {
        setIsdeleteImage(false)
        // setItem("")
    }
    const confirmDeleteImage = (e) => {
        setIsdeleteImage(false)
        console.log("Item to delete:", itemToDelete);
        console.log("imagessssssssssssssss", editcontent)
        const filterImgs = editcontent?.images?.filter(
            (item) => item?.filepath !== itemToDelete?.filepath
        );
        setEditContent({ ...editcontent, images: filterImgs })
        setDefaultImage(filterImgs?.[0]?.filepath)
        console.log("Filtered Images:", filterImgs);
       
        //  imageDeleteFun()
    }
    // console.log('render editcontent',editcontent)


    function onCancel() {
        setDelete(false)
        setDelSeller(false)
    }





    const uploadS3 = async (e, type) => {
        console.log("type---------", type);

        let file = e.target.files[0];  // Get the first file from the input
        console.log("fileeeeeeeeeeeeeeeeee", file);

        if (!file) {
            console.error("No file selected");
            return;
        }

        let checkformat = file.name.split(".");
        let checkfileformat = checkformat[checkformat.length - 1];
        setBtnLoader1(true);

        // Check file size and file category
        if(!file.type.startsWith("image/")){
            setBtnLoader1(false);
            setValidateFiles(true)
        }else
        if (file.size >= 10000000) {
            setExcededFile(true);
            setBtnLoader1(false);
            return;
        }else{
            var ObjectID = require("bson-objectid");
            var fileid = new ObjectID().toString();
            const timestamp = Date.now();
    
            // Build the uploadFilePath using the necessary variables
            let path = itemid ? `news/${itemid}` : `news/${id}`;
            let uploadFilePath = `${appname}/${path}/${fileid}_THUMBNAILS_${timestamp}.${checkfileformat}`;
    
            const newImageObj = {
                id: fileid,
                filename: file.name,
                filepath: uploadFilePath,
                filetype: file.type,
                fileformat: checkfileformat,
                timestamp: timestamp
            };
    
            console.log("uploadFilePath", uploadFilePath);
    
            // Prepare S3 data
            const bucket = window.site.common.resourceBucket;
            const data = { source_bucket: bucket, sourcepath: uploadFilePath };
            setUploadedObj(data);
    
            const token = localStorage.getItem("token");
            const userid = localStorage.getItem("userId");
    
            try {
                // First API call to prepare for the upload
                const response = await axios.post(`${lambda}/uploadFiles?appname=${appname}&token=${token}&userid=${userid}`, data, { type: 'application/json' });
                console.log("S3 Response: ", response.data.result);
    
                if (response.data && response.data.result) {
                    const url = response.data.result;
    
                    // Perform the file upload to the S3 URL
                    const uploadResponse = await axios.put(url, file, {
                        headers: {
                            "Content-Type": file.type,
                            "Cache-Control": "no-cache",
                            "Connection": "keep-alive",
                            "cache-control": "no-cache"
                        }
                    });
    
                    if (uploadResponse.status === 200) {
                        console.log("File uploaded successfully to S3");
    
                        // Append the new image object to the existing array of images
                        const now = new Date();
                        const currentDateTime = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}:${now.getSeconds().toString().padStart(2, '0')}`;
    
                        let uploadObj = {
                            appname: appname,
                            itemfileid: fileid,
                            itemid: itemid || id,
                            name: fileName,  // Use file.name instead of undefined variable
                            contentType: "THUMBNAILS",
                            filename: file.name,
                            sourcepath: uploadFilePath,
                            mimetype: checkfileformat,
                            created: currentDateTime,
                            filetype: checkfileformat.toUpperCase(),
                            createdBy: { userid, username: localStorage.getItem("ClientName") }
                        };
    
                        setEditContent(prevState => {
                            const updatedImages = [...(prevState?.images || []), newImageObj];
    
                            // Set the default image only if it's the first image
                            if (!prevState?.images || prevState.images.length === 0) {
                                setDefaultImage(newImageObj.filepath);
                            }
    
                            return {
                                ...prevState,
                                images: updatedImages
                            };
                        });
                        setExcededFile(false)
                        console.log("Upload Object: ", uploadObj);
    
                        // Reset loader states
                        setBtnLoader1(false);
                    }
                }
                setShowUpload(false);
            } catch (error) {
                console.error("Error during upload:", error);
                setBtnLoader1(false);
            }
        }

       
    };


    console.log("imagessssssssssssssssss", editcontent)



    // function onUploadCancel(e) {
    //     uploadObj.abort();
    //     setShowPopup(false)
    // }
    // const handleUploadFile = () => {
    //     setBtnLoader1(false)
    //     setShowUpload(false)
    //     setShowPopup(false)
    // }
    const filteredArray = filesData && filesData.filter(obj => obj.contentType === 'THUMBNAILS' && obj.status != 'archive');
    console.log("filteredArray", filteredArray)


    // const removeDuplicates = (arr1, arr2) => {
    //     console.log("updatedArrayOfObjects", arr1, "comparecombination", arr2);
    //     setIsLoading(true)
    //     let res = [];
    //     res = arr1.filter(el => {
    //         return !arr2.find(element => {
    //             return element.country === el.country && element.typesofrights === el.typesofrights && element.revenuetype === el.revenuetype && element.exclusivity === el.exclusivity;
    //         });
    //     });
    //     setIsLoading(false)
    //     return res;

    // }
    const removeDuplicates = (arr1, arr2) => {
        // Define a helper function to compare the specified fields
        const isDuplicate = (a, b) => {
            return a.country === b.country &&
                a.typesofrights === b.typesofrights &&
                a.exclusivity === b.exclusivity;
        };

        // Filter out items from arr2 that match any item in arr1
        const filteredArray2 = arr1.filter(item2 =>
            !arr2.some(item1 => isDuplicate(item1, item2))
        );

        return filteredArray2;
    };

    function checkObjectProperties(obj) {
        for (const key in obj) {
            if (countryArray[key].length > 0) {
                return false
            }

        }
        return true; // All key-value pairs exist in the object
    }
    function checkAdvObjectProperties(obj) {
        for (const key in obj) {
            if (advancedSearch && advancedSearch[key]?.length > 0) {
                return false
            }

        }
        return true; // All key-value pairs exist in the object
    }

    const loadMoreData = () => {
        console.log("checkCombination1", checkCombination)
        if (checkCombination && checkCombination.length > 30) {
            console.log("checkCombination2", checkCombination)
            isLoading1.current = true;
            const nextPageStart = currentPage1.current * itemsPerPage1;
            const nextPageEnd = nextPageStart + itemsPerPage1;
            const nextData = checkCombination.slice(nextPageStart, nextPageEnd);
            console.log("nextData", nextData)
            if (nextData.length > 0 && checkCombination && checkCombination.length > 30) {
                setVisibleData((prevVisibleData) => [...prevVisibleData, ...nextData]);
                currentPage1.current++;
                isLoading1.current = false;
            }
        }
    };

    console.log("visible", visibleData);


    const handleSearch = () => {
        document.getElementById("orderidcheck01").checked = false;
        setIsLoading(true)
        setCombinationArray(null)
        setAdvancedSearch({})
        let itemId;
        if (!id) {
            itemId = localStorage.getItem("itemid")
        } else {
            itemId = id
        }
        getCombinationSearch(itemId, search).then((data) => {
            console.log("getClientcontent data", data);
            setIsLoading(false)
            if (data.statusCode == 200) {
                console.log(data, "data------------->")
                if (data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {

                    let tmpData = data.result.data.map((obj) => ({
                        ...obj,
                        isSelected: false
                    }));
                    setCombinationData(tmpData);
                }
            }
        }).catch((error) => {
            console.log(error);
        })

    }
    const handleAdvSearch = (e) => {
        setCombinationArray(null)
        for (const key in advancedSearch) {
            if (advancedSearch.hasOwnProperty(key)) {

                if (advancedSearch[key] === null || advancedSearch[key] === undefined || advancedSearch[key] === '' || advancedSearch[key].length <= 0) {
                    delete advancedSearch[key];
                }
            }
        }
        if (advancedSearch["min"] != undefined) {
            advancedSearch["min"] = parseInt(advancedSearch["min"]);
        }
        if (advancedSearch["max"] != undefined) {
            advancedSearch["max"] = parseInt(advancedSearch["max"]);
        }
        if (advancedSearch["period"] != undefined) {
            advancedSearch["period"] = parseInt(advancedSearch["period"]);
        }

        setBtnLoader1(true)
        setSearch({ ...search, country: "" })
        console.log("advancedSearch", advancedSearch)
        let itemId;
        if (!id) {
            itemId = localStorage.getItem("itemid")
        } else {
            itemId = id
        }
        getCombinationAdvSearch(itemId, advancedSearch).then((data) => {
            setBtnLoader1(false)
            console.log("getClientcontent data", data);
            setShowAdvancedSearchPopup(false)
            if (data.statusCode == 200) {
                console.log(data, "data------------->")
                if (data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {

                    let tmpData = data.result.data.map((obj) => ({
                        ...obj,
                        isSelected: false
                    }));
                    setCombinationData(tmpData);
                    document.getElementById("orderidcheck01").checked = false;
                }
            }
        }).catch((error) => {
            console.log(error);
        })

    }

    const customNoRecords = () => {
        return (

            <div className="empty-state-body empty-record"  >
                <div className="empty-state__message">
                    <span className="material-icons">summarize</span>
                    <p className="form-check font-size-16">No combinations were found for the searched keyword</p>
                </div> </div>
        )
    }
    const customNoRecords1 = () => {
        return (

            <div className="empty-state-body empty-record"  >
                <div className="empty-state__message">
                    <span className="material-icons">summarize</span>
                    <p className="form-check font-size-16">Combinations already exist for the searched keyword</p>
                </div> </div>
        )
    }
    console.log("editcontent", combinationData)
    const handleCalculate = (e) => {


        if (percentage > 0) {
            const keyToUpdate = "minbidprice";
            const updatedArray = combinationData.map((obj) => {
                // Create a copy of the object to avoid mutating it directly
                const updatedObject = { ...obj };

                // Check if the object has the specified key
                if (updatedObject.hasOwnProperty(keyToUpdate)) {
                    // Update the value for the key
                    let value = updatedObject[keyToUpdate] * percentage / 100;

                    updatedObject[keyToUpdate] = Math.round(updatedObject[keyToUpdate] + value)
                }

                return updatedObject;
            });
            setCombinationData(updatedArray);
            setVisibleData(updatedArray)
            setRefreshTable(true);
            setPercentage("")

        }
        if (percentage < 0) {
            const keyToUpdate = "minbidprice";
            const updatedArray = combinationData.map((obj) => {
                const updatedObject = { ...obj };

                if (updatedObject.hasOwnProperty(keyToUpdate)) {
                    let value = updatedObject[keyToUpdate] * (-percentage) / 100;
                    updatedObject[keyToUpdate] = Math.round(updatedObject[keyToUpdate] - value);

                }

                return updatedObject;
            });
            setCombinationData(updatedArray);
            setVisibleData(updatedArray)
            setRefreshTable(true);
            setPercentage("")
        }
    }

    const handleEditCalculate = (e) => {

        if (percentage > 0) {
            const keyToUpdate = "minbidprice";
            const updatedArray = combinationData.map((obj) => {
                // Create a copy of the object to avoid mutating it directly
                const updatedObject = { ...obj };

                // Check if the object has the specified key
                if (updatedObject.hasOwnProperty(keyToUpdate) && updatedObject.hasOwnProperty('isSelected') && updatedObject.isSelected === true) {
                    // Update the value for the key
                    let value = updatedObject[keyToUpdate] * percentage / 100;
                    console.log(value)
                    updatedObject[keyToUpdate] = Math.round(updatedObject[keyToUpdate] + value)
                }

                return updatedObject;
            });
            //   console.log("updatedObject",updatedArray);

            setCombinationData(updatedArray);
            //   setRefreshTable(true);
            setPercentage("");

        }
        if (percentage < 0) {
            const keyToUpdate = "minbidprice";
            const updatedArray = combinationData.map((obj) => {
                const updatedObject = { ...obj };

                if (updatedObject.hasOwnProperty(keyToUpdate)) {
                    let value = updatedObject[keyToUpdate] * (-percentage) / 100;
                    console.log(value)
                    updatedObject[keyToUpdate] = Math.round(updatedObject[keyToUpdate] - value);

                }

                return updatedObject;
            });

            //  console.log("updatedArray", updatedArray)

            setCombinationData(updatedArray);
            //  setRefreshTable(true);
            setPercentage("");
        }
    }

    const handleChangePrice = (e, key, index) => {
        let updatedObject = {}
        if (refreshTable === true) {
            setRefreshTable(false)
        }
        let numberValue = e.target.value?.replace(/\D/g, '');
        //let numberValue =parseInt(e.target.value)
        if (numberValue) {
            updatedObject = { ...key, minbidprice: parseInt(numberValue) };
        } else {
            updatedObject = { ...key, minbidprice: '' };
        }
        console.log("index ", index)
        let newArr = [...combinationData]; // copying the old datas array
        newArr[index] = updatedObject; // replace e.target.value with whatever you want to change it to
        setCombinationData(newArr);
    }

    const handleChangePrice1 = (e, key, index, page) => {
        let updatedObject = {}
        if (refreshTable === true) {
            setRefreshTable(false)
        }
        let numberValue = e.target.value?.replace(/\D/g, '');
        //let numberValue =parseInt(e.target.value)
        if (numberValue) {
            updatedObject = { ...key, minbidprice: parseInt(numberValue) };
        } else {
            updatedObject = { ...key, minbidprice: '' };
        }

        let cindex
        if (page === 1) {
            cindex = index
        } else {
            cindex = index + (15 * (page - 1))
        }
        console.log("index ", cindex)
        let newArr = [...combinationData]; // copying the old datas array
        console.log("newArr@@@ ", newArr)
        newArr[cindex] = updatedObject; // replace e.target.value with whatever you want to change it to
        setCombinationData(newArr);
    }




    const onUpdateConfirm = () => {
        setEnableEdit(false);
        setCombinationArray(null);
        setUpdateComb(false)
    }

    const handleEnableEdit = () => {
        setEnableEdit(true)
    }
    const handleEnableDelete = () => {
        // let filteredIds = combinationData.filter(item => item.isSelected)
        // console.log("filteredIds", filteredIds)
        // if (filteredIds.length === combinationData.length && editcontent.status === "ACTIVE") {
        //     setDeleteAlert1(true)
        // }
        // else {
        setDeleteComb(true)
        //}
    }
    const onCancel1 = () => {
        //setDeleteAlert1(false);
        document.getElementById("orderidcheck01").checked = false;
        setCombinationLoader(true)
        getCombination(id).then((data) => {
            console.log("getClientcontent data", data);
            if (data.statusCode == 200) {
                console.log(data, "data------------->")
                if (data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                    setIsLoading2(false)
                } else {
                    if (data.result.data.length > 0) {

                        let tmpData = data.result.data.map((obj) => ({
                            ...obj,
                            isSelected: false
                        }));
                        setCombinationData(tmpData);
                        setCheckExist(true)
                        const keysToFilter = ['country', 'typesofrights', 'exclusivity', 'revenuetype'];

                        const newArray = tmpData.map(obj => {
                            const filteredObj = {};

                            keysToFilter.forEach(key => {
                                if (obj.hasOwnProperty(key)) {
                                    filteredObj[key] = obj[key];
                                }
                            });

                            return filteredObj;
                        });
                        // console.log("newArray",newArray);
                        setCompareCombination(newArray);

                    }
                    setCombinationLoader(false)

                }
            }
        }).catch((error) => {
            console.log(error);
        })
    }




    const clearSearch = () => {

    }



    let selectedItems = [];
    const onSelected = (e, row) => {
        let obj = {
            "combinationid": row?.combinationid,
            "minbidprice": row.minbidprice
        }
        let selectedItemsCopy = [...selectedItems];
        if (e.target.checked) {
            selectedItemsCopy.push(obj);
            if (combinationArray && combinationArray.length > 0) {
                setCombinationArray((prevArray) => [...prevArray, obj]);
            } else {
                setCombinationArray([obj]);
            }
        } else {
            //  setCombinationArray([]);
            let filterObj = [];
            filterObj = combinationArray && combinationArray.filter(function (el) { return el.combinationid != obj.combinationid });
            setCombinationArray(filterObj);
        }
        selectedItems = [...selectedItemsCopy];
        let tmpData = [...combinationData];
        tmpData = tmpData.map((itm) => {
            if (itm?.combinationid === row.combinationid) {
                itm.isSelected = e.target.checked
            }
            return itm;
        });
        setCombinationData(tmpData)
    }

    const onSelectAll = (e) => {
        selectedItems = [];
        let tmpData = [...combinationData];
        let isSelected = e.target.checked;
        if (isSelected) {
            tmpData.forEach(item => {
                let obj = {
                    "combinationid": item?.combinationid,
                    "minbidprice": item.minbidprice
                }
                selectedItems.push(obj)
            })
        }
        tmpData = tmpData.map((obj) => ({
            ...obj,
            isSelected: isSelected
        }));
        setCombinationData(tmpData);
        // console.log("tmpData",selectedItems);
        setCombinationArray(selectedItems);
    }
    const handleKeyDown = (event) => {
        // Prevent the minus sign from being entered
        if (event.key === '-' || event.key === '–') {
            event.preventDefault();
        }
    };
    // console.log("edit@@@",enableEdit)
    // console.log("combinationArray", combinationData)

    const columns = [
        {
            name: 'country',
            selector: row => row.country,
            sortable: !initialFlag,
        },
        {
            name: 'region',
            selector: row => row.region && row.region.length > 1 ? row.region.join(", ") : row.region,
            sortable: !initialFlag,
        },
        {
            name: 'types of rights',
            selector: row => row.typesofrights,
            sortable: !initialFlag,
        },
        {
            name: 'revenue',
            // cell: (props) => <img src={props.image} width={60} alt='Image' />,
            selector: row => row.revenuetype,
            sortable: !initialFlag,

        },

        {
            name: 'exclusivity',
            selector: row => row.exclusivity,
            // cell: (props) => <div className="text-elipsis">{props.comments}</div>,
            sortable: !initialFlag,
        },
        {
            name: 'period',
            // selector: row => moment(row.updated).utc().format("DD-MMM-YYYY HH:mm"),
            selector: row => row.period + " year",

        },


        {
            name: <>{'min price (usd)'}</>,
            selector: (row, index) => {


                if (initialFlag === true) {
                    return (<div className="d-flex price-input">
                        {/* <span className="input-group-text">$</span> */}
                        <input type="number" class="form-control" inputMode="numeric" min="1" aria-label="Amount (to the nearest dollar)" onKeyDown={handleKeyDown} onChange={(e) => handleChangePrice(e, row, index)} value={refreshTable ? row.minbidprice : null} />
                    </div>)
                }
                else if (enableEdit === true && row.isSelected) {
                    return (
                        <div className="d-flex price-input">
                            {/* <span className="input-group-text">$</span> */}
                            <input type="number" class="form-control" inputMode="numeric" min="1" aria-label="Amount (to the nearest dollar)"
                                value={row.minbidprice}
                                onKeyDown={handleKeyDown} onChange={(e) => handleChangePrice1(e, row, index, currentCPageNew)} />
                        </div>
                    )
                } else if (refreshTable) {
                    return (<div className="d-flex price-input">
                        {/* <span className="input-group-text">$</span> */}
                        <input type="number" class="form-control" inputMode="numeric" min="1" aria-label="Amount (to the nearest dollar)" onKeyUp={(e) => handleChangePrice(e, row, index)} onKeyDown={handleKeyDown} value={row.minbidprice} />
                    </div>)
                }
                else {
                    return (<><div>
                        {/* <span className="input-group-text">$ */}
                        {row.minbidprice}
                        {/* </span> */}
                    </div></>)
                }
            }
            ,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];
    if (initialFlag === false) {
        columns.unshift({
            name: <div class="form-check font-size-16">
                <input class="form-check-input" type="checkbox" id="orderidcheck01" onClick={(e) => onSelectAll(e)} />
                <label class="form-check-label" for="orderidcheck01"></label>
            </div>,
            selector: (row, index) => <div class="form-check font-size-16">
                <input class="form-check-input" type="checkbox" checked={row?.isSelected} onClick={(e) => onSelected(e, row)} />
                <label class="form-check-label" for="orderidcheck01"></label>
            </div>,
        });
    }
    const handlePublish = async () => {
        const isValid = validateMandatoryFields()
        const data = { ...editcontent, description: value, defaultimage: defaultImg??"" };
        const token = localStorage.getItem("token");
        const userid = localStorage.getItem("userId");

        if (isValid) {
            try {
                setBtnLoader(true)
                // First API call to prepare for the upload
                const response = await axios.put(`${lambda}/addNews?appname=${appname}&token=${token}&userid=${userid}`, data, { type: 'application/json' });
                console.log("S3 Response: ", response);
                if (response?.data?.result === "news added") {
                    setAddSuccess(true)
                } else if (response?.data?.result === "title name already exists") {
                    setIsTitleExist(true)
                }

                setBtnLoader(false)

            } catch (error) {
                console.error("Error during upload:", error);
                setBtnLoader1(false);
            }
        }


    }

    const handleUpdate = async () => {
        const data = { title: editcontent?.title,socialmedia:editcontent?.socialmedia, images: editcontent?.images, status: editcontent?.status, description: value, defaultimage: defaultImg ?? "" };
        const token = localStorage.getItem("token");
        const userid = localStorage.getItem("userId");
        const isValid = validateMandatoryFields()
        if (isValid) {
            try {

                setBtnLoader(true)
                // First API call to prepare for the upload
                const response = await axios.post(`${lambda}/updateNews?appname=${appname}&token=${token}&userid=${userid}&newsid=${id}`, data, { type: 'application/json' });
                console.log("S3 Response: ", response);

                setBtnLoader(false)
                if (response?.data?.result === "Updated Successfully") {
                    setSuccess(true)
                } else if (response?.data?.result === "title name already exists") {
                    setIsTitleExist(true)
                }

            } catch (error) {
                console.error("Error during upload:", error);
                setBtnLoader1(false);
            }
        }

    }

    const handleDeleteImg = (item) => {
        setIsdeleteImage(true)
   setitemToDelete(item)
      

        // You may want to update the state with the filtered images if needed
        // setEditContent({ ...editcontent, images: filterImgs });
    };





    const handlePreview = () => {
        setShowPreview(false)
    }
    //  var myDate = new Date(editcontent.releasedate);



    const cardWidth = $(".basic-info-img").width();




    const onclickInvalid = () => {
        setInvalidContent(false)
        history.push('/itemmanagement')
    }
    // console.log('sellerContent',sellerContent)
    const closeSuccess = () => {
        setAddSuccess(false);
        setBtnLoader(false);
        history.push("/newsmanagement")

    }
    const handleDefault = (item) => {
        console.log("defaulttttttttttttttttttt", item)
        setDefaultImage(item?.filepath)
    }
    const validateMandatoryFields = () => {
        let formIsValid = true;
        let error = { ...errors };
        const linkedinRegex = /^(https:\/\/www\.linkedin\.com\/.*)$/;
        const facebookRegex = /^(https:\/\/www\.facebook\.com\/.*)$/;
          const youtubeRegex = /^(https:\/\/www\.youtube\.com\/.*)$/;
           const twitterRegex =/^https:\/\/x\.com\/.*$/; ;

        // Validate title field
        if (!editcontent || !editcontent.title) {
            error["title"] = "Title is required";
            formIsValid = false;
        }
        if (editcontent?.socialmedia?.linkedin && !linkedinRegex.test(editcontent.socialmedia.linkedin)) {
            error["linkedin"] = "Linkedin URL is invalid";
            formIsValid = false;
        }
       
        if (editcontent?.socialmedia?.facebook && !facebookRegex.test(editcontent.socialmedia.facebook)) {
            error["facebook"] = "Facebook URL is invalid";
            formIsValid = false;
        }
        if (editcontent?.socialmedia?.twitter && !twitterRegex.test(editcontent.socialmedia.twitter)) {
            error["twitter"] = "Twitter URL is invalid";
            formIsValid = false;
        }
        if (editcontent?.socialmedia?.youtube && !youtubeRegex.test(editcontent.socialmedia.youtube)) {
            error["youtube"] = " Youtube URL is invalid";
            formIsValid = false;
        }

        if (value != undefined) {
            var tmpdetails = value
            tmpdetails = tmpdetails.replace("<p>", "").trim()
            tmpdetails = tmpdetails.replace("</p>", "").trim()
            console.log("tmpdetails", tmpdetails);
            if (tmpdetails == "<br>" || tmpdetails == "") {
                error["description"] = "Description is required";
                formIsValid = false;
            }
        }


        if (!editcontent || !editcontent.status) {
            error["status"] = "Status is required";
            formIsValid = false;
        }
        // Validate bidstartdate (uncomment if needed)
        // if (editcontent.bidstartdate && moment(editcontent.bidstartdate).isBefore(moment().format('YYYY-MM-DD'))) {
        //     error["bidstartdate"] = "Bid start date must be a future date";
        //     formIsValid = false;
        // }

        console.log("error", error);
        setErrors(error);

        return formIsValid;
    };

    console.log("checkExist", editcontent.status);

    const customStyles = {
        control: (styles) => ({
            ...styles,
            // Add custom control styles if needed
        }),
        // Add more custom styles for other components as needed
    };
console.log("editcontentttttttttttttt",editcontent)
    console.log("ad", advancedSearch)
    return (
        <>
            {showSessionPopupup && <SessionPopup />}
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <SweetAlert show={invalidContent}
                    custom
                    confirmBtnText="ok"
                    confirmBtnBsStyle="primary"
                    title={"Items Not Found"}
                    onConfirm={e => onclickInvalid()}
                >
                </SweetAlert>
                {!invalidContent &&
                    <div className="main-content create-user edit-content add-client">

                        <div className="page-content ">
                            <div className="container-fluid">



                                <div className="row mb-4 breadcrumb">
                                    <div className="col-lg-12">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1">
                                                <h4 className="mb-2 card-title">{id ? "Edit News" : "CREATE NEWS"}</h4>
                                                <p className="menu-path"><span >News  Management</span> / <b> {id ? "Edit News" : "CREATE NEWS"}</b></p>
                                            </div>
                                            <div>
                                                <a onClick={handleBack} className="btn btn-primary">back</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="create-user-block content_edit edit-item">
                                    {isLoading2 === false ?
                                        <div className="form-block">

                                            <div className="addnews pt-0 text-muted">
                                                <div className={`tab-pane ${activeTab == 'info' ? 'active show' : ''}`} role="tabpanel">

                                                    <>
                                                        <div className="row">
                                                            <div className="col-md-9">
                                                                <div className="row basic-details">

                                                                    <div className="col-md-12">
                                                                        <div className="input-field">
                                                                            <label for="example-text-input" className="col-form-label">Title name<span className="required">*</span></label>
                                                                            <input className="form-control contact-number" type="text" name="title" placeholder="Enter title name" id="example-email-input" onChange={(e) => handleChange(e)} value={editcontent.title} />
                                                                            {errors.title && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.title}</span>}
                                                                        </div>
                                                                    </div>




                                                                    <div className="col-md-12">
                                                                        {/* <div className="input-field">
                                                                         {id ? <><label className="col-form-label">status<span className="required">*</span></label><select className="form-select" name="status" placeholder="Select Type" onChange={(e) => handleChange(e)} value={editcontent.status}>
                                                                             <option value="COMING SOON">COMING SOON</option>
                                                                             <option value="CLOSED">CLOSED</option>
                                                                             <option value="ACTIVE">ACTIVE</option>
                                                                             <option value="INACTIVE">INACTIVE</option>

                                                                         </select></> : null

                                                                         }
                                                                         {errors.status && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.status}</span>}
                                                                     </div> */}
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="input-field">
                                                                            <label className="col-form-label">Description <span className="required">*</span></label>
                                                                            {/* <ReactQuill theme="snow" value={value} onChange={setValue} /> */}
                                                                            {quillElement}
                                                                            {isValidEditor === false ? delete errors["description"] : null}
                                                                            {errors.description && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.description}</span>}
                                                                        </div>
                                                                    </div>




                                                                </div>

                                                            </div>
                                                            <div className="col-md-3">

                                                            </div>
                                                        </div>
                                                    </>





                                                    <div className="col-md-9 ps-0 box-border">
                                                        <label className="col-form-label">IMAGES</label>
                                                        <div className="d-flex a">
                                                            {editcontent?.images?.map((item) => {
                                                                return (

                                                                    <div className={item?.filepath === defaultImg ? "thumbnail-pic thumb_selected" : "thumbnail-pic"}>
                                                                        <span className="material-icons-outlined thumbnail-cls-icon" style={{
                                                                            position: 'absolute',
                                                                            fontSize: '20px',
                                                                            top: '-8px',
                                                                            right: '-3px',
                                                                            cursor: 'pointer',
                                                                            zIndex: '999',
                                                                            color: '#777',
                                                                            fontWeight: '600',
                                                                            backgroundColor: '#fff',
                                                                            borderRadius: '100%',
                                                                        }} onClick={() => handleDeleteImg(item)}>highlight_off</span>
                                                                        <img src={image + item.filepath} />
                                                                        {/* <p>Title 1</p> */}
                                                                        <span className="thuumbnail" style={{ cursor: "pointer" }} onClick={() => handleDefault(item)}>set as thumbnail</span>
                                                                        {defaultImg === item?.filepath && (
                                                                            <span className="thuumbnail_tag">Thumbnail</span>
                                                                        )}


                                                                    </div>
                                                                )
                                                            })}


                                                            <div className="add-block" onClick={(e) => handleAddFile(e)}><span className="material-icons-outlined">add</span>add</div>
                                                        </div>
                                                        <p style={{ marginBottom: "0" }}>
                                                            <span style={{ color: "red", fontSize: "16px", marginBottom: "0" }}>*</span>
                                                            Please upload only jpg and png images
                                                        </p>
                                                    </div>

                                                    <div className="col-md-12 mb-2 social_media">
                                                 <label className="col-form-label">SOCIAL MEDIA</label>
                                                 <div className="row">
                                                 <div className="col-md-6">
                                                 <div className="input-field">
                                                 <img src={image+"orasi/admin/resources/orasiv1/images/linkedin.png"}/>
                                                <input className="form-control" type="text" name="linkedin" placeholder="Paste Linked In link here" id="example-email-input" value={editcontent?.socialmedia?.linkedin} onChange={(e) => handleChange(e)}/>
                                                {errors.linkedin && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.linkedin}</span>}
                                                 </div>
                                                 </div>
                                                 <div className="col-md-6">
                                                 <div className="input-field">
                                                 <img src={image+"orasi/admin/resources/orasiv1/images/facebook.png"}/>
                                                <input className="form-control" type="text" name="facebook" placeholder="Paste Facebook link here" id="example-email-input" value={editcontent?.socialmedia?.facebook} onChange={(e) => handleChange(e)}/>
                                                {errors.facebook && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.facebook}</span>}
                                                 </div>
                                                 </div>
                                                 <div className="col-md-6">
                                                 <div className="input-field">
                                                 <img src={image+"orasi/admin/resources/orasiv1/images/twitter.png"}/>
                                                <input class="form-control" type="text" name="twitter" placeholder="Paste X link here" id="example-email-input" value={editcontent?.socialmedia?.twitter} onChange={(e) => handleChange(e)}/>
                                                {errors.twitter && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.twitter}</span>}
                                                 </div>
                                                 </div>
                                                 <div className="col-md-6">
                                                 <div className="input-field">
                                                 <img src={image+"orasi/admin/resources/orasiv1/images/youtube-logo.png"}/>
                                                <input className="form-control" type="text" name="youtube" placeholder="Paste Youtube link here" id="example-email-input" value={editcontent?.socialmedia?.youtube} onChange={(e) => handleChange(e)}/>
                                                {errors.youtube && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.youtube}</span>}
                                                 </div>
                                                 </div>
                                                </div>
                                                </div>

                                                    <div className="col-md-3 justify-content-between ps-0">
                                                        <div className="input-field">
                                                            <><label className="col-form-label">status<span className="required">*</span></label><select className="form-select" name="status" placeholder="Select Type" onChange={(e) => handleChange(e)} value={editcontent.status}>

                                                                <><option value="">Select</option><option value="Publish">Publish</option><option value="UnPublish">UnPublish</option></>

                                                            </select></>

                                                            {errors.status && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.status}</span>}
                                                        </div>
                                                    </div>



                                                    <div className="col-md-9 mt-3 d-flex align-items-center">

                                                        {id ? <button className="btn btn-primary" onClick={handleUpdate}>{BtnLoader ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}UPDATE</button> : <button className="btn btn-primary" onClick={handlePublish}>{BtnLoader ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}DONE</button>}


                                                    </div>
                                                    {(errors.landscapeimage || errors.portraitimage) && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>Please upload a portrait or landscape image in the files tab to activate the content.</span>}






                                                </div>

                                                {/* {activeTab == "seller" && sellersTab()} */}

                                                {/* {activeTab == "history" && historyTab()} */}

                                            </div>

                                        </div> : <div className="create-user-block"><div className="form-block">
                                            <div className="tab-content p-3 text-muted">
                                                <div className="tab-pane active show" id="home1" role="tabpanel">
                                                    <div><Loader /></div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>}


                                </div>



                            </div>
                        </div>

                        {/* <Modal className="seller-pop" show={showupdateSeller} onHide={handleClosePopup}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{sellerContent && sellerContent.clientcontentid ? 'Update ' : 'Add '} Seller</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>{updateSeller()}</Modal.Body>
    
                            </Modal> */}
                        <Modal className="seller-pop preview-pop" show={showPreview}>
                            <Modal.Header>
                                <button className="close-btn" onClick={handlePreview}><span className="material-icons">close</span></button>
                                <Modal.Title>Preview</Modal.Title>
                            </Modal.Header>
                            <Modal.Body><img src={previewImg} className="w-100" /></Modal.Body>

                        </Modal>
                        {/* <Modal className="seller-pop new-look-up" show={showAddLookup} onHide={setShowAddLookup}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Add New lookup</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>{AddLookUp()}</Modal.Body>
    
                            </Modal> */}
                        <Footer />
                        <SweetAlert show={success}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Updated successfully"}
                            onConfirm={e => { setSuccess(false); setBtnLoader(false) }}
                        ></SweetAlert>
                        <SweetAlert show={deleteAlert}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Cannot Delete, Some of the selected combinations have Bids"}
                            onConfirm={e => { setDeleteAlert(false); }}
                        ></SweetAlert>
                        <SweetAlert show={deleteSuccess}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Combination Deleted Successfully"}
                            onConfirm={e => { setDeleteSuccess(false); }}
                        ></SweetAlert>
                        <SweetAlert show={subSuccess}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Subtitle added successfully"}
                            onConfirm={e => { setSubSuccess(false); setBtnLoader(false) }}
                        ></SweetAlert>
                        <SweetAlert show={delSuccess}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Image deleted successfully"}
                            onConfirm={e => { setDelSuccess(false); setBtnLoader(false) }}
                        >
                        </SweetAlert>
                        <SweetAlert show={lookupSuccess}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Added successfully"}
                            onConfirm={e => { setLookupSuccess(false); setBtnLoader(false) }}
                        ></SweetAlert>
                        {/* <SweetAlert show={isdelete}
                                 custom
                                 confirmBtnText="ok"
                                 confirmBtnBsStyle="primary"
                                 title={"File Deleted successfully"}
                                 onConfirm={e => setDelete(false)}
                             >
                             </SweetAlert> */}
                        <SweetAlert show={AddSuccess}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"

                            title={editcontent?.status === "Publish" ? "Published successfully" : "Saved Successfully. You can publish this anytime later"}
                            onConfirm={e => { closeSuccess() }}
                        >
                        </SweetAlert>
                        <SweetAlert show={uploadsuccess}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Image uploaded successfully"}
                            onConfirm={e => { setUploadSuccess(false); setBtnLoader(false) }}
                        >
                        </SweetAlert>
                        <SweetAlert show={delPopup}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Deleted successfully"}
                            onConfirm={e => { setDelPopup(false); setBtnLoader(false) }}
                        >
                        </SweetAlert>
                        <SweetAlert show={isCompExist}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Company already exists"}
                            onConfirm={e => { setIsCompExist(false); setBtnLoader(false) }}
                        >
                        </SweetAlert>
                        <SweetAlert show={isTitleExist}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"This title name already exists"}
                            onConfirm={e => { setIsTitleExist(false); setBtnLoader(false) }}
                        >
                        </SweetAlert>


                        <SweetAlert show={addComb}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Combination Saved SuccessFully"}
                            onConfirm={e => { setAddComb(false); setInitialFlag(false); setEnableEdit(false); setRefreshTable(false); setTableChange(false); setIsLoading(false) }}
                        >
                        </SweetAlert>
                        <SweetAlert show={updateComb}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Combination updated SuccessFully"}
                            onConfirm={e => onUpdateConfirm()}
                        >
                        </SweetAlert>
                        <SweetAlert show={docError}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Please upload pdf files only"}
                            onConfirm={e => setDocError(false)}
                        >
                        </SweetAlert>


                        
                      
                        <Modal className="access-denied" show={isdeleteImage}>

                            <div className="modal-body enquiry-form">
                                <div className="container">
                                    <button className="close-btn" onClick={e => onCancelDelete()}><span className="material-icons">close</span></button>
                                    <span className="material-icons access-denied-icon">delete_outline</span>
                                    <h3>Delete</h3>
                                    <p>This action cannot be undone.</p>
                                    <p>Are you sure you want to delete ?</p>
                                    <div className="popup-footer">
                                        <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => confirmDeleteImage()}>Yes, Delete</button>
                                    </div>
                                </div>
                            </div>

                        </Modal>



                        <Modal className="access-denied" show={showResetPopup}>

                            <div className="modal-body enquiry-form">
                                <div className="container">
                                    <button className="close-btn" onClick={e => SetShowResetPopup(false)}><span className="material-icons">close</span></button>
                                    <span className="material-icons access-denied-icon">delete_outline</span>
                                    <h3>Reset</h3>
                                    <p>This action cannot be undone.</p>
                                    <p>Are you sure do you want {tableChange === false ? "to reset search filters" : "to clear minimum bid price for combinations"} </p>
                                    <div className="popup-footer">
                                        <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => clearSearch()}>{BtnLoader ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}Yes, Cancel</button>
                                    </div>
                                </div>
                            </div>

                        </Modal>
                        <Modal className="access-denied" show={iserrorpopup}>

                            <div className="modal-body enquiry-form">
                                <div className="container">
                                    <button className="close-btn" onClick={e => setIsErrorPopup(false)}><span className="material-icons">close</span></button>
                                    {/* <span className="material-icons access-denied-icon">delete_outline</span> */}
                                    <h3>Alert</h3>
                                    <p>Some of combination having minbidprice 0 or empty. please enter non-zero values and update </p>
                                    <div className="popup-footer">
                                        <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => setIsErrorPopup(false)}>OKAY</button>
                                    </div>
                                </div>
                            </div>

                        </Modal>

                        {showUpload &&
                            <Modal show={true} className="seller-pop new-look-up add-document" >
                                <Modal.Header >
                                    <Modal.Title> Upload Image</Modal.Title>
                                    <button className="close-btn" onClick={handleClose}><span className="material-icons">close</span></button>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row">
                                        <div className="col-md-12 documents">
                                            <div className="mb-3 input-field">
                                                {/* <label className="form-label form-label">{fileCategory === "CONTENT_IMAGE" ? "Thumbnail Name" :"File Name"}</label> */}
                                                <input name="filename" placeholder="Enter File Name" type="text" className="form-control"onChange={(e) => {setFileName(e.target.value);setValidateFiles(false)}}
 value={fileName} />
                                            </div>
                                            <div className="mb-3 input-field btn-gray">

                                                <input type="file" name="upload" accept="image/png, image/jpeg" className="udisplay-none" id="upload"
                                                    onChange={e => uploadS3(e, ["1920*1080", "Image"])}
                                                    onClick={(e) => { e.target.value = ''; setValidateFiles(false) }} ref={ref} />


                                                {BtnLoader1 ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : <span className="material-icons">upload </span>}Upload Image</div>
                                            {/* <p>Only pdf and word document allowed.</p> */}
                                            <p className={excededfile ? "uploaderror" : ""}><span style={{ color: "red", fontSize: "16px" }}>*</span>Please upload file size less than 10MB</p>
                                          {validateFiles? <p className="uploaderror">Please upload image files only</p>:""} 

                                            {/* <a className="btn btn-primary">done</a> */}
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>


                        }
                        {/* <Modal className="access-denied" show={showPopup} >
 
                             <div className="modal-body enquiry-form upload_file">
                                 <div className="container">
                                     <button className="close-btn" onClick={e => onUploadCancel(e)}><span className="material-icons">close</span></button>
 
 
                                     <p>Do you want cancel upload File?</p>
                                     <div className="popup-footer">
                                         <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => handleUploadFile(e)}>{BtnLoader == "sessionLoad" ? (<img src="https://d9nwtjplhevo0.cloudfront.net/orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg" className="loading-icon" />) : null}Yes</button>
                                         <button className="fill_btn" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => onUploadCancel(e)}>No</button>
                                     </div>
                                 </div>
                             </div>
 
                         </Modal> */}


                    </div>


                }


            </div>

        </>
    );
};

export default EditNews;
