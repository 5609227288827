/***
 **Module Name: editCategory
 **File Name :  editCategory.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains editCategory details.
 ***/
import React, { useState, useEffect, useRef, useContext } from "react";
import Footer from "../../../components/dashboard/footer";
import Header from "../../../components/dashboard/header";
import Sidebar from "../../../components/dashboard/sidebar";
import { useHistory, Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import { useParams } from 'react-router-dom';
import Loader from "../../../components/loader";
import SessionPopup from "../../SessionPopup"
import moment from "moment";
import { contentContext } from "../../../context/contentContext";
let { appname, lambda } = window.app;

const ViewBidsSummary = () => {
    const history = useHistory();
    let { id } = useParams();
    const ref = useRef();
    const [combinationData, setCombinationData] = useState({});
    const [itemDetails, setItemDetails] = useState([]);
    const [image, setImg] = useState('');
    const [showSessionPopupup, setShowSessionPopupup] = useState(false);
    const [awardHistory, setAwardHistory] = useState({});
    const [biddersData, setBiddersData] = useState([]);
    const [approvedDetails, setApprovedDetails] = useState({});
    const [clientDetails, setClientDetails] = useState({});
    const [popup, setPopup] = useState(false);
    const [reason, setReason] = useState("");
    const [aprovedclientbid, setApprovedClientBid] = useState("");
    const [awardSuccess, setAwardSucces] = useState(false);
    const [revertSuccess, setRevertSucces] = useState(false);
    const [loaderEnable, setLoaderEnable] = useState(false);
    const [load, setLoad] = useState(false);
    const { route, currentPageNew, setRoute, setActiveMenuObj, setCurrentPage, setRowsPerPage, usePrevious, setActiveMenuId, isLoading, setIsLoading } = useContext(contentContext);

    const prevRoute = usePrevious(route);
    console.log("prevRoute", prevRoute)

    useEffect(() => {
        if (prevRoute != undefined && prevRoute != route) {
            setCurrentPage(1)
            setRowsPerPage(15)
        }
    }, [prevRoute]);
    useEffect(() => {
        if (!localStorage.getItem("token")) {
            history.push("/");
        }
        setCombinationData(JSON.parse(localStorage.getItem("item")));
        //setRoute("bids")
        setActiveMenuId(300022)
        setActiveMenuObj({
            "Contact Management": false,
            "Client Management": false,
            "Reports": false,
            "Auction Management": true
        })
        getItemDetails();
        getAwardHistory();
        getBidders();
        // if(prevRoute === "bids"){
        //     setRoute("bids");
        // }
        // if(prevRoute === "bidsdashboard"){
        //     setRoute("bidsdashboard")
        // }

    }, []);
    useEffect(() => {
        if (localStorage.getItem("flag")) {
            setRoute("bidsdashboard");
        } else {
            setRoute("bids");
        }

    }, [prevRoute]);

    useEffect(() => {

        if (window.site === undefined) {
            setTimeout(() => {
                if (window.site && window.site.common && window.site.common.imagesCDN) {
                    setImg(window.site.common.imagesCDN)
                }
            }, 1000);
        }
        if (window.site && window.site.common && window.site.common.imagesCDN) {
            setImg(window.site.common.imagesCDN)

        }

    }, [window.site]);

    const getItemDetails = (e) => {

        const token = localStorage.getItem("token")
        axios({
            method: 'GET',
            url: lambda + '/biddingItems?appname=' + appname + "&itemid=" + localStorage.getItem("itemid") + "&token=" + token,
        })
            .then(function (response) {
                if (response.data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    console.log(response)
                    const result = response.data.result.data[0];
                    setItemDetails(result);

                }
            });

    }
    const getAwardHistory = (e) => {
        setLoad(true)
        const token = localStorage.getItem("token")
        axios({
            method: 'POST',
            url: lambda + '/combinationlist?appname=' + appname + "&combinationid=" + id + "&token=" + token,
        })
            .then(function (response) {
                if (response.data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    console.log(response)
                    const result = response.data.result.data[0];
                    setAwardHistory(result);
                    let lastdetails = response.data.result.data[0].dealinfo[response.data.result.data[0].dealinfo?.length - 1]

                    //console.log("lastdetails",lastdetails);
                    setApprovedDetails(lastdetails)
                    getClienDetails(lastdetails.clientid)
                    setLoad(false)
                }
            });

    }
    console.log("isloading", isLoading);
    const getClienDetails = (id) => {
        const token = localStorage.getItem("token")
        axios({
            method: 'GET',
            url: lambda + '/client?appname=' + appname + '&clientuserid=' + id + "&token=" + token,
        })
            .then(function (response) {
                console.log("response", response);
                if (response.data.result) {
                    setClientDetails(response.data.result[0])
                }

            });
    }
    const getBidders = (e) => {
        const token = localStorage.getItem("token")
        let data = JSON.parse(localStorage.getItem("item"))
        console.log("sferger", data);
        axios({
            method: 'POST',
            url: lambda + '/getbids?appname=' + appname + "&token=" + token + "&userid=" + localStorage.getItem("userId") + "&itemcombinationid=" + id + "&isCombination=true",
        })
            .then(function (response) {
                if (response.data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    if (response.data.result.data) {
                        setBiddersData(response.data.result.data);


                    }
                }
            });

    }

    const handleBack = () => {
        localStorage.removeItem("item")
        localStorage.removeItem("itemid")
        if (prevRoute === "bids") {
            history.push("/bids");
        }
        else if (prevRoute === "bidsdashboard") {
            localStorage.removeItem("flag")
            history.push("/itembids/" + itemDetails.itemid);
        }
    }


    let defaultImg = `${image}orasi/common/images/img-default.jpg`;
    let imgUrl = itemDetails && itemDetails.itemimage ? image + itemDetails.itemimage : defaultImg

    const statusClass = (status) => {
        let className = 'text-danger'
        switch (status.toLowerCase()) {
            case 'approved':
                className = 'text-warning';
                break
        }
        return className
    }

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        setReason("")
    }
    const handleShow = () => {
        setShow(true);
    }

    const handleShowPopup = () => {
        setPopup(true);
    }
    const handleClosePopup = () => {
        setPopup(false);
        setReason("")
    }

    const onConfirm = () => {
        setAwardSucces(false);
        getAwardHistory();
    }
    const onConfirm1 = () => {
        setRevertSucces(false);
        history.push("/viewtitlebids/" + awardHistory.combinationid);
    }
    const handleAward = () => {
        const filteredArray = biddersData.filter(obj => obj.clientid === clientDetails.clientid);
        console.log("filteredArray", filteredArray);
        let url;


        const apiUrl = lambda + '/deal/';

        let country = awardHistory.country != undefined ? awardHistory.country : ""
        let region = awardHistory.region != undefined ? awardHistory.region : ""
        let typesofrights = awardHistory.typesofrights != undefined ? awardHistory.typesofrights : ""
        let revenuetype = awardHistory.revenuetype != undefined ? awardHistory.revenuetype : ""
        let exclusivity = awardHistory.exclusivity != undefined ? awardHistory.exclusivity : ""

        let token = localStorage.getItem("token")
        let queryParams;

        queryParams = {
            appname: appname,
            token: token,
            combinationid: awardHistory.combinationid,
            userid: localStorage.getItem("userId"),
            country: country,
            isgroup: approvedDetails.isgroup,
            itemid: awardHistory.itemid,
            region: region,
            typesofrights: typesofrights,
            revenuetype: revenuetype,
            exclusivity: exclusivity,
            title: awardHistory.title,
            minbidprice: awardHistory.minbidprice
        };
        const buildQueryString = (params) => {
            return Object.keys(params)
                .filter((key) => params[key] !== '')
                .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
                .join('&');
        };

        url = `${apiUrl}?${buildQueryString(queryParams)}`;
        let payload;
        if (approvedDetails.isgroup === true) {
            payload = {
                "status": "AWARDED",
                "notes": reason,
                "clientbidid": filteredArray[0].clientbidid,
                "clientid": approvedDetails.clientid,
                "clientname": approvedDetails.clientname,
                "companyid": approvedDetails.companyid,
                "companyname": approvedDetails.companyname,
                "bidprice": awardHistory.maxbidprice,
                "period": approvedDetails.period,
                "isgroup": approvedDetails.isgroup,
                "clientbidcombinationid": filteredArray[0].clientbidcombinationid
            }
        } else {
            payload = {
                "status": "AWARDED",
                "notes": reason,
                "clientbidid": filteredArray[0].clientbidid,
                "clientid": approvedDetails.clientid,
                "clientname": approvedDetails.clientname,
                "companyid": approvedDetails.companyid,
                "companyname": approvedDetails.companyname,
                "bidprice": approvedDetails.bidprice,
                "period": approvedDetails.period,
                "isgroup": approvedDetails.isgroup,
            }
        }

        setLoaderEnable(true);
        axios({
            method: 'POST',
            url: url,
            data: payload
        })
            .then(function (response) {
                console.log("response", response);
                if (response.data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    setLoaderEnable(false);
                    setShow(false);
                    setAwardSucces(true);
                    setReason("");
                }

            });

    }

    const handleReverse = () => {
        const filteredArray = biddersData.filter(obj => obj.clientid === clientDetails.clientid);
        console.log("filteredArray", filteredArray);
        let url;
        const apiUrl = lambda + '/deal/';
        let country = awardHistory.country != undefined ? awardHistory.country : ""
        let typesofrights = awardHistory.typesofrights != undefined ? awardHistory.typesofrights : ""
        let revenuetype = awardHistory.revenuetype != undefined ? awardHistory.revenuetype : ""
        let exclusivity = awardHistory.exclusivity != undefined ? awardHistory.exclusivity : ""

        let token = localStorage.getItem("token")
        let queryParams;

        queryParams = {
            appname: appname,
            token: token,
            combinationid: awardHistory.combinationid,
            userid: localStorage.getItem("userId"),
            country: country,
            // region: region,
            isgroup: approvedDetails.isgroup,
            itemid: awardHistory.itemid,
            typesofrights: typesofrights,
            revenuetype: revenuetype,
            exclusivity: exclusivity,
            title: awardHistory.title,
            minbidprice: awardHistory.minbidprice
        };
        const buildQueryString = (params) => {
            return Object.keys(params)
                .filter((key) => params[key] !== '')
                .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
                .join('&');
        };

        url = `${apiUrl}?${buildQueryString(queryParams)}`;
        let payload;
        if (approvedDetails.isgroup === true) {
            payload = {
                "status": "REVERTED",
                "notes": reason,
                "clientbidid": filteredArray[0].clientbidid,
                "clientid": approvedDetails.clientid,
                "clientname": approvedDetails.clientname,
                "companyid": approvedDetails.companyid,
                "deal": false,
                "companyname": approvedDetails.companyname,
                "bidprice": awardHistory.maxbidprice,
                "period": approvedDetails.period,
                "isgroup": approvedDetails.isgroup,
                "clientbidcombinationid": filteredArray[0].clientbidcombinationid
            }
        } else {
            payload = {
                "status": "REVERTED",
                "notes": reason,
                "clientbidid": filteredArray[0].clientbidid,
                "clientid": approvedDetails.clientid,
                "clientname": approvedDetails.clientname,
                "companyid": approvedDetails.companyid,
                "deal": false,
                "companyname": approvedDetails.companyname,
                "bidprice": approvedDetails.bidprice,
                "period": approvedDetails.period,
                "isgroup": approvedDetails.isgroup,
            }
        }
        setLoaderEnable(true);
        console.log("pay", payload);

        axios({
            method: 'POST',
            url: url,
            data: payload
        })
            .then(function (response) {
                console.log("response", response);
                if (response.data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    setLoaderEnable(false);
                    setShow(false);
                    setRevertSucces(true)
                    setReason("");
                }

            });
    }
    console.log("group", awardHistory)

    return (
        <>
            <Modal className="advance-search search-popup create-combination-pop deal-pop-up" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>AWARD</Modal.Title>
                </Modal.Header>
                <Modal.Body> <div>

                    <div className="row">

                        <p>Do you want to the award the bid to <span>{approvedDetails.clientname}</span> ?</p>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="form-label form-label">reason</label>
                                <textarea id="verticalnav-address-input" name="followup" onChange={(e) => setReason(e.target.value)} value={reason} placeholder="Add Reason For Award" class="form-control" rows="2" spellcheck="false"></textarea>
                            </div>
                        </div>

                    </div>
                </div></Modal.Body>
                <Modal.Footer>
                    <div className="adv_footer">

                        <button className="fill_btn yellow-gradient reset-btn"
                            onClick={handleClose}
                        >cancel</button>

                        <button
                            onClick={(e) => handleAward(e)}
                            className={`fill_btn yellow-gradient float-end ${reason === "" ? "disable" : ""}`} disabled={reason === ""}
                        >
                            {loaderEnable ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}
                            award
                        </button>

                        <div className="clearfix"></div>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal className="advance-search search-popup create-combination-pop deal-pop-up" show={popup} onHide={handleClosePopup}>
                <Modal.Header closeButton>
                    <Modal.Title>Reverse</Modal.Title>
                </Modal.Header>
                <Modal.Body> <div>

                    <div className="row">

                        <p>Are you sure you want to reverse the bid ?</p>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="form-label form-label">reason</label>
                                <textarea id="verticalnav-address-input" name="followup" onChange={(e) => setReason(e.target.value)} value={reason} placeholder="Add Reason For Revert" class="form-control" rows="2" spellcheck="false"></textarea>
                            </div>
                        </div>

                    </div>
                </div></Modal.Body>
                <Modal.Footer>
                    <div className="adv_footer">

                        <button className="fill_btn yellow-gradient reset-btn"
                            onClick={handleClosePopup}
                        >cancel</button>

                        <button
                            onClick={(e) => handleReverse(e)}
                            className={`fill_btn yellow-gradient float-end ${reason === "" ? "disable" : ""}`} disabled={reason === ""}
                        >
                            {loaderEnable ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}
                            reverse
                        </button>

                        <div className="clearfix"></div>
                    </div>
                </Modal.Footer>
            </Modal>

            {showSessionPopupup && <SessionPopup />}
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                {load ?
                    <Loader />
                    :
                    <div className="main-content create-user edit-content add-client edit-category view-category bidders-list view-combination">

                        <div className="page-content ">
                            <div className="container-fluid">



                                <div className="row mb-4 breadcrumb">
                                    <div className="col-lg-12">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1">
                                                <h4 className="mb-2 card-title">{itemDetails.title}</h4>
                                                {/* <span className="badge badge-warning">pending approval</span> */}
                                            </div>
                                            <div><a className="btn btn-primary" onClick={handleBack}>back</a></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="create-user-block content_edit block_buyer">



                                    <div className="row">
                                        <div className="col-md-3 pe-1">
                                            <div className="form-block">
                                                <div className="left_block">
                                                    <div className="bidders-btn-block">
                                                        <img src={imgUrl + "?auto=compress,format&width=85"} />
                                                        <div className="offcanvas offcanvas-end" id="demo">
                                                            <div className="offcanvas-header">
                                                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas"></button>
                                                                <h3>bidders</h3>
                                                            </div>
                                                            <div className="offcanvas-body user-management">
                                                                <table className="table table-striped seller_table buyer_table align-middle">
                                                                    <thead>
                                                                        <tr>

                                                                            <th>#</th>
                                                                            <th>Company name</th>
                                                                            <th>client name</th>
                                                                            <th>average price</th>
                                                                            <th>Period</th>
                                                                            <th>last bid date</th>

                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {biddersData && biddersData.length > 0 && biddersData.map((item, i) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{i + 1}</td>
                                                                                    <td>{item.companyname}</td>
                                                                                    <td>{item.clientname}</td>
                                                                                    <td>{item.period > 1 ? item.averageprice : item.bidprice}</td>
                                                                                    <td>{item.period}</td>
                                                                                    <td>{item.bidon ? new Date(item.bidon).toLocaleDateString('en-IN', {
                                                                                        day: 'numeric',
                                                                                        month: 'short',
                                                                                        year: 'numeric',
                                                                                    }) : null}</td>

                                                                                </tr>

                                                                            )
                                                                        })}


                                                                    </tbody>
                                                                </table>

                                                            </div>
                                                        </div>
                                                        <a className="btn btn-primary offcanvas-btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#demo"><span className="material-icons-outlined">group</span>all bidders</a>
                                                    </div>
                                                    {/* <div className="row">
                                                        <div className="col-md-6"> <img src={imgUrl + "?auto=compress,format&width=85"} />
                                                        </div>
                                                        <div className="col-md-6">
                                                        <a className="btn btn-primary offcanvas-btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#demo"><span className="material-icons-outlined">group</span>all bidders</a>
                                                        </div>
                                                    </div> */}
                                                    <h5>combinations</h5>
                                                    {combinationData && combinationData.country && <div className="input-field">
                                                        <label for="example-text-input" className="col-form-label">country</label>
                                                        <p>{combinationData.country}</p>
                                                    </div>}
                                                    {combinationData && combinationData.region && <div className="input-field">
                                                        <label for="example-text-input" className="col-form-label">region</label>
                                                        <p>
                                                            {combinationData && typeof (combinationData.region) === 'string' ? combinationData.region : combinationData.region.join(", ")}
                                                        </p>
                                                    </div>}
                                                    {combinationData && combinationData.typesofrights && <div className="input-field">
                                                        <label for="example-text-input" className="col-form-label">type of rights</label>
                                                        <p>{combinationData.typesofrights}</p>
                                                    </div>}
                                                    {combinationData && combinationData.revenuetype && <div className="input-field">
                                                        <label for="example-text-input" className="col-form-label">revenue type</label>
                                                        <p>{combinationData.revenuetype}</p>
                                                    </div>}
                                                    {combinationData && combinationData.exclusivity && <div className="input-field">
                                                        <label for="example-text-input" className="col-form-label">exclusivity</label>
                                                        <p>{combinationData.exclusivity}</p>
                                                    </div>}
                                                    <div className="input-field">
                                                        <label for="example-text-input" className="col-form-label">period</label>
                                                        <p>{awardHistory && awardHistory.period}</p>
                                                    </div>
                                                    <div className="input-field">
                                                        <label for="example-text-input" className="col-form-label">min bid price</label>
                                                        <p>{combinationData && combinationData.minbidprice}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-9 ps-1">
                                            <div className="form-block">
                                                <label className="form-label form-label">{approvedDetails.status === "APPROVED" ? "APPROVED to" : "awarded to"}</label>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="input-field">
                                                            <label for="example-text-input" className="col-form-label">bidder name</label>
                                                            <p>{approvedDetails.clientname}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="input-field">
                                                            <label for="example-text-input" className="col-form-label">company name</label>
                                                            <p>{approvedDetails.companyname}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="input-field">
                                                            <label for="example-text-input" className="col-form-label">e mail</label>
                                                            <p>{clientDetails.personalemail}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="input-field">
                                                            <label for="example-text-input" className="col-form-label">contact</label>
                                                            <p>{clientDetails.personalphone}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="input-field mb-0">
                                                            <label for="example-text-input" className="col-form-label">bid price (usd)</label>
                                                            <p>{approvedDetails.bidprice}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="input-field mb-0">
                                                            <label for="example-text-input" className="col-form-label">period</label>
                                                            <p>{approvedDetails.period === 1 ? approvedDetails.period + " year" : approvedDetails.period + " years"} </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="input-field mb-0">
                                                            <label for="example-text-input" className="col-form-label">max bid price</label>
                                                            <p>{awardHistory.maxbidprice}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>


                                            <div className="row award-history">
                                                <div className="col-md-7">
                                                    <div className="form-block mt-2">
                                                        <h6>reasons / notes</h6>
                                                        <p>{approvedDetails.notes}</p>
                                                        <h6 className="mt-4 mb-2">awarded date</h6>
                                                        <p>{approvedDetails.dealon ? new Date(approvedDetails.dealon).toLocaleDateString('en-IN', {
                                                            day: 'numeric',
                                                            month: 'short',
                                                            year: 'numeric',
                                                        }) : null}</p>
                                                        <div style={{ "display": "flex" }}>
                                                            <a className="btn btn-primary" onClick={handleShowPopup}>reverse</a>
                                                            {awardHistory.status != "AWARDED" && <a className="btn btn-primary" onClick={handleShow}>Award</a>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="form-block mt-2">
                                                        <label className="form-label form-label">deal history</label>
                                                        {awardHistory && <div className="history-block-scroll">

                                                            {
                                                                awardHistory.dealinfo && awardHistory.dealinfo.map((awardItem, index) => <div className={"history-block " + (index != 0 ? 'mt-2' : '')} key={'H-' + index}>
                                                                    <h6 className={statusClass(awardItem.status)}>{awardItem.status}</h6>
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="input-field">
                                                                                <label for="example-text-input" className="col-form-label">bidder name</label>
                                                                                <p>{awardItem.clientname}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <div className="input-field">
                                                                                <label for="example-text-input" className="col-form-label">company name</label>
                                                                                <p>{awardItem.companyname}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <div className="input-field mb-0">
                                                                                <label for="example-text-input" className="col-form-label">bid price (usd)</label>
                                                                                <p>{awardItem.bidprice}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <div className="input-field mb-0">
                                                                                <label for="example-text-input" className="col-form-label">period</label>
                                                                                <p>{awardItem.period === 1 ? awardItem.period + " year" : awardItem.period + " years"}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <div className="input-field mb-0">
                                                                                <label for="example-text-input" className="col-form-label">approved on</label>
                                                                                <p>{
                                                                                    awardItem.dealon && new Date(awardItem.dealon).toLocaleDateString('en-IN', {
                                                                                        day: 'numeric',
                                                                                        month: 'short',
                                                                                        year: 'numeric',
                                                                                    })
                                                                                }</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <p className="w-100">{awardItem.notes}</p>
                                                                </div>).reverse()
                                                            }


                                                        </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>


                        <Footer />
                        <SweetAlert show={awardSuccess}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Awarded Successfully"}
                            onConfirm={e => onConfirm()}
                        >
                        </SweetAlert>
                        <SweetAlert show={revertSuccess}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Reverted Successfully"}
                            onConfirm={e => onConfirm1()}
                        >
                        </SweetAlert>


                    </div>
                }
            </div>
        </>
    );
};

export default ViewBidsSummary;
