/***
**Module Name: not found
 **File Name :  notfound.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains page not found details.
 ***/
 import React, { useState, useEffect, useContext, useRef } from "react";
 import Footer from "../../../components/dashboard/footer";
 import Header from "../../../components/dashboard/header";
 import Sidebar from "../../../components/dashboard/sidebar";
 import { useHistory, Link } from "react-router-dom";
 import axios from "axios";
 import { useParams } from 'react-router-dom';
 import Select from 'react-select';
 import $ from 'jquery';
 import { contentContext } from "../../../context/contentContext";
 import TableLoader from "../../../components/tableLoader";
 import tmdbApi from "../../../api/tmdbApi";
 import moment from "moment";
 import asyncLoop from "node-async-loop";
 import { updateContentData, AddItem, getContentFiles, updateBiddingItems, getItemFiles, CountryCombination, getCombination, SaveCombination, UpdateCombination, getCombinationSearch, getCombinationAdvSearch, setDefault } from "../../../utils/reducer";
 import PlayerInfo from "../../../components/player";
 import FileViewer from "../../../components/docViewer";
 import Modal from 'react-bootstrap/Modal';
 import SweetAlert from 'react-bootstrap-sweetalert';
 import Loader from "../../../components/loader";
 import SessionPopup from "../../SessionPopup";
 import ReactQuill from 'react-quill';
 import DataTable from 'react-data-table-component';
 import 'react-quill/dist/quill.snow.css';
 let { lambda, appname } = window.app;
 
 
 const ViewItem = () => {
     let { id } = useParams();
     let videoClipcategories = ['VIDEOS',
         'THUMBNAILS',
         'DOCUMENTS',
     ]
     const history = useHistory();
     const ref = useRef();
     const [editcontent, setEditContent] = useState({});
     const [contentTitle, setContentTitle] = useState("");
     const [sellerContent, setSellercontent] = useState({});
     const [genreTag, setGenreTag] = useState({});
     const [selectedOptions, setSelectedOptions] = useState([]);
     const [selectedOptionsgenre, setSelectedOptionsgenre] = useState([]);
     const [genreData, setGenreData] = useState({});
     const [lookup, setLookUp] = useState({});
     const [selectedOptionscountryOfOrigin, setSelectedOptionscountryOfOrigin] = useState([]);
     const [countryData, setCountryData] = useState({});
     const [regionsData, setRegionsData] = useState({});
     const [typesofrights, setTypesofrights] = useState([]);
     const [exclusivityData, setExclusivityData] = useState([]);
     const [BtnLoader, setBtnLoader] = useState(false);
 
     const [apiDataCountry, setApiDataCountry] = useState({});
     const { categoryName, Categories, catBasedContentFields } = useContext(contentContext);
     const [image, setImg] = useState('');
     const [companiesData, setCompaniesData] = useState([]);
     const [sellersData, setSellersData] = useState([]);
     const [filesData, setFilesData] = useState([]);
     const [errors, setErrors] = useState({});
     const [play, setPlay] = useState(false);
     const [playContent, setPlayContent] = useState({});
     const [sellereErrors, setSellereErrors] = useState({});
     const [showupdateSeller, setshowupdateSeller] = useState(false);
     const [showPreview, setShowPreview] = useState(false);
     const [previewImg, setPreviewImg] = useState("");
     const [showDoc, setshowDoc] = useState(false);
     const [success, setSuccess] = useState(false);
     const [subSuccess, setSubSuccess] = useState(false);
     const [AddSuccess, setAddSuccess] = useState(false);
 
     const [excededfile, setExcededFile] = useState(false);
     const [videoexcededfile, setVideoExcededFile] = useState(false);
     const [isdelete, setDelete] = useState(false);
     const [isdeleteImage, setIsdeleteImage] = useState(false);
     const [item, setItem] = useState("");
 
     const [fileCategory, setFileCategory] = useState("");
     const [lookupSuccess, setLookupSuccess] = useState(false);
 
     const [fileName, setFileName] = useState("");
     const [showUpload, setShowUpload] = useState(false);
     const [showSubUpload, setShowSubUpload] = useState(false);
     const [activeSubTitles, setActiveSubTitles] = useState([]);
     const [showSessionPopupup, setShowSessionPopupup] = useState(false);
     const [invalidContent, setInvalidContent] = useState(false);
     const [type, setType] = useState(false);
     const [activeTab, setActiveTab] = useState('info');
     const [isInfoFormValid, setIsInfoFormValid] = useState(false);
 
     const [uploadType, setUploadType] = useState('');
     const [activeTrailerId, setActiveTrailerId] = useState('');
     const [playerSubTitles, setPlayerSubTitles] = useState('');
 
     const [delPopup, setDelPopup] = useState(false);
     const [delSuccess, setDelSuccess] = useState(false);
     const [delSeller, setDelSeller] = useState(false);
 
     const [uploadsuccess, setUploadSuccess] = useState(false);
     const [isCompExist, setIsCompExist] = useState(false);
     const [isTitleExist, setIsTitleExist] = useState(false);
 
     // const [includeInputsCat, setIncludeInputsCat] = useState([]); 
 
     const includeInputsCat = ["SPORTS", "SHORT FILMS", "LIVE EVENTS", "MUSIC", "COOKING SHOWS", "LIFESTYLE", "SERIES & TELENOVELAS", "DOCUMENTARIES", "DOCUSERIES", "KIDS MOVIES", "MOVIES", "KIDS SERIES", "MUSIC", "LIVE EVENTS", "MY CATEGORY"];
 
     const [countryArray, setCountryArray] = useState([]);
     const [initialFlag, setInitialFlag] = useState(false);
     const [addComb, setAddComb] = useState(false);
     const [updateComb, setUpdateComb] = useState(false);
     const [combinationpopup, setShowCombinationPopup] = useState(false);
     const [advancedsearchpopup, setShowAdvancedSearchPopup] = useState(false);
     const [enableEdit, setEnableEdit] = useState(false);
     const [combinationArray, setCombinationArray] = useState([]);
     const [tableChange, setTableChange] = useState(false);
     const [search, setSearch] = useState({});
     const [percentage, setPercentage] = useState(0);
     const [refreshTable, setRefreshTable] = useState(false);
     const [advancedSearch, setAdvancedSearch] = useState({});
     const [editorFlag, setEditorFlag] = useState(false);
     const [itemId, setItemId] = useState("");
     const [combinationData, setCombinationData] = useState("");
     const [comparecombination, setCompareCombination] = useState([]);
     const [cancelCombinationpopup, setCancelCombinationPopup] = useState(false);
     const [fileCheck, setFileCheck] = useState("");
     const [resetKey, setResetKey] = useState(0);
     const [defaultShow, setDefaultShow] = useState(false);
     // console.log('catBasedContentFieldscatBasedContentFields',catBasedContentFields)
     const { route, setRoute, setCurrentPage,setActiveMenuObj, setRowsPerPage, usePrevious, userData, setActiveMenuId, rowsPerPage, currentPageNew, isLoading, setIsLoading } = useContext(contentContext);
     // let fields = [];
     const prevRoute = usePrevious(route)
     useEffect(() => {
         if (prevRoute != undefined && prevRoute != route) {
             setCurrentPage(1)
             setRowsPerPage(15)
         }
     }, [route]);
     console.log('prevRoute--->', prevRoute)
     console.log('currentRoute--->', route)
     useEffect(() => {
         console.log("excuted");
         if (!(localStorage.getItem("token"))) {
             console.log('tokaen excte');
             history.push("/");
             localStorage.setItem("check", id);
         }
         console.log('content excte');
         setRoute("item")
         setActiveMenuId(300021)
        setActiveMenuObj({
            "Contact Management": false,
            "Client Management": false,
            "Reports": false,
            "Auction Management":true
        })
         Categories();
 
         GetLookUp();
         if (id) {
             getBiddingItem();
             //setCombinationData([])
         }
         console.log('before calling');
 
         if (id) {
             getItemFiles(id).then((data) => {
                 // console.log("getClientcontent data", data);
                 if (data.statusCode == 200) {
                     //  console.log(data.result, "---")
                     if (data.result == "Invalid token or Expired") {
                         setShowSessionPopupup(true)
                     } else {
                         setFilesData(data.result);
                         setBtnLoader(false)
                     }
 
                 }
             }).catch((error) => {
 
                 console.log(error);
             })
         }
 
         if (id) {
            setCurrentPage(1)
             setRowsPerPage(15)
             getCombination(id).then((data) => {
                 console.log("getClientcontent data", data);
                 if (data.statusCode == 200) {
                     console.log(data, "data------------->")
                     if (data.result == "Invalid token or Expired") {
                         setShowSessionPopupup(true)
                     } else {
                         if (data.result.data.length > 0) {
 
                             let tmpData = data.result.data.map((obj) => ({
                                 ...obj,
                                 isSelected: false
                             }));
                             setCombinationData(tmpData);
                             const keysToFilter = ['country', 'typesofrights', 'exclusivity', 'revenuetype'];
 
                             const newArray = tmpData.map(obj => {
                                 const filteredObj = {};
 
                                 keysToFilter.forEach(key => {
                                     if (obj.hasOwnProperty(key)) {
                                         filteredObj[key] = obj[key];
                                     }
                                 });
 
                                 return filteredObj;
                             });
                             // console.log("newArray",newArray);
                             setCompareCombination(newArray)
                         }
 
                     }
                 }
             }).catch((error) => {
                 console.log(error);
             })
         }
         //  renderDynamicCategoryFields();
 
 
     }, []);
     useEffect(() => {
 
         if (window.site === undefined) {
             setTimeout(() => {
                 if (window.site && window.site.common && window.site.common.imagesCDN) {
                     setImg(window.site.common.imagesCDN)
                 }
             }, 1000);
         }
         if (window.site && window.site.common && window.site.common.imagesCDN) {
             setImg(window.site.common.imagesCDN)
         }
 
     }, [window.site]);
 
     useEffect(() => {
         if (id) {
             getBiddingItem();
         }
     }, [activeTab === "info"]);
     useEffect(() => {
         setEnableEdit(false);
     }, [combinationArray && combinationArray.length <= 0]);
 
 
     const handleFocus = () => {
         setEditorFlag(true)
     }
     const handleBlur = () => {
         setEditorFlag(false)
     }
 
 
 
     const saveNext = (e) => {
         const isValid = validateMandatoryFields();
         if (isValid) {
             // console.log('asdfasdfasdfasdfasdfasdfasdfkbasdfa sdf asdf asd f')
             setIsInfoFormValid(true)
             setActiveTab("files")
         }
     }
 
 
     // console.log('delSuccess', delSuccess)
     const imageDeleteFun = (e) => {
         // let newContent = Object.assign({}, editcontent);
         // delete newContent[item];
         // setEditContent(newContent)
         const obj = {
             "contentid": id,
             [item]: "",
         }
         updateContentData(id, obj).then((data) => {
             console.log("getClientcontent data", data);
             if (data.statusCode == 200) {
                 console.log(data.result, "---")
                 if (data.result == "Invalid token or Expired") {
                     setShowSessionPopupup(true)
                 } else {
                     console.log("image delete");
                     setBtnLoader(false)
                     setDelSuccess(true);
                     setIsdeleteImage(false)
                     //history.push("/contentmanagement");
                     getBiddingItem();
                     // setBtnLoader(false);
 
 
                 }
             }
         }).catch((error) => {
             setBtnLoader(false)
             setBtnLoader(false);
             console.log(error);
         })
     }
     // console.log('error----->',errors)
     const handleSaveItem = async (e) => {
         submit();
     }
 
     const submit = async (e) => {
         // e.preventDefault();
         const isValid = validateMandatoryFields();
         // if(isValid) {
         //     setActiveTab("seller")
         // }
         // const isValid = validate();
         //setBtnLoader(true);
 
         if (isValid) {
             // if (id === undefined) {
             //     const isValidSeller = validateSeller();
             // }
             setBtnLoader(true);
 
             console.log('form valid', editcontent);
             if (id) {
                 delete editcontent["createdBy"];
                 delete editcontent["updatedBy"];
                 updateBiddingItems(id, editcontent).then((data) => {
                     console.log("getClientcontent data", data);
                     if (data.statusCode == 200) {
                         console.log(data.result, "---")
                         if (data.result == "Invalid token or Expired") {
                             setShowSessionPopupup(true)
                         } else if (data.result == "Same title aready exists") {
                             setBtnLoader(false);
                             setIsTitleExist(true)
                         }
                         else if (data.result == "updated successfully") {
                             setSuccess(true);
                             //history.push("/contentmanagement");
                             getBiddingItem();
                             setBtnLoader(false);
                         }
                     }
                 }).catch((error) => {
                     setBtnLoader(false);
                     console.log(error);
                 })
             } else {
                 let addObj = { ...editcontent, createdBy: { userid: userData.userid, username: userData.name }, bidderscount: 0, countiescount: 0 }
                 AddItem(addObj).then((data) => {
                     console.log("Add Content data", data);
                     if (data.statusCode == 200) {
                         console.log(data.result, "---add")
                         if (data.result == "Invalid token or Expired") {
                             setShowSessionPopupup(true)
                         }
                         else if (data.result) {
                             setAddSuccess(true);
                             let id = data.result;
                             setItemId(data.result)
                             // history.push("/edititem/" + id);
 
                             setBtnLoader(false);
                         }
                     }
                 }).catch((error) => {
                     setBtnLoader(false);
                     console.log(error);
                 })
             }
             // 
             // console.log("loader disable");
         } else {
             console.log('form in valid');
         }
     }
     // console.log('catBasedContentFields-->',catBasedContentFields)
     // console.log('editcontent-->',editcontent)
 
 
 
 
     // console.log('category fields', categoryName);
 
     const handleBack = (e) => {
         if (id) {
             history.push({
                 pathname: "/itemmanagement",
                 state: { search: true }
             });
         } else {
             history.push("/itemmanagement")
         }
     }
 
     const getBiddingItem = (e) => {
         const token = localStorage.getItem("token")
         axios({
             method: 'GET',
             url: lambda + '/biddingItems?appname=' + appname + '&itemid=' + id + "&token=" + token,
         })
             .then(function (response) {
                 if (response.data.result == "Invalid token or Expired") {
                     setShowSessionPopupup(true)
                 } else {
                     console.log(response);
                     let result = response.data.result.data[0]
                     if (response.data.result.data.length > 0) {
 
                         setEditContent({ ...editcontent, ...result });
                         setContentTitle(result.title)
 
 
 
                     }
                     else {
                         setInvalidContent(true)
                     }
                 }
             });
 
     }
 
     const AfterAddgetContent = (id) => {
         const token = localStorage.getItem("token")
         axios({
             method: 'GET',
             headers: {
                 'Content-Type': 'application/json'
             },
             url: lambda + '/contentInfo?appname=' + appname + '&contentid=' + id + "&token=" + token,
         })
             .then(function (response) {
                 if (response.data.result == "Invalid token or Expired") {
                     setShowSessionPopupup(true)
                 } else {
                     let result = response.data.result[0]
                     setEditContent({ ...editcontent, ...result });
                     setContentTitle(result.title)
 
 
                     const apiCategory = result.category?.map((eachItem) => { return { value: eachItem, label: eachItem }; })
                     setSelectedOptions(apiCategory);
 
                     const apigenre = result.genre?.map((eachItem) => { return { value: eachItem, label: eachItem }; })
                     setSelectedOptionsgenre(apigenre);
 
                     const apiCountryofOrigin = result.countryoforigin?.map((eachItem) => { return { value: eachItem, label: eachItem }; })
                     setSelectedOptionscountryOfOrigin(apiCountryofOrigin)
                 }
             });
 
     }
 
 
 
     const handleChange = (e, editor) => {
         if (!!errors[e.target.name]) {
             let error = Object.assign({}, errors);
             delete error[e.target.name];
             setErrors(error);
         }
         console.log('e.target.value--->', e.target.value)
         console.log('e.target.value222222222--->', editor);
 
         setEditContent({ ...editcontent, [e.target.name]: e.target.value });
 
     }
     const handleEditorChange = (newContent) => {
         console.log("exxxxxxxxxxxxxxxxxxxxxxxxxxxxx")
         if (errors["synopsis"]) {
             delete errors["synopsis"];
         }
         if (editorFlag === true) {
             setEditContent({ ...editcontent, synopsis: newContent });
         }
         // You can perform any other logic here based on the changed content
     };
     console.log('editcontent', editcontent)
 
 
 
     const returnArray = (arr) => {
         let arr2 = []
         arr.map((eachItem) => {
             arr2.push(eachItem.value)
         })
         // console.log(arr2)
         return arr2
     }
 
     const handleChangeMultiSelectCategory = (selected, key) => {
         setCountryArray({ ...countryArray, [key]: selected })
     }
     function checkAdvObjectProperties(obj) {
        for (const key in obj) {
            if (advancedSearch[key].length > 0) {
                return false
            }

        }
        return true; // All key-value pairs exist in the object
    }
 
     const handleChangeMultiSelectAdvSearch = (selected, key) => {
         if (key === "typesofrights") {
             if (selected === null) {
                 const updatedObject = { ...advancedSearch };
                 delete updatedObject[key];
                 delete updatedObject["revenuetype"];
                 setAdvancedSearch(updatedObject);
             } else {
                 setAdvancedSearch({ ...advancedSearch, [key]: selected.value, revenuetype: selected.revenuetype });
             }
         } else {
             let selectedArray = returnArray(selected)
             setAdvancedSearch({ ...advancedSearch, [key]: selectedArray })
         }
     }
     const handleadvSearchChange = (e, key) => {
         setAdvancedSearch({ ...advancedSearch, [e.target.name]: e.target.value });
     }
 
     console.log("adv", advancedSearch);
  
     const GetLookUp = async (e) => {
         try {
             let arrayType = ["country", "genre", "videoformat", "resolution", "musicgenre", "rights", "cuisine", "sports", "certificate", "language", "territories", "region", "exclusivity"];
 
             const response = await tmdbApi.getBiddingLookUp({
                 type: arrayType
 
             });
             // console.log('response here', response);
             if (response.statusCode == 200) {
                 let arrayType = ["country", "genre", "videoformat", "resolution", "musicgenre", "rights", "cuisine", "sports", "certificate", "language", "territories", "region", "exclusivity"];
 
                 let lookupsData = response.result?.data || []
 
                 arrayType.map((eachItem) => {
                     let arr1 = []
                     lookupsData.map((item1) => {
                         if (item1.type == eachItem) {
                             arr1.push(item1.name)
                             if (item1.type == "rights") {
                                setTypesofrights(typesofrights => ([
                                    ...typesofrights,
                                    item1
                                ]));

                            }
                         }
                     });
                     lookup[eachItem] = arr1
                     setLookUp({ ...lookup });
                 })
                 //  let arrayType = ["country","genre","videoformat","resolution","musicgenre","rights","cuisine","sports","certificate"];
 
                 const countryObj = lookup.country.map((eachItem) => { return { value: eachItem, label: eachItem }; })
                 setCountryData(countryObj)
                 const regionsObj = lookup.region.map((eachItem) => { return { value: eachItem, label: eachItem }; })
                 setRegionsData(regionsObj)
                 const exclusivityObj = lookup.exclusivity
                 setExclusivityData(exclusivityObj)
 
             }
 
         } catch {
             console.log("error");
         }
     }
     console.log(exclusivityData)
     const handlePlayer = (e, content) => {
         console.log("content", content)
         let inn = content && content.subtitles && content.subtitles.FRENCH
         if (content.filetype === "VIDEO" && content?.video?.m3u8?.proxyUrl) {
             let source = window.site.common.proxiesCloudFront + content?.video?.m3u8?.proxyUrl
             // let subtitlePath = window.site.common.proxiesCloudFront +"/"+ content?.subtitles
 
 
             setPlayContent(source);
 
             setPlay(true);
             console.log("playercontent", content);
         }
         if (content.filetype === "AUDIO") {
             let source = window.site.common.proxiesCloudFront + content?.video?.m3u8?.proxyUrl
             setPlayContent(source);
             setPlay(true);
             console.log("playercontent", content);
         }
         // if (content.filetype === "VTT"|| content.filetype === "SRT") {
         //     let source = window.site.common.proxiesCloudFront + content?.video?.m3u8?.proxyUrl
         //     let subtitlePath = window.site.common.proxiesCloudFront + content?.sourcepath
         //     setPlayerSubTitles(subtitlePath)
         //     setPlayContent(source);
         //     setPlay(true);
         //     console.log("playercontent", content);
         // }
 
     }
     const openFileViewer = (e, content) => {
         console.log('contenttt item', content)
         let source;
         // if (content.filetype === "JPG") {
         source = image+ content.sourcepath;
         // console.log("source", source)
         // console.log("source", content)
         // } else {
         //     source = window.site.common.proxiesCloudFront + "/" + content.sourcepath;
         //     console.log("source", source)
         // }
 
         setPlayContent(source);
         setType(content.mimetype);
         setshowDoc(true);
         console.log("source content", content);
         console.log("source content",);
     }
 
     const handleAddFile = (e, item, type) => {
         console.log("item", item);
         console.log("type", type);
         setFileCategory(item);
         setUploadType(type);
         setShowUpload(true);
     }
 
 
 
     const handleClose = (e) => {
         setShowUpload(false);
         setShowSubUpload(false);
         setFileName("");
         ref.current.value = "";
         setExcededFile(false)
         setVideoExcededFile(false)
     }
 
     const handleDeleteFile = (e, fileid, type) => {
         if (type == 'seller') setDelSeller(true)
         setDelete(true);
         console.log('fileid', fileid)
         setItem(fileid);
     }
     const handleDeleteImage = (e, field) => {
         console.log('fileidfileidfileid', field)
         setIsdeleteImage(true);
         setItem(field)
 
     }
     const onCancelDelete = () => {
         setIsdeleteImage(false)
         setItem("")
     }
     const confirmDeleteImage = (e) => {
 
         setBtnLoader(true)
         // let newContent = Object.assign({}, editcontent);
         // delete newContent[item];
         // setEditContent(newContent)
         // console.log('after editcontent', editcontent)
         // setItem("");
 
         imageDeleteFun()
     }
     // console.log('render editcontent',editcontent)
     const handleDelete = (e) => {
         const token = localStorage.getItem("token")
         const userid = localStorage.getItem("userId")
 
         setBtnLoader(true)
         axios({
             method: 'DELETE',
             url: lambda + '/biddingFiles?appname=' + appname + "&itemid=" + id + "&itemfileid=" + item + "&token=" + token + "&userid=" + userid,
         })
             .then(function (response) {
                 if (response) {
                     if (response.data.result == "Invalid token or Expired") {
                         setShowSessionPopupup(true)
                     } else {
                         getItemFiles(id).then((data) => {
                             // console.log("getClientcontent data", data);
                             if (data.statusCode == 200) {
                                 //  console.log(data.result, "---")
                                 if (data.result == "Invalid token or Expired") {
                                     setShowSessionPopupup(true)
                                 } else {
                                     setFilesData(data.result);
                                     setDelete(false)
                                     setBtnLoader(false)
                                     setDelPopup(true)
                                 }
 
                             }
                         }).catch((error) => {
                             setBtnLoader(false)
                             setDelete(false)
                             console.log(error);
                         })
                         // setDelete(true);
                     }
                 }
             });
 
     }
 
     const deleteSellerFun = (item) => {
         // console.log('clientcontentid',item)
         const token = localStorage.getItem("token")
         const userid = localStorage.getItem("userId")
 
         setBtnLoader(true)
         axios({
             method: 'DELETE',
             url: lambda + '/clientcontent?appname=' + appname + "&contentid=" + id + "&clientcontentid=" + item.clientcontentid + "&token=" + token + "&userid=" + userid,
         })
             .then(function (response) {
                 if (response) {
                     if (response.data.result == "Invalid token or Expired") {
                         setShowSessionPopupup(true)
                     } else {
 
                         console.log('responsseeeeeeeeee', response)
 
                         setDelete(false)
                         setBtnLoader(false)
                         setDelPopup(true)
                         setDelSeller(false)
                     }
                 }
             }).catch((error) => {
                 setDelete(false)
                 setBtnLoader(false)
                 console.log(error);
             });
 
     }
     // console.log('sellerContent',sellerContent)
 
     function onCancel() {
         setDelete(false)
         setDelSeller(false)
     }
 
     const ContentFiles = (e) => {
         getItemFiles(id).then((data) => {
             console.log("getClientcontent data", data);
             if (data.statusCode == 200) {
                 // console.log(data.result, "---")
                 if (data.result == "Invalid token or Expired") {
                     setShowSessionPopupup(true)
                 } else {
                     setFilesData(data.result);
                     console.log('data.result--------->', data.result)
                     if (activeTrailerId != "" && activeTrailerId != undefined) {
                         const k = data && data.result && data.result.length > 0 && data.result.filter(eachItem => eachItem.contentfileid === activeTrailerId)
                         // console.log('kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk',k)
                         setActiveSubTitles(k && k[0] && k[0]?.subtitles)
                     }
 
 
                 }
             }
         }).catch((error) => {
             console.log(error);
         })
     }
 
 
 
 
     const uploadS3 = async (e, type) => {
         console.log("type---------", type[2]);
         // setBtnLoader(true);
 
         setBtnLoader(true)
         var fileData = new FormData();
         var file = e.target.files[0];
         if ((fileCategory === "THUMBNAILS" && file.size >= 10000000) || (fileCategory === "DOCUMENTS" && file.size >= 10000000)) {
             setExcededFile(true)
             setBtnLoader(false);
         } else if (fileCategory != "VIDEOS" && file.size >= 1200000000) {
             setVideoExcededFile(true);
             setBtnLoader(false);
         } else {
             console.log("file", file);
             var uploadFilePath = "";
             var filename = e.target.files[0].name;
             var s3file = e.target.files[0];
             fileData.append(type[1], s3file);
             var bucket;
 
             var reader = new FileReader();
             reader.readAsDataURL(s3file);
             reader.onload = function (e) {
                 var image = new Image();
                 image.src = e.target.result;
                 var ObjectID = require("bson-objectid");
                 var fileid = new ObjectID().toString();
 
                 var videoId = type[1]
 
                 let format = file.name.split(".");
                 var fileformat = format[format.length - 1]
                 console.log('file------>', file)
                 console.log('formatttt------>', format)
                 console.log('fileformat------>', fileformat)
                 const timestamp = Date.now();
                 console.log("format", format)
                 console.log("fileformat", fileCategory)
                 let allowedVideoFiles = ["avi", "divx", "f4v", "flv", "mts", "m2t", "m4v", "mkv", "mov", "mp4", "mpeg", "mpg", "mxf", "r3d", "wmv"]
                 let audio = ["aif", "aiff", "m4a", "mp3", "ogg", "wav"]
                 let extension;
                 if (allowedVideoFiles.includes(fileformat)) {
                     extension = "VIDEO"
                 } else if (audio.includes(fileformat)) {
                     extension = "AUDIO"
                 } else {
                     extension = fileformat.toUpperCase()
                 }
 
 
                 if (extension === "VIDEO") {
                     bucket = window.site.common.sourceBucket;
                 } else if (extension === "AUDIO") {
 
                     bucket = window.site.common.sourceBucket;
                 } else {
                     bucket = window.site.common.resourceBucket;
                 }
 
                 console.log("type", file.type)
                 var path = "bidding/" + id;
 
 
 
                 uploadFilePath = appname + "/" + path + "/" + fileid + "_" + fileCategory + "_" + timestamp + "." + fileformat;
 
                 console.log("uploadFilePath", uploadFilePath);
                 // let imagePath = window.site && window.site.common && window.site.common.imagesCDN;
                 var data = { source_bucket: bucket, sourcepath: uploadFilePath }
                 const token = localStorage.getItem("token")
                 const userid = localStorage.getItem("userId")
 
 
 
                 axios.post(lambda + '/uploadFiles?appname=' + appname + "&token=" + token + "&userid=" + userid, data, { type: 'application/json' })
                     .then((response) => {
                         if (response.data && response.data.result) {
                             var url = response.data.result;
 
                             console.log("url", url);
                             axios.put(url, file, {
                                 "headers": {
                                     "Content-Type": "multipart/form-data",
                                     "Accept": "/",
                                     "Cache-Control": "no-cache",
                                     "Accept-Encoding": "gzip, deflate",
                                     "Connection": "keep-alive",
                                     "cache-control": "no-cache"
                                 }
                             })
                                 .then((response) => {
                                     if (response && response.status === 200) {
 
                                         let imageUploadPath = uploadFilePath;
                                         console.log("parth", imageUploadPath);
 
                                         const now = new Date();
                                         const year = now.getFullYear();
                                         const month = (now.getMonth() + 1).toString().padStart(2, '0');
                                         const day = now.getDate().toString().padStart(2, '0');
                                         const hours = now.getHours().toString().padStart(2, '0');
                                         const minutes = now.getMinutes().toString().padStart(2, '0');
                                         const seconds = now.getSeconds().toString().padStart(2, '0');
 
                                         const currentDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
 
                                         // const current = new Date();
                                         // const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
 
 
                                         let allowedVideoFiles = ["avi", "divx", "f4v", "flv", "mts", "m2t", "m4v", "mkv", "mov", "mp4", "mpeg", "mpg", "mxf", "r3d", "wmv"]
                                         let audio = ["aif", "aiff", "m4a", "mp3", "ogg", "wav"]
                                         let extension;
                                         if (allowedVideoFiles.includes(fileformat)) {
                                             extension = "VIDEO"
                                         } else if (audio.includes(fileformat)) {
                                             extension = "AUDIO"
                                         } else {
                                             extension = fileformat.toUpperCase()
                                         }
                                         let uploadObj = {};
 
                                         uploadObj.appname = appname;
 
                                         uploadObj.itemfileid = fileid;
                                         uploadObj.itemid = id;
                                         uploadObj.name = fileName;
                                         uploadObj.contentType = fileCategory;
                                         uploadObj.filename = file.name;
                                         uploadObj.sourcepath = imageUploadPath;
                                         uploadObj.mimetype = fileformat;
                                         // uploadObj.created = date.replaceAll("/", "-");
                                         uploadObj.created = currentDateTime;
                                         uploadObj.filetype = extension;
                                         uploadObj.createdBy = { userid: localStorage.getItem("userId"), username: localStorage.getItem("ClientName") }
 
                                         setBtnLoader(false);
                                         const userid = localStorage.getItem("userId")
 
                                         console.log("obj", uploadObj)
                                         axios({
                                             method: 'PUT',
                                             url: lambda + '/biddingFiles?appname=' + appname + "&token=" + token + "&userid=" + userid,
                                             data: uploadObj,
                                         })
                                             .then(function (response) {
                                                 if (response) {
                                                     setBtnLoader(false)
                                                     setShowUpload(false);
                                                     setSuccess(true)
                                                     ContentFiles();
 
                                                     setFileName("");
                                                     ref.current.value = "";
 
 
                                                 }
                                             }).catch((err) => {
                                                 setBtnLoader(false)
                                                 console.log(err);
                                             });
 
 
 
 
 
                                     }
                                 })
                                 .catch((err) => {
                                     console.error.bind(err);
                                 })
                         }
                     })
                     .catch((err) => {
                         console.error.bind(err);
                     })
 
             }
         }
 
     }
 
     // console.log("userData",userData)
 
     const previewImage = (e, value) => {
         console.log('valueeeee', value)
         setShowPreview(true)
         setPreviewImg(value)
     }
     const defaultImage = (e, path, fileid) => {
         setDefaultShow(true)
         setFileCheck(fileid);
         let defaultObj = {
             "itemimage": path,
             "defaultimage": fileid
         }
         setDefault(id, defaultObj).then((data) => {
             if (data.statusCode == 200) {
                 console.log(data.result, "---add")
                 if (data.result == "Invalid token or Expired") {
                     setShowSessionPopupup(true)
                 }
                 else if (data.result === "updated successfully") {
                     setSuccess(true)
                 }
             }
         }).catch((error) => {
             setBtnLoader(false);
             console.log(error);
         })
     }
     const filesTab = () => {
 
         return (
             <><div className={`tab-pane ${activeTab == 'files' ? 'active show' : ''}`} role="tabpanel" id="videoclips">

                 {videoClipcategories?.map((eachItem, key) => {

                     const items = filesData.filter(item => item.contentType.indexOf(eachItem) !== -1);



                     // if(items && items[0]?.contentType){
                     return (
                         <>

                             {items.length > 0 ?

                                 <div className="row" key={key}>

                                     <div className="col-md-3 d-flex align-items-center">
                                         <h5 className="font-size-14">{eachItem}</h5>
                                         {/* <a className="btn btn-primary" onClick={(e) => handleAddFile(e, eachItem)}><span className="material-icons">add</span>add</a> */}
                                         {/* <div className="add-block" onClick={(e) => handleAddFile(e, eachItem)}>
                              <span className="material-icons-outlined">add</span>
                              add
                          </div> */}
                                     </div>
                                     <div className="col-md-9">

                                     </div>
                                     {filesData?.map((item, key) => {


                                         return (
                                             eachItem == item.contentType && <><div className={item.contentType === "DOCUMENTS" ? "thumbnail-block pdf-block" : "thumbnail-block"}>


                                                 <div className="asset-card">
                                                     {item.filetype == 'VIDEO' && item.status === "AVAILABLE" && <div className="play-icon">
                                                         <span className="material-icons-outlined">play_circle</span>
                                                     </div>}
                                                     <a className="spinner-class" onClick={(e) => (item.filetype == 'VIDEO') || (item.filetype == 'AUDIO') ? handlePlayer(e, item) : item.filetype != 'AUDIO' ? openFileViewer(e, item) : null}>
                                                         {item.contentType === "VIDEOS" && <img src={image + editcontent.itemimage} />} {item.contentType === "THUMBNAILS" && <img src={image + item.sourcepath} />}
                                                         {item.contentType === "DOCUMENTS" && <div className="position-relative">
                                                             <div className="pdf-document">
                                                                 <img src={image+"orasi/admin/resources/orasiv1/images/pdf.png?auto=compress,format"} />
                                                             </div>
                                                         </div>}
                                                     </a>
                                                     <p style={{ clear: 'both', display: "inline-block", overflow: 'hidden', whiteSpace: 'nowrap' }}>{item.name ? item.name : item.filename}</p>

                                                     {/* <button className="btn btn-primary btn-block btn-sm float-end" type="submit" onClick={(e)=>handleAddSubtitle(e, item)}>add Subtitles</button> */}


                                                 </div>
                                                 {item.contentType === "THUMBNAILS" &&
                                                     <div className="edit-info black-gradient landscape-btns">
                                                         <div className="form-check font-size-16">
                                                             {defaultShow === true ? <input className="form-check-input" type="radio" value={item.source} onClick={(e) => defaultImage(e, item.sourcepath, item.itemfileid)} checked={fileCheck === item.itemfileid} /> :
                                                                 <input className="form-check-input" type="radio" value={item.source} onClick={(e) => defaultImage(e, item.sourcepath, item.itemfileid)} checked={editcontent.defaultimage === item.itemfileid} disabled />}
                                                             {/* <label className="form-check-label" for="orderidcheck01">
                                                Set as default
                                            </label> */}
                                                         </div>
                                                     </div>}
                                             </div>

                                             </>

                                         );

                                     })}


                                 </div>
                                 : <div className="row">
                                     <div className="col-md-3 d-flex align-items-center">
                                         <h5 className="font-size-14">{eachItem}</h5>

                                         {/* <a className="btn btn-primary" onClick={(e) => handleAddFile(e, eachItem)}><span className="material-icons">add</span>add</a> */}
                                     </div>
                                     <div className="col-md-9">

                                     </div>
                                     {/* <p>No Files Found</p> */}
                                     <div className="basic-info-img">

                                         {/* <div className="add-block" onClick={(e) => handleAddFile(e, eachItem)}>
                            <span className="material-icons-outlined">add</span>
                            add
                        </div> */}
                                     </div>
                                 </div>}
                         </>
                     );



                     // }
                 })}
                 {play ?

                     <PlayerInfo source={playContent} play={play} setPlay={setPlay} subtitles={playerSubTitles} />
                     : null}
                 {showDoc && <FileViewer source={playContent} type={type} close={setshowDoc} />}
             </div>
             <div className="row status">

                     <div className="col-md-3">
                         <div className="input-field">
                             <label className="col-form-label">status</label>

                             <input className="form-control contact-number" type="text" name="availability" placeholder="Status" id="example-email-input" value={editcontent.status} disabled />
                         </div>
                     </div>

                     <div className="col-md-9 justify-content-end d-flex align-items-center">



                     </div>
                     {(errors.landscapeimage || errors.portraitimage) && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>Please upload a portrait or landscape image in the files tab to activate the content.</span>}

                 </div></>
             
         )
     }
 
     const removeDuplicates = (arr1, arr2) => {
         let res = [];
         res = arr1.filter(el => {
             return !arr2.find(element => {
                 return element.country === el.country && element.typesofrights === el.typesofrights && element.revenuetype === el.revenuetype && element.exclusivity === el.exclusivity;
             });
         });
         return res;
 
     }
 
     const handleCreateCombination = (e, countries) => {
         setTableChange(true)
         setBtnLoader(true)
         setIsLoading(true)
         let mycountry = [];
 
         countryArray && countryArray.countries?.length > 0 && countryArray.countries.map((eachItem) => {
             mycountry.push(eachItem.value);
         })
 
         let myregion = [];
         countryArray && countryArray.region?.length > 0 && countryArray.region.map((eachItem) => {
             myregion.push(eachItem.value);
         })
         //console.log("country",mycountry , myregion);
         let payload = {};
         if (mycountry && mycountry?.length > 0) {
             payload = {
                 "countries": mycountry,
             }
         } else if (myregion && myregion?.length > 0) {
             payload = {
                 "region": myregion,
             }
         }
 
 
         console.log("country", payload);
         CountryCombination(payload).then((data) => {
             console.log("getClientcontent data", data);
             if (data.statusCode == 200) {
                 //  console.log(data.result, "---ssssssssssssssss")
                 let objKeys = Object.keys(data.result)
 
                 //   console.log("objKeys ", objKeys)
 
                 // function arrayLoop(array, array2) {
                 //     let filteredValues = [];
                 //     array.forEach((item) => {
                 //         array2.forEach((item2) => {
                 //             let object = { ...item, ...item2 }
                 //             filteredValues.push(object)
                 //         });
 
                 //     });
                 //     return filteredValues
                 // }
 
 
                 function arrayLoop(array, array2) {
                     let filteredValues = [];
                     if (array.length > 0 && array2.length > 0) {
                         array.forEach((item) => {
                             array2.forEach((item2) => {
                                 let object = { ...item, ...item2 }
                                 filteredValues.push(object)
                             });
 
                         });
                         return filteredValues
                     } else {
                         filteredValues = array
                         return filteredValues
                     }
                 }
 
                 // objKeys.forEach((item) => {
                 //     console.log("item ", item)
                 //     let loopval = arrayLoop(objKeys[item], [])
                 //     console.log("loopval ", loopval)
 
 
                 // });
                 var asyncLoop = require('node-async-loop');
                 let loopvalarray = []
                 asyncLoop(objKeys, function (item, next) {
                     console.log('item!', data.result[item]);
                     loopvalarray = arrayLoop(data.result[item], loopvalarray)
 
                     next();
 
                 }, function (err) {
                     // console.log('loopvalarray!', loopvalarray);
                     // console.log('loopvalarray!!!!!!!!!!!', JSON.stringify(loopvalarray));
                     // console.log('Finished!');
 
                 });
 
                 const updatedArrayOfObjects = loopvalarray.map((obj) => ({
                     ...obj,
                     period: 1,
                     itemid: id,
                 }));
 
                 updatedArrayOfObjects.sort((a, b) => {
                     const nameA = a.country.toUpperCase(); // Convert names to uppercase for case-insensitive sorting
                     const nameB = b.country.toUpperCase();
 
                     if (nameA < nameB) {
                         return -1; // a should come before b in the sorted order
                     }
                     if (nameA > nameB) {
                         return 1; // a should come after b in the sorted order
                     }
                     return 0; // a and b are equal
                 });
                 setIsLoading(false)
 
                 console.log('updatedArrayOfObjects!', updatedArrayOfObjects);
                 const uniqueArray = removeDuplicates(updatedArrayOfObjects, comparecombination);
                 console.log('uniqueArray!', uniqueArray);
                 setCombinationData(uniqueArray);
                 setShowCombinationPopup(false);
                 setInitialFlag(true);
                 setBtnLoader(false);
                 setCountryArray({ ...countryArray, countries: "", region: "" })
 
             }
         }).catch((error) => {
             console.log(error);
         })
     }
 
 
     const handlePageChange = (page) => {
         setCurrentPage(page);
     };
 
     const handlePerRowsChange = (newPerPage) => {
         setRowsPerPage(newPerPage);
     };
     const handleShowPopup = () => {
         setShowCombinationPopup(true)
     }
 
     const handleShowAdvancedSearchPopup = () => {
         setShowAdvancedSearchPopup(true)
     }
     const handleCancelCombination = () => {
         setCancelCombinationPopup(true)
     }
     const handleSaveCombination = () => {
         const newArray = combinationData.map(obj => ({ ...obj, title: contentTitle , itemimage:editcontent.itemimage }));
         const filteredData = newArray.filter(item => item.hasOwnProperty('minbidprice'));
         console.log("filteredData", filteredData)
         setBtnLoader(true)
         SaveCombination(id, filteredData).then((data) => {
             setInitialFlag(false);
             setEnableEdit(false);
             setRefreshTable(false);
             setTableChange(false);
             if (data.statusCode == 200) {
                 console.log(data, "mydata------------->")
                 if (data.result == "Invalid token or Expired") {
                     setShowSessionPopupup(true)
 
                 } else {
                     setAddComb(true)
                     setBtnLoader(false)
                     getCombination(id).then((data) => {
                         console.log("getClientcontent data", data);
                         if (data.statusCode == 200) {
                             console.log(data, "data------------->")
                             if (data.result == "Invalid token or Expired") {
                                 setShowSessionPopupup(true)
                             } else {
                                 if (data.result.data.length > 0) {
 
                                     let tmpData = data.result.data.map((obj) => ({
                                         ...obj,
                                         isSelected: false
                                     }));
                                     setCombinationData(tmpData);
 
                                 }
 
                             }
                         }
                     }).catch((error) => {
                         console.log(error);
                     })
 
 
                 }
             }
         }).catch((error) => {
             console.log(error);
             setBtnLoader(false)
         })
     }
     const handleUpdateCombination = () => {
         const result = combinationData.filter((item1) => {
             // Check if item1.id is not present in array2
             return combinationArray.some((item2) => item2.combinationid === item1.combinationid);
         });
 
         const filteredData = result.map(({ combinationid, minbidprice }) => ({ combinationid, minbidprice }));
 
         UpdateCombination(id, filteredData).then((data) => {
             setBtnLoader(true)
             if (data.statusCode == 200) {
                 console.log(data, "mydata------------->")
                 if (data.result == "Invalid token or Expired") {
                     setShowSessionPopupup(true)
 
                 } else {
                     setUpdateComb(true);
                     setBtnLoader(false)
                     setInitialFlag(false);
                     getCombination(id).then((data) => {
                         console.log("getClientcontent data", data);
                         if (data.statusCode == 200) {
                             console.log(data, "data------------->")
                             if (data.result == "Invalid token or Expired") {
                                 setShowSessionPopupup(true)
                             } else {
                                 if (data.result.data.length > 0) {
 
                                     let tmpData = data.result.data.map((obj) => ({
                                         ...obj,
                                         isSelected: false
                                     }));
                                     setCombinationData(tmpData);
 
                                 }
 
                             }
                         }
                     }).catch((error) => {
                         console.log(error);
                     })
 
 
                 }
             }
         }).catch((error) => {
             console.log(error);
             setBtnLoader(false);
         })
     }
     const handleSearch = () => {
         setIsLoading(true)
         setAdvancedSearch({})
         getCombinationSearch(id, search).then((data) => {
             console.log("getClientcontent data", data);
             setIsLoading(false)
             if (data.statusCode == 200) {
                 console.log(data, "data------------->")
                 if (data.result == "Invalid token or Expired") {
                     setShowSessionPopupup(true)
                 } else {
 
                     let tmpData = data.result.data.map((obj) => ({
                         ...obj,
                         isSelected: false
                     }));
                     setCombinationData(tmpData);
                 }
             }
         }).catch((error) => {
             console.log(error);
         })
 
     }
     const handleAdvSearch = () => {
        for (const key in advancedSearch) {
            if (advancedSearch.hasOwnProperty(key)) {

                if (advancedSearch[key] === null || advancedSearch[key] === undefined || advancedSearch[key] === '' || advancedSearch[key].length <= 0) {
                    delete advancedSearch[key];
                }
            }
        }
         if (advancedSearch["min"] != undefined) {
             advancedSearch["min"] = parseInt(advancedSearch["min"]);
         }
         if (advancedSearch["max"] != undefined) {
             advancedSearch["max"] = parseInt(advancedSearch["max"]);
         }
         if (advancedSearch["period"] != undefined) {
             advancedSearch["period"] = parseInt(advancedSearch["period"]);
         }
       
         setBtnLoader(true)
         setSearch({ ...search, country: "" })
         getCombinationAdvSearch(id, advancedSearch).then((data) => {
             setBtnLoader(false)
             console.log("getClientcontent data", data);
             setShowAdvancedSearchPopup(false)
             if (data.statusCode == 200) {
                 console.log(data, "data------------->")
                 if (data.result == "Invalid token or Expired") {
                     setShowSessionPopupup(true)
                 } else {
 
                     let tmpData = data.result.data.map((obj) => ({
                         ...obj,
                         isSelected: false
                     }));
                     setCombinationData(tmpData);
                 }
             }
         }).catch((error) => {
             console.log(error);
         })
 
     }
     const customNoRecords = () => {
         return (
 
             <div className="empty-state-body empty-record"  >
                 <div className="empty-state__message">
                     <span className="material-icons">summarize</span>
                     <p className="form-check font-size-16">No content was found for the searched keyword</p>
                 </div> </div>
         )
     }
 
     const handleCalculate = (e) => {
 
 
         if (percentage > 0) {
             const keyToUpdate = "minbidprice";
             const updatedArray = combinationData.map((obj) => {
                 // Create a copy of the object to avoid mutating it directly
                 const updatedObject = { ...obj };
 
                 // Check if the object has the specified key
                 if (updatedObject.hasOwnProperty(keyToUpdate)) {
                     // Update the value for the key
                     let value = updatedObject[keyToUpdate] * percentage / 100;
                     console.log(value)
                     updatedObject[keyToUpdate] = Math.round(updatedObject[keyToUpdate] + value)
                 }
 
                 return updatedObject;
             });
 
             setCombinationData(updatedArray);
             setRefreshTable(true);
 
         }
         if (percentage < 0) {
             const keyToUpdate = "minbidprice";
             const updatedArray = combinationData.map((obj) => {
                 const updatedObject = { ...obj };
 
                 if (updatedObject.hasOwnProperty(keyToUpdate)) {
                     let value = updatedObject[keyToUpdate] * (-percentage) / 100;
                     console.log(value)
                     updatedObject[keyToUpdate] = Math.round(updatedObject[keyToUpdate] - value);
 
                 }
 
                 return updatedObject;
             });
             console.log("updatedArray", updatedArray)
             setCombinationData(updatedArray);
             setRefreshTable(true);
         }
     }
 
     const handleEditCalculate = (e) => {
 
         if (percentage > 0) {
             const keyToUpdate = "minbidprice";
             const updatedArray = combinationData.map((obj) => {
                 // Create a copy of the object to avoid mutating it directly
                 const updatedObject = { ...obj };
 
                 // Check if the object has the specified key
                 if (updatedObject.hasOwnProperty(keyToUpdate) && updatedObject.hasOwnProperty('isSelected') && updatedObject.isSelected === true) {
                     // Update the value for the key
                     let value = updatedObject[keyToUpdate] * percentage / 100;
                     console.log(value)
                     updatedObject[keyToUpdate] = Math.round(updatedObject[keyToUpdate] + value)
                 }
 
                 return updatedObject;
             });
             //   console.log("updatedObject",updatedArray);
 
             setCombinationData(updatedArray);
             //   setRefreshTable(true);
 
         }
         if (percentage < 0) {
             const keyToUpdate = "minbidprice";
             const updatedArray = combinationData.map((obj) => {
                 const updatedObject = { ...obj };
 
                 if (updatedObject.hasOwnProperty(keyToUpdate)) {
                     let value = updatedObject[keyToUpdate] * (-percentage) / 100;
                     console.log(value)
                     updatedObject[keyToUpdate] = Math.round(updatedObject[keyToUpdate] - value);
 
                 }
 
                 return updatedObject;
             });
             console.log("updatedArray", updatedArray)
             setCombinationData(updatedArray);
             // setRefreshTable(true);
         }
     }
 
     const handleChangePrice = (e, key, index) => {
         let updatedObject = {}
         if (refreshTable === true) {
             setRefreshTable(false)
         }
         let numberValue = parseInt(e.target.value)
         console.log("key", key);
         if (!Number.isNaN(numberValue)) {
             updatedObject = { ...key, minbidprice: parseInt(e.target.value) };
         } else {
             updatedObject = { ...key, minbidprice: '' };
         }
         console.log("index ", index)
         let newArr = [...combinationData]; // copying the old datas array
         newArr[index] = updatedObject; // replace e.target.value with whatever you want to change it to
         setCombinationData(newArr);
     }
 
 
 
     const onUpdateConfirm = () => {
         setEnableEdit(false);
         setCombinationArray([]);
         setUpdateComb(false)
     }
 
     const handleEnableEdit = () => {
         setEnableEdit(true)
     }
     const onCancelUpdate = () => {
         setEnableEdit(false);
         setCombinationArray([]);
         getCombination(id).then((data) => {
             console.log("getClientcontent data", data);
             if (data.statusCode == 200) {
                 console.log(data, "data------------->")
                 if (data.result == "Invalid token or Expired") {
                     setShowSessionPopupup(true)
                 } else {
                     if (data.result.data.length > 0) {
 
                         let tmpData = data.result.data.map((obj) => ({
                             ...obj,
                             isSelected: false
                         }));
                         setCombinationData(tmpData);
 
                     }
 
                 }
             }
         }).catch((error) => {
             console.log(error);
         })
     }
     const clearAdvSearch = () => {
        setResetKey((prevKey) => (prevKey + 1) % 2);
         setAdvancedSearch({ ...advancedSearch, country: "",region:"", typesofrights: "", revenuetype: "", period: "", exclusivity: "", min: "", max: "" })
         setBtnLoader(false)
     }
     const clearSearch = () => {
         if (initialFlag === true) {
 
             const updatedArray = combinationData.map(obj => {
                 // Clone the original object to avoid mutating state directly
                 const modifiedObj = { ...obj };
 
                 // Check if the key exists in the object
                 if (modifiedObj.hasOwnProperty("minbidprice")) {
                     // Set the value of the specified key to empty ('' or null, depending on your preference)
                     modifiedObj["minbidprice"] = ""
                 }
 
                 return modifiedObj;
             });
             setCombinationData(updatedArray);
             setRefreshTable(true)
 
         } else {
             setAdvancedSearch({ ...advancedSearch, country: "", typesofrights: "", revenuetype: "", period: "", exclusivity: "", min: "", max: "" })
             setSearch({ ...search, country: "" })
             setIsLoading(true)
             getCombination(id).then((data) => {
                 setIsLoading(false)
                 console.log("getClientcontent data", data);
                 if (data.statusCode == 200) {
                     console.log(data, "data------------->")
                     if (data.result == "Invalid token or Expired") {
                         setShowSessionPopupup(true)
                     } else {
                         if (data.result.data.length > 0) {
 
                             let tmpData = data.result.data.map((obj) => ({
                                 ...obj,
                                 isSelected: false
                             }));
                             setCombinationData(tmpData);
 
                         }
 
                     }
                 }
             }).catch((error) => {
                 console.log(error);
             })
         }
     }
     const onCancelCombination = () => {
         setPercentage("")
         // setEnableEdit(false)
         setCancelCombinationPopup(false)
         setInitialFlag(false);
         setTableChange(false)
         getCombination(id).then((data) => {
             console.log("getClientcontent data", data);
             if (data.statusCode == 200) {
                 console.log(data, "data------------->")
                 if (data.result == "Invalid token or Expired") {
                     setShowSessionPopupup(true)
                 } else {
                     if (data.result.data.length > 0) {
 
                         let tmpData = data.result.data.map((obj) => ({
                             ...obj,
                             isSelected: false
                         }));
                         setCombinationData(tmpData);
 
                     }
 
                 }
             }
         }).catch((error) => {
             console.log(error);
         })
 
     }
 
     let selectedItems = [];
     const onSelected = (e, row) => {
         let obj = {
             "combinationid": row?.combinationid,
             "minbidprice": row.minbidprice
         }
         let selectedItemsCopy = [...selectedItems];
         if (e.target.checked) {
             selectedItemsCopy.push(obj);
             setCombinationArray((prevArray) => [...prevArray, obj]);
         } else {
             setCombinationArray([]);
             let filterObj = combinationArray.filter(function (el) { return el.combinationid != obj.combinationid && el.minbidprice != obj.minbidprice; });
             setCombinationArray(filterObj);
         }
         selectedItems = [...selectedItemsCopy];
         let tmpData = [...combinationData];
         tmpData = tmpData.map((itm) => {
             if (itm?._id === row._id) {
                 itm.isSelected = e.target.checked
             }
             return itm;
         });
         setCombinationData(tmpData)
 
     }
     console.log("combinationArray", combinationArray)
     const onSelectAll = (e) => {
         selectedItems = [];
         let tmpData = [...combinationData];
         let isSelected = e.target.checked;
         if (isSelected) {
             tmpData.forEach(item => {
                 let obj = {
                     "combinationid": item?.combinationid,
                     "minbidprice": item.minbidprice
                 }
                 selectedItems.push(obj)
             })
         }
         tmpData = tmpData.map((obj) => ({
             ...obj,
             isSelected: isSelected
         }));
         setCombinationData(tmpData);
         // console.log("tmpData",selectedItems);
         setCombinationArray(selectedItems);
     }
 
      console.log("combinationData ",combinationData);
     const columns = [
         {
             name: 'country',
             selector: row => row.country,
             sortable: !initialFlag,
         },
         {
             name: 'region',
             selector: row => row.region && row.region.length > 1 ? row.region.join(", ") : row.region,
             sortable: !initialFlag,
         },
         {
             name: 'types of rights',
             selector: row => row.typesofrights,
             sortable: !initialFlag,
         },
         {
             name: 'revenue',
             // cell: (props) => <img src={props.image} width={60} alt='Image' />,
             selector: row => row.revenuetype,
             sortable: !initialFlag,
 
         },
 
         {
             name: 'exclusivity',
             selector: row => row.exclusivity,
             // cell: (props) => <div className="text-elipsis">{props.comments}</div>,
             sortable: !initialFlag,
         },
         {
             name: 'period',
             // selector: row => moment(row.updated).utc().format("DD-MMM-YYYY HH:mm"),
             selector: row => row.period + " year",
 
         },
 
 
         {
             name: <>{'min price (usd)'}</>,
             selector: (row, index) => {
 
 
                 if (initialFlag === true) {
                     return (<div className="d-flex price-input">
                         {/* <span className="input-group-text">$</span> */}
                         <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)" onKeyUp={(e) => handleChangePrice(e, row, index)} value={refreshTable ? row.minbidprice : null} />
                     </div>)
                 }
                 else if (enableEdit === true && row.isSelected) {
 
                     return (
                         <div className="d-flex price-input">
                             {/* <span className="input-group-text">$</span> */}
                             <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)" value={row.minbidprice} onChange={(e) => handleChangePrice(e, row, index)} />
                         </div>
                     )
                 } else if (refreshTable) {
                     return (<div className="d-flex price-input">
                         {/* <span className="input-group-text">$</span> */}
                         <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)" onKeyUp={(e) => handleChangePrice(e, row, index)} value={row.minbidprice} />
                     </div>)
                 }
                 else {
                     return (<><div>
                         {/* <span className="input-group-text">$ */}
                         {row.minbidprice}
                         {/* </span> */}
                     </div></>)
                 }
             }
             ,
             ignoreRowClick: true,
             allowOverflow: true,
             button: true,
         },
     ];
    //  if (initialFlag === false) {
    //      columns.unshift({
    //          name: <div class="form-check font-size-16">
    //              <input class="form-check-input" type="checkbox" id="orderidcheck01" onClick={(e) => onSelectAll(e)} />
    //              <label class="form-check-label" for="orderidcheck01"></label>
    //          </div>,
    //          selector: (row, index) => <div class="form-check font-size-16">
    //              <input class="form-check-input" type="checkbox" checked={row?.isSelected} onClick={(e) => onSelected(e, row)} />
    //              <label class="form-check-label" for="orderidcheck01"></label>
    //          </div>,
    //      });
    //  }
     const handleCloseCombinationPopup = () => {
         setShowCombinationPopup(false);
         setCountryArray({ ...countryArray, countries: "", region: "" })
     }
     const handleCloseAdvacedSearchPopup = () => setShowAdvancedSearchPopup(false);
     const handleChangeSearch = (e) => {
         setSearch({ ...search, "country": [e.target.value] })
     }
     const handleCheck = (e) => {
         let keyExists = true;
 
         for (const obj of combinationData) {
             if (obj.hasOwnProperty("minbidprice") && obj["minbidprice"] > 0) {
                 keyExists = false;
                 break; // Exit the loop as soon as the key is found in one object
             }
         }
         return keyExists;
     }
     const handleKeypress = (e) => {
        //it triggers by pressing the enter key

        if ((e.key === "Enter")) {
            setTimeout(function () {
                handleSearch();
            }, 10);
        }
    };
     console.log(combinationData);
     const combinationTab = () => {
         return (<><div className={`tab-pane ${activeTab == 'combination' ? 'active show' : ''}`} role="tabpanel">
             <div className="user-management clients deals create-combination">
                 <div className="row table-data">
                     <div className="col-12">
                         <div className="card">

                             <div className="card-body">
                                 <div className="row mb-2">
                                     <div className="col-sm-4">
                                         <div className="dataTables_length" id="datatable_length">
                                             {/* <button className="fill_btn combination-btn"
        onClick={handleShowPopup}
    >Create combination</button> */}
                                         </div>
                                     </div>
                                     <div className="col-sm-8">
                                         <div className="search-box mb-2 d-inline-block">
                                             {combinationArray && combinationArray.length > 0 &&
                                                 <>
                                                     <div className="dataTables_length" id="datatable_length">
                                                         <button className="fill_btn" onClick={handleEnableEdit}><i className="mdi mdi-pencil font-size-18"></i>Edit</button>
                                                     </div></>}
                                             {initialFlag === false && <><div className="position-relative">
                                                 <input type="text" className="form-control"
                                                     value={search.country}
                                                     onChange={(e) => handleChangeSearch(e)}
                                                     onKeyPress={handleKeypress}
                                                     placeholder="Search by country" />
                                                 <button className="fill_btn"><span className="material-icons search-icon" onClick={handleSearch}>search</span></button>
                                             </div>
                                                 <div className="dataTables_length" id="datatable_length">
                                                     <button className="fill_btn"
                                                         onClick={handleShowAdvancedSearchPopup}
                                                     >Advanced Search</button>
                                                 </div></>}
                                             <div className="dataTables_length" id="datatable_length">
                                                 <button className="fill_btn" onClick={clearSearch}><span className="material-icons-outlined">sync</span>Reset</button>
                                             </div>

                                         </div>
                                         <div className="text-sm-end">

                                         </div>
                                     </div>
                                 </div>


                                 {tableChange === true ? <div className="addCombination"><DataTable
                                     // title=""
                                     columns={columns}
                                     // className="table align-middle table-nowrap table-check"
                                     keyField='_id'
                                     data={combinationData}
                                     direction="auto"
                                     highlightOnHover
                                     fixedHeaderScrollHeight="300px"
                                     // pagination
                                     responsive
                                     persistTableHead
                                     // selectableRowsVisibleOnly
                                     striped
                                     // selectableRowsHighlight
                                     // selectableRows
                                     subHeaderAlign="right"
                                     defaultSortField="name"
                                     subHeaderWra
                                     noDataComponent={customNoRecords()}
                                     // paginationTotalRows={data && data.length}
                                     // onChangeRowsPerPage={handlePerRowsChange}
                                     // onChangePage={handlePageChange}
                                     // paginationPerPage={rowsPerPage}
                                     //  paginationDefaultPage={currentPageNew}
                                     // paginationComponentOptions={{
                                     //     rowsPerPageText: 'Per page:',
                                     //     rangeSeparatorText: 'of',
                                     //     noRowsPerPage: false,
                                     //     selectAllRowsItem: false,
                                     //     selectAllRowsItemText: 'All',
                                     // }}
                                     progressPending={isLoading}
                                     progressComponent={<TableLoader />} /> </div> : <div className="viewCombination"><DataTable
                                         // title=""
                                         columns={columns}
                                         // className="table align-middle table-nowrap table-check"
                                         keyField='_id'
                                         data={combinationData}
                                         direction="auto"
                                         highlightOnHover
                                         fixedHeaderScrollHeight="300px"
                                         pagination
                                         responsive
                                         persistTableHead
                                         // selectableRowsVisibleOnly
                                         striped
                                         // selectableRowsHighlight
                                         // selectableRows
                                         subHeaderAlign="right"
                                         defaultSortField="name"
                                         subHeaderWra
                                         noDataComponent={customNoRecords()}
                                         paginationTotalRows={combinationData && combinationData.length}
                                         onChangeRowsPerPage={handlePerRowsChange}
                                         onChangePage={handlePageChange}
                                         paginationPerPage={rowsPerPage}
                                         paginationDefaultPage={currentPageNew}
                                         paginationRowsPerPageOptions={[15, 25, 50, 75, 100]}
                                         paginationComponentOptions={{
                                             rowsPerPageText: 'Per page:',
                                             rangeSeparatorText: 'of',
                                             noRowsPerPage: false,
                                             selectAllRowsItem: false,
                                             selectAllRowsItemText: 'All',
                                         }}

                                         progressPending={isLoading}
                                         progressComponent={<TableLoader />} /> </div>}



                                 {initialFlag &&
                                     <> <div className="row mt-2">
                                         <div className="col-md-7"></div>
                                         <div className="col-md-3 increase-btn">
                                             <div className="d-flex align-items-center">
                                                 {/* <label for="example-text-input" class="col-form-label">Increase/decrease by</label> */}
                                                 <input type="number" className="form-control" placeholder="%" value={percentage} onChange={(e) => setPercentage(e.target.value)} disabled={handleCheck()} /><span className="percentage">%</span>
                                                 <button className="fill_btn" onClick={handleCalculate}><span className="material-icons-outlined search-icon">check</span></button>
                                             </div>
                                         </div>
                                         <div className="col-md-2 combination-btn-grp">
                                             <button className="fill_btn" onClick={e => handleCancelCombination()}>Close</button>
                                             <button className={`fill_btn combination-btn ${handleCheck() ? "disable" : ""}`} disabled={handleCheck()} onClick={handleSaveCombination}>{BtnLoader ? (<img src="orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg" className="loading-icon" />) : null}save & done</button>
                                         </div>
                                     </div>

                                     </>}
                                 {combinationArray && combinationArray.length > 0 && enableEdit === true &&
                                     <div className="row mt-2">
                                         <div className="col-md-7"></div>
                                         <div className="col-md-3 increase-btn">
                                             <div className="d-flex align-items-center">
                                                 {/* <label for="example-text-input" class="col-form-label">Increase/decrease by</label> */}
                                                 <input type="number" className="form-control" placeholder="%" value={percentage} onChange={(e) => setPercentage(e.target.value)} /><span className="percentage">%</span>
                                                 <button className="fill_btn" onClick={handleEditCalculate}><span className="material-icons-outlined search-icon">check</span></button>
                                             </div>
                                         </div>
                                         <div className="col-md-2 combination-btn-grp">
                                             <button className="fill_btn" onClick={e => onCancelUpdate()}>cancel</button>
                                             <button className="fill_btn combination-btn" onClick={handleUpdateCombination}>{BtnLoader ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}Update</button>

                                         </div></div>}

                             </div>

                         </div>
                     </div>
                 </div>
             </div>



         </div><div className="row status">

                 <div className="col-md-3">
                     <div className="input-field">
                         <label className="col-form-label">status</label>

                         <input className="form-control contact-number" type="text" name="availability" placeholder="Status" id="example-email-input" value={editcontent.status} disabled />
                     </div>
                 </div>

                 <div className="col-md-9 justify-content-end d-flex align-items-center">



                 </div>
                 {(errors.landscapeimage || errors.portraitimage) && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>Please upload a portrait or landscape image in the files tab to activate the content.</span>}

             </div></>
         )
 
 
 
     }
     const historyTab = () => {
         return (<div className={`tab-pane ${activeTab == 'history' ? 'active show' : ''}`} role="tabpanel">
 
             <div className="row">
                 <div className="col-md-12">
                     <label>Created By  :</label> <span>{editcontent && editcontent.createdBy && editcontent.createdBy.username}</span><br />
                     <label>Updated Time :</label> <span>{new Date(editcontent.created).toLocaleDateString('en-IN', {
                         day: 'numeric',
                         month: 'short',
                         year: 'numeric',
                         hour: 'numeric',
                         minute: 'numeric',
                     })}</span>
 
                 </div>
 
             </div>
 
 
         </div>)
     }
 
 
     const handlePreview = () => {
         setShowPreview(false)
     }
     //  var myDate = new Date(editcontent.releasedate);
 
 
 
     const cardWidth = $(".basic-info-img").width();
 
     const handleBread = () => {
         history.push("/itemmanagement")
     }
 
     const onClickSwitchTab = (e, typeOfTab) => {
         setActiveTab(typeOfTab)
 
     }
     const onclickInvalid = () => {
         setInvalidContent(false)
         history.push('/itemmanagement')
     }
     // console.log('sellerContent',sellerContent)
     const validateMandatoryFields = () => {
         let flag = true;
 
         let error = { ...errors }
         let k;
         if (editcontent.status === "COMING SOON" || editcontent.status === "ACTIVE") {
             k = [{ name: 'Title', key: 'title' },
             { name: 'Availability', key: 'availability' },
             { name: 'Bidstartdate', key: 'bidstartdate' },
             { name: 'Synopsis', key: 'synopsis' }
             ];
         } else {
             k = [{ name: 'Title', key: 'title' },
             { name: 'Status', key: 'status' },
             ];
         }
 
         console.log("k", k);
 
         if (flag) {
             k.forEach(function (item) {
                 // console.log(' k flag 11111')
                 if (editcontent[item.key] == "" || editcontent[item.key] == undefined || editcontent[item.key] == "undefined") {
                     // console.log(' k flag 22222222')
                     error[item.key] = item.name + " is required";
                     flag = false;
                 }
 
             });
         }
 
         if (editcontent.status === "COMING SOON" || editcontent.status === "ACTIVE") {
             if (editcontent.synopsis != undefined) {
                 var tmpdetails = editcontent.synopsis
                 tmpdetails = tmpdetails.replace("<p>", "").trim()
                 tmpdetails = tmpdetails.replace("</p>", "").trim()
                 console.log("tmpdetails", tmpdetails);
                 if (tmpdetails == "<br>" || tmpdetails == "") {
                     error["synopsis"] = "synopsis is required";
                     flag = false;
                 }
             }
         }
         // if (editcontent.bidstartdate < moment(new Date().toISOString()).format('YYYY-MM-DD')) {
         //     error["bidstartdate"] = "bidstartdate must be future data";
         //     flag = false;
         // }
 
         console.log("error", error)
         setErrors(error)
 
 
         return flag;
     }
     // console.log('activeTabactiveTab', activeTab)
     console.log('release date', typesofrights)
     return (
         <>
             {showSessionPopupup && <SessionPopup />}
             <div id="layout-wrapper">
                 <Header />
                 <Sidebar />
                 <SweetAlert show={invalidContent}
                     custom
                     confirmBtnText="ok"
                     confirmBtnBsStyle="primary"
                     title={"Items Not Found"}
                     onConfirm={e => onclickInvalid()}
                 >
                 </SweetAlert>
                 {!invalidContent &&
                     <div className="main-content create-user edit-content add-client">
 
                         <div className="page-content ">
                             <div className="container-fluid">
 
 
 
                                 <div className="row mb-4 breadcrumb">
                                     <div className="col-lg-12">
                                         <div className="d-flex align-items-center">
                                             <div className="flex-grow-1">
                                                 <h4 className="mb-2 card-title">{id === undefined ? "Add Item" : contentTitle}</h4>
                                                 <p className="menu-path"><span onClick={handleBread}>Item  Management</span> / <b>{id === undefined ? "Add Item" : contentTitle}</b></p>
                                             </div>
                                             <div>
                                                 <a onClick={handleBack} className="btn btn-primary">back</a>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                                 <div className="create-user-block content_edit edit-item">
 
                                     <div className="form-block">
                                         <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                                             <li className="nav-item" role="presentation">
                                                 <a className={`nav-link ${activeTab == 'info' ? 'active' : ''}`} style={{ cursor: 'pointer' }} data-bs-toggle="tab" role="tab" onClick={(e) => onClickSwitchTab(e, "info")}>
                                                     <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                                     <span className="d-none d-sm-block">item details</span>
                                                 </a>
                                             </li>
                                             {/* {(id !== undefined || isInfoFormValid == true) && <li className="nav-item" role="presentation">
                                                  <button className={`nav-link ${activeTab == 'seller' ? 'active' : ''}`}
                                                      style={{ cursor: 'pointer' }}
                                                      data-bs-toggle="tab" role="tab" onClick={(e) => onClickSwitchTab(e, "seller")}
                                                  //   disabled={!isValidInfo}
                                                  >
                                                      <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                                      <span className="d-none d-sm-block">SELLER</span>
                                                  </button>
                                              </li>} */}
                                             {(id !== undefined || isInfoFormValid == true) ?
 
                                                 <li className="nav-item" role="presentation">
                                                     <a className={`nav-link ${activeTab == 'files' ? 'active' : ''}`} style={{ cursor: 'pointer' }} data-bs-toggle="tab" role="tab" onClick={(e) => onClickSwitchTab(e, "files")} >
                                                         <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                                         <span className="d-none d-sm-block">Files</span>
                                                     </a>
                                                 </li>
                                                 : null}
                                             {id !== undefined ?
 
                                                 <li className="nav-item" role="presentation">
                                                     <a className={`nav-link ${activeTab == 'combination' ? 'active' : ''}`} style={{ cursor: 'pointer' }} data-bs-toggle="tab" role="tab" onClick={(e) => onClickSwitchTab(e, "combination")} >
                                                         <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                                         <span className="d-none d-sm-block">combination</span>
                                                     </a>
                                                 </li>
                                                 : null}
                                             {/* {id !== undefined ?
 
                                                 <li className="nav-item" role="presentation">
                                                     <a className={`nav-link ${activeTab == 'history' ? 'active' : ''}`} style={{ cursor: 'pointer' }} data-bs-toggle="tab" role="tab" onClick={(e) => onClickSwitchTab(e, "history")} >
                                                         <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                                         <span className="d-none d-sm-block">history</span>
                                                     </a>
                                                 </li>
                                                 : null} */}
 
 
 
 
                                             {/* <li className="nav-item" role="presentation">
                                              <a className="nav-link" data-bs-toggle="tab" href="#images" role="tab" aria-selected="false" tabIndex="-1">
                                                  <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                                  <span className="d-none d-sm-block">IMAGES</span>
                                              </a>
                                          </li> */}
 
 
                                         </ul>
                                         <div className="tab-content p-3 text-muted">
                                             {activeTab == "info" && <div className={`tab-pane ${activeTab == 'info' ? 'active show' : ''}`} role="tabpanel" id="home1">
                                             {Object.keys(editcontent).length > 0 ?
                                                    <>
                                                 <div className="row">
                                                     <h5 className="font-size-14"><i className="mdi mdi-arrow-right"></i> item details</h5>
                                                     <div className="col-md-12">
                                                         <div className="row basic-details">
 
                                                             <div className="col-md-6">
                                                                 <div className="input-field">
                                                                     <label for="example-text-input" className="col-form-label">Item name</label>
                                                                     <input className="form-control contact-number" type="text" name="title" placeholder="Enter title name" id="example-email-input" value={editcontent.title} disabled/>
                                                                     
                                                                 </div>
                                                             </div>
                                                             <div className="col-md-6">
                                                                 <div className="input-field">
                                                                     <label for="example-text-input" className="col-form-label">availability</label>
                                                                     <input className="form-control contact-number" type="text" name="availability" placeholder="Enter availability" id="example-email-input"  value={editcontent.availability} disabled/>
                                                                     
                                                                 </div>
                                                             </div>
                                                             <div className="col-md-6">
                                                                 <div className="input-field">
                                                                     <label className="form-label form-label">bid start Date</label>
                                                                     <input className="form-control contact-number" type="text" name="availability" placeholder="Bid start date" id="example-email-input"  value={editcontent.bidstartdate ? new Date(editcontent.bidstartdate).toLocaleDateString('en-IN', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
            }):null } disabled/>
                                                                 </div>
                                                             </div>
                                                             {id && editcontent && editcontent.status === 'CLOSED' && <div className="col-md-6">
                                                                 <div className="input-field">
                                                                     <label className="form-label form-label">Bid End Date</label>
                                                                     <input className="form-control contact-number" type="text" name="availability" placeholder="Bid end data" id="example-email-input"  value={editcontent.bidenddate ? new Date(editcontent.bidenddate).toLocaleDateString('en-IN', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
            }):null } disabled/>
                                                                 </div>
                                                             </div>}
 
                                                             
                                                             <div className="col-md-12">
                                                                 <div className="input-field">
                                                                     <label className="col-form-label">details</label>
                                                                     <div>  <ReactQuill theme="snow" name="editor" value={editcontent.synopsis} readOnly/></div>
                                                                    
                                                                 </div>
                                                             </div>
 
                                                         </div>
                                                     </div>
                                                 </div></>
 
                                                 :
                                                    <div className="create-user-block"><div className="form-block">
                                                        <div className="tab-content p-3 text-muted">
                                                            <div className="tab-pane active show" id="home1" role="tabpanel">
                                                                <div className="row"><Loader /></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                }
 
 
 
                                                 <div className="row status">
 
                                                 <div className="col-md-3">
                                                                 <div className="input-field">
                                                                     <label className="col-form-label">status</label>
                                                                    
                                                                     <input className="form-control contact-number" type="text" name="availability" placeholder="Status" id="example-email-input"  value={editcontent.status} disabled/>
                                                                 </div>
                                                             </div>
 
                                                     <div className="col-md-9 justify-content-end d-flex align-items-center">
 
 

                                                     </div>
                                                     {(errors.landscapeimage || errors.portraitimage) && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>Please upload a portrait or landscape image in the files tab to activate the content.</span>}
 
                                                 </div>
 
 
 
 
                                             </div>}
                                             {activeTab == "files" && filesTab()}
                                             {/* {activeTab == "seller" && sellersTab()} */}
                                             {activeTab == "combination" && combinationTab()}
                                             {/* {activeTab == "history" && historyTab()} */}

 
                                         </div>
 
                                     </div>
 
                                 </div>
 
 
                             </div>
                         </div>
 
                         {/* <Modal className="seller-pop" show={showupdateSeller} onHide={handleClosePopup}>
                              <Modal.Header closeButton>
                                  <Modal.Title>{sellerContent && sellerContent.clientcontentid ? 'Update ' : 'Add '} Seller</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>{updateSeller()}</Modal.Body>
  
                          </Modal> */}
                         <Modal className="seller-pop preview-pop" show={showPreview}>
                             <Modal.Header>
                                 <button className="close-btn" onClick={handlePreview}><span className="material-icons">close</span></button>
                                 <Modal.Title>Preview</Modal.Title>
                             </Modal.Header>
                             <Modal.Body><img src={previewImg} className="w-100" /></Modal.Body>
 
                         </Modal>
                         {/* <Modal className="seller-pop new-look-up" show={showAddLookup} onHide={setShowAddLookup}>
                              <Modal.Header closeButton>
                                  <Modal.Title>Add New lookup</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>{AddLookUp()}</Modal.Body>
  
                          </Modal> */}
                         <Footer />
                         <SweetAlert show={success}
                             custom
                             confirmBtnText="ok"
                             confirmBtnBsStyle="primary"
                             title={"Updated successfully"}
                             onConfirm={e => { setSuccess(false); setBtnLoader(false) }}
                         ></SweetAlert>
                         <SweetAlert show={subSuccess}
                             custom
                             confirmBtnText="ok"
                             confirmBtnBsStyle="primary"
                             title={"Subtitle added successfully"}
                             onConfirm={e => { setSubSuccess(false); setBtnLoader(false) }}
                         ></SweetAlert>
                         <SweetAlert show={delSuccess}
                             custom
                             confirmBtnText="ok"
                             confirmBtnBsStyle="primary"
                             title={"Image deleted successfully"}
                             onConfirm={e => { setDelSuccess(false); setBtnLoader(false) }}
                         >
                         </SweetAlert>
                         <SweetAlert show={lookupSuccess}
                             custom
                             confirmBtnText="ok"
                             confirmBtnBsStyle="primary"
                             title={"Added successfully"}
                             onConfirm={e => { setLookupSuccess(false); setBtnLoader(false) }}
                         ></SweetAlert>
                         {/* <SweetAlert show={isdelete}
                               custom
                               confirmBtnText="ok"
                               confirmBtnBsStyle="primary"
                               title={"File Deleted successfully"}
                               onConfirm={e => setDelete(false)}
                           >
                           </SweetAlert> */}
                         <SweetAlert show={AddSuccess}
                             custom
                             confirmBtnText="ok"
                             confirmBtnBsStyle="primary"
                             title={"Content details saved"}
                             onConfirm={e => { setAddSuccess(false); setBtnLoader(false); window.location.replace("/edititem/" + itemId); }}
                         >
                         </SweetAlert>
                         <SweetAlert show={uploadsuccess}
                             custom
                             confirmBtnText="ok"
                             confirmBtnBsStyle="primary"
                             title={"Image uploaded successfully"}
                             onConfirm={e => { setUploadSuccess(false); setBtnLoader(false) }}
                         >
                         </SweetAlert>
                         <SweetAlert show={delPopup}
                             custom
                             confirmBtnText="ok"
                             confirmBtnBsStyle="primary"
                             title={"Deleted successfully"}
                             onConfirm={e => { setDelPopup(false); setBtnLoader(false) }}
                         >
                         </SweetAlert>
                         <SweetAlert show={isCompExist}
                             custom
                             confirmBtnText="ok"
                             confirmBtnBsStyle="primary"
                             title={"Company already exists"}
                             onConfirm={e => { setIsCompExist(false); setBtnLoader(false) }}
                         >
                         </SweetAlert>
                         <SweetAlert show={isTitleExist}
                             custom
                             confirmBtnText="ok"
                             confirmBtnBsStyle="primary"
                             title={"This title already exists"}
                             onConfirm={e => { setIsTitleExist(false); setBtnLoader(false) }}
                         >
                         </SweetAlert>
 
                         <SweetAlert show={addComb}
                             custom
                             confirmBtnText="ok"
                             confirmBtnBsStyle="primary"
                             title={"Combination Saved SuccessFully"}
                             onConfirm={e => { setAddComb(false) }}
                         >
                         </SweetAlert>
                         <SweetAlert show={updateComb}
                             custom
                             confirmBtnText="ok"
                             confirmBtnBsStyle="primary"
                             title={"Combination updated SuccessFully"}
                             onConfirm={e => onUpdateConfirm()}
                         >
                         </SweetAlert>
 
                         <Modal className="access-denied" show={isdelete}>
 
                             <div className="modal-body enquiry-form">
                                 <div className="container">
                                     <button className="close-btn" onClick={e => onCancel()}><span className="material-icons">close</span></button>
                                     <span className="material-icons access-denied-icon">delete_outline</span>
                                     <h3>Delete</h3>
                                     <p>This action cannot be undone.</p>
                                     <p>{`Are you sure you want to delete the ${delSeller ? 'seller' : 'File'}?`}</p>
                                     <div className="popup-footer">
                                         <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => handleDelete()}>{BtnLoader ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}Yes, Delete</button>
                                     </div>
                                 </div>
                             </div>
 
                         </Modal>
                         <Modal className="access-denied" show={isdeleteImage}>
 
                             <div className="modal-body enquiry-form">
                                 <div className="container">
                                     <button className="close-btn" onClick={e => onCancelDelete()}><span className="material-icons">close</span></button>
                                     <span className="material-icons access-denied-icon">delete_outline</span>
                                     <h3>Delete</h3>
                                     <p>This action cannot be undone.</p>
                                     <p>{`Are you sure you want to delete ${item == 'portraitimage' ? 'portrait image' : 'landscape image'}?`}</p>
                                     <div className="popup-footer">
                                         <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => confirmDeleteImage()}>{BtnLoader ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}Yes, Delete</button>
                                     </div>
                                 </div>
                             </div>
 
                         </Modal>
 
                         <Modal className="access-denied" show={cancelCombinationpopup}>
 
                             <div className="modal-body enquiry-form">
                                 <div className="container">
                                     <button className="close-btn" onClick={e => setCancelCombinationPopup(false)}><span className="material-icons">close</span></button>
                                     <span className="material-icons access-denied-icon">delete_outline</span>
                                     <h3>Cancel</h3>
                                     <p>This action cannot be undone.</p>
                                     <p>Are you sure do you want cancel combination</p>
                                     <div className="popup-footer">
                                         <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => onCancelCombination()}>{BtnLoader ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}Yes, Cancel</button>
                                     </div>
                                 </div>
                             </div>
 
                         </Modal>
 
                         {showUpload &&
                             <Modal show={true} className="seller-pop new-look-up add-document" >
                                 <Modal.Header >
                                     <Modal.Title>{fileCategory === "VIDEOS" ? "Upload Video" : fileCategory === "THUMBNAILS" ? "Upload Thumbnail" : "Upload Document"}</Modal.Title>
                                     <button className="close-btn" onClick={handleClose}><span className="material-icons">close</span></button>
                                 </Modal.Header>
                                 <Modal.Body>
                                     <div className="row">
                                         <div className="col-md-12 documents">
                                             <div className="mb-3 input-field">
                                                 {/* <label className="form-label form-label">{fileCategory === "CONTENT_IMAGE" ? "Thumbnail Name" :"File Name"}</label> */}
                                                 <input name="filename" placeholder="Enter File Name" type="text" className="form-control" onChange={(e) => setFileName(e.target.value)} value={fileName} />
                                             </div>
                                             <div className="mb-3 input-field btn-gray">
                                                 {fileCategory === "DOCUMENTS" ?
                                                     <input type="file" name="upload" accept=".pdf" className="udisplay-none" id="upload"
                                                         onChange={e => uploadS3(e, ["1920*1080", "Image", "landscape_logo_image"])}
                                                         onClick={(e) => { e.target.value = ''; }} ref={ref} />
                                                     : fileCategory === "THUMBNAILS" ?
                                                         <input type="file" name="upload" accept="image/png, image/jpeg" className="udisplay-none" id="upload"
                                                             onChange={e => uploadS3(e, ["1920*1080", "Image", uploadType])}
                                                             onClick={(e) => { e.target.value = ''; }} ref={ref} />
                                                         :
                                                         <input type="file" name="upload" accept="video/*,.mkv,.avi, .divx, .f4v, .flv, .mts, .m2t, .m4v, .mkv, .mov, .mp4, .mpeg, .mpg, .mxf, .r3d, .wmv" className="udisplay-none" id="upload"
                                                             onChange={e => uploadS3(e, ["1920*1080", "Image", "landscape_logo_image"])}
                                                             onClick={(e) => { e.target.value = ''; }} ref={ref} />
                                                 }
 
                                                 {BtnLoader ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : <span className="material-icons">upload</span>}{fileCategory === "THUMBNAILS" ? "Upload Image" : fileCategory === "DOCUMENTS" ? "Upload File" : "Upload Video"}</div>
                                             {/* <p>Only pdf and word document allowed.</p> */}
                                             {fileCategory != "DOCUMENTS" && fileCategory != "THUMBNAILS" ? <p className={videoexcededfile ? "uploaderror" : ""}><span style={{ color: "red", fontSize: "16px" }}>*</span>Please upload file size less than 1.2 GB</p> : (fileCategory === "THUMBNAILS") || (fileCategory === "DOCUMENTS") ? <p className={excededfile ? "uploaderror" : ""}><span style={{ color: "red", fontSize: "16px" }}>*</span>Please upload file size less than 10MB</p> : ""}
                                             {/* <a className="btn btn-primary">done</a> */}
                                         </div>
                                     </div>
                                 </Modal.Body>
                             </Modal>
 
 
                         }
                         {combinationpopup &&
                             <Modal className="advance-search search-popup create-combination-pop" show={true} onHide={handleCloseCombinationPopup} backdrop="static">
                                 <Modal.Header closeButton>
                                     <Modal.Title>create combination</Modal.Title>
                                 </Modal.Header>
                                 <Modal.Body>
                                     <div>
                                     <div className="row">
                                             <div className="col-md-12">
                                                 <div className="form-group">
                                                     <label className="form-label form-label">select regions</label>
                                                     <Select isMulti={true}
                                                         placeholder='Select regions'
                                                         isClearable={true}
                                                         onChange={(e) => handleChangeMultiSelectCategory(e, "region")}
                                                         isDisabled={countryArray && countryArray.countries && countryArray.countries.length > 0 ? true : false}
                                                         options={regionsData}
 
                                                         value={countryArray && countryArray.region && countryArray.region.length > 0 && countryArray.region?.map((eachItem) => { return { value: eachItem.value, label: eachItem.label } })}
                                                     />
                                                 </div>
                                             </div>
 
                                         </div>
                                         
                                         <p>--------Or -----------</p>
                                         <div className="row">
                                             <div className="col-md-12">
                                                 <div className="form-group">
                                                     <label className="form-label form-label">select countries</label>
                                                     <Select isMulti={true}
                                                         placeholder='Select countries'
                                                         isClearable={true}
                                                         isDisabled={countryArray && countryArray.region && countryArray.region.length > 0 ? true : false}
                                                         onChange={(e) => handleChangeMultiSelectCategory(e, "countries")}
 
                                                         options={countryData}
 
                                                         value={countryArray && countryArray.countries && countryArray.countries.length > 0 && countryArray.countries?.map((eachItem) => { return { value: eachItem.value, label: eachItem.label, buyerid: eachItem.buyerid } })}
                                                     />
                                                 </div>
                                             </div>
 
                                         </div>
                                     </div>
                                 </Modal.Body>
                                 <Modal.Footer>
 
                                     <div className="adv_footer">
                                         <button className="fill_btn yellow-gradient reset-btn" onClick={handleCloseCombinationPopup}>cancel</button>
 
                                         <button
                                             onClick={(e) => handleCreateCombination(e, countryArray)}
                                             className="fill_btn yellow-gradient float-end"
                                         >
                                             {BtnLoader ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}
                                             create
                                         </button>
 
                                         <div className="clearfix"></div>
                                     </div>
                                 </Modal.Footer>
                             </Modal>
 
                         }
                         <Modal className="advance-search search-popup bid-advance-search" show={advancedsearchpopup} onHide={handleCloseAdvacedSearchPopup} backdrop="static">
                             <Modal.Header closeButton>
                                 <Modal.Title>Advanced Search</Modal.Title>
                             </Modal.Header>
                             <Modal.Body>
                                 <div className="adv_body">
                                     <div className="adv_body-inner">
                                         <div className="row">
                                             <div className="col-md-6">
                                                 <div className="form-group">
                                                     <label className="form-label form-label">country</label>
                                                     <Select isMulti={true}
                                                       key={resetKey}
                                                         placeholder='Select countries'
                                                         isClearable={true}
                                                         onChange={(e) => handleChangeMultiSelectAdvSearch(e, "country")}
 
                                                         options={countryData}
 
                                                         value={advancedSearch && advancedSearch.country && advancedSearch.country.length > 0 && advancedSearch.country?.map((eachItem) => { return { value: eachItem, label: eachItem } })}
                                                     />
                                                 </div>
                                             </div>
                                             <div className="col-md-6">
                                                 <div className="form-group">
                                                     <label className="form-label form-label">Region</label>
                                                     <Select isMulti={true}
                                                       key={resetKey}
                                                         placeholder='Select regions'
                                                         isClearable={true}
                                                         onChange={(e) => handleChangeMultiSelectAdvSearch(e, "region")}
                                                         options={regionsData}
                                                         value={advancedSearch && advancedSearch.region && advancedSearch.region.length > 0 && advancedSearch.region?.map((eachItem) => { return { value: eachItem, label: eachItem } })}
                                                     />
                                                 </div>
                                             </div>
                                             <div className="col-md-6">
                                                 <div className="form-group">
                                                     <label className="form-label form-label">type of rights</label>
                                                     {/* <select name="typesofrights" className="colorselect capitalize form-control" onChange={(e) => handleadvSearchChange(e, "typesofrights")} value={advancedSearch.typesofrights}>
                                                         <option value="">Select types of rights</option>
                                                         {
                                                             typesofrights && typesofrights.map((item, index) => <option key={index} value={item}>{item.name}</option>)
                                                         }
 
                                                     </select> */}
                                                     <Select isMulti={false}
                                                       key={resetKey}
                                                         placeholder='Select types of rights'
                                                         isClearable={true}
                                                         onChange={(e) => handleChangeMultiSelectAdvSearch(e, "typesofrights")}
                                                         options={typesofrights && typesofrights.map((eachItem) => { return { value: eachItem.name, label: eachItem.name, revenuetype: eachItem.revenue } })}
                                                         value={advancedSearch && advancedSearch.typesofrights && advancedSearch.typesofrights.length > 0 && advancedSearch.typesofrights.split(",")?.map((eachItem) => { return { value: eachItem, label: eachItem } })}
                                                     />
                                                 </div>
                                             </div>
                                             <div className="col-md-6">
 
                                                 <div className="form-group">
                                                     <label htmlFor="example-text-input" className="col-form-label">revenue type</label>
 
                                                     {/* <input
                                                         type="text"
                                                         className="form-control for-clear"
                                                         id="example-email-input"
                                                         placeholder="revenue type"
                                                         name="revenuetype"
                                                         onChange={(e) => handleadvSearchChange(e)} value={advancedSearch.revenuetype === undefined ? "" : advancedSearch.revenuetype}
                                                     /> */}
                                                       <Select isMulti={true}
                                                         key={resetKey}
                                                         placeholder='Select revenue type'
                                                         isClearable={true}
                                                         onChange={(e) => handleChangeMultiSelectAdvSearch(e, "revenuetype")}
                                                         // options={typesofrights && typesofrights.map((eachItem) => { return { value: eachItem.name, label: eachItem.name, revenuetype: eachItem.revenue } })}
                                                         value={advancedSearch && advancedSearch.revenuetype && advancedSearch.revenuetype.length > 0 && advancedSearch.revenuetype?.map((eachItem) => { return { value: eachItem, label: eachItem } })}
                                                     />
 
                                                 </div>
                                             </div>
 
                                             <div className="col-md-6">
 
                                                 <div className="form-group">
                                                     <label htmlFor="example-text-input" className="col-form-label">exclusivity</label>
 
                                                     {/* <input
                                                         type="text"
                                                         className="form-control for-clear"
                                                         id="example-email-input"
                                                         placeholder="exclusivity"
                                                         name="exclusivity"
                                                         onChange={(e) => handleadvSearchChange(e)} value={advancedSearch.exclusivity}
                                                     /> */}
                                                     <select name="exclusivity" className="colorselect capitalize form-control" onChange={(e) => handleadvSearchChange(e, "exclusivity")} value={advancedSearch.exclusivity}>
                                                         <option value="">select exclusivity</option>
                                                         {
                                                             exclusivityData && exclusivityData.map((item, index) => <option key={index} value={item}>{item}</option>)
                                                         }
 
                                                     </select>
                                                 </div>
                                             </div>
                                             <div className="col-md-6">
                                                 <div className="form-group">
                                                     <div className="row">
                                                         <div className="col-md-6">
                                                             <label for="example-text-input" className="col-form-label">min price range</label>
                                                             <input type="number" class="form-control for-clear" id="example-email-input" placeholder="min price" name="min" onChange={(e) => handleadvSearchChange(e)} value={advancedSearch.min} />
                                                         </div>
 
                                                         <div className="col-md-6">
                                                             <label for="example-text-input" className="col-form-label text-white">max price range</label>
                                                             <input type="number" class="form-control for-clear" id="example-email-input" placeholder="max price" name="max" onChange={(e) => handleadvSearchChange(e)} value={advancedSearch.max} />
                                                         </div>
                                                     </div>
                                                 </div>
                                             </div>
 
                                             {/* <div className="col-md-6 mt-3 mb-3">
                                                 <div className="form-group">
                                                     <input className="form-check-input" type="checkbox" id="checkAll" />
                                                     <label class="form-check-label" for="checkAll"></label>
                                                     <label for="example-text-input" className="col-form-label">excluded bidded items</label>
                                                 </div>
                                             </div> */}
 
                                         </div>
                                     </div>
                                 </div>
                             </Modal.Body>
                             <Modal.Footer>
 
                                 <div className="adv_footer">
                                     <button className="fill_btn yellow-gradient reset-btn"
                                         onClick={clearAdvSearch}
                                     ><span className="material-icons-outlined">sync</span>Reset</button>
 
                                     <button
                                         onClick={(e) => handleAdvSearch(e, "click")}
                                         className={`fill_btn yellow-gradient float-end ${checkAdvObjectProperties(advancedSearch) ? "disable" : ""}`} disabled={checkAdvObjectProperties(advancedSearch)}
                                     >
                                         {BtnLoader ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}
                                         SEARCH
                                     </button>
 
                                     <div className="clearfix"></div>
                                 </div>
                             </Modal.Footer>
                         </Modal>
                     </div>
 
 
                 }
 
 
             </div>
 
         </>
     );
 };
 
 export default ViewItem;
 