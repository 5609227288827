/***
**Module Name: bidsDashboard
 **File Name :  Bidsdashboard.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains bids dashboard table details.
 ***/
import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/dashboard/footer";
import Header from "../../../components/dashboard/header";
import Sidebar from "../../../components/dashboard/sidebar";
import tmdbApi from "../../../api/tmdbApi";
import { useHistory, Link } from "react-router-dom";
import SweetAlert from 'react-bootstrap-sweetalert';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from "moment";
import Loader from "../../../components/loader";
import SessionPopup from "../../SessionPopup"
import TableLoader from "../../../components/tableLoader";
import { contentContext } from "../../../context/contentContext";
import DataTable from 'react-data-table-component';

let { appname, lambda } = window.app;


const BidsDashBoard = () => {
    let { id } = useParams();
    const history = useHistory();
    const location = useLocation();

    const [invalidContent, setInvalidContent] = useState(false);
    const [showSessionPopupup, setShowSessionPopupup] = useState(false);



    const { searchedFlag, setSearchedFlag, isLoading, setIsLoading, sortTableAlpha, arrow, userData, sortTableByDate, clientsearch, setClientSearch, currentPage, setcurrentPage, data, setData, rowsPerPage, setRowsPerPage, currentPageNew, setCurrentPage, route, setRoute, usePrevious, setActiveMenuObj, setActiveMenuId,SubRoute, setSubRoute } = useContext(contentContext)

    const validateObj = userData && userData.permissions && userData.permissions.length > 0 && userData.permissions.filter(eachItem => eachItem.menu === "Auction Management")
    const subValDashboard = validateObj && validateObj[0] && validateObj[0].submenus && validateObj[0].submenus[0].dashboard
    // console.log('subValDashboard obj',subValDashboard)
    const { state } = useLocation();
    const { search } = state || {};


    const prevRoute = usePrevious(route)
    useEffect(() => {
        if (prevRoute != undefined && prevRoute != route) {
            setCurrentPage(1)
            setRowsPerPage(15)

        }
    }, [prevRoute]);
    const prevSubRoute = usePrevious(SubRoute)
    useEffect(() => {
        if (prevSubRoute != undefined && prevSubRoute != SubRoute) {
            setCurrentPage(1)
            setRowsPerPage(15)

        }
    }, [prevSubRoute]);
    console.log("sub",SubRoute)
     console.log('prevRoute--->',prevRoute)
     console.log('currentRoute--->',route)

    const columns = [

        {
            name: 'country',
            selector: row => row.country,
            sortable: true,
        },
        {
            name: 'region',
            selector: row => row.region && row.region.length > 1 ? row.region.join(", "): row.region,
            sortable: true,
        },
        {
            name: 'Type of rights',
            selector: row => row.typesofrights,
            sortable: true,
        },
        {
            name: 'revenue type',
            selector: row => row.revenuetype,
            sortable: true,
        },
        {
            name: 'exclusivity',
            selector: row => row.exclusivity,

            sortable: true,
        },
        {
            name: 'min bid price (usd)',
            selector: row => row.minbidprice,
            //    selector: row => moment(row.login_time).utc().format("DD-MMM-YYYY HH:mm"),
            sortable: true,
        },
        {
            name: 'max bid price (usd)',
            selector: row => row.maxbidprice,
            sortable: true,
        },
        {
            name: 'last bid date',
            selector: row => row.lastbiddedon ? new Date(row.lastbiddedon).toLocaleDateString('en-IN', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
            }) : null,
            sortable: true,
        },
        {
            name: '# bidders',
            selector: row => row.bidderscount,
            sortable: true,
        },
        {
            name: 'status',
            selector: row => row.status,
            sortable: true,
        },

        {
            name: 'view',
            cell: (props) =>
                //   {
                <div className="d-flex">

                    <a onClick={(e) => handleViewBids(e, props)} className="text-success action-button"><i className="mdi mdi-eye font-size-18"></i></a>

                </div>
            //  }
            ,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerRowsChange = (newPerPage) => {
        setRowsPerPage(newPerPage);
    };


    const handleViewBids = (e, item) => {
        if (item.status === "APPROVED" || item.status === "AWARDED") {
            console.log("item",item)
            localStorage.setItem("flag", "flag");
            localStorage.setItem("item", JSON.stringify(item));
            localStorage.setItem("itemid", item.itemid);
            history.push("/viewbidsummary/" + item.combinationid);
         
        } else {
            localStorage.setItem("flag", "flag");
            localStorage.setItem("item", JSON.stringify(item));
            localStorage.setItem("itemid", item.itemid);
            history.push("/viewtitlebids/" + item.combinationid);
        }
    }




    useEffect(() => {
        if (!localStorage.getItem("token")) {
            history.push("/");
        }
       // setRoute("bidsdashboard")
        setActiveMenuId(300021)
        setActiveMenuObj({
            "Contact Management": false,
            "Client Management": false,
            "Reports": false,
            "Auction Management":true
        })
        getTitleBids();

    }, []);

    useEffect(() => {
        if (localStorage.getItem("title")) {
            setRoute("item");
            setSubRoute("titlebids")
        } else {
            setRoute("bidsdashboard");
        }

    }, [prevRoute]);


    const getTitleBids = async (e) => {
        setIsLoading(true)
        const token = localStorage.getItem("token")
        axios({
            method: 'POST',
            url: lambda + '/combinationlist/?appname=' + appname + "&token=" + token + "&isbidder=true" + "&itemid=" + id + "&userid=" + localStorage.getItem("userId"),
        })
            .then(function (response) {
                console.log("response", response);
                if (response.data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    if(response.data.result.data.length > 0){
                    setData(response.data.result.data);
                    setIsLoading(false);
                    }else{
                        setInvalidContent(true)
                    }

                }

            });

    }

    const handleBack = (e) => {
        if (id) {
            history.push({
                pathname: "/itemmanagement",
                state: { search: true }
            });
            localStorage.removeItem("title")
        } else {
            localStorage.removeItem("title")
            history.push("/itemmanagement")
        }
    }



    console.log("data", data);

    const onclickInvalid = () => {
        setInvalidContent(false)
        history.push('/itemmanagement')
    }



    const customNoRecords = () => {
        return (

            <div className="empty-state-body empty-record"  >
                <div className="empty-state__message">
                    <span className="material-icons">summarize</span>
                    <p className="form-check font-size-16">No bids were found for the searched keyword</p>
                </div> </div>
        )
    }
    const customSort = (rows, selector, direction) => {
        const sortedData = rows.slice().sort((rowA, rowB) => {
          // use the selector function to resolve your field names by passing the sort comparators
          // const aField = selector(rowA);
          const aField = String(selector(rowA) || '').toLowerCase();
          const bField = String(selector(rowB) || '').toLowerCase();
          let comparison = 0;
      
          if (aField > bField) {
            comparison = 1;
          } else if (aField < bField) {
            comparison = -1;
          }
      
          return direction === 'desc' ? comparison * -1 : comparison;
        });
      
        // Log the sorted data to the console
        // setSortedRecomData(sortedData)
        global.exportExcelData = sortedData
        // console.log('Sorted Data------>:', sortedData);
      
        return sortedData;
      };  
    return (
        <>
            {showSessionPopupup && <SessionPopup />}
            <div id="layout-wrapper">
                <Header />
                <Sidebar />

                <div className="main-content user-management clients clients-search combination bids-summary">
                <SweetAlert show={invalidContent}
                    custom
                    confirmBtnText="ok"
                    confirmBtnBsStyle="primary"
                    title={"No Bids Available"}
                    onConfirm={e => onclickInvalid()}
                >
                </SweetAlert>
                {!invalidContent &&
                    <div className="page-content">
                        <div className="container-fluid">



                            <div className="row mb-4 breadcrumb">
                                <div className="col-lg-12">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <div className="title-block">
                                                <h4 className="mb-2 card-title">item -{localStorage.getItem("title")}</h4>
                                                <div><a className="btn btn-primary" onClick={handleBack}>back</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row table-data">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row mb-2">
                                                <div className="col-sm-4">
                                                    <h5 className="mb-2 card-title">BIDS SUMMARY</h5>
                                                </div>
                                                <div className="col-sm-8">

                                                    {/* <div className="input-field">
                                                 <select className="form-select" name="companytype" placeholder="Select Type">
                                                 <option>SORT BY</option>
                                                 <option>OFFLINE</option>
                                                 </select>
                                                 </div> */}
                                                </div>
                                            </div>

                                            < DataTable
                                                // title=""
                                                columns={columns}
                                                // className="table align-middle table-nowrap table-check"
                                                keyField='_id'
                                                data={data}
                                                direction="auto"
                                                highlightOnHover
                                                fixedHeaderScrollHeight="300px"
                                                pagination
                                                responsive
                                                persistTableHead
                                                // selectableRowsVisibleOnly
                                                striped
                                                // selectableRowsHighlight
                                                // selectableRows
                                                subHeaderAlign="right"
                                                sortFunction={customSort}
                                                subHeaderWra
                                                noDataComponent={customNoRecords()}
                                                paginationTotalRows={data.length}
                                                onChangeRowsPerPage={handlePerRowsChange}
                                                onChangePage={handlePageChange}
                                                paginationPerPage={rowsPerPage}
                                                paginationDefaultPage={currentPageNew}
                                                paginationRowsPerPageOptions={[15, 25, 50, 75, 100]}
                                                paginationComponentOptions={{
                                                    rowsPerPageText: 'Per page:',
                                                    rangeSeparatorText: 'of',
                                                    noRowsPerPage: false,
                                                    selectAllRowsItem: false,
                                                    selectAllRowsItemText: 'All',
                                                }}

                                                progressPending={isLoading}
                                                progressComponent={<TableLoader />}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
}



                    <Footer />



                </div>

            </div>
        </>
    );
};

export default BidsDashBoard;
