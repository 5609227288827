/***
**Module Name: header
 **File Name :  header.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains header details.
 ***/
import React, { useState, useEffect ,useContext} from "react";
import { useHistory, Link } from "react-router-dom";
import * as Config from "../../constants/Config";
import moment from "moment";
import tmdbApi from "../../api/tmdbApi";

import { contentContext } from "../../context/contentContext";


const Header = ({fn}) => {

    const history = useHistory();
    const [image, setImg]= useState('');
    const [data, setData]= useState("");
    const [userName, setUserName]= useState([]);


    const { userData , setUserData,setShowPopup,setSelectedOptions,setMultiSelectFields ,setActiveFieldsObj,setActiveMenuObj,
        setSelectedOptionsClientName,setSearchPayload,setInitialCategoriesData1 ,toggleMenu,setToggleMenu, GetTimeActivity} = useContext(contentContext)

    const handleProfile = (e) => {
        GetTimeActivity();
        history.push("/profile");
     }

     const handleLogout = (e) => {
        GetTimeActivity();
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        localStorage.removeItem("ClientName");
        localStorage.removeItem("ClientType");
        signoutsession();
        history.push("/");
        setUserData([])
        setActiveMenuObj({
            "Client Management": false,
            "Reports": false
        })
        setInitialCategoriesData1([])
        setSelectedOptions([])
        setMultiSelectFields({dubbinglanguages:[],typeofrights:[],countryorigin:[], languages :[], typeofrights :[], genre :[], videoquality :[], certificate :[], subtitleslanguages :[], territoriesavailable :[], sport :[], musicgenre :[]})
        setActiveFieldsObj({CookingshowActive:false,seriesActive:false,SportsActive:false,LiveEventActive:false,MusicActive:false})
        setSelectedOptionsClientName([])
        setSearchPayload({})
        setShowPopup(false)
        
     }
 
// console.log('user edataaa',userData)
     useEffect(() => {
     
        if(window.site===undefined){
            setTimeout(() => {
                if (window.site && window.site.common && window.site.common.imagesCDN) {
                    setImg(window.site.common.imagesCDN)
                  }
            }, 1000);
        }
        if (window.site && window.site.common && window.site.common.imagesCDN) {
            setImg(window.site.common.imagesCDN)
          }
        
       
      }, [window.site]);

      useEffect(() => {
        // userName = localStorage.getItem("ClientName")?.split(" ");
       setUserName(userData.name?.split(" "));
         
       
      }, [ userData]);

      const signoutsession = async () => {
        GetTimeActivity();
        try {
          const response = await tmdbApi.SignOutUser({ emailId: userData.emailid });
          console.log(response);
    
        } catch {
          console.log("error");
        }
      }
      const handleRedirect = () => {
        GetTimeActivity();
        let userArr = userData.permissions

        console.log("userArr",userData)

                const obj = userArr.reduce((acc, item) => {
                    if (item.submenus) acc[item.menu] = false;
                    return acc;
                }, {});
                // console.log('objjjjjjjj--->',obj)

                let k = userData

                let permissionList = userData && userData.permissions
                let filterdList = permissionList.filter((item) => item.display == true)
                let id = filterdList && filterdList[0] && filterdList[0].menu.split(" ").join("").toLowerCase()

                history.push("/" + id);
      }

    //   let userName=[]
    //   userName = localStorage.getItem("ClientName")?.split(" ");

const handleMenuToggle = () => {
    setToggleMenu(!toggleMenu)
}

    return (
        <>

            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box">
                            <a onClick={handleRedirect} className="logo">
                                <span className="logo-sm">
                                    {image && <img src={"https://d2aif2zmoi437u.cloudfront.net/"+ Config.imgmiddle + "favicon.png?" + Config.compress} />}
                                </span>
                                <span className="logo-lg"> 
                               
                                    
                                    {image && <img src={"https://d2aif2zmoi437u.cloudfront.net/"+ Config.imgmiddle + "logo_white.png?" + Config.compress} /> || <img src={"https://d2aif2zmoi437u.cloudfront.net/orasi/admin/resources/orasiv1/images/logo_white.png?auto=compress,format"}/>}
                                </span>
                            </a>
                        </div>

                        <button type="button" className="btn btn-sm px-3 font-size-16 header-item waves-effect"
                            id="vertical-menu-btn" onClick={handleMenuToggle}>
                            <i className="fa fa-fw fa-bars"></i>
                        </button>


                        {/* <form className="app-search d-none d-lg-block">
                            <div className="position-relative">
                                <input type="text" className="form-control" placeholder="Search..." />
                                <span className="bx bx-search-alt"></span>
                            </div>
                        </form> */}


                    </div>

                    <div className="d-flex profile_section">

                        <div className="dropdown d-inline-block d-lg-none ms-2">
                            <button type="button" className="btn header-item noti-icon waves-effect"
                                id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                <i className="mdi mdi-magnify"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                                aria-labelledby="page-header-search-dropdown">

                                <form className="p-3">
                                    <div className="form-group m-0">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search ..."
                                                aria-label="Recipient's username" />
                                            <div className="input-group-append">
                                                <button className="btn btn-primary" type="submit"><i
                                                    className="mdi mdi-magnify"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="dropdown d-inline-block">
                        <label className="form-label">Login: {localStorage.getItem("loginTime")}</label>   
                            <button type="button" className="btn header-item noti-icon waves-effect"
                                id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                <i className="bx bx-bell bx-tada"></i>
                                <span className="badge bg-danger rounded-pill">3</span>
                            </button>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                                aria-labelledby="page-header-notifications-dropdown">
                                <div className="p-3">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h6 className="m-0" key="t-notifications"> Notifications </h6>
                                        </div>
                                        <div className="col-auto">
                                            <a href="#!" className="small" key="t-view-all"> View All</a>
                                        </div>
                                    </div>
                                </div>
                                <div data-simplebar style={{ maxHeight: "230px" }}>
                                    <a href="" className="text-reset notification-item">
                                        <div className="d-flex">
                                            <div className="avatar-xs me-3">
                                                <span className="avatar-title bg-primary rounded-circle font-size-16">
                                                    <i className="bx bx-cart"></i>
                                                </span>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h6 className="mb-1" key="t-your-order">Your order is placed</h6>
                                                <div className="font-size-12 text-muted">
                                                    <p className="mb-1" key="t-grammer">If several languages coalesce the
                                                        grammar</p>
                                                    <p className="mb-0"><i className="mdi mdi-clock-outline"></i> <span
                                                        key="t-min-ago">3 min ago</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="" className="text-reset notification-item">
                                        <div className="d-flex">
                                            <img src={image+Config.img + "avatar-3.jpg"}
                                                className="me-3 rounded-circle avatar-xs" alt="user-pic" />
                                            <div className="flex-grow-1">
                                                <h6 className="mb-1">James Lemire</h6>
                                                <div className="font-size-12 text-muted">
                                                    <p className="mb-1" key="t-simplified">It will seem like simplified English.
                                                    </p>
                                                    <p className="mb-0"><i className="mdi mdi-clock-outline"></i> <span
                                                        key="t-hours-ago">1 hours ago</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="" className="text-reset notification-item">
                                        <div className="d-flex">
                                            <div className="avatar-xs me-3">
                                                <span className="avatar-title bg-success rounded-circle font-size-16">
                                                    <i className="bx bx-badge-check"></i>
                                                </span>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h6 className="mb-1" key="t-shipped">Your item is shipped</h6>
                                                <div className="font-size-12 text-muted">
                                                    <p className="mb-1" key="t-grammer">If several languages coalesce the
                                                        grammar</p>
                                                    <p className="mb-0"><i className="mdi mdi-clock-outline"></i> <span
                                                        key="t-min-ago">3 min ago</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </a>

                                    <a
                                     href="" 
                                     className="text-reset notification-item">
                                        <div className="d-flex">
                                            <img src={image+Config.img + "avatar-4.jpg"}
                                                className="me-3 rounded-circle avatar-xs" alt="user-pic" />
                                            <div className="flex-grow-1">
                                                <h6 className="mb-1">Salena Layfield</h6>
                                                <div className="font-size-12 text-muted">
                                                    <p className="mb-1" key="t-occidental">As a skeptical Cambridge friend of
                                                        mine occidental.</p>
                                                    <p className="mb-0"><i className="mdi mdi-clock-outline"></i> <span
                                                        key="t-hours-ago">1 hours ago</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="p-2 border-top d-grid">
                                    <a className="btn btn-sm btn-link font-size-14 text-center" href="#">
                                        <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">View
                                            More..</span>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="dropdown d-inline-block">
                            <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown" 
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {/* <img className="rounded-circle header-profile-user" src={image + Config.imgmiddle + "avatar-1.jpg"}
                                    alt="Header Avatar" /> */}
                                       
                                            
                                       
                                       <span onClick={GetTimeActivity()} className="user-badge">{userName && userName.length > 1 ?  userName && userName.length > 0 && userName[0]?.slice(0,1)?.toUpperCase() + userName[1]?.slice(0,1)?.toUpperCase()
           : userData && userData.name?.slice(0,2)?.toUpperCase() }</span>
       

                                <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end">

                                <a className="dropdown-item" onClick={handleProfile}><i className="bx bx-user font-size-16 align-middle me-1"></i>
                                    <span key="t-profile">Profile</span></a>
                                {/* <a className="dropdown-item" href="#"><i
                                    className="bx bx-wallet font-size-16 align-middle me-1"></i> <span key="t-my-wallet">My
                                        Wallet</span></a>
                                <a className="dropdown-item d-block" href="#"><span
                                    className="badge bg-success float-end">11</span><i
                                        className="bx bx-wrench font-size-16 align-middle me-1"></i> <span
                                            key="t-settings">Settings</span></a>
                                <a className="dropdown-item" href="#"><i
                                    className="bx bx-lock-open font-size-16 align-middle me-1"></i> <span
                                        key="t-lock-screen">Lock screen</span></a> */}
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item text-danger" onClick={handleLogout}><i
                                    className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i> <span
                                        key="t-logout">Logout</span></a>
                            </div>
                        </div>

                    </div>
                </div>
            </header>


        </>
    );
};

export default Header;
